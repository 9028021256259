import { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import styles from '../FilterList.module.scss';
import CustomPopper from '../CustomPopper/CustomPopper';
import Rating from './Rating/Rating';
import CustomPopperContent from '../CustomPopper/CustomPopperContent/CustomPopperContent';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { batch } from 'react-redux';
import { setSearchResultFilter } from 'redux/searchResultFilterSlice';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import { clearMapData } from 'redux/mapSliceV2';
import useIsMobile from 'hooks/useIsMobile';
import CustomDialog from '../CustomDialog/CustomDialog';

const RatingButton = () => {
  const isMobile = useIsMobile();
  const defaultMinRating = useAppSelector(
    (state) => state.searchResultFilterSlice.minRating
  );
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useState<number>(defaultMinRating);

  const onClose = () => {
    setValue(defaultMinRating);
    setAnchorEl(null);
  };

  const onApplyClicked = () => {
    // PREVENT UPDATE IF NO CHANGED
    if (defaultMinRating === value) {
      return onClose();
    }

    batch(() => {
      setAnchorEl(null);
      dispatch(setSearchResultFilter({ minRating: value }));
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
      dispatch(clearMapData());
    });
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <button
          data-filter="rating"
          className={styles['btn']}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          data-active={defaultMinRating >= 3}
          data-open={!!anchorEl}
        >
          Rating
        </button>
        {isMobile ? (
          <CustomDialog
            open={Boolean(anchorEl)}
            isResetBtnDisabled={value === 0}
            onResetClicked={() => setValue(0)}
            onApplyClicked={onApplyClicked}
            onClose={onClose}
          >
            <CustomPopperContent title="Rating" onClose={onClose}>
              <Rating value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomDialog>
        ) : (
          <CustomPopper
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            isResetBtnDisabled={value === 0}
            onResetClicked={() => setValue(0)}
            onApplyClicked={onApplyClicked}
          >
            <CustomPopperContent title="Rating" onClose={onClose}>
              <Rating value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomPopper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default RatingButton;
