import { SPECIALIST } from 'components/DentalMap/utils/constant';

const getPractitionerName = <
  T extends {
    firstName: string;
    name: string;
    title: string;
    specialist: string;
  }
>(
  agr: T
) => {
  const { firstName, name, specialist, title } = agr;
  if (specialist === SPECIALIST.HYGIENIST) {
    return firstName;
  }

  return title ? `${title}. ${name}` : name;
};

export default getPractitionerName;
