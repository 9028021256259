import { MOBILE_OS } from "./constants/common"

export const getMobileOS = () => {
  const userAgent = navigator.userAgent

  if (/android/i.test(userAgent)) {
    return MOBILE_OS.ANDROID
  }
  
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return MOBILE_OS.IOS
  }

  return MOBILE_OS.OTHER
}