import moment from 'moment';

export const MINUTE_OF_A_BLOCK = 5;

export const convertTime = (minutes: number) =>
  moment().startOf('day').add(minutes, 'minutes').format('hh:mm A');

export const convertTimeHHmmss = (minutes: number) =>
  moment().startOf('day').add(minutes, 'minutes').format('HH:mm:ss');

export const convertTimeToMinutes = (time: string) =>
  moment.duration(time).asMinutes();

export const calculateOrdinalBlocks = (timeInMinute: number) => {
  return Math.floor(timeInMinute / MINUTE_OF_A_BLOCK);
};
