import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C2C1C1',
      light: '#C138C0',
      dark: '#8E058D',
    },
    secondary: {
      main: '#B01BAE',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiCircularProgress: {
      color: 'secondary',
    },
  },
});

export const deactiveIconColor = '#373737';

export const primaryPurple = '#b118b0';

export const white = '#ffff';

export const colors = {
  deactiveIconColor: '#373737',
  primaryPurple: '#b118b0',
  lighterPurple: '#BF48BE',
  darkerPurple: '#8C158B',
  white: '#ffff',
  main: '#B01BAE',
  mainLight: '#D78DD6',
  lightGrey: '#E8E8E8',
  lightGreyf9f9f9: '#f9f9f9',
  lightMidGrey: '#d9d9d9',
  neutralGrey: '#C2C1C1',
  borderGrey: 'rgba(203, 203, 203, 0.2)',
  autoCompleteHover: '#0000000a',
  sliderRail: '#ECECEC',
  error: '#f44336',
};

export const toastColorsError = {
  textColor: '#F60000',
  borderColor: '#FFA39E',
  backgroundColor: '#FFF1F0',
};

export const toastColorsSuccess = {
  textColor: '#1A9901',
  borderColor: '#83D972',
  backgroundColor: '#EAFDE7',
};

export const buttonColors = {
  contained: {
    backgroundColor: 'linear-gradient(206.28deg, #B01BAE 0%, #DA3F85 100%)',
    hover: {
      backgroundColor: 'linear-gradient(206.28deg, #E11EDF 0%, #D93A7D 100%)',
    },
    active: {
      backgroundColor: 'linear-gradient(206.28deg, #871286 0%, #C52668 100%)',
    },
    disabled: {
      backgroundColor: colors.neutralGrey,
    },
  },
  outlined: {
    border: '1.5px solid #B01BAE',
    hover: {
      border: '1.5px solid #BF48BE',
    },
    active: {
      border: '1.5px solid #8C158B',
    },
    disabled: {
      border: '1.5px solid #C2C1C1',
    },
  },
};

export const fontSize = {
  xs: '1.2rem',
  s: '1.4rem',
  m: '1.6rem',
  ml: '1.8rem',
  l: '3rem',
  xl: '3.6rem',
  xxl: '5.6rem',
};
