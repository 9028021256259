import moment from 'moment';
import { convertToDate } from 'utils/dates';

const getSearchQueryFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const service = searchParams.get('service');
  const date = searchParams.get('date');
  const lat = searchParams.get('lat');
  const lng = searchParams.get('lng');

  if (!service || !date || !lat || !lng) {
    return null;
  }

  return {
    service,
    date: moment(convertToDate(date)).format('YYYY-MM-DD'),
    lat: Number(lat),
    lng: Number(lng),
  };
};

export default getSearchQueryFromUrl;
