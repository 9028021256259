import { useAppSelector } from 'redux/reduxHooks';
import { capitalizeAvatarName } from 'utils/common';
import styles from './Avatar.module.scss';

const Avatar = () => {
  const { userProfile } = useAppSelector((state) => state.authSlice);

  if (!userProfile) return null;

  const avatarText = capitalizeAvatarName(
    `${userProfile.firstName.replace(/\s/g, '').trim()} ${userProfile.lastName
      .replace(/\s/g, '')
      .trim()}`
  );

  return <div className={styles['avatar']}>{avatarText}</div>;
};

export default Avatar;
