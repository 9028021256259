import React from 'react';
import Field from '../Field/Field';

import styles from './PatientContact.module.scss';

interface Props {
  name: string;
  phone: string;
  email: string;
}

const PatientContact: React.FC<Props> = ({ name, phone, email }) => {
  return <div className={styles['container']}>
    <div className={styles['title']}>Contact Details</div>
    <Field title="Full name" value={name}/>
    <Field title="Phone number" value={phone}/>
    <Field title="Email" value={email}/>
  </div>;
};

export default PatientContact;
