import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import FullCalendar, {
  CustomContentGenerator,
  DayCellContentArg,
} from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import { debounce } from 'lodash';

import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';

import styles from './MonthView.module.scss';
import { ROUTES } from 'routes/config';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface MonthViewProps {
  selectedDate: string;
  contentHeight: number;
  isRenderPast: boolean;
  weekCount?: number;
  dayCellContent?: CustomContentGenerator<DayCellContentArg>;
  dateClick?: (arg: DateClickArg) => void | undefined;
  fetchTimeSlots?: (startDate: Date, endDate: Date) => void;
  practitionerId?: string | null;
  serviceId?: string | null;
}

const MonthView: FC<MonthViewProps> = (props) => {
  const {
    contentHeight,
    isRenderPast,
    selectedDate,
    weekCount,
    dateClick,
    dayCellContent,
    fetchTimeSlots,
    practitionerId,
    serviceId,
  } = props;

  const [isDateClick, setIsDateClick] = useState(false);

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const calendarRef = useRef<FullCalendar | null>(null);

  const location = useLocation();

  const fetchDebounce = useMemo(() => {
    return debounce((start, end) => {
      if (fetchTimeSlots) {
        fetchTimeSlots(start, end);
      }
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionerId, serviceId]);

  const isSearchResultPage = location.pathname === ROUTES.RESULT_PAGE;

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi || isDateClick) return;

    const parsedDate = selectedDate
      ? moment(selectedDate, 'YYYY-MM-DD').toDate()
      : new Date();

    calendarApi.gotoDate(parsedDate);

    const calendarData = calendarApi.getCurrentData();
    const renderDateRange = calendarData.dateProfile.renderRange;

    fetchDebounce(renderDateRange.start, renderDateRange.end);
  }, [selectedDate, isDateClick, fetchDebounce]);

  const views = useMemo(() => {
    return weekCount
      ? {
          dayGridMonth: {
            type: 'dayGridMonth',
            duration: { weeks: weekCount },
          },
        }
      : undefined;
  }, [weekCount]);

  return (
    <div
      className={styles.wrapper}
      id="month-view-search"
      data-highlight-current-date={`${fetchTimeSlots ? true : false}`}
    >
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        views={views}
        contentHeight={contentHeight}
        fixedWeekCount={weekCount ? false : true}
        firstDay={
          isRenderPast ? undefined : moment(selectedDate, 'YYYY-MM-DD').days()
        }
        initialDate={selectedDate}
        initialView="dayGridMonth"
        showNonCurrentDates
        headerToolbar={false}
        dayHeaderClassNames={
          isSearchResultPage || isLoadedInsideIframe
            ? 'calendar-header'
            : 'calendar-header-detail-page'
        }
        dayCellClassNames="calendar-cell"
        dayCellContent={dayCellContent}
        dateClick={(props) => {
          setIsDateClick(true);
          if (dateClick) {
            dateClick(props);
          }
        }}
      />
    </div>
  );
};

export default MonthView;
