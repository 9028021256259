import { MINUTE_OF_A_BLOCK } from 'utils/convertMinutesIntoHoursOfDay';

const getPractitionerSlotPer30Minutes = (
  data: {
    date: string;
    blocks: number[];
  }[]
) => {
  const step = 25 / MINUTE_OF_A_BLOCK; // 25 minutes

  return data.map((d) => {
    const blocksPer30Minutes = [];
    for (let index = 0; index < d.blocks.length; index++) {
      if (index === 0) {
        blocksPer30Minutes.push(d.blocks[0]);
        continue;
      }
      const lastBlocksPer30Minutes =
        blocksPer30Minutes[blocksPer30Minutes.length - 1];

      if (d.blocks[index] > lastBlocksPer30Minutes + step) {
        blocksPer30Minutes.push(d.blocks[index]);
        continue;
      }
    }
    return { date: d.date, blocks: blocksPer30Minutes };
  });
};

export default getPractitionerSlotPer30Minutes;
