import React from 'react';

import styles from './Field.module.scss';

interface Props {
  title: string;
  value: string;
}

const Field: React.FC<Props> = ({ title, value }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>{title}</div>
      <div className={styles['value']}>{value}</div>
    </div>
  );
};

export default Field;
