import React from 'react';

import { ReactComponent as Marker } from 'assets/icons/marker-active.svg';

import styles from './MarkerActive.module.scss';

interface Props {
  practitionerQuantity: number;
}

export type MarkerActiveComponent = React.FC<Props>;

const MarkerActive: MarkerActiveComponent = (props) => {
  const { practitionerQuantity } = props;
  return (
    <div className={styles['container']}>
      <Marker />
      {practitionerQuantity > 0 && (
        <span className={styles['badge']}>{practitionerQuantity}</span>
      )}
    </div>
  );
};

export default MarkerActive;
