import { IFilterState } from 'interfaces/filterTypes';
import { SocialData } from 'redux/authSlice';

const FILTER_KEY = 'filters';
const TOKEN_KEY = 'token';
const OTP_TOKEN_KEY = 'otp-token';
const SOCIAL_USER_DATA = 'socialUserData';
const PREVIOUS_URL = 'previousUrl';
const SESSION_TOKEN = 'sessionLocation';

interface CachedOtpAndPhoneNumber {
  phoneNumber: string;
  otpToken: string;
}

export const saveFilters = (filters: IFilterState) => {
  localStorage.setItem(FILTER_KEY, JSON.stringify(filters));
};

export const loadFilters = () => {
  const stringifyFilters = localStorage.getItem(FILTER_KEY);

  if (stringifyFilters) {
    return JSON.parse(stringifyFilters);
  }

  return null;
};

export const saveAuthToken = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  localStorage.setItem(
    TOKEN_KEY,
    JSON.stringify({ accessToken, refreshToken })
  );
};

export const loadAuthToken = (): {
  accessToken: string;
  refreshToken: string;
} | null => {
  const stringifyToken = localStorage.getItem(TOKEN_KEY);

  if (stringifyToken) {
    return JSON.parse(stringifyToken);
  }

  return null;
};

export const removeAuthToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const saveSocialUserData = (data: any) => {
  sessionStorage.setItem(SOCIAL_USER_DATA, JSON.stringify(data));
};

export const loadSocialUserData = (): {
  socialData: SocialData;
  sysType: string;
} | null => {
  const stringifySocialUserData = sessionStorage.getItem(SOCIAL_USER_DATA);
  if (stringifySocialUserData) {
    return JSON.parse(stringifySocialUserData);
  }

  return null;
};

export const removeSocialUserData = () => {
  sessionStorage.removeItem(SOCIAL_USER_DATA);
};

export const savePreviousUrl = (url: string) => {
  sessionStorage.setItem(PREVIOUS_URL, url);
};

export const loadPreviousUrl = () => {
  return sessionStorage.getItem(PREVIOUS_URL);
};

export const saveOtpToken = (otpToken: string, phoneNumber: string) => {
  const cachedOtpToken = localStorage.getItem(OTP_TOKEN_KEY);

  if (!cachedOtpToken) {
    const cachedOtpToken = [{ otpToken, phoneNumber }];
    return localStorage.setItem(OTP_TOKEN_KEY, JSON.stringify(cachedOtpToken));
  }

  const parsedCachedOtpToken = JSON.parse(
    cachedOtpToken
  ) as CachedOtpAndPhoneNumber[];

  const indexOfExistedPhoneNumber = parsedCachedOtpToken.findIndex(
    (item) => item.phoneNumber === phoneNumber
  );

  if (indexOfExistedPhoneNumber === -1) {
    parsedCachedOtpToken.push({ otpToken, phoneNumber });
  } else {
    parsedCachedOtpToken[indexOfExistedPhoneNumber].otpToken = otpToken;
  }

  localStorage.setItem(OTP_TOKEN_KEY, JSON.stringify(parsedCachedOtpToken));
};

export const loadOtpToken = (phoneNumber?: string) => {
  if (!phoneNumber || !localStorage.getItem(OTP_TOKEN_KEY)) return '';

  const cachedOtpToken = localStorage.getItem(OTP_TOKEN_KEY)!;

  const parsedCachedOtpToken = JSON.parse(
    cachedOtpToken
  ) as CachedOtpAndPhoneNumber[];

  const cachedOtpAndPhoneNumber = parsedCachedOtpToken.find(
    (item) => item.phoneNumber === phoneNumber
  );

  return cachedOtpAndPhoneNumber ? cachedOtpAndPhoneNumber.otpToken : '';
};

export const removeOtpToken = () => {
  localStorage.removeItem(OTP_TOKEN_KEY);
};

export const saveSessionLocation = (data: any) => {
  sessionStorage.setItem(SESSION_TOKEN, JSON.stringify(data));
};

export const getSessionLocation = () => {
  const sessionLocation = sessionStorage.getItem(SESSION_TOKEN);

  if (!sessionLocation) return null;

  const parseSessionLocation = JSON.parse(sessionLocation);

  return {
    placeId: parseSessionLocation.placeId,
    name: parseSessionLocation.name,
    lat: parseSessionLocation.lat,
    lng: parseSessionLocation.lng,
    types: parseSessionLocation.types,
  };
};
