import classNames from 'classnames';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import styles from './HelperText.module.scss';

interface Props {
  error: string;
}

export const HelperText: React.FC<Props> = (props) => {
  const helperTextClassNames = classNames({
    [styles['helper-text']]: true,
    [styles['visible']]: props.error !== '',
  });

  return (
    <p className={helperTextClassNames}>
      <ErrorIcon className={styles['helper-text_icon']} />
      <span>{props.error}</span>
    </p>
  );
};

export const Asterix: React.FC<Props> = (props: Props) => {
  const asterixClassNames = classNames({
    [styles['helper-text']]: true,
    [styles['asterix']]: true,
    [styles['visible']]: props.error !== '',
  });

  return <span className={asterixClassNames}>*</span>;
};
