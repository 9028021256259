import React, { FC } from 'react';
import { ReactComponent as GoogleIcon } from 'assets/icons/google-icon.svg';
import styles from './SocialButton.module.scss';
import { GoogleAuthProvider, UserCredential } from 'firebase/auth';

import {
  AUTH_SYS_TYPE,
  GOOGLE_ADD_BIRTHDAY_READ,
  GOOGLE_ADD_EMAIL_READ,
  GOOGLE_ADD_PROFILE_READ,
} from 'utils/constants/common';
import { signInGoogleWithPopUp } from '../utils/getOAuthData';

interface GoogleButtonProps {
  onSuccess: (userCredential: UserCredential, sysType: string) => void;
}

const GoogleButton: FC<GoogleButtonProps> = ({ onSuccess }) => {
  const handleSignInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope(GOOGLE_ADD_EMAIL_READ);
      provider.addScope(GOOGLE_ADD_PROFILE_READ);
      provider.addScope(GOOGLE_ADD_BIRTHDAY_READ);
      provider.setCustomParameters({ prompt: 'select_account' });
      const result = await signInGoogleWithPopUp(provider);
      onSuccess(result, AUTH_SYS_TYPE.GOOGLE);
    } catch (error: any) {}
  };

  return (
    <button
      className={styles['social-icon']}
      type="button"
      onClick={handleSignInWithGoogle}
    >
      <GoogleIcon />
    </button>
  );
};

export default GoogleButton;
