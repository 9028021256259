import _ from 'lodash';
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  ALLOWED_PRACTITIONER_STUDIES,
  KILOMETER_IN_METER,
} from 'utils/constants/common';
import { IFilterState } from 'interfaces/filterTypes';
import { IClinicListDataV2 } from './clinicsSearchResultsSliceV2';
import getTimeBlocksData from 'utils/getTimeBlocksData';
import { Specialty } from 'interfaces/specialty';

interface MetaData {
  limit: number;
  page: number;
  total: number;
}

interface IAxiosClinicResponseData {
  metadata: MetaData;
  data: ClinicResponseData[];
}

interface ClinicResponseData extends IClinicListDataV2 {
  location: {
    city: string;
    state: string;
  };
  phoneNumber: string;
}

export interface IMapClinicData {
  id: string;
  name: string;
  avatar: string;
  totalScore: number;
  reviewCount: number;
  address: string;
  distance: number;
  city: string;
  state: string;
  phone: string;
  website: string;
  services: { id: string; name: string }[];
  practitionerIds: string[];
  workingHours: {
    day: string;
    time: string;
  }[];
  coordinates: [number, number];
}

export interface IMapClinics {
  ids: string[];
  data: {
    [key: string]: IMapClinicData;
  };
}

export interface IMapPractitioner {
  name: string;
  avatar: string;
  totalScore: number;
  reviewCount: number;
  study: string[];
  specialty: Specialty[];
  id: string;
  practice: string;
  address: string;
  clinic: string;
  clinicId: string;
  phone: string;
  distance: number;
  specialist: string;
  firstName: string;
  title: string;
}

interface IMapPractitioners {
  [key: string]: IMapPractitioner;
}

export interface IMapSliceState {
  clinics: IMapClinics;
  practitioners: IMapPractitioners;
  status: 'loading' | 'success' | 'failure';
}

interface Props {
  dates: string[];
  searchDate?: string;
  currentDate: string;
  lat: number;
  lng: number;
  serviceId: string;
  minRating: number;
  gender: ('male' | 'female' | 'non-binary')[];
  timeBlocks: number[][];
}

const MAX_RATING = 5;
const MAX_DISTANCE_IN_METER = 20000;

export const getMapClinicsAndPractitioners = createAsyncThunk(
  'mapSlice2/getMapClinicsAndPractitioners',
  async (
    {
      serviceId,
      lat,
      lng,
      dates,
      searchDate,
      currentDate,
      gender,
      minRating,
      timeBlocks,
    }: Props,
    { getState }
  ) => {
    const { filterSlice } = getState() as {
      filterSlice: IFilterState;
    };

    const clinics: IMapClinics = {
      ids: [],
      data: {},
    };
    const practitioners: IMapPractitioners = {};

    const locationTimezone = filterSlice.location.timezone;

    const distances = [0, MAX_DISTANCE_IN_METER];

    const clinicResponseData = await axios.get<IAxiosClinicResponseData>(
      '/clinics/weekly-timeslots',
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          dates,
          searchDate,
          currentDate,
          timeBlocks: getTimeBlocksData(timeBlocks),
          latitude: lat,
          longitude: lng,
          distances,
          serviceId: serviceId,
          rating: [minRating, MAX_RATING],
          includedNonRated: minRating === 0,
          genders: gender,
          timezone: locationTimezone,
        },
      }
    );

    clinicResponseData.data.data.forEach((item) => {
      const distance = _.round(item.distance / KILOMETER_IN_METER, 1);

      item.doctors.forEach((doctor: any) => {
        practitioners[doctor.id] = {
          id: doctor.id,
          name: doctor.name,
          avatar: doctor.avatar,
          totalScore: doctor.rating,
          reviewCount: doctor.reviewCount,
          practice: doctor.practice,
          address: item.address,
          clinic: item.name,
          clinicId: item.id,
          phone: item.phoneNumber,
          distance,
          study: doctor.studies
            ? doctor.studies
                .map((item: any) => item.name)
                .filter((study: any) =>
                  ALLOWED_PRACTITIONER_STUDIES.includes(study)
                )
            : [],
          specialty:
            doctor.specialties && Array.isArray(doctor.specialties)
              ? doctor.specialties
              : [],
          firstName: doctor.firstName,
          specialist: doctor.specialist,
          title: doctor.title,
        };
      });

      clinics.ids.push(item.id);

      clinics.data[item.id] = {
        id: item.id,
        name: item.name,
        avatar: item.avatar,
        address: item.address,
        city: item.location.city,
        distance,
        state: item.location.state,
        services: item.services.map((item: any) => ({
          id: item.id,
          name: item.name,
        })),
        phone: item.phoneNumber,
        workingHours: item.workingHours,
        reviewCount: item.reviewCount,
        totalScore: item.rating,
        website: item.website,
        practitionerIds: item.doctors.map((doctor) => doctor.id),
        coordinates: [item.latitude, item.longitude],
      } as IMapClinicData;
    });

    return { clinics, practitioners };
  }
);

const initialState: IMapSliceState = {
  clinics: {
    ids: [],
    data: {},
  },
  practitioners: {},

  status: 'loading',
};

const mapSlice2 = createSlice({
  name: 'mapSlice2',
  initialState,
  reducers: {
    clearMapData: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMapClinicsAndPractitioners.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: 'success',
          ...action.payload,
        };
      }
    );
    builder.addCase(getMapClinicsAndPractitioners.pending, () => {
      return {
        ...initialState,
      };
    });
  },
});

export const { clearMapData } = mapSlice2.actions;
export default mapSlice2.reducer;
