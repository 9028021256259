import { ITimeSlot } from 'interfaces/timeslotType';
import { MINUTE_OF_A_BLOCK } from './convertMinutesIntoHoursOfDay';
import getIntervalStep from './getIntervalStep';

export const getSlotsPer30MinutesForAvailability = (
  timeSlots?: ITimeSlot[],
  slotInterval?: number
) => {
  if (!timeSlots) return [];

  return timeSlots.map((timeSlot) => {
    let blocksPerDuration: number[] = [];
    for (let index = 0; index < timeSlot.blocks.length; index++) {
      if (index === 0) {
        blocksPerDuration.push(timeSlot.blocks[0]);
        continue;
      }
      const lastBlocksPerDuration =
        blocksPerDuration[blocksPerDuration.length - 1];

      const step = getIntervalStep(slotInterval);

      if (timeSlot.blocks[index] > lastBlocksPerDuration + step) {
        blocksPerDuration.push(timeSlot.blocks[index]);
        continue;
      }
    }

    return { ...timeSlot, blocks: blocksPerDuration };
  });
};

export const getSlotsPer30MinutesForCard = <T extends { value: number }>(
  timeSlots?: T[]
) => {
  if (!timeSlots) return [];

  const blocksPer30Minutes = [];
  for (let index = 0; index < timeSlots.length; index++) {
    if (index === 0) {
      blocksPer30Minutes.push(timeSlots[0]);
      continue;
    }
    const lastBlocksPer30Minutes =
      blocksPer30Minutes[blocksPer30Minutes.length - 1];

    const step = 25 / MINUTE_OF_A_BLOCK; // 25 minutes

    if (timeSlots[index].value > lastBlocksPer30Minutes.value + step) {
      blocksPer30Minutes.push(timeSlots[index]);
      continue;
    }
  }
  return blocksPer30Minutes;
};
