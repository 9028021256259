import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';

import parse from 'autosuggest-highlight/parse';

export const renderTextOption = (
  option: any,
  state: AutocompleteRenderOptionState,
  isHightlighting: boolean,
  classes: ClassNameMap<string>
) => {
  const firstCharacterIndex = option.title
    .toLocaleLowerCase()
    .indexOf(state.inputValue.toLowerCase());
  const lastCharacterIndex = firstCharacterIndex + state.inputValue.length;
  const parts = isHightlighting
    ? parse(option.title, [[firstCharacterIndex, lastCharacterIndex]])
    : parse(option.title, [[0, option.title.length - 1]]);

  return (
    <div>
      {isHightlighting ? (
        parts.map((part, index) => (
          <span
            key={index}
            className={
              part.highlight && isHightlighting ? classes.highlightText : ''
            }
          >
            {part.text}
          </span>
        ))
      ) : (
        <span>{option.title}</span>
      )}
    </div>
  );
};
