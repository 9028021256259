import React, { useRef } from 'react';
import moment from 'moment';
import { FormControlLabel } from '@material-ui/core';
import cx from 'classnames';
import { CountryCode } from 'libphonenumber-js';

import CustomRadio from './CustomRadio/CustomRadio';
import styles from './PatientCard.module.scss';
import { NULL_PATIENT_ID, NewPatient } from '../Step3';
import { capitalizeAvatarName, concatFullName } from 'utils/common';
import {
  formatPhoneNumberWithCountryCode,
  geCountryByPhoneNumber,
} from 'utils/formatPhoneNumber';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface IPatient extends NewPatient {
  id: string;
}

interface PatientCardProps {
  isMultipleResult?: boolean;
  patient: IPatient;
  chosenPatientId: string;
}

const userInfo = [
  { label: 'Date of birth', property: 'dob' },
  { label: 'Phone Number', property: 'phoneNumber' },
  { label: 'Email', property: 'email' },
];

const PatientCard = (props: PatientCardProps) => {
  const { isMultipleResult = false, patient, chosenPatientId } = props;
  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const radioInputRef = useRef<HTMLLabelElement>();

  // Suggest another patient if patient has the same info but different dob
  const isPatientIdNullIncluded =
    !patient.id && chosenPatientId === NULL_PATIENT_ID;

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['active']]:
      isMultipleResult &&
      (chosenPatientId === patient.id || isPatientIdNullIncluded),
    [styles['hover-cursor']]:
      isMultipleResult &&
      (chosenPatientId !== patient.id || isPatientIdNullIncluded),
  });

  const leftSectionClassName = cx({
    [styles['left-section']]: true,
    [styles['left-section-iframe']]: isLoadedInsideIframe,
  });

  const avatarText = capitalizeAvatarName(
    `${patient.firstName.replace(/\s/g, '').trim()} ${patient.lastName
      .replace(/\s/g, '')
      .trim()}`
  );

  const renderText = (userInfo: { label: string; property: string }) => {
    const property = userInfo.property as keyof IPatient;
    let value = patient[property];

    if (property === 'dob') {
      value = moment(patient[property]).format('MMM DD, YYYY');
    }

    if (property === 'phoneNumber') {
      const countryCode = geCountryByPhoneNumber(patient[property]);
      value = countryCode
        ? formatPhoneNumberWithCountryCode(
            patient[property],
            countryCode as CountryCode
          )
        : patient[property];
    }

    return value;
  };

  const handleOnClickContainer = () => {
    if (!isMultipleResult) return;
    radioInputRef.current?.click();
  };

  return (
    <div className={containerClassName} onClick={handleOnClickContainer}>
      {isMultipleResult && (
        <FormControlLabel
          ref={radioInputRef}
          label=""
          control={<CustomRadio />}
          className={styles['control-radio']}
          value={patient.id || NULL_PATIENT_ID}
        />
      )}
      <div className={leftSectionClassName}>
        <div className={styles['avatar']}>{avatarText}</div>
        <div className={styles['full-name']}>
          {concatFullName(patient.firstName, patient.lastName)}
        </div>
      </div>
      <div className={styles['right-section']}>
        {userInfo.map((userInfo) => {
          const property = userInfo.property as keyof IPatient;
          if (!patient[property]) {
            return null;
          }

          return (
            <div key={userInfo.label} className={styles['user-info']}>
              <span className={styles['user-detail']}>{userInfo.label}: </span>
              <span className={styles['user-data']}>
                {renderText(userInfo)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PatientCard;
