import { makeStyles } from '@material-ui/core/styles';
import { fontSize, colors } from 'utils/theme';

const useStyles = (isSubField: boolean | undefined) =>
  makeStyles({
    input: {
      '& .MuiInputBase-root': {
        fontFamily: 'Nexa',
        fontSize: fontSize.s,
        lineHeight: '2.1rem',
        backgroundColor: colors.white,
        borderColor: colors.neutralGrey,
        width: isSubField ? '10rem' : '',
      },
      '& .MuiAutocomplete-endAdornment': {
        padding: '0.7rem 1.6rem 0 0',
      },
      '& .MuiInput-formControl': {
        margin: '1rem 0 0.8rem 0',
        paddingLeft: '1.4rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.5px solid transparent',
        boxShadow: '0px 0px 5px 2px rgba(220, 220, 220, 0.5)',
        borderRadius: '8px',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        boxShadow: '0px 0px 5px 2px rgba(176, 27, 174, 0.2)',
        border: '0.5px solid #B01BAE',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        boxShadow: '0px 0px 5px 2px rgba(176, 27, 174, 0.2)',
        border: '0.5px solid #B01BAE',
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(245, 34, 45, 1)',
        boxShadow: '0px 0px 5px 2px rgba(220, 220, 220, 0.5)',
        border: '1px solid #uF5222D',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'Nexa',
        fontSize: fontSize.xs,
        lineHeight: '1.8rem',
      },
      '& .MuiFormHelperText-contained': {
        marginLeft: 0,
      },
    },
    icon: {
      cursor: 'pointer',
    },
    highlightText: {
      color: colors.primaryPurple,
    },
    dateFieldWrapperWithoutHelperText: {
      position: 'relative',
      height: '5.3rem',
    },
    dateFieldWrapperWithHelperText: {
      position: 'relative',
      height: '7.7rem',
    },
    datePickerInput: {
      position: 'absolute',
      width: '100%',
      pointerEvents: 'auto',
    },
    datePickerTextDisplayer: {
      position: 'absolute',
      width: '100%',
      pointerEvents: 'none',
    },
    button: {
      '&.MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },
    },
  })();

export default useStyles;
