/* eslint-disable no-useless-escape */
export const fullNameValidation = (inputString: string) => {
  const regEx = new RegExp('^[a-zA-Z ]*$');
  return regEx.test(inputString);
};

export const phoneNumberValidation = (phoneNumber: string) => {
  const regEx = new RegExp('^[+ 0-9]*$');
  return regEx.test(phoneNumber);
};

export const emailValidation = (email: string) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
};

export const monthValidation = (input: string) => {
  const regEx = new RegExp('^[+ 0-9]*$');
  return regEx.test(input) && input.length <= 2 && Number(input) <= 12;
};

export const dayValidation = (input: string) => {
  const regEx = new RegExp('^[+ 0-9]*$');
  return regEx.test(input) && input.length <= 2 && Number(input) <= 31;
};

export const yearValidation = (year: string) => {
  const regEx = new RegExp('^[+ 0-9]*$');
  return regEx.test(year) && year.length === 4;
};

export const passwordValidation = (password: string) => {
  return /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(password);
};

export const firstNameValidation = (firstName: string) => {
  const words = firstName.split(' ');

  const lastWord = words.slice(-1)[0];

  const isIncludeOneLetter = words.length > 1 && lastWord.length === 1;

  if (firstName && firstName.length > 15) {
    return 'First name should not exceed 15 characters';
  }

  if (firstName && isIncludeOneLetter) {
    return 'Middle name should be in full instead of initial';
  }

  return '';
};

export const lastNameValidation = (lastName: string) => {
  if (lastName && lastName.length > 20) {
    return 'Last name should not exceed 20 characters';
  }

  return '';
};
