import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import cx from 'classnames';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { SearchField } from 'components/SearchField';
import { Divider } from 'components/DentalDivider';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import { searchFields } from 'utils/searchBarOptions';
import { useAppSelector } from 'redux/reduxHooks';

import styles from './SearchBar.module.scss';

import useIsMobile from 'hooks/useIsMobile';
import { MOBILE_OS } from 'utils/constants/common';
import { getMobileOS } from 'utils/getMobileOS';

interface ISearchBarProps {
  handleOnClickSearch?: (
    selectedFields: ISearchBarFields,
    place: {
      formatted_address: string;
      geometry: {
        location: {
          lat: () => number;
          lng: () => number;
        };
      };
    }
  ) => void;
  isResultPage?: boolean;
  currentDate?: string;
}

interface ISearchBarFields {
  service: string;
  date: string;
  location: string;
}

function SearchBar({
  isResultPage,
  currentDate = '',
}: RouteComponentProps & ISearchBarProps) {
  const filters = useAppSelector((state) => state.filterSlice);
  const [selectedFields, setSelectedFields] = useState<ISearchBarFields>({
    ...filters,
    location: filters.location.name,
  });

  const [isEmptyFields, setIsEmptyFields] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (
      selectedFields.service &&
      selectedFields.date &&
      selectedFields.location
    ) {
      setIsEmptyFields(false);
    }
  }, [selectedFields]);

  useEffect(() => {
    setSelectedFields({ ...filters, location: filters.location.placeId });
  }, [filters]);

  const onSelectionChanged = (key: string, value: string) => {
    setSelectedFields({
      ...selectedFields,
      [key]: value,
    });
  };

  const onScrollToElement = (elementName: string, index: number) => {
    if (isMobile) {
      const extraHeight = -80;

      let headerHeight = index === 1 ? -80 : extraHeight;

      const mobileOS = getMobileOS();

      if (mobileOS === MOBILE_OS.ANDROID || mobileOS === MOBILE_OS.OTHER) {
        headerHeight =
          -(document.getElementById('main-header')?.offsetHeight || 0) +
          extraHeight;
      }

      Scroll.scroller.scrollTo(elementName, {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: headerHeight,
      });
    }
  };

  const searchBarContainerClassName = cx({
    [styles.container]: true,
    [styles['box-shadow-container']]: !isResultPage || !isMobile,
  });

  return (
    <div className={searchBarContainerClassName}>
      {searchFields.map((item, index) => (
        <Scroll.Element name={`search-field-${index}`} key={`scroll-${index}`}>
          <div
            className={styles['container-search-field']}
            key={item.title}
            onClick={(event) => {
              onScrollToElement(`search-field-${index}`, index);
            }}
          >
            <SearchField
              title={item.title}
              icon={item.icon}
              onSelectionChanged={onSelectionChanged}
              inputComponent={item.inputComponent}
              error={
                isEmptyFields && !item.validation(selectedFields[item.title])
              }
              placeHolder={item.placeHolder}
              isResultPage={isResultPage}
              currentDate={currentDate}
              onClose={() => {
                Scroll.animateScroll.scrollToTop({
                  duration: 500,
                  delay: 10,
                  smooth: true,
                });
              }}
              isLocationFieldHadValue={!!selectedFields.location}
            />
            {index !== 2 && (
              <Divider
                orientation={isMobile ? 'horizontal' : 'vertical'}
                width={isMobile ? 28.7 : null}
                height={isMobile ? null : 3.4}
              />
            )}
          </div>
        </Scroll.Element>
      ))}
      <DentalButton className={styles.button} variant="contained">
        <SearchIcon className={styles['button-label']} />
        <span className={styles['button-label-mobile']}>Search</span>
      </DentalButton>
    </div>
  );
}

export default withRouter(SearchBar);
