import React from 'react';
import { Rating } from '@material-ui/lab';

import { ReactComponent as LeftArrow } from 'assets/icons/left-darker.svg';
import { ReactComponent as RightArrow } from 'assets/icons/right-darker.svg';
import { ReactComponent as Dot } from 'assets/icons/dot.svg';

import styles from './PractitionerInfo.module.scss';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { Link } from 'react-router-dom';
import useIsMobile from 'hooks/useIsMobile';
import { Specialty } from 'interfaces/specialty';
import { filterSpecialties } from 'utils/common';

interface Props {
  avatar: string;
  name: string;
  specialty: Specialty[];
  averageScore: number;
  reviewQuantity: number;
  currPage: number;
  totalPage: number;
  id: string;
  onPageChange: (page: number) => void;
  onViewPractitionerAvailability: (id: string) => void;
}

export type PractitionerInfoComponent = React.FC<Props>;

const PractitionerInfo: PractitionerInfoComponent = (props) => {
  const {
    id,
    name,
    avatar,
    currPage,
    totalPage,
    specialty,
    averageScore,
    onPageChange,
    onViewPractitionerAvailability,
  } = props;

  const isMobile = useIsMobile();

  const handleClickPrevious = () => {
    if (currPage > 1) {
      onPageChange(currPage - 1);
    } else {
      onPageChange(totalPage);
    }
  };

  const handleClickNext = () => {
    if (currPage < totalPage) {
      onPageChange(currPage + 1);
    } else {
      onPageChange(1);
    }
  };

  const handleClickRequestAppointment = () => {
    onViewPractitionerAvailability(id);
  };

  const renderSpecialty = () => {
    return filterSpecialties(specialty).map((item, index) => (
      <span key={index}>
        {index !== 0 && <Dot className={styles['dot-icon']} />}
        <span>{item.name}</span>
      </span>
    ));
  };
  const renderReviews = () => {
    return (
      <div className={`${styles['flex']} ${styles['review-container']}`}>
        <Rating
          name="read-only"
          value={averageScore || 0}
          readOnly
          precision={0.5}
          size="medium"
          className={styles['rating-stars']}
        />
        {averageScore > 0 && (
          <span className={`${styles.bold} ${styles['average-score']}`}>
            {averageScore}
          </span>
        )}
      </div>
    );
  };

  const renderAvatar = () => {
    return (
      <img
        className={styles['practitioner-avatar']}
        src={avatar}
        alt="popup-avatar"
      />
    );
  };

  const renderInformation = () => {
    return (
      <div className={styles['text-center']}>
        <Link
          to={`/practitioner?id=${id}`}
          className={`${styles['bold']} ${styles['name']}`}
        >
          {name}
        </Link>
        <div className={styles['specialty-container']}>{renderSpecialty()}</div>
        {renderReviews()}
      </div>
    );
  };

  const renderPageNavigationBtn = () => {
    if (isMobile) return null;
    if (totalPage > 1) {
      return (
        <div className={styles['navigation']}>
          <DentalButton
            className={`${styles['navigation-btn']} ${styles['previous']}`}
            onClick={handleClickPrevious}
          >
            <LeftArrow />
          </DentalButton>
          <DentalButton
            className={`${styles['navigation-btn']} ${styles['next']}`}
            onClick={handleClickNext}
          >
            <RightArrow />
          </DentalButton>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles['container']}>
      {renderAvatar()}
      {renderInformation()}
      {renderPageNavigationBtn()}
      <DentalButton
        className={styles['view-availability-btn']}
        variant="outlined"
        onClick={handleClickRequestAppointment}
      >
        View more
      </DentalButton>
      <div className={styles['page-number']}>
        {`${currPage} of ${totalPage}`}
      </div>
    </div>
  );
};

export default PractitionerInfo;
