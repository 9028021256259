/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { FilterTitle } from 'interfaces/filterTypes';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { setSearchBarFieldsIsOpen } from 'redux/searchBarSlice';
import useIsMobile from 'hooks/useIsMobile';
import { convertToDate } from 'utils/dates';

import './DateSelector.scss';

interface Props {
  title?: FilterTitle;
  onSelectionChanged?: (title: string, value?: string) => void;
  setIsActive?: (isActive: boolean) => void;
  isResultPage?: boolean;
  isBookingPage?: boolean;
  localStorageDate?: string | null;
  onChangeAppointmentDate?: (value: string) => void;
  onOpen?: () => void;
  isLocationFieldHadValue?: boolean;
}

export type DateSelectorComponent = React.FC<Props>;

const DateSelector: DateSelectorComponent = (props) => {
  const {
    onChangeAppointmentDate = () => {},
    onOpen,
    isLocationFieldHadValue,
  } = props;
  const defaultDate = props.localStorageDate;

  const defaultValue = defaultDate ? convertToDate(defaultDate) : new Date();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.searchBarSlice.date);

  const [startDate, setStartDate] = useState<Date | null>(defaultValue);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (props.onSelectionChanged !== undefined && props.title !== undefined) {
      props.onSelectionChanged(
        props.title,
        moment(convertToDate(startDate || new Date())).format('YYYY-MM-DD')
      );
    }
    onChangeAppointmentDate(startDate?.toDateString() || '');
  }, [startDate]);

  useEffect(() => {
    if (isOpen) {
      props.setIsActive && props.setIsActive(true);
      onOpen?.();
    }
  }, [isOpen]);

  const renderDatePicker = () => {
    const onFocus = () => {
      if (!isOpen) {
        dispatch(
          setSearchBarFieldsIsOpen({
            date: true,
          })
        );
        props.setIsActive && props.setIsActive(true);
      }
    };

    const onChange = (date: Date | null) => {
      setStartDate(date);
      dispatch(
        setSearchBarFieldsIsOpen({
          date: false,
        })
      );
      if (!props.isResultPage && !isLocationFieldHadValue) {
        dispatch(
          setSearchBarFieldsIsOpen({
            location: true,
          })
        );
      }
    };

    const onClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (isMobile && event.type === 'touchstart') return;
      dispatch(
        setSearchBarFieldsIsOpen({
          date: false,
        })
      );
      if (!startDate) {
        props.setIsActive && props.setIsActive(false);
      }
    };

    return (
      <DatePicker
        id="date-picker-search-popper"
        open={isOpen}
        dateFormat={!props.isBookingPage ? 'dd MMM' : 'EEEE, MMM dd'}
        minDate={new Date()}
        placeholderText={!props.isBookingPage ? 'Choose date' : ''}
        readOnly={true}
        onFocus={onFocus}
        selected={startDate}
        onChange={(date: Date | null) => onChange(date)}
        onClickOutside={onClickOutside}
      />
    );
  };

  return <div className="date-selector-container">{renderDatePicker()}</div>;
};

export default DateSelector;
