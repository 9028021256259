import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import { IPractitioner } from 'interfaces/practitionerTypes';
import {
  ALLOWED_PRACTITIONER_STUDIES,
  KILOMETER_IN_METER,
} from 'utils/constants/common';
import { IFilterState } from 'interfaces/filterTypes';
import { IClinic } from 'interfaces/clinicType';
import {
  AvailableBlockOperatory,
  ITimeSlot,
  SearchingService,
} from 'interfaces/timeslotType';
import { getPractitionerAvailability } from 'services/APIs/getPractitionerAvailability';
import getTimeBlocksData from 'utils/getTimeBlocksData';
import { concatFullName } from 'utils/common';

export interface IPractitionerResponse
  extends Omit<IPractitioner, 'availableBlocks' | 'clinic'> {
  availableBlocks: {
    date: string;
    availableBlockOperatories: AvailableBlockOperatory[];
    blocks: number[];
    searchingService: SearchingService;
  }[];
  clinic: IClinic;
}

interface MonthlyTimeSlot {
  timeSlots: ITimeSlot[];
  practitionerId: string;
}

interface IPractitionerState {
  status: 'loading' | 'success' | 'failure' | 'loading-more';
  isLoadingMore: boolean;
  practitioners: IPractitionerResponse[];
  hasMore: boolean | null;
  totalPractitioners: number;
  monthlyTimeSlots: MonthlyTimeSlot[];
  page: number;
}

export interface IGetPractitionersProps {
  serviceId: string;
  latitude: number;
  longitude: number;
  dates: string[];
  page: number;
  sortBy: 'rating' | 'distance' | null;
  searchDate?: string;
  currentDate: string;
  isMonthView?: boolean;
  timeBlocks: number[][];
  minRating: number;
  gender: ('male' | 'female' | 'non-binary')[];
}

interface IAxiosResponseData {
  data: any[];
  metadata: {
    total: number;
    page: number;
    limit: number;
  };
}

const PER_PAGE = 5;
const MAX_RATING = 5;
const MAX_DISTANCE_IN_METER = 20000;

const getMonthlyTimeSlots = async (
  practitioners: IPractitionerResponse[],
  timezone: string,
  serviceId: string,
  timeBlocks: number[] | string[]
) => {
  const monthlyTimeSlot: [ITimeSlot[], ITimeSlot[], string][] =
    await Promise.all(
      practitioners.map((practitioner) => {
        const startDate = practitioner.availableBlocks[0].date;

        const firstRange = [
          startDate,
          moment(startDate).add(6, 'd').format('YYYY-MM-DD'),
        ];
        const promise = getPractitionerAvailability({
          clinicId: practitioner.id,
          practitionerId: practitioner.id,
          timezone,
          serviceId,
          dates: firstRange,
          timeBlocks,
        });

        const secondRange = [
          moment(startDate).add(7, 'd').format('YYYY-MM-DD'),
          moment(startDate).add(13, 'd').format('YYYY-MM-DD'),
        ];

        const promise2 = getPractitionerAvailability({
          clinicId: practitioner.id,
          practitionerId: practitioner.id,
          timezone,
          serviceId,
          dates: secondRange,
          timeBlocks,
        });

        return Promise.all([promise, promise2, practitioner.id]);
      })
    );

  const monthlyTimeSlots = monthlyTimeSlot.map((timeSlot) => ({
    timeSlots: [...timeSlot[0], ...timeSlot[1]],
    practitionerId: timeSlot[2],
  }));

  return monthlyTimeSlots;
};

export const getPractitionerList = createAsyncThunk(
  'pratitionerSearchResult/getPratitionerSearchResult',
  async (
    {
      dates,
      serviceId,
      latitude,
      longitude,
      page,
      sortBy,
      currentDate,
      searchDate,
      isMonthView,
      timeBlocks,
      minRating,
      gender,
    }: IGetPractitionersProps,
    { getState }
  ) => {
    const { filterSlice } = getState() as {
      filterSlice: IFilterState;
    };

    let sort: string = '';
    let sortByParam: string = '';
    let distances = [0, MAX_DISTANCE_IN_METER];
    switch (sortBy) {
      case 'rating': {
        sort = 'desc,desc';
        sortByParam = 'rating,reviewCount';
        break;
      }
      case 'distance': {
        sort = 'asc,desc,desc';
        sortByParam = 'distance,rating,reviewCount';
        break;
      }
      default:
        break;
    }

    const locationTimezone = filterSlice.location.timezone;

    const timeBlocksData = getTimeBlocksData(timeBlocks);

    const response = await axios.get<IAxiosResponseData>(
      '/practitioners/weekly-timeslots',
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          limit: PER_PAGE * page,
          dates: dates,
          page: 1,
          timezone: locationTimezone,
          latitude: latitude,
          longitude: longitude,
          serviceId: serviceId,
          distances,
          timeBlocks: timeBlocksData,
          rating: [minRating, MAX_RATING],
          includedNonRated: minRating === 0,
          genders: gender,
          specialties: [],
          searchDate,
          currentDate,
          ...(sortBy && {
            sort,
            sortBy: sortByParam,
          }),
        },
      }
    );

    const practitioners = response.data.data.map((item: any) => {
      const clinic = item.clinic;

      return {
        id: item.id,
        avatar: item.avatar,
        slug: item.slug,
        name: concatFullName(item.firstName, item.lastName),
        practice: item.practice,
        specialty:
          item.specialties && Array.isArray(item.specialties)
            ? item.specialties
            : [],
        description: item.bio,
        distance: _.round(clinic.distance / KILOMETER_IN_METER, 1),
        clinic: clinic,
        clinicId: clinic.id,
        address: clinic.address,
        workingHours: item.workingHours,
        study: item.studies
          ? item.studies
              .map((item: any) => item.name)
              .filter((item: any) =>
                ALLOWED_PRACTITIONER_STUDIES.includes(item)
              )
          : [],
        email: clinic.email,
        phone: clinic.phoneNumber,
        availableBlocks: clinic.availableBlocks,
        totalScore: item.rating,
        reviewCount: item.reviewCount,
        services: item.services.map((item: any) => ({
          id: item.id,
          name: item.name,
          clinicId: item.clinicId,
          duration: item.duration,
        })),
        specialist: item.specialist,
        title: item.title,
        firstName: item.firstName,
      };
    });

    let monthlyTimeSlots: MonthlyTimeSlot[] = [];

    if (isMonthView) {
      monthlyTimeSlots = await getMonthlyTimeSlots(
        practitioners,
        locationTimezone,
        serviceId,
        timeBlocksData
      );
    }

    const result: any = {
      practitioners: practitioners,
      hasMore: practitioners.length < response.data.metadata.total,
      totalPractitioners: response.data.metadata.total,
      monthlyTimeSlots,
    };

    return result;
  }
);

const initialState: IPractitionerState = {
  practitioners: [],
  isLoadingMore: false,
  status: 'loading',
  hasMore: null,
  totalPractitioners: 0,
  monthlyTimeSlots: [],
  page: 1,
};

const practitionerSearchResultSlice = createSlice({
  name: 'pratitioners',
  initialState,
  reducers: {
    clearPractitionerSearchDetails: () => {
      return {
        ...initialState,
      };
    },
    setIsLoadingMore: (state) => {
      return {
        ...state,
        page: state.page + 1,
        isLoadingMore: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPractitionerList.fulfilled, (state, action) => {
      return {
        ...state,
        status: 'success',
        ...action.payload,
        isLoadingMore: false,
      };
    });
    builder.addCase(getPractitionerList.pending, (state) => {
      return {
        ...state,
        status: state.isLoadingMore ? 'loading-more' : 'loading',
      };
    });
    builder.addCase(getPractitionerList.rejected, (state) => {
      return {
        ...state,
        status: 'failure',
      };
    });
  },
});

export const { clearPractitionerSearchDetails, setIsLoadingMore } =
  practitionerSearchResultSlice.actions;
export default practitionerSearchResultSlice.reducer;
