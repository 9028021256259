import React, { FC, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/google-icon.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-icon.svg';
import styles from './LoginOptionDialog.module.scss';
import { loginSocialThunk, resetSysType, updateSysType } from 'redux/authSlice';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { AUTH_SYS_TYPE, PROVIDER_ID } from 'utils/constants/common';
import { checkEmail } from 'services/APIs';
import { useAppDispatch } from 'redux/reduxHooks';
import { auth } from 'services/firebase';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { useHistory } from 'react-router-dom';
import { saveSocialUserData } from 'utils/storage';
import {
  getUserDataFromFacebook,
  getUserDataFromGoogle,
  getUserEmailFromSocial,
  signInGoogleWithPopUp,
} from 'components/BookingForm/utils/getOAuthData';
import { ROUTES } from 'routes/config';

interface LoginOptionDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onNavigateEmail?: () => void;
  isInBookingFlow?: boolean;
  onNavigateSignUpPage?: () => void;
  resetForm?: () => void;
}

const LoginOptionDialog: FC<LoginOptionDialogProps> = ({
  isOpen,
  setIsOpen,
  onNavigateEmail,
  onNavigateSignUpPage,
  isInBookingFlow = false,
  resetForm,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const resetUserData = () => {
    dispatch(resetSysType());
    onNavigateEmail?.();
  };

  const navigateAfterSignUp = () => {
    if (resetForm) {
      setIsOpen(false);
      return resetForm();
    }
    if (isInBookingFlow) return onNavigateSignUpPage?.();
    history.push(ROUTES.SIGN_UP_PAGE);
  };

  const navigateAfterLogin = () => {
    if (!isInBookingFlow) {
      history.goBack();
    }
    if (history.location.pathname === ROUTES.SIGN_UP_PAGE) {
      history.push(ROUTES.HOME);
    }
  };

  const handleLoginGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    const sysType = AUTH_SYS_TYPE.GOOGLE;
    try {
      const result = await signInGoogleWithPopUp(provider);
      const user = result.user;
      setIsLoading(true);
      const userToken = await user.getIdToken();

      const email = getUserEmailFromSocial(result, PROVIDER_ID.GOOGLE);

      const { data } = await checkEmail(email);
      if (data?.isExistingEmail) {
        await dispatch(loginSocialThunk({ email, sysType, userToken }));
        setIsOpen(false);
        return navigateAfterLogin();
      }
      const userData = await getUserDataFromGoogle(result);
      const socialData = { ...userData, userToken };

      dispatch(
        updateSysType({
          socialData,
          sysType,
        })
      );
      if (!isInBookingFlow) {
        saveSocialUserData({ socialData, sysType });
      }
      setIsLoading(false);
      navigateAfterSignUp();
    } catch (error) {}
  };

  const handleLoginFacebook = async () => {
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    const sysType = AUTH_SYS_TYPE.FACEBOOK;
    try {
      const result = await signInWithPopup(auth, provider);
      setIsLoading(true);

      const userToken = await result.user.getIdToken();

      const email = getUserEmailFromSocial(result, PROVIDER_ID.FACEBOOK);

      const { data } = await checkEmail(email);
      if (data?.isExistingEmail) {
        await dispatch(loginSocialThunk({ email, sysType, userToken }));
        setIsOpen(false);
        return navigateAfterLogin();
      }
      const userData = await getUserDataFromFacebook(result);
      const socialData = { ...userData, userToken };

      dispatch(
        updateSysType({
          socialData,
          sysType,
        })
      );
      if (!isInBookingFlow) {
        saveSocialUserData({ socialData, sysType });
      }
      setIsLoading(false);
      navigateAfterSignUp();
    } catch (error) {}
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      {isLoading && <LoadingScreen />}
      <div className={styles['sign-up-option']}>
        <div className={styles['header']}>
          <h3>Sign up with</h3>
          <button
            className={styles['close-icon']}
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={styles['body']}>
          <button className={styles['option']} onClick={handleLoginGoogle}>
            <GoogleIcon className={styles['option-icon']} />
            <span>Google</span>
          </button>
          <button className={styles['option']} onClick={handleLoginFacebook}>
            <FacebookIcon className={styles['option-icon']} />
            <span>Facebook</span>
          </button>
          {onNavigateEmail && (
            <>
              <span className={styles['option-divider']}>or</span>
              <button className={styles['option']} onClick={resetUserData}>
                Email address
              </button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default LoginOptionDialog;
