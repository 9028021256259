import { FC } from 'react';
import cx from 'classnames';
import { ReactComponent as CaretIcon } from 'assets/icons/caret.svg';
import css from './HeaderSection.module.scss';
import useIsMobile from 'hooks/useIsMobile';
import { STEPS } from 'redux/familyBookingSlice';

interface HeaderSectionProps {
  currentPage?: string;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  dropdownText: string;
}

const HeaderSection: FC<HeaderSectionProps> = ({
  currentPage,
  isExpanded,
  setIsExpanded,
  children,
  title,
  dropdownText,
}) => {
  const isMobile = useIsMobile();

  const isFamilyBookingEnabled =
    process.env.REACT_APP_IS_FAMILY_BOOKING_ENABLED === 'true';

  if (
    currentPage === STEPS.MONTHVIEW_SELECTION ||
    currentPage === STEPS.SLOT_SELECTION
  ) {
    const headerSectionClassName = cx({
      [css['select-time-header-section']]: true,
    });

    return (
      <div className={headerSectionClassName}>
        <h3 className={css['title']} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? (
            <CaretIcon className={css['caret-icon']} />
          ) : (
            <CaretIcon />
          )}
          <span>{title}</span>
        </h3>

        {children}
      </div>
    );
  }

  if (!isFamilyBookingEnabled) {
    return (
      <div className={css['header-section']}>
        <h3 className={css['title']}>{title}</h3>
      </div>
    );
  }

  if (currentPage !== STEPS.MONTHVIEW_SELECTION) {
    const isDropdownExpandedOnMobile = isMobile && !isExpanded;

    const headerSectionClassName = cx({
      [css['header-section']]: true,
      [css['header-section-reverse']]: isDropdownExpandedOnMobile,
    });

    return (
      <div className={headerSectionClassName}>
        <h3 className={css['title']} onClick={() => setIsExpanded(false)}>
          {isExpanded && <CaretIcon className={css['caret-icon']} />}
          <span>{title}</span>
        </h3>
        {isDropdownExpandedOnMobile ? (
          <button
            className={css['mobile-container']}
            onClick={() => setIsExpanded?.(true)}
          >
            <CaretIcon />
            <span>{dropdownText}</span>
          </button>
        ) : (
          children
        )}
      </div>
    );
  }

  return null;
};

export default HeaderSection;
