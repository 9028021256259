import { convertToDate } from 'utils/dates';

export const getDayinWeek = (date: string) => {
  const daysInWeek: {
    [key: number]:
      | 'Monday'
      | 'Tuesday'
      | 'Wednesday'
      | 'Thursday'
      | 'Friday'
      | 'Saturday'
      | 'Sunday';
  } = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };
  const day = convertToDate(date).getDay();
  return daysInWeek[day];
};
