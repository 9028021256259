import axios from 'axios';

export const getGeocodeByPlaceDetails = async (lat: number, lng: number) => {
  const { data } = await axios.get('/gmap/geocode', {
    baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    params: {
      lat,
      lng,
    },
  });

  return data?.results || [];
};
