import React from 'react';
import { PractitionerReview } from 'components/PractitionerReview';
import PaginationController from 'components/PaginationController/PaginationController';
import { useAppSelector } from 'redux/reduxHooks';
import useIsMobile from 'hooks/useIsMobile';

import ReviewSummary from 'components/ReviewSummary/ReviewSummary';

import styles from './ClinicReviews.module.scss';

interface Props {
  reviewIds: string[];
  currentPage: number;
  onChangePage: (page: number) => void;
  pageCount: number;
  isLoading: boolean;
}

export type ClinicReviewsComponent = React.FC<Props>;

const ClinicReviews: ClinicReviewsComponent = ({
  reviewIds,
  currentPage,
  onChangePage,
  pageCount,
}) => {
  const { reviewsData, clinicData } = useAppSelector(
    (state) => state.clinicDetailsSlice
  );
  const isMobile = useIsMobile();

  const renderPractitionerReview = (
    totalScore: number,
    reviewCount: number,
    reviewIds: string[]
  ) => {
    return (
      <>
        <ReviewSummary
          totalScore={totalScore}
          reviewCount={reviewCount}
          title="Practice Review"
        />
        {reviewIds.map((item, index) => (
          <PractitionerReview
            key={item}
            content={reviewsData.reviews.data[item].content}
            reviewer={{
              avatar: reviewsData.reviews.data[item].avatar,
              name: reviewsData.reviews.data[item].reviewer,
            }}
            score={reviewsData.reviews.data[item].score}
            createdAt={reviewsData.reviews.data[item].createdAt}
            source={reviewsData.reviews.data[item].source}
            isEvenIndex={(index + 1) % 2 === 0}
          />
        ))}
      </>
    );
  };

  if (reviewIds.length > 0) {
    return (
      <div className={styles['container']}>
        {renderPractitionerReview(
          clinicData.totalScore,
          clinicData.reviewCount,
          reviewIds
        )}
        {!isMobile && (
          <PaginationController
            currentPage={currentPage}
            pageCount={pageCount}
            onChangePage={onChangePage}
          />
        )}
      </div>
    );
  }
  return null;
};

export default ClinicReviews;
