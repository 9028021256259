import { makeStyles } from '@material-ui/core/styles';
import { fontSize, colors, buttonColors } from 'utils/theme';

export default makeStyles((theme) => ({
  button: {
    minWidth: '2.4rem',
    width: '15rem',
    height: '4rem',
    fontFamily: 'Nexa',
    fontSize: fontSize.s,
    fontWeight: 700,
    lineHeight: 'unset',
    textTransform: 'none',
    borderRadius: '100px',
    boxShadow: 'none',
    // 500px is mobile breakpoint
    [theme.breakpoints.down(500)]: {
      width: '10rem',
      height: '3.6rem',
    },
    '& .MuiButton-sizeSmall': {
      width: '15rem',
      height: '3.2rem',
    },
    '& .MuiButton-sizeLarge': {
      width: '18rem',
      height: '4.8rem',
    },
    '&.MuiButton-text': {
      fontWeight: '300',
      '&:hover': {
        background: 'transparent',
        color: colors.lighterPurple,
      },
      '&:active': {
        color: colors.darkerPurple,
      },
      '&:disabled': {
        color: colors.neutralGrey,
      },
    },
    '&.MuiButton-contained': {
      background: buttonColors.contained.backgroundColor,
      color: colors.white,
      '&:hover': {
        background: buttonColors.contained.hover.backgroundColor,
      },
      '&:active': {
        background: buttonColors.contained.active.backgroundColor,
      },
      '&:disabled': {
        background: buttonColors.contained.disabled.backgroundColor,
      },
    },
    '&.MuiButton-outlined': {
      background: colors.white,
      border: buttonColors.outlined.border,
      color: colors.primaryPurple,
      '&:hover': {
        border: buttonColors.outlined.hover.border,
        color: colors.lighterPurple,
      },
      '&:active': {
        border: buttonColors.outlined.active.border,
        color: colors.darkerPurple,
      },
      '&:disabled': {
        border: buttonColors.outlined.disabled.border,
        color: colors.neutralGrey,
      },
    },
  },
  circularProgress: {
    color: colors.white,
  },
}));
