import className from 'classnames';

import heroImage from 'assets/images/hero.svg';

import styles from './FeatureImage.module.scss';

function FeatureImage() {
  const containerClassNames = className(styles.container);
  const heroTextContainerClassNames = className(styles['hero-text-container']);

  return (
    <div className={containerClassNames}>
      <div className={heroTextContainerClassNames}>
        <div className={styles['hero-text-container--title']}>
          Book Healthcare Appointments Instantly Online
        </div>
        <div className={styles['hero-text-container--description']}>
          <div>
            No more waiting on hold or phoning multiple practices to find a
            convenient appointment
          </div>
          <br />
          FirstIn Practice Solutions LTD is an online booking platform for
          patients. You can quickly and easily see doctors available appointment
          times and book instantly online. We allow you to schedule your
          healthcare needs outside of regular hours, based on your availability
        </div>
      </div>
      <div className={styles['hero-background']}>
        <img src={heroImage} alt="hero" />
      </div>
    </div>
  );
}

export default FeatureImage;
