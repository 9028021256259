const normalizeClinicAvaiBlocks = (arg: {
  dateItems: {
    [key: string]: {
      [key: string]: {
        practitionerIds: string[];
      };
    };
  };
  availableDates: string[];
}) => {
  const normalizeData = Object.entries(arg.dateItems).map((data) => {
    const [date, value] = data;

    const blocks = Object.entries(value).map((value) => {
      const [block, { practitionerIds }] = value;
      return { block: Number(block), practitionerIds };
    });

    return { date, blocks };
  });
  return normalizeData;
};

export default normalizeClinicAvaiBlocks;
