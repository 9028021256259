import { signOut } from 'firebase/auth';
import { ClickAwayListener, Popper } from '@material-ui/core';
import React, { useState } from 'react';
import { logoutThunk } from 'redux/authSlice';
import { useAppDispatch } from 'redux/reduxHooks';
import { auth } from 'services/firebase';
import Avatar from 'components/NavigationBar/Avatar/Avatar';

import styles from './SignedInAvatar.module.scss';

const SignedInLogo = () => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onLogout = async () => {
    await signOut(auth);
    await dispatch(logoutThunk());
    setAnchorEl(null);
  };

  return (
    <>
      <button
        className={styles['avatar-container']}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Avatar />
      </button>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '-90, 10',
          },
        }}
        className={styles['popper']}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <button className={styles['drop-down']} onClick={onLogout}>
            Sign out
          </button>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default SignedInLogo;
