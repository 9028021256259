import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { ROUTES } from 'routes/config';

import styles from './InvalidPage.module.scss';
import { Header, Layout } from 'components/Layout';
import { NavigationBar } from 'components/NavigationBar';

const InvalidPage = () => {
  const history = useHistory();

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header hasBoxShadow>
            <NavigationBar hasFullLogo />
          </Header>
        </div>

        <div className={styles.content}>
          <div className={styles.wrapper}>
            <ConfirmIcon />
            <div className={styles.title}>Invalid link</div>
            <DentalButton
              variant="contained"
              onClick={() => history.push(ROUTES.HOME)}
            >
              Go to FirstIn
            </DentalButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InvalidPage;
