import React, { FC, useEffect, useState } from 'react';
import InputContainer from 'components/Form/InputContainer/InputContainer';
import { HelperText } from 'components/Form/HelperText/HelperText';
import PhoneInputCode, { CountryData } from 'react-phone-input-2';
import {
  CountryCode,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { Controller } from 'react-hook-form';

import 'react-phone-input-2/lib/style.css';
import styles from './PhoneInput.module.scss';
import { formatNationalPhoneNumber } from 'utils/formatPhoneNumber';
import { DEFAULT_COUNTRY_CODE } from 'redux/bookingFormSlice';
import { getAllowedCountry } from 'utils/common';

interface PhoneInputProps {
  control: any;
  countryCode: string;
  phoneNumber: string;
  onChangeCountryCode: (countryCode: string, phoneNumber: string) => void;
}

const PhoneInput: FC<PhoneInputProps> = ({
  control,
  countryCode,
  phoneNumber,
  onChangeCountryCode,
}) => {
  const [tmpPhoneNumber, setTmpPhoneNumber] = useState(phoneNumber || '');

  useEffect(() => {
    if (phoneNumber && countryCode) {
      const formattedValue = formatNationalPhoneNumber(
        phoneNumber,
        countryCode.toUpperCase() as CountryCode
      );
      setTmpPhoneNumber(formattedValue);
    }
  }, [countryCode, phoneNumber]);

  return (
    <InputContainer title="Phone Number" required="*">
      <Controller
        control={control}
        name="phoneNumber"
        rules={{
          required: 'This field is required',
          validate: (value: string) => {
            const code = countryCode?.toUpperCase() as CountryCode;
            if (!isValidPhoneNumber(value, code)) {
              return 'Invalid phone number';
            }
          },
        }}
        render={({ field, fieldState }) => {
          const { onChange, ...rest } = field;
          return (
            <div className={styles.container}>
              <div className={styles['input-wrapper']}>
                <div className={styles.divider}></div>
                <PhoneInputCode
                  onlyCountries={getAllowedCountry()}
                  enableSearch
                  inputProps={{
                    ...rest,
                    autoComplete: 'tel',
                    value: tmpPhoneNumber,
                    onChange: (event: any) => {
                      let lastLetter =
                        event.target.value[event.target.value.length - 1];

                      if (!/\d/.test(lastLetter)) {
                        const removed = event.target.value.slice(
                          0,
                          event.target.value.length - 1
                        );
                        setTmpPhoneNumber(removed);
                        return;
                      }

                      if (event.target.value[0] === '+') {
                        const rawValue = event.target.value;
                        const phoneNumber = parsePhoneNumber(
                          event.target.value
                        );

                        if (phoneNumber) {
                          const formattedValue = phoneNumber.formatNational();
                          const fullPhoneNumber = rawValue.replace(/\D/g, '');
                          onChange(fullPhoneNumber);
                          setTmpPhoneNumber(formattedValue);
                          onChangeCountryCode(
                            phoneNumber.country?.toLowerCase() as CountryCode,
                            fullPhoneNumber
                          );
                        }
                      } else {
                        const rawValue = event.target.value;

                        if (tmpPhoneNumber.length > event.target.value.length) {
                          // Deleting
                          const currentLastLetter =
                            tmpPhoneNumber[tmpPhoneNumber.length - 1];
                          if (!/\d/.test(currentLastLetter)) {
                            setTmpPhoneNumber(event.target.value);
                            return;
                          }
                        }

                        const formattedValue = formatNationalPhoneNumber(
                          rawValue,
                          countryCode.toUpperCase() as CountryCode
                        );

                        const callingCode = getCountryCallingCode(
                          countryCode.toUpperCase() as CountryCode
                        );

                        const phoneWithoutCountryCode = formattedValue.replace(
                          /\D/g,
                          ''
                        );
                        const fullPhoneNumber = `${callingCode}${phoneWithoutCountryCode}`;

                        onChange(fullPhoneNumber);
                        setTmpPhoneNumber(formattedValue);
                      }
                    },
                  }}
                  disableCountryGuess
                  countryCodeEditable
                  disableCountryCode
                  placeholder="Input your phone number"
                  country={countryCode}
                  preferredCountries={[DEFAULT_COUNTRY_CODE]}
                  containerClass={styles.container}
                  inputClass={`${styles.input} ${
                    fieldState.error?.message ? styles.error : ''
                  }`}
                  searchClass={styles.search}
                  buttonClass={styles.button}
                  onChange={(value, countryData: CountryData) => {
                    if (countryData.countryCode !== countryCode) {
                      setTmpPhoneNumber('');
                      onChange('');
                      onChangeCountryCode(countryData.countryCode, '');

                      return;
                    }
                  }}
                />
              </div>
              {fieldState.error?.message && (
                <div className={styles['error-message']}>
                  <HelperText error={fieldState.error.message} />
                </div>
              )}
            </div>
          );
        }}
      />
    </InputContainer>
  );
};

export default PhoneInput;
