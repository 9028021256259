export const VIEW_ACTION: {
  IS_SCROLLING: 'isScrolling';
  SCROLL_TO_TOP: 'scrollToTop';
  SCROLL_TO_BOTTOM: 'scrollToBottom';
  RESET: 'reset';
} = {
  IS_SCROLLING: 'isScrolling',
  SCROLL_TO_TOP: 'scrollToTop',
  SCROLL_TO_BOTTOM: 'scrollToBottom',
  RESET: 'reset',
};

interface IAction {
  type: (typeof VIEW_ACTION)[keyof typeof VIEW_ACTION];
}
interface IState {
  isScrolling: boolean;
  displayBlurBottom: boolean;
  displayMoreIcon: boolean;
}

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case VIEW_ACTION.SCROLL_TO_TOP:
      return {
        isScrolling: false,
        displayMoreIcon: true,
        displayBlurBottom: true,
      };
    case VIEW_ACTION.SCROLL_TO_BOTTOM:
      return { ...state, displayBlurBottom: false };
    case VIEW_ACTION.IS_SCROLLING:
      return {
        isScrolling: true,
        displayMoreIcon: false,
        displayBlurBottom: true,
      };
    case VIEW_ACTION.RESET:
      return {
        isScrolling: false,
        displayMoreIcon: false,
        displayBlurBottom: false,
      };
    default:
      throw Error('Unhandled action type');
  }
};

const initialState = {
  isScrolling: false,
  displayBlurBottom: true,
  displayMoreIcon: false,
};

export default reducer;
export { initialState };
