import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PATIENT_EXAM_AND_CLEANING } from 'components/DentalMap/utils/constant';
import { setFilter } from './filterSlice';

interface IOptions {
  [key: string]: string;
}

interface ISearchBarOptions {
  service: {
    ids: string[];
    data: IOptions;
    slugs: IOptions;
  };
  location: PlaceType[];
}

export interface IServicesResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  slug: string;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

const initialState: ISearchBarOptions = {
  service: {
    ids: [],
    data: {},
    slugs: {},
  },
  location: [],
};

export const getServicesOptions = createAsyncThunk(
  'SearchBarOptionsSlice/getServicesOptions',
  async (_, { dispatch, getState }: any) => {
    const response = await axios.get<IServicesResponse[]>('/services', {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    });

    const data = response.data.reduce((accum: IOptions, currentValue) => {
      accum[currentValue.id] = currentValue.name;
      return accum;
    }, {});

    const slugs = response.data.reduce((accum: IOptions, currentValue) => {
      accum[currentValue.id] = currentValue.slug;
      return accum;
    }, {});

    const ids = Object.keys(data);

    const defaultService = response.data.find(
      (item) => item.name === PATIENT_EXAM_AND_CLEANING
    );

    const filterState = getState().filterSlice;
    dispatch(
      setFilter({
        ...filterState,
        service: defaultService?.id || '',
      })
    );

    return {
      service: {
        ids,
        data,
        slugs,
      },
    };
  }
);

export const SearchBarOptionsSlice = createSlice({
  name: 'searchBar',
  initialState,
  reducers: {
    setLocationOptions: (state, action: PayloadAction<PlaceType[]>) => {
      return { ...state, location: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServicesOptions.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
  },
});

export const { setLocationOptions } = SearchBarOptionsSlice.actions;
export default SearchBarOptionsSlice.reducer;
