import { FC, ReactNode } from 'react';
import cx from 'classnames';

import styles from './CardInfo.module.scss';
import AvatarInfo from 'components/AvatarInfo/AvatarInfo';

interface CardInfoProps {
  avatar: string;
  rating: number;
  reviewCount: number;
  name: string;
  detailContent: ReactNode;
  distance: number;
  navigateToProfilePage: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  customStyle?: string;
  specialties?: string;
}

const CardInfo: FC<CardInfoProps> = ({
  avatar,
  rating,
  reviewCount,
  name,
  children,
  detailContent,
  navigateToProfilePage,
  customStyle,
  distance,
  specialties,
}) => {
  return (
    <div
      className={cx(styles.container, customStyle)}
      onClick={navigateToProfilePage}
    >
      <AvatarInfo
        avatar={avatar}
        rating={rating}
        reviewCount={reviewCount}
        name={name}
        specialties={specialties}
        distance={distance}
      />
      <div className={styles['main-content']}>
        <h3 className={styles['main-content-title']}>{name}</h3>
        <div onClick={(e) => e.stopPropagation()}>{children}</div>
      </div>
      <div className={styles['description']}>{detailContent}</div>
    </div>
  );
};

export default CardInfo;
