import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import useIsMobile from 'hooks/useIsMobile';
import { Toaster } from 'react-hot-toast';

const ToasterWrapper = () => {
  const isLoadedInsideIframe = useIsLoadedInsideIframe();
  const isMobile = useIsMobile();

  const top = isMobile || isLoadedInsideIframe ? '7rem' : '5rem';

  return (
    <Toaster
      containerStyle={{
        top,
      }}
      toastOptions={{
        className: 'toast',
        icon: null,
      }}
    />
  );
};

export default ToasterWrapper;
