import React, { FC } from 'react';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-icon.svg';
import styles from './SocialButton.module.scss';
import {
  FacebookAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { auth } from 'services/firebase';
import { AUTH_SYS_TYPE } from 'utils/constants/common';

interface FacebookButtonProps {
  onSuccess: (userCredential: UserCredential, sysType: string) => void;
}

const FacebookButton: FC<FacebookButtonProps> = ({ onSuccess }) => {
  const handleSignInFacebook = async () => {
    try {
      const provider = new FacebookAuthProvider();
      provider.addScope('email');
      provider.addScope('public_profile');
      provider.setCustomParameters({ prompt: 'select_account' });
      const result = await signInWithPopup(auth, provider);
      onSuccess(result, AUTH_SYS_TYPE.FACEBOOK);
    } catch (error: any) {}
  };

  return (
    <button
      className={styles['social-icon']}
      type="button"
      onClick={handleSignInFacebook}
    >
      <FacebookIcon />
    </button>
  );
};

export default FacebookButton;
