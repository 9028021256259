import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { NavigationBar } from 'components/NavigationBar';
import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';
import { Header } from 'components/Layout';

import styles from './BookingConfirmPage.module.scss';
import { Divider } from 'components/DentalDivider';
import BookingInfo from './BookingInfo/BookingInfo';
import PatientContact from './PatientContact/PatientContact';
import PatientSections from './PatientSections/PatientSections';
import BackToTopButton from 'components/Buttons/BackToTopButton/BackToTopButton';
import { ROUTES } from 'routes/config';
import { concatFullName } from 'utils/common';

interface IReturnCreateBookingData {
  clinic: {
    id: string;
    createdAt: string;
    updatedAt: string | null;
    name: string;
    avatar: string;
    address: string;
  };
  createdAt: string;
  email: string;
  id: string;
  isClinicBooking: boolean;
  name: string;
  otpUserId: string;
  patients: {
    appointmentDate: string;
    address: string | null;
    dob: string;
    doctor: {
      id: string;
      firstName: string;
      lastName: string;
    };
    name: string;
    id: string;
    insuranceFiles: string[];
    service: {
      id: string;
      name: string;
    };
    startTime: string;
    useInsurance: boolean;
  }[];
  phoneNumber: string;
  timezone: string;
}

const BookingConfirmPage = ({ history }: RouteComponentProps) => {
  const data = history.location.state as IReturnCreateBookingData;

  if (!data) {
    history.push(ROUTES.HOME);
  }

  const [isShowBackToTopBtn, setIsShowBackToTopBtn] = useState(false);

  const onScroll = (e: Event) => {
    if (window.scrollY > 0) {
      setIsShowBackToTopBtn(true);
    }
    if (window.scrollY <= 0) {
      setIsShowBackToTopBtn(false);
    }
  };

  const renderBackToTopButton = () => {
    if (isShowBackToTopBtn) {
      return (
        <div className={styles['back-to-top']}>
          <BackToTopButton />
        </div>
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={styles['container']}>
      <Header hasBoxShadow>
        <NavigationBar />
      </Header>
      <div className={styles['content-container']}>
        {renderBackToTopButton()}
        <ConfirmIcon className={styles['confirm-icon']} />
        <div className={styles['header-title']}>Booking Confirmed!</div>
        <div className={styles['header-message']}>
          Your booking was successful, you will receive a confirmation email
          shortly.
        </div>
        {data && (
          <>
            <Divider className={styles['divider']} orientation="horizontal" />
            <BookingInfo
              title={
                !data.isClinicBooking
                  ? concatFullName(
                      data.patients[0].doctor.firstName,
                      data.patients[0].doctor.lastName
                    )
                  : data.clinic.name
              }
              appointmentDate={data.patients[0]?.appointmentDate}
              address={
                !data.isClinicBooking
                  ? `${data.clinic.name} ${data.clinic.address}`
                  : data.clinic.address
              }
              service={
                !data.isClinicBooking ? data.patients[0].service.name : ''
              }
              startTime={
                !data.isClinicBooking ? data.patients[0].startTime : ''
              }
            />
            <Divider className={styles['divider']} orientation="horizontal" />
            <PatientContact
              name={data.name}
              phone={data.phoneNumber}
              email={data.email}
            />
            {data.patients.map((item, index: number) => (
              <PatientSections
                key={index}
                dob={item.dob}
                name={item.name}
                order={index + 1}
                service={data.isClinicBooking ? item.service.name : ''}
                practitioner={
                  data.isClinicBooking
                    ? concatFullName(
                        item.doctor.firstName,
                        item.doctor.lastName
                      )
                    : ''
                }
                useInsurance={item.useInsurance}
                isOnePatient={data.patients.length <= 1}
                startTime={!data.isClinicBooking ? '' : item.startTime}
              />
            ))}
          </>
        )}
        <a href="/" className={styles['button']}>
          <DentalButton variant="contained">Done</DentalButton>
        </a>
      </div>
    </div>
  );
};

export default withRouter(BookingConfirmPage);
