import React from 'react';
import moment from 'moment';
import { DayCellContentArg } from '@fullcalendar/react';

import { CLOSED } from 'utils/constants/common';
import { convertToDate } from 'utils/dates';

import styles from './DayCell.module.scss';

type PractitionerTimeSlots = DayCellContentArg & {
  timeslots: { date: string; blocks: number[] }[];
  isLoading?: boolean;
};

type ClinicTimeSlots = DayCellContentArg & {
  timeslots: { date: string; blocks: number[] }[];
  closedDates: string[];
  isLoading?: boolean;
};

const isClinicTimeSlotsType = (
  value: PractitionerTimeSlots | ClinicTimeSlots
): value is ClinicTimeSlots => {
  return 'closedDates' in value;
};

const DayCell = (props: PractitionerTimeSlots | ClinicTimeSlots) => {
  const dayNumberText =
    props.isFuture && props.dayNumberText === '1'
      ? moment(convertToDate(props.date)).format('D MMM')
      : props.dayNumberText;

  const isClosedDate = isClinicTimeSlotsType(props)
    ? props.closedDates.includes(
        moment(convertToDate(props.date)).format('YYYY-MM-DD')
      )
    : false;

  const blocks =
    props.timeslots.find((item: any) =>
      moment(item.date).isSame(props.date, 'days')
    )?.blocks || [];

  const isAvailableSlot =
    !isClosedDate && !props.isPast && blocks && blocks.length !== 0;

  const isNonAvailableSlot =
    !isClosedDate && !props.isPast && blocks && blocks.length === 0;

  return (
    <div className={styles.cellWrapper}>
      <div>
        <span className={styles.dayNumber}>{dayNumberText.split(' ')[0]}</span>{' '}
        {dayNumberText.split(' ')[1] && (
          <span>&nbsp;{dayNumberText.split(' ')[1]}</span>
        )}
      </div>
      {props.isLoading ? (
        <div className={styles.loading} />
      ) : (
        <>
          {isClosedDate && <div className={styles.closedSlot}>{CLOSED}</div>}

          {isNonAvailableSlot && <div className={styles.emptySlot}>-</div>}

          {isAvailableSlot && (
            <div className={styles.openSlot}>{`${blocks?.length} open`}</div>
          )}
        </>
      )}
    </div>
  );
};

export default DayCell;
