import { Divider, makeStyles } from '@material-ui/core';

interface IDividerProps {
  orientation: 'vertical' | 'horizontal';
  width?: number | null;
  height?: number | null;
  className?: string;
}

function DentalDivider({ orientation, width, height, className }: IDividerProps) {
  const useStyles = makeStyles({
    divider: {
      height: height ? `${height}rem` : '',
      width: width ? `${width}rem` : '',
      alignSelf: 'center',
    },
  });
  const classes = useStyles();

  return <Divider orientation={orientation} className={`${classes.divider} ${className}`} />;
}

export default DentalDivider;
