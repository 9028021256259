import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavigationBar.module.scss';
import NavigationLink from './NavigationLink/NavigationLink';
import useIsMobile from 'hooks/useIsMobile';
import { ReactComponent as HamburgerIcon } from 'assets/icons/hamburger-icon.svg';
import Drawer from '@material-ui/core/Drawer';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { logoutThunk } from 'redux/authSlice';
import { ROUTES } from 'routes/config';
import Avatar from './Avatar/Avatar';
interface INavigationBarProps {
  hasFullLogo?: boolean;
  children?: any;
  isAuthButton?: boolean;
  displaySidebar?: boolean;
}

const getLinkLocation = (isLoggedIn: boolean) => {
  return isLoggedIn
    ? [
        { name: 'Home', to: ROUTES.HOME },
        { name: 'Sign out', to: 'sign-out' },
      ]
    : [
        { name: 'Home', to: ROUTES.HOME },
        { name: 'Sign in', to: ROUTES.SIGN_IN_PAGE },
        { name: 'Sign up', to: ROUTES.SIGN_UP_PAGE },
      ];
};

const NavigationBar: React.FC<INavigationBarProps> = (props) => {
  const { hasFullLogo, isAuthButton, displaySidebar } = props;

  const { isLoggedIn } = useAppSelector((state) => state.authSlice);
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const containerClassName = classNames({
    [styles['container']]: true,
    [styles['flex-center']]: true,
    [styles['space-between']]: !props.children,
  });

  const logoClassName = classNames({
    [styles['logo']]: true,
    [styles['logo-icon-only']]: !hasFullLogo,
  });

  const rightContainerClassName = classNames({
    [styles['container--right']]: true,
    [styles['hidden']]: !isAuthButton,
    [styles['order-last']]: location.pathname === ROUTES.RESULT_PAGE,
  });

  const { isShownOrHidden } = useAppSelector(
    (state) => state.showMapMobileSlice
  );

  const isMobileAndDisplaySidebar = displaySidebar && isMobile;

  const handleOnSignOut = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    await dispatch(logoutThunk());
  };

  return (
    <div className={containerClassName}>
      {isMobileAndDisplaySidebar ? (
        !isShownOrHidden && (
          <>
            <button onClick={() => setIsOpen(true)}>
              <HamburgerIcon />
            </button>
            <Drawer
              anchor="left"
              open={isOpen}
              onClose={() => setIsOpen(false)}
              classes={{ paper: styles['drawer-root'] }}
            >
              <div className={styles['navigation-container']}>
                <Link className={styles['navigation-link']} to={ROUTES.HOME}>
                  <div className={logoClassName} />
                </Link>
                {getLinkLocation(isLoggedIn).map((link) => {
                  if (link.to === 'sign-out') {
                    return (
                      <Link
                        key={link.to}
                        to={link.to}
                        className={`${styles['navigation-link']} ${styles['navigation-sign-out']}`}
                        onClick={handleOnSignOut}
                      >
                        <Avatar />
                        {link.name}
                      </Link>
                    );
                  }
                  return (
                    <Link
                      key={link.to}
                      to={link.to}
                      className={`${styles['navigation-link']}`}
                    >
                      {link.name}
                    </Link>
                  );
                })}
              </div>
            </Drawer>
          </>
        )
      ) : (
        <Link className={styles['container--left']} to={ROUTES.HOME}>
          <div className={logoClassName} />
        </Link>
      )}
      <div className={rightContainerClassName}>
        <NavigationLink />
      </div>
      {props.children && (
        <div className={styles['children']}>{props.children}</div>
      )}
    </div>
  );
};

export default NavigationBar;
