import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { ReactComponent as ServiceIcon } from 'assets/icons/services.svg';
import { ReactComponent as DateIcon } from 'assets/icons/date-picker.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';
import { ReactComponent as SearchBarSearchIcon } from 'assets/icons/searchbar-search-icon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { Divider } from 'components/DentalDivider';

import styles from './InnerSearchBar.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import useIsMobile from 'hooks/useIsMobile';
import { setShownOrHiddenMapOnMobile } from 'redux/showMapMobileSlice';
import { convertToDate } from 'utils/dates';
import getServiceName from 'utils/getServiceName';

interface IResultPageSearchBarProps {
  onClick: () => void;
}

const InnerSearchBar: React.FC<IResultPageSearchBarProps> = (props) => {
  const { onClick = () => {} } = props;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const currentDateOption = query.get('date');
  const searchBarOptionsSlice = useAppSelector(
    (state) => state.searchBarOptionsSlice
  );
  const { isShownOrHidden } = useAppSelector(
    (state) => state.showMapMobileSlice
  );

  const mapSlice2 = useAppSelector((state) => state.mapSlice2);

  const filters = useAppSelector((state) => state.filterSlice);

  const dispatch = useAppDispatch();

  const secondaryFieldStyle = `${styles['search-bar__fields-wrapper']} ${styles['search-bar__fields-wrapper__secondary']}`;

  const serviceName = getServiceName(
    query.get('service'),
    searchBarOptionsSlice.service
  );

  const isMobile = useIsMobile();

  const toggleMap = () => {
    dispatch(
      setShownOrHiddenMapOnMobile({
        isShownOrHidden: !isShownOrHidden,
      })
    );
  };

  const isMapLabelDisplayed = () => {
    if (mapSlice2.clinics.ids.length > 0) {
      return true;
    }
  };

  return (
    <>
      {!isShownOrHidden && (
        <div className={styles['search-bar']} onClick={onClick}>
          <div className={styles['search-bar__content-on-mobile']}>
            <SearchBarSearchIcon
              className={styles['search-bar__mini-search-icon']}
            />
            <div className={styles['search-bar__mobile-content']}>
              <p
                className={`${styles['search-bar__fields-content']} ${styles['search-bar__mobile-service-content']}`}
              >
                {serviceName}
              </p>
              {isMobile && (
                <p
                  className={`${styles['search-bar__fields-content']} ${styles['search-bar__mobile-date-content']}`}
                >
                  {currentDateOption
                    ? moment(convertToDate(currentDateOption)).format(
                        'DD MMM, YYYY'
                      )
                    : null}
                </p>
              )}
            </div>
          </div>
          <div className={secondaryFieldStyle}>
            <ServiceIcon className={styles['search-bar__icons']} />
            <p className={styles['search-bar__fields-content']}>
              {serviceName}
            </p>
          </div>
          <div className={styles['search-bar__divider-wrapper']}>
            <Divider orientation="vertical" height={2.8} />
          </div>
          <div className={secondaryFieldStyle}>
            <DateIcon className={styles['search-bar__icons']} />
            <p className={styles['search-bar__fields-content']}>
              {currentDateOption
                ? moment(convertToDate(currentDateOption)).format('DD MMM')
                : null}
            </p>
          </div>
          <div className={styles['search-bar__divider-wrapper']}>
            <Divider orientation="vertical" height={2.8} />
          </div>
          <div className={secondaryFieldStyle}>
            <LocationIcon
              className={styles['search-bar__icons']}
              color={filters.location.name ? '#b118b0' : ''}
            />
            <p className={styles['search-bar__fields-content']}>
              {filters.location.name}
            </p>
          </div>
          <DentalButton className={styles.button} variant="contained">
            <SearchIcon className={styles['button-label']} />
          </DentalButton>
        </div>
      )}
      <div className={styles['map-button-wrapper']} onClick={toggleMap}>
        {isMapLabelDisplayed() && !isShownOrHidden && (
          <p className={styles['map-button-content']}>Map</p>
        )}
        {isShownOrHidden && (
          <button className={styles['map-back']}>
            <ArrowLeft className={styles['back-icon']} />
            <span>Back to List</span>
          </button>
        )}
      </div>
    </>
  );
};

export default InnerSearchBar;
