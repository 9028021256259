import React, { forwardRef, LegacyRef } from 'react';
import moment from 'moment';

import { ReactComponent as LeftArrow } from 'assets/icons/left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/right.svg';

import styles from './MonthInput.module.scss';

interface IProps {
  value?: string;
  onClick?: () => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
}

const MonthInput = (props: IProps, ref: LegacyRef<HTMLDivElement>) => {
  const isCurrentMonth = moment(props.value, 'MMMM YYYY')
    .clone()
    .isSame(moment(), 'months');

  return (
    <div className={styles.inputWrapper} ref={ref}>
      <button
        disabled={isCurrentMonth}
        className={styles.leftArrowBtn}
        onClick={props.onClickPrevious}
      >
        <LeftArrow />
      </button>
      <div className={styles.label} onClick={props.onClick}>
        {props.value}
      </div>
      <button className={styles.rightArrowBtn} onClick={props.onClickNext}>
        <RightArrow />
      </button>
    </div>
  );
};

export default forwardRef(MonthInput);
