import axios, { CancelToken } from 'axios';
import getPractitionerName from 'utils/getPractitionerName';

interface IPractitioner {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  specialties: string[];
  rating: number;
  reviewCount: number;
  name: string;
  title: string;
  specialist: string;
}

interface IGetPractitionerOptions {
  data: IPractitioner[];
}

const mapPractitionerOptions = (data: IPractitioner) => ({
  id: data.id,
  title: getPractitionerName(data),
  avatar: data.avatar,
  specialties:
    data.specialties && Array.isArray(data.specialties) ? data.specialties : [],
  rating: data.rating,
  reviewCount: data.reviewCount,
});

export const getPractitionerOptions = async (
  clinicId: string,
  serviceId: string,
  sortBy?: string,
  cancelToken?: CancelToken
) => {
  const res = await axios.get<IGetPractitionerOptions>(
    `clinics/${clinicId}/practitioners`,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: {
        id: clinicId,
        sort: sortBy ? 'asc,asc' : 'asc',
        sortBy: sortBy ? `${sortBy},firstName` : 'firstName',
        serviceId,
      },
      cancelToken,
    }
  );

  return res.data.data.map((item) => mapPractitionerOptions(item));
};
