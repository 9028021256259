import React, { FC } from 'react';

import {
  MINUTE_OF_A_BLOCK,
  convertTime,
} from 'utils/convertMinutesIntoHoursOfDay';
import { IWeeklyTimeSlotsClinicData } from 'services/APIs/getClinicAvailability';

import { toLongestArrayAvaiBlocksForClinic } from './utils/toLongestArrayAvaiBlocks';

import css from './DayView.module.scss';
import { mergePractitionersAvaiBlocks } from 'pages/UpdatedSearchResultPage/utils/mergePractitionersAvaiBlocks';

interface DayViewClinicProps {
  clinicData?: IWeeklyTimeSlotsClinicData;
  onClickTimeSlot: (timeSlot: number, practitionerIds: string[]) => void;
  date: string;
}

const DayViewClinic: FC<DayViewClinicProps> = ({
  clinicData,
  onClickTimeSlot,
  date,
}) => {
  const avaiBlocks = mergePractitionersAvaiBlocks(
    clinicData?.doctors || [],
    clinicData?.slotInterval
  );

  const renderBlocks = toLongestArrayAvaiBlocksForClinic(avaiBlocks, date).map(
    (block, index) => {
      return block ? (
        <button
          key={`${date}-block-${block}`}
          className={css['timeslot']}
          onClick={() =>
            onClickTimeSlot(
              Number(block),
              avaiBlocks.dateItems[date][block].practitionerIds
            )
          }
        >
          {convertTime(Number(block) * MINUTE_OF_A_BLOCK)}
        </button>
      ) : (
        <button key={`${date}-${index}`} className={css['timeslot-none']}>
          -
        </button>
      );
    }
  );
  return <>{renderBlocks}</>;
};

export default DayViewClinic;
