import React from 'react';

import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';
import { Divider } from 'components/DentalDivider';

import styles from './Info.module.scss';

interface Props {
  clinicName: string;
  clinicAddress: string;
  distance: number;
}

const Info: React.FC<Props> = ({ clinicName, clinicAddress, distance }) => {
  return (
    <div className={styles['container']}>
      <div
        className={`${styles['section']} ${styles.clinicName} ${styles['bold']}`}
      >
        {clinicName}
      </div>
      <Divider orientation="horizontal" width={4.2} />
      {distance && (
        <div className={`${styles.clinicAddress} ${styles['section']}`}>
          <LocationIcon className={styles['icon']} />

          <div className={styles['location-container']}>
            <span className={styles['bold']}>{`${distance} km`}</span>
            <div className={styles['desktop-view']}>
              <span>•</span>
              <span>{clinicAddress}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Info;
