import { ITimeSlot } from 'interfaces/timeslotType';

const toLongestArrayAvaiBlocksForClinic = (
  avaiBlocks: {
    dateItems: {
      [key: string]: {
        [key: string]: {
          practitionerIds: string[];
        };
      };
    };
    availableDates: string[];
  },
  key: string
): (string | undefined)[] => {
  if (avaiBlocks?.dateItems?.[key]) {
    const amountBlockByDate = Object.keys(avaiBlocks.dateItems).map(
      (dateItem) => Object.keys(avaiBlocks.dateItems[dateItem]).length
    );
    const maximumBlockByDate = Math.max(...amountBlockByDate);

    const baseArray = Object.keys(avaiBlocks.dateItems[key]);
    const appendArray = Array.from(
      {
        length: maximumBlockByDate - baseArray.length,
      },
      () => undefined
    );
    return [...baseArray, ...appendArray];
  } else {
    return [];
  }
};

const toLongestArrayAvaiBlocksForPractitioner = (
  timeSlots: ITimeSlot[],
  currentIndex: number
): (number | undefined)[] => {
  if (timeSlots.length !== 0) {
    const amountBlockByDate = timeSlots.map((slot) => slot.blocks.length);
    const maximumBlockByDate = Math.max(...amountBlockByDate);
    const baseArray = timeSlots[currentIndex].blocks;
    const appendArray = Array.from(
      {
        length: maximumBlockByDate - baseArray.length,
      },
      () => undefined
    );
    return [...baseArray, ...appendArray];
  }
  return [];
};

export {
  toLongestArrayAvaiBlocksForClinic,
  toLongestArrayAvaiBlocksForPractitioner,
};
