export enum ESearchFields {
  service = 'service',
  date = 'date',
  location = 'location',
}

export type IFilterState = {
  service: string;
  date: string;
  location: {
    name: string;
    lat: number;
    lng: number;
    placeId: string;
    timezone: string;
  };
};

export type FilterTitle =
  | `${ESearchFields.service}`
  | `${ESearchFields.date}`
  | `${ESearchFields.location}`;
