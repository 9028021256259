import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { LOADING_STATUS } from 'utils/constants/common';
import { passwordValidation } from 'utils/inputValidation';
import { clearAuthStatusAndError, resetPasswordThunk } from 'redux/authSlice';
import { Header, Layout } from 'components/Layout';
import { NavigationBar } from 'components/NavigationBar';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import FormTextInput from 'components/BookingForm/FormTextInput/FormTextInput';
import PasswordGuidance, {
  VALIDATIONS,
} from 'components/PasswordGuidance/PasswordGuidance';

import styles from './ResetPasswordPage.module.scss';
import { verifyResetPasswordTokenAPI } from 'services/APIs';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import SuccessOrInvalidPage from './SuccessOrInvalidPage';
import { renderToast } from 'components/CustomToast/CustomToast';

const ResetPasswordPage = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.authSlice);

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const token = query.get('token');

  const { control, formState, watch, getValues } = useForm({
    mode: 'all',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const [isDone, setIsDone] = useState(false);

  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const [isInvalidPage, setIsInvalidPage] = useState(false);

  const verifyToken = useCallback(async () => {
    if (!token || !email) {
      setIsLoadingScreen(false);
      setIsInvalidPage(true);
    } else {
      const isVerified = await verifyResetPasswordTokenAPI(token, email);
      setIsLoadingScreen(false);
      setIsInvalidPage(!isVerified);
    }
  }, [token, email]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  const disabledSubmitButton = () => {
    return !formState.isDirty || !formState.isValid;
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (token && email) {
      dispatch(
        resetPasswordThunk({
          token,
          email,
          password: getValues('password'),
        })
      );
    }
  };

  useEffect(() => {
    if (authState.status === LOADING_STATUS.SUCCESS) {
      if (authState.error) {
        renderToast({ message: authState.error });
      } else {
        setIsDone(true);
      }
    }
  }, [authState, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearAuthStatusAndError());
    };
  }, [dispatch]);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header hasBoxShadow>
            <NavigationBar hasFullLogo />
          </Header>
        </div>

        {isLoadingScreen ? (
          <LoadingScreen />
        ) : (
          <div className={styles['content-container']}>
            {isInvalidPage && (
              <SuccessOrInvalidPage
                message="Invalid or expired link"
                isInvalid
              />
            )}

            {!isInvalidPage && isDone && (
              <SuccessOrInvalidPage
                message="Your password has been successfully reset"
                isInvalid={false}
              />
            )}

            {!isInvalidPage && !isDone && (
              <div className={styles.content}>
                <div className={styles.headerSection}>
                  <div className={styles.title}>Reset your password</div>
                  <div className={styles.subTitle}>
                    A request has been made for reset your account password with
                    email address&nbsp;
                    <span className={styles.email}>{email}</span>
                  </div>
                </div>

                <div className={styles.bodySection}>
                  <form noValidate>
                    <FormTextInput
                      type="password"
                      name="password"
                      label="Password"
                      control={control}
                      placeholder="Input your password"
                      required="This field is required"
                      rules={{
                        validate: (value: string) => {
                          const isPasswordValid = VALIDATIONS.every(
                            ({ pattern }) => pattern.test(value)
                          );
                          return isPasswordValid;
                        },
                      }}
                    />

                    <PasswordGuidance currentPassword={watch('password')} />

                    <FormTextInput
                      type="password"
                      name="confirmPassword"
                      label="Confirm password"
                      control={control}
                      placeholder="Re-enter password"
                      required="This field is required"
                      rules={{
                        validate: (value: string) => {
                          const password = getValues('password');

                          if (value !== password) {
                            return 'Passwords do not match';
                          }
                        },
                      }}
                    />
                  </form>
                </div>

                <div className={styles.footerSection}>
                  <DentalButton
                    variant="contained"
                    disabled={
                      disabledSubmitButton() ||
                      !passwordValidation(getValues('password'))
                    }
                    onClick={onSubmit}
                    isLoading={authState.status === LOADING_STATUS.LOADING}
                    type="submit"
                  >
                    Submit
                  </DentalButton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ResetPasswordPage;
