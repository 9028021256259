import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import MonthHeader from './MonthHeader/MonthHeader';
import MonthInput from './MonthInput/MonthInput';

import './MonthViewNavigation.module.scss';

interface IProps {
  date: string | null;
  setDate: (date: string) => void;
}

const MonthViewNavigation = (props: IProps) => {
  const { date, setDate } = props;

  const parsedDate = useMemo(() => {
    if (date && !moment(date, 'YYYY-MM-DD').isValid()) {
      return moment(Date.parse(date)).format('YYYY-MM-DD');
    }
    return date;
  }, [date]);

  const onChangeDate = (date: Date) => {
    const dateString = moment(date).format('YYYY-MM-DD');
    const isSameMonthAndYear =
      moment().isSame(moment(dateString, 'YYYY-MM-DD'), 'month') &&
      moment().isSame(moment(dateString, 'YYYY-MM-DD'), 'year');

    if (isSameMonthAndYear) {
      setDate(moment().format('YYYY-MM-DD'));
      return;
    }

    setDate(
      moment(dateString, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD')
    );
  };

  const onClickNext = () => {
    const nextDate = moment(parsedDate, 'YYYY-MM-DD')
      .clone()
      .add(1, 'months')
      .toDate();

    onChangeDate(nextDate);
  };

  const onClickPrevious = () => {
    const previousDate = moment(parsedDate, 'YYYY-MM-DD')
      .clone()
      .subtract(1, 'months')
      .toDate();

    onChangeDate(previousDate);
  };

  const startedDateOfMonth = moment().startOf('months').toDate();
  const selectedDate = date ? moment(parsedDate, 'YYYY-MM-DD').toDate() : null;

  return (
    <div className="calendar-selector-container">
      <DatePicker
        selected={selectedDate}
        minDate={startedDateOfMonth}
        onChange={onChangeDate}
        dateFormat="MMM yyyy"
        showMonthYearPicker
        renderCustomHeader={MonthHeader}
        customInput={
          <MonthInput
            onClickNext={onClickNext}
            onClickPrevious={onClickPrevious}
          />
        }
      />
    </div>
  );
};

export default MonthViewNavigation;
