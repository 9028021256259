import ForgotPasswordForm from 'components/ForgotPasswordForm/ForgotPasswordForm';
import { FC, useState } from 'react';
import { STEPS } from 'redux/bookingFormSlice';
import NewPatient from './NewPatient/NewPatient';
import SignInForm from './SignInForm/SignInForm';
import SignUpForm from './SignUpForm/SignUpForm';
import Verification from './Verification/Verification';

interface Step2Props {
  page: string;
  clinicId: string;
  serviceId: string;
  appointmentDate: string;
  startTime: string;
}

const { VERIFICATION, SIGN_IN, SIGN_UP, NEW_PATIENT, FORGET_PASSWORD } = STEPS;

const Step2: FC<Step2Props> = ({
  page,
  clinicId,
  serviceId,
  appointmentDate,
  startTime,
}) => {
  const [key, setKey] = useState(0);
  const resetForm = () => {
    setKey((key) => key + 1);
  };
  return (
    <>
      {page === VERIFICATION && (
        <Verification
          clinicId={clinicId}
          serviceId={serviceId}
          appointmentDate={appointmentDate}
          startTime={startTime}
        />
      )}
      {page === SIGN_IN && <SignInForm isInBookingFlow />}
      {page === SIGN_UP && (
        <SignUpForm key={key} isInBookingFlow resetForm={resetForm} />
      )}
      {page === NEW_PATIENT && <NewPatient key={key} resetForm={resetForm} />}
      {page === FORGET_PASSWORD && <ForgotPasswordForm isInBookingFlow />}
    </>
  );
};

export default Step2;
