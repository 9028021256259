import React from 'react';
import cx from 'classnames';
import { Pagination } from '@material-ui/lab';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { ReactComponent as PrevIcon } from 'assets/icons/left-darker.svg';
import { ReactComponent as NextIcon } from 'assets/icons/right-darker.svg';

import useStyles from './useStyles';
import styles from './PaginationController.module.scss';

interface Props {
  currentPage: number;
  pageCount: number;
  onChangePage: (page: number) => void;
}

const PaginationController: React.FC<Props> = ({
  currentPage,
  pageCount,
  onChangePage,
}) => {
  const prevIconClassName = cx({
    [styles['nav-btn']]: true,
    [styles['disabled']]: currentPage === 1,
  });

  const nextIconClassName = cx({
    [styles['nav-btn']]: true,
    [styles['disabled']]: currentPage === pageCount,
  });

  const classes = useStyles();

  const handleClickPrev = () => {
    onChangePage(currentPage - 1);
  };

  const handleClickNext = () => {
    onChangePage(currentPage + 1);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    onChangePage(page);
  };

  if (pageCount > 1) {
    return (
      <div className={styles['container']}>
        <DentalButton
          variant="text"
          disabled={currentPage === 1}
          onClick={handleClickPrev}
          className={styles.navigateBtn}
        >
          <PrevIcon className={prevIconClassName} />
        </DentalButton>
        <Pagination
          page={currentPage}
          count={pageCount}
          className={classes.text}
          onChange={handleChangePage}
          color="secondary"
          size="small"
          hideNextButton
          hidePrevButton
        />
        <DentalButton
          variant="text"
          disabled={currentPage === pageCount}
          onClick={handleClickNext}
          className={styles.navigateBtn}
        >
          <NextIcon className={nextIconClassName} />
        </DentalButton>
      </div>
    );
  }
  return null;
};

export default PaginationController;
