import moment from 'moment';
import { convertToDate } from './dates';

export const isCurrentDate = (date: string, timezone?: string) => {
  const now = moment()
    .tz(timezone || moment.tz.guess())
    .format('YYYY-MM-DD');

  if (moment(date, 'YYYY-MM-DD', true).isValid()) {
    return moment(date, 'YYYY-MM-DD').isSame(moment(now, 'YYYY-MM-DD'), 'days');
  } else {
    const dateStr = moment(convertToDate(date)).format('YYYY-MM-DD');
    return moment(dateStr, 'YYYY-MM_DD').isSame(
      moment(now, 'YYYY-MM-DD'),
      'days'
    );
  }
};
