import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';

import styles from './SuccessOrInvalidPage.module.scss';
import { ROUTES } from 'routes/config';

interface IProps {
  message: string;
  isInvalid: boolean;
}

const SuccessOrInvalidPage = ({ message, isInvalid }: IProps) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ConfirmIcon />
        <div className={styles.title}>{message}</div>
        {isInvalid ? (
          <DentalButton
            variant="contained"
            onClick={() => history.push(ROUTES.HOME)}
          >
            Go to FirstIn
          </DentalButton>
        ) : (
          <DentalButton
            className={styles.continueBtn}
            variant="contained"
            onClick={() => history.push(ROUTES.SIGN_IN_PAGE)}
          >
            Continue scheduling appointment
          </DentalButton>
        )}
      </div>
    </div>
  );
};

export default SuccessOrInvalidPage;
