import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { FC, memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import {
  IPractitionerResponse,
  clearPractitionerSearchDetails,
  getPractitionerList,
  setIsLoadingMore,
} from 'redux/practitionerSearchResultSlice';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';

import PractitionerAvailability from './PractitionerAvailability/PractitionerAvailability';
import PractitionerInfo from '../CardInfo/Info/PractitionerInfo/PractitionerInfo';
import TotalCount from '../TotalCount/TotalCount';
import PractitionerItem from './PractitionerItem/PractitionerItem';

import styles from './PractitionerList.module.scss';
import BookingForm from 'components/BookingForm/BookingForm';
import useIsMobile from 'hooks/useIsMobile';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { resetSearchResultFilter } from 'redux/searchResultFilterSlice';
import { batch } from 'react-redux';
import { clearMapData } from 'redux/mapSliceV2';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { DentistInfo } from '../utils';

interface PractitionerListProps {
  date: string;
  isDayView: boolean;
  searchDate: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}

const PractitionerList: FC<PractitionerListProps> = ({
  isDayView,
  date,
  searchDate,
  setDate,
}) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const isMobile = useIsMobile();

  const {
    filterSlice: {
      location: { lat, lng, timezone, name },
      service,
    },
    practitionerSearchResultSlice: {
      practitioners,
      status,
      hasMore,
      page,
      totalPractitioners,
    },
    searchBarOptionsSlice,
    searchResultFilterSlice: {
      sort,
      gender,
      minRating,
      timeBlocks,
      numberOfAppliedFilters,
    },
  } = useAppSelector((state) => state);

  const [selectedPractitioner, setSelectedPractitioner] =
    useState<IPractitionerResponse | null>(null);

  const [selectedDate, setSelectedDate] = useState('');

  const [selectedSlot, setSelectedSlot] = useState<{
    date: string;
    timeSlot: number;
    operatoryId: string;
    practitioner: IPractitionerResponse;
    dentist: DentistInfo;
  } | null>(null);

  useEffect(() => {
    const getData = async () => {
      const dayCount = isMobile ? 2 : 4;
      const endDate = moment(date, 'YYYY-MM-DD')
        .add(dayCount, 'days')
        .format('YYYY-MM-DD');
      const dates = [date, endDate];

      const { payload } = await dispatch(
        getPractitionerList({
          searchDate: date === searchDate ? searchDate : undefined,
          currentDate: moment().format(),
          dates,
          latitude: lat,
          longitude: lng,
          page,
          sortBy: sort,
          serviceId: service,
          isMonthView: !isDayView,
          timeBlocks,
          minRating,
          gender,
        })
      );

      if (numberOfAppliedFilters > 0) return;

      if (searchDate !== date) return;

      const firstDateOfBlock =
        payload.practitioners[0]?.availableBlocks[0]?.date;

      if (firstDateOfBlock === searchDate) return;

      sessionStorage.setItem('cachedDate', searchDate);

      if (firstDateOfBlock) {
        setDate(firstDateOfBlock);
      }
    };

    getData();
  }, [
    date,
    dispatch,
    gender,
    isDayView,
    isMobile,
    lat,
    lng,
    minRating,
    numberOfAppliedFilters,
    page,
    searchDate,
    service,
    setDate,
    sort,
    timeBlocks,
    timezone,
  ]);

  const isSearchDateNotFoundResult =
    sessionStorage.getItem('cachedDate') ||
    (practitioners.length > 0 &&
      date === searchDate &&
      !moment(date, 'YYYY-MM-DD').isSame(
        moment(practitioners[0].availableBlocks[0].date, 'YYYY-MM-DD'),
        'd'
      ));

  const navigateToProfilePage = ({
    event,
    clinicSlugName,
    practitionerSlugName,
  }: {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    clinicSlugName: string;
    practitionerSlugName: string;
  }) => {
    if (event.target instanceof HTMLAnchorElement) return;

    const path = `/${clinicSlugName}/${practitionerSlugName}?date=${encodeURIComponent(
      date
    )}&service=${encodeURIComponent(
      searchBarOptionsSlice.service.slugs[service]
    )}`;

    history.push(path);
  };

  const handleResetAllFilters = () => {
    batch(() => {
      dispatch(resetSearchResultFilter());
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
      dispatch(clearMapData());
    });
  };

  if (status === 'loading' && practitioners.length === 0) {
    return (
      <div className={styles['circular-loader-wrapper']}>
        <CircularProgress size={48} className={styles['circular-loader']} />;
      </div>
    );
  }

  if (selectedSlot) {
    const serviceTitle = searchBarOptionsSlice.service.data[service];
    const serviceName = serviceTitle;

    return (
      <BookingForm
        date={selectedSlot.date}
        timeBlock={selectedSlot.timeSlot}
        clinicId={selectedSlot.practitioner.clinicId}
        serviceId={service}
        practitionerId={selectedSlot.practitioner.id}
        operatoryId={selectedSlot.operatoryId}
        dentist={selectedSlot.dentist}
        bookingSummaryInfo={{
          avatar: selectedSlot.practitioner.avatar,
          title: `${selectedSlot.practitioner.name}${
            selectedSlot.practitioner.study
              ? `, ${selectedSlot.practitioner.study}`
              : ''
          }`,
          clinicName: `${selectedSlot.practitioner.clinic.name}`,
          clinicEmail: `${selectedSlot.practitioner.clinic.email}`,
          clinicPhoneNumber: `${selectedSlot.practitioner.clinic.phoneNumber}`,
          clinicAddress: `${selectedSlot.practitioner.clinic.address}`,
          serviceTitle: `${serviceName} • ${selectedSlot.practitioner.clinic.name}`,
        }}
        closeBookingDialog={() => setSelectedSlot(null)}
      />
    );
  }

  if (practitioners.length === 0 && numberOfAppliedFilters > 0) {
    return (
      <div className={styles['filter-no-result']}>
        <span>No results found for your filters.</span>
        <button onClick={handleResetAllFilters}>Reset all filters</button>
      </div>
    );
  }

  return (
    <>
      {selectedPractitioner && (
        <Modal
          open={!!selectedPractitioner}
          onClose={() => {
            setSelectedPractitioner(null);
            setSelectedDate('');
          }}
          customStyle={styles['modal-wrapper']}
        >
          <PractitionerAvailability
            practitioner={selectedPractitioner}
            serviceId={service}
            searchingDate={
              selectedDate
                ? selectedDate
                : selectedPractitioner.availableBlocks[0].date
            }
            onBookAppointment={({ date, timeSlot, operatoryId, dentist }) => {
              setSelectedSlot({
                date,
                timeSlot,
                operatoryId,
                practitioner: selectedPractitioner,
                dentist,
              });
            }}
            isDefaultDayView={selectedDate ? true : isDayView}
          />
        </Modal>
      )}
      <div className={styles['container']}>
        {isSearchDateNotFoundResult ? (
          <>
            <div className={styles['no-result']}>
              No results found for{' '}
              {moment(
                sessionStorage.getItem('cachedDate'),
                'YYYY-MM-DD'
              ).format('ddd, MMM D')}
            </div>
            <div className={styles['latest-availability']}>
              See our latest availability on{' '}
              {moment(
                practitioners[0].availableBlocks[0].date,
                'YYYY-MM-DD'
              ).format('ddd, MMM D')}{' '}
            </div>
          </>
        ) : (
          <TotalCount
            title={`${totalPractitioners} Practitioner(s)`}
            nearByLocation={`nearby ${name}`}
          />
        )}

        {practitioners.map((practitioner) => (
          <PractitionerItem
            key={practitioner.id}
            practitioner={practitioner}
            isDayView={isDayView}
            navigatedDate={date}
            setSelectedPractitioner={setSelectedPractitioner}
            setSelectedDate={setSelectedDate}
            navigateToProfilePage={navigateToProfilePage}
            setSelectedSlot={setSelectedSlot}
          >
            <div className={styles['body-container']}>
              <PractitionerInfo
                specialty={practitioner.specialty}
                practiceName={practitioner.clinic.name}
                distance={practitioner.distance}
              />
              <div className={styles.line} />
              <span className={styles.text}>{practitioner.description}</span>
            </div>
          </PractitionerItem>
        ))}
      </div>

      <div className={styles['center-content']}>
        {hasMore && (
          <DentalButton
            size="large"
            variant="outlined"
            onClick={() => {
              dispatch(setIsLoadingMore());
            }}
            isLoading={status === 'loading-more'}
            className={styles['load-more-button']}
          >
            Load more
          </DentalButton>
        )}
        {!hasMore && practitioners.length > 0 && (
          <div className={styles['no-more-result']}>No more search results</div>
        )}
      </div>
    </>
  );
};

export default memo(PractitionerList);
