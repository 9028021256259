import { FC, useCallback, useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import cx from 'classnames';
import CountDownTimer from './CountDownTimer/CountDownTimer';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import NavigationBtnControl from 'components/BookingForm/NavigationBtnControl/NavigationBtnControl';
import { verifyOtpByCode } from 'services/APIs/otp';

import styles from './VerificationInput.module.scss';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import { renderToast } from 'components/CustomToast/CustomToast';

interface VerificationInputProps {
  onBackClick: () => void;
  phoneNumber: string;
  onCheckedOtpSuccessful: (otpAccessToken: string) => Promise<void>;
}

const VerificationInput: FC<VerificationInputProps> = ({
  onBackClick,
  phoneNumber,
  onCheckedOtpSuccessful,
}) => {
  const isLoadedInsideIframe = useIsLoadedInsideIframe();
  const containerClassName = cx({
    [styles.container]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const inputRef = useRef<any>(null);

  const [isExpired, setIsExpired] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [otpKey, setOtpKey] = useState(0);
  const [otpValue, setOtpValue] = useState('');

  const displayErrorToast = useCallback((message: string) => {
    if (!inputRef.current) return;
    renderToast({ message });
  }, []);

  const handleCheckedOtp = async (input: string) => {
    setOtpValue(input);

    if (input.length === 6) {
      if (isExpired) {
        setOtpValue('');
        setOtpKey((prev) => prev + 1);
        return displayErrorToast(
          'Verification code expired. Please click on resend.'
        );
      }
      setIsChecked(true);
      try {
        const otpAccessToken = await verifyOtpByCode(phoneNumber, input);
        if (otpAccessToken) {
          await onCheckedOtpSuccessful(otpAccessToken);
        }
      } catch (error) {
        setOtpValue('');
        setOtpKey((prev) => prev + 1);
        displayErrorToast(
          'Invalid verification code. Please enter the correct verification code.'
        );
      }
      setIsChecked(false);
      inputRef.current?.textInput[0].focus();
    }
  };

  return (
    <div className={containerClassName}>
      {isChecked && <LoadingScreen />}
      <div className={styles['otp-form']}>
        <div className={styles['title']}>Enter Verification Code</div>
        <div className={styles['section']}>
          <div>We just sent you an text message.</div>
          <div>Input your code below.</div>
          <ReactCodeInput
            ref={inputRef}
            className={styles['code-input']}
            name="code-input"
            inputMode="numeric"
            type="number"
            fields={6}
            onChange={handleCheckedOtp}
            disabled={isChecked}
            value={otpValue}
            key={otpKey}
          />
          <CountDownTimer
            phoneNumber={phoneNumber}
            onError={displayErrorToast}
            setIsExpired={setIsExpired}
            setIsChecked={setIsChecked}
          />
        </div>
      </div>
      <NavigationBtnControl onBack={onBackClick} />
    </div>
  );
};

export default VerificationInput;
