import { Popper, PopperProps } from '@material-ui/core';
import useStyles from './useStyles';
import cx from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
interface CustomPopperProps extends PopperProps {
  isPractitionerDropdown: boolean;
  isReasonDropdown: boolean;
  isSingular: boolean;
  isForceFullWidth: boolean;
}

const CustomPopper: React.FC<CustomPopperProps> = ({
  isPractitionerDropdown,
  isReasonDropdown,
  isSingular,
  isForceFullWidth,
  ...props
}) => {
  const styles = useStyles();
  const isMobile = useIsMobile();

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const popperClassName = cx({
    [styles.customPopper]: true,
    [styles.spacingContent]: isPractitionerDropdown,
    [styles.shiftPopper]: isPractitionerDropdown && isMobile,
    [styles.shiftPopperIframe]:
      isPractitionerDropdown && !isMobile && isLoadedInsideIframe,
    [styles.fullWidth]: isSingular && isMobile,
    [styles.shiftPopperReasonIframe]:
      isReasonDropdown && !isMobile && isLoadedInsideIframe,
    [styles.forceFullWidth]: isForceFullWidth,
  });

  return (
    <Popper
      {...props}
      className={popperClassName}
      placement="bottom"
      modifiers={{
        offset: {
          enabled: true,
        },
      }}
    />
  );
};

export default CustomPopper;
