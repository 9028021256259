import { AvailableBlockOperatory } from 'interfaces/timeslotType';
import getIsBlockOptimized from './getIsBlockOptimized';

interface Result {
  availableBlockOperatory: AvailableBlockOperatory;
  point: number;
}

const findBestOperatory = ({
  selectedSlot,
  availableBlockOperatories,
  serviceDuration,
}: {
  selectedSlot: number;
  availableBlockOperatories: AvailableBlockOperatory[];
  serviceDuration: number;
}): AvailableBlockOperatory => {
  let bestOperatory: Result | null = null;

  for (let i = 0; i < availableBlockOperatories.length; i++) {
    const availableBlockOperatory = availableBlockOperatories[i];

    const { isOptimized, point } = getIsBlockOptimized(
      selectedSlot,
      availableBlockOperatory.blocks,
      serviceDuration
    );

    if (!isOptimized) {
      continue;
    }

    if (!bestOperatory) {
      bestOperatory = { availableBlockOperatory, point };
      continue;
    }

    // PRIORITY LOWER POINT
    if (bestOperatory.point > point) {
      bestOperatory = { availableBlockOperatory, point };
    }
  }

  // NO OPTIMIZED OPERATORY FOUND, PICK RANDOM
  if (!bestOperatory) {
    // ENSURE THAT THE RANDOM SLOT SELECTED INCLUDE THE SELECTED SLOT
    const suitableBlockOperatory = availableBlockOperatories.find((op) =>
      op.blocks.includes(selectedSlot)
    )!;

    bestOperatory = {
      point: 0,
      availableBlockOperatory: suitableBlockOperatory,
    };
  }

  return bestOperatory.availableBlockOperatory;
};

const findPractitionerWithBestOp = <
  T extends {
    bestOperatory: AvailableBlockOperatory;
  }
>({
  listOfPractitioners,
  selectedSlot,
  serviceDuration,
}: {
  listOfPractitioners: T[];
  selectedSlot: number;
  serviceDuration: number;
}) => {
  let bestPractitioner: { point: number; practitioner: T } | null = null;

  for (let i = 0; i < listOfPractitioners.length; i++) {
    const bestOpCurrentPractitioner = listOfPractitioners[i].bestOperatory;

    const { isOptimized, point } = getIsBlockOptimized(
      selectedSlot,
      bestOpCurrentPractitioner.blocks,
      serviceDuration
    );

    if (!isOptimized) {
      continue;
    }

    if (!bestPractitioner) {
      bestPractitioner = { point, practitioner: listOfPractitioners[i] };
    }

    if (bestPractitioner.point > point) {
      bestPractitioner = { point, practitioner: listOfPractitioners[i] };
    }
  }

  // NO PRACTITIONER HAVE OPTIMIZED OPERATORY, PICK RANDOM
  if (!bestPractitioner) {
    bestPractitioner = { point: 0, practitioner: listOfPractitioners[0] };
  }

  return bestPractitioner.practitioner;
};

export { findBestOperatory, findPractitionerWithBestOp };
