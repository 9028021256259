import { FC } from 'react';
import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';
import { ReactComponent as TimeOfDayIcon } from 'assets/icons/timeofday.svg';
import styles from '../Info.module.scss';
import { useAppSelector } from 'redux/reduxHooks';
import moment from 'moment';

interface ClinicInfoProps {
  distance: number;
  workingHours: {
    day: string;
    time: string;
  }[];
  date: string;
}

const ClinicInfo: FC<ClinicInfoProps> = ({ distance, workingHours, date }) => {
  const { location } = useAppSelector((state) => state.filterSlice);

  const momentDate = moment(date, 'YYYY-MM-DD');

  const timeSlot = workingHours.find(
    (workingHour) => momentDate.format('dddd') === workingHour.day
  )!;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <LocationIcon className={styles['icon']} />
        <div className={`${styles['bold']} ${styles['text']}`}>
          {distance}km •{' '}
          <span className={styles['font-normal']}>{location.name}</span>
        </div>
      </div>
      <div className={styles.row}>
        <TimeOfDayIcon className={styles['icon']} />
        <div className={styles.text}>
          {momentDate.format('ddd')} {timeSlot.time}
        </div>
      </div>
    </div>
  );
};

export default ClinicInfo;
