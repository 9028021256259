import React, { useEffect, useState } from 'react';
import { NavigationBar } from 'components/NavigationBar';
import { Header } from 'components/Layout';

import styles from './SignUpPage.module.scss';
import { Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import SignUpForm from 'components/BookingForm/Step2/SignUpForm/SignUpForm';
import SignUpSuccessful from './SignUpSuccessful/SignUpSuccessful';
import { resetStep2SignUp } from 'redux/bookingFormSlice';
import VerificationInput from 'components/VerificationInput/VerificationInput';
import { saveOtpToken } from 'utils/storage';
import { AUTH_SYS_TYPE } from 'utils/constants/common';
import { signUpSocialThunk, signUpThunk } from 'redux/authSlice';
import { omit } from 'lodash';
import { formatDobForBE } from 'components/BookingForm/utils/helperFunction';
import ProgressBar from 'components/ProgressBar/ProgressBar';

const PAGES_INFO = [
  { page: 1, content: 'User Info', mobileContent: 'Patient' },
  { page: 2, content: 'Verify', mobileContent: 'Verify' },
  { page: 3, content: 'Complete', mobileContent: 'Complete' },
];

function SignUpPage() {
  const {
    authSlice: { isLoggedIn, socialData, sysType },
    bookingFormSlice: {
      step2: { signUp },
    },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [key, setKey] = useState(0);

  const [step, setStep] = useState(PAGES_INFO[0]);

  useEffect(() => {
    return () => {
      dispatch(resetStep2SignUp());
    };
  }, [dispatch]);

  const handleResetForm = () => {
    setKey((key) => key + 1);
  };

  if (isLoggedIn && !socialData) {
    return <Redirect to="/" />;
  }

  const handleOnSignUpSuccessful = () => {
    setStep(PAGES_INFO[1]);
  };

  const handleOnCheckedOtpSuccessful = async (otpAccessToken: string) => {
    saveOtpToken(otpAccessToken, signUp.phoneNumber);

    if (sysType === AUTH_SYS_TYPE.PASSWORD) {
      await dispatch(
        signUpThunk({
          ...omit(signUp, ['countryCode']),
          dob: formatDobForBE(signUp.dob),
          phoneNumber: signUp.phoneNumber,
          sysType,
        })
      );
    } else {
      await dispatch(
        signUpSocialThunk({
          ...omit(signUp, ['countryCode', 'password']),
          dob: formatDobForBE(signUp.dob),
          phoneNumber: signUp.phoneNumber,
        })
      );
    }

    setStep(PAGES_INFO[2]);
  };

  return (
    <div className={styles['upper-section']}>
      <Header hasBoxShadow>
        <NavigationBar>
          <ProgressBar page={step.page} options={PAGES_INFO} />
        </NavigationBar>
      </Header>
      <div className={styles['body']}>
        {step.page === PAGES_INFO[0].page && (
          <SignUpForm
            key={key}
            resetForm={handleResetForm}
            onSignUpSuccessful={handleOnSignUpSuccessful}
          />
        )}
        {step.page === PAGES_INFO[1].page && (
          <VerificationInput
            onBackClick={() => setStep(PAGES_INFO[0])}
            phoneNumber={signUp.phoneNumber}
            onCheckedOtpSuccessful={handleOnCheckedOtpSuccessful}
          />
        )}
        {step.page === PAGES_INFO[2].page && <SignUpSuccessful />}
      </div>
    </div>
  );
}

export default SignUpPage;
