import React, { FC } from 'react';
import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';

import styles from './SignUpSuccessful.module.scss';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import Message from 'components/BookingForm/Message/Message';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import { useAppSelector } from 'redux/reduxHooks';
import { AUTH_SYS_TYPE } from 'utils/constants/common';

interface SignUpSuccessfulProps {}

const SignUpSuccessful: FC<SignUpSuccessfulProps> = () => {
  const {
    authSlice: { sysType },
  } = useAppSelector((state) => state);

  const history = useHistory();
  const isSocialLoggedIn = sysType !== AUTH_SYS_TYPE.PASSWORD;

  const handleNavigatePreviousPage = () => {
    history.goBack();
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ConfirmIcon />
        <p className={styles.text}>Account successfully set up!</p>

        {isSocialLoggedIn ? (
          <DentalButton
            variant="contained"
            onClick={() => history.push(ROUTES.HOME)}
          >
            Go to FirstIn
          </DentalButton>
        ) : (
          <>
            <Message
              type={'warning'}
              message={
                'Further action is required for FirstIn account registration Please click the link in the verification email to complete setting up your account.'
              }
            />
            <div className={styles['container-link']}>
              <DentalButton
                variant="contained"
                className={styles['btn-email']}
                onClick={handleNavigatePreviousPage}
              >
                Schedule Appointment
              </DentalButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignUpSuccessful;
