import moment from 'moment';

const getBookingDate = (date: string) => {
  if (date && !moment(date, 'YYYY-MM-DD', true).isValid()) {
    return moment(Date.parse(date)).format('ddd, MMM D, YYYY');
  }

  return moment(date, 'YYYY-MM-DD').format('ddd, MMM D, YYYY');
};

const formatDobForBE = (date: string) => {
  if (moment(date, 'YYYY-MM-DD', true).isValid()) return date;
  return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
};

export { getBookingDate, formatDobForBE };
