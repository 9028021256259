import { useHistory } from 'react-router-dom';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { ROUTES } from 'routes/config';

import styles from './UnsubscribeSuccessfullyPage.module.scss';

const UnsubscribeSuccessfullyPage = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ConfirmIcon />
        <div className={styles.title}>Unsubscribed Successfully</div>
        <p className={styles.description}>
          You have been successfully removed from our mailing list.
        </p>
        <DentalButton
          variant="contained"
          onClick={() => history.push(ROUTES.HOME)}
          className={styles.btn}
        >
          Done
        </DentalButton>
      </div>
    </div>
  );
};

export default UnsubscribeSuccessfullyPage;
