import React, {
  FC,
  UIEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { CircularProgress } from '@material-ui/core';
import cx from 'classnames';

import { ReactComponent as MoveDown } from 'assets/icons/move-down.svg';
import { ReactComponent as EmptySlotCalendar } from 'assets/icons/empty-slot-calendar.svg';
import useIsMobile from 'hooks/useIsMobile';
import { ITimeSlot } from 'interfaces/timeslotType';
import { IWeeklyTimeSlotsClinicData } from 'services/APIs/getClinicAvailability';

import DayViewClinic from './DayViewClinic';
import DayViewPractitioner from './DayViewPractitioner';
import convertDateBlocks from './utils/convertDateBlocks';
import reducer, { VIEW_ACTION, initialState } from './reducer';

import css from './DayView.module.scss';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import axios from 'axios';
import moment from 'moment';
import getNearestAvailForClinic from './utils/getNearestAvailForClinic';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import { useLocation } from 'react-router-dom';
import { parseQueryUrl } from 'utils/parseQueryUrl';

interface DayViewProps {
  isLoading: boolean;
  date: string;
  onDateUpdate: (newDate: string) => void;
  isClinicSearch: boolean;
  clinicData?: IWeeklyTimeSlotsClinicData;
  timeSlots?: ITimeSlot[];
  onClickClinicTimeSlot?: (
    timeSlot: number,
    appointmentDate: string,
    practitionerIds: string[]
  ) => void;
  onClickPractitionerTimeSlot?: (
    timeSlot: number,
    appointmentDate: string
  ) => void;
  headerClassName?: string;
  bodyClassName?: string;
  blurBottomBgClassName?: string;
  blurTopBgClassName?: string;
  iconClassName?: string;
  clinicId?: string;
  practitionerId?: string;
  serviceId: string;
}

const DayView: FC<DayViewProps> = ({
  isLoading,
  date,
  isClinicSearch,
  clinicData,
  timeSlots,
  onClickClinicTimeSlot,
  onClickPractitionerTimeSlot,
  headerClassName,
  bodyClassName,
  blurTopBgClassName,
  blurBottomBgClassName,
  iconClassName,
  clinicId,
  practitionerId,
  serviceId,
  onDateUpdate,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isLoadedInsideIframe = useIsLoadedInsideIframe();
  const isMobile = useIsMobile();
  const location = useLocation();

  const { codeUrl, serviceSlugNameUrl } = parseQueryUrl(location);

  const baseIconClassName = cx({
    [css['animate-icon']]: true,
    [css['animate-icon-remove']]: !state.displayMoreIcon,
  });

  const bodyContainerClassName = cx({
    [css['grid-container-body']]: true,
    [css['grid-container-body-iframe']]: isLoadedInsideIframe,
  });

  const blurTopClassName = cx({
    [css['blur-bg-top']]: true,
    [css['blur-bg-top-iframe']]: isLoadedInsideIframe,
  });

  const blurBottomClassName = cx({
    [css['blur-bg-bottom']]: true,
    [css['blur-bg-bottom-iframe']]: isLoadedInsideIframe,
  });

  const isAllAvailableBlocksEmpty = clinicData?.doctors?.every((doctor) =>
    doctor.availableBlocks.every((block) => block.blocks.length === 0)
  );

  const isClinicDataEmpty = isClinicSearch && isAllAvailableBlocksEmpty;
  const isTimeSlotsEmpty = !isClinicSearch && timeSlots?.length === 0;
  const dataBlocks = convertDateBlocks(date, isMobile);

  const [nextAvailabilityDate, setNextAvailabilityDate] = useState('');
  const [isLoadingNextAvailabilityDate, setIsLoadingNextAvailabilityDate] =
    useState(false);

  useEffect(() => {
    const getNearestAvailabilitySlots = async () => {
      if (isLoading) return;

      const body = {
        date: moment(date).format('YYYY-MM-DD'),
        serviceId,
        ...(codeUrl && { code: codeUrl }),
        ...(serviceSlugNameUrl && { serviceSlugName: serviceSlugNameUrl }),
      };

      if (isClinicDataEmpty) {
        setIsLoadingNextAvailabilityDate(true);

        const response = await axios.post(
          `/clinics/${clinicId}/next-availability`,
          body,
          {
            baseURL: process.env.REACT_APP_AXIOS_BE_URL,
          }
        );
        if (response.data.doctors.length > 0) {
          const nextAvail = getNearestAvailForClinic(response.data.doctors);
          setNextAvailabilityDate(nextAvail.nextAvailabilityDate!);
        }

        setIsLoadingNextAvailabilityDate(false);
        return;
      }

      if (isTimeSlotsEmpty && practitionerId && practitionerId !== 'all') {
        setNextAvailabilityDate('');
        setIsLoadingNextAvailabilityDate(true);

        const response = await axios.post(
          `/practitioners/${practitionerId}/next-availability`,
          body,
          {
            baseURL: process.env.REACT_APP_AXIOS_BE_URL,
          }
        );

        if (response.data?.nextAvailabilityDate) {
          setNextAvailabilityDate(response.data.nextAvailabilityDate);
        }

        setIsLoadingNextAvailabilityDate(false);
      }
    };
    getNearestAvailabilitySlots();
  }, [
    clinicId,
    codeUrl,
    date,
    isClinicDataEmpty,
    isLoading,
    isTimeSlotsEmpty,
    practitionerId,
    serviceId,
    serviceSlugNameUrl,
  ]);

  useEffect(() => {
    if (isLoading || isLoadingNextAvailabilityDate) {
      return;
    }
    const element = containerRef.current!;
    const isOverflow = element.scrollHeight > element.clientHeight;

    dispatch({
      type: isOverflow ? VIEW_ACTION.SCROLL_TO_TOP : VIEW_ACTION.RESET,
    });
  }, [isLoading, isLoadingNextAvailabilityDate]);

  const renderContent = (
    data: { timeDate: string; labelDayOfWeek: string; labelDayOfMonth: string },
    index: number
  ) => {
    if (isClinicSearch && clinicData && onClickClinicTimeSlot) {
      return (
        <DayViewClinic
          date={data.timeDate}
          clinicData={clinicData}
          onClickTimeSlot={(block: number, practitionerIds: string[]) => {
            onClickClinicTimeSlot(block, data.timeDate, practitionerIds);
          }}
        />
      );
    }
    if (!isClinicSearch && timeSlots && onClickPractitionerTimeSlot) {
      return (
        <DayViewPractitioner
          timeSlots={timeSlots}
          currentIndex={index}
          onClickTimeSlot={(block: number) =>
            onClickPractitionerTimeSlot(block, data.timeDate)
          }
        />
      );
    }
  };

  const handleOnScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollTop === 0) {
      dispatch({ type: VIEW_ACTION.SCROLL_TO_TOP });
    } else {
      dispatch({ type: VIEW_ACTION.IS_SCROLLING });
    }
    if (
      Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight
    ) {
      dispatch({ type: VIEW_ACTION.SCROLL_TO_BOTTOM });
    }
  };

  const handleGetNextAvailability = () => {
    setNextAvailabilityDate('');
    onDateUpdate(nextAvailabilityDate);
  };

  const isEmptySlotDisplayed = isClinicDataEmpty || isTimeSlotsEmpty;

  if (isLoading || isLoadingNextAvailabilityDate) {
    return <CircularProgress className={css['circular-progress']} />;
  }

  return (
    <>
      <div className={`${css['grid-container-header']} ${headerClassName}`}>
        {dataBlocks.map((data) => (
          <div key={`${data.timeDate}`} className={css['grid-column']}>
            <span className={css['label-sm']}>{data.labelDayOfWeek}</span>
            <span className={css['label-md']}>{data.labelDayOfMonth}</span>
          </div>
        ))}
        {state.isScrolling && (
          <div className={`${blurTopClassName} ${blurTopBgClassName}`} />
        )}
      </div>
      <div
        className={`${bodyContainerClassName} ${bodyClassName}`}
        onScroll={handleOnScroll}
        ref={containerRef}
      >
        {isEmptySlotDisplayed && !isLoadingNextAvailabilityDate ? (
          <div className={css['emptyslot-container']}>
            {nextAvailabilityDate ? (
              <>
                <EmptySlotCalendar />
                <span>No availabilities</span>
                <DentalButton
                  variant="outlined"
                  className={css['next-availability-button']}
                  onClick={handleGetNextAvailability}
                >
                  View next availability on{' '}
                  {moment(nextAvailabilityDate, 'YYYY-MM-DD').format(
                    'ddd, 	MMM DD'
                  )}
                </DentalButton>
              </>
            ) : (
              <>
                <EmptySlotCalendar />
                <span>No availabilities</span>
              </>
            )}
          </div>
        ) : (
          dataBlocks.map((data, index) => (
            <div key={data.timeDate} className={css['column-container']}>
              {renderContent(data, index)}
            </div>
          ))
        )}
        <MoveDown className={`${baseIconClassName} ${iconClassName}`} />
      </div>
      {state.displayBlurBottom && (
        <div className={`${blurBottomClassName} ${blurBottomBgClassName}`} />
      )}
    </>
  );
};

export default DayView;
