import { FC } from 'react';
import styles from '../../FilterList.module.scss';

const SORTS = ['distance', 'rating'] as const;

interface SortProps {
  value: 'distance' | 'rating' | null;
  setValue: React.Dispatch<React.SetStateAction<'distance' | 'rating' | null>>;
}

const Sort: FC<SortProps> = ({ value, setValue }) => {
  return (
    <div className={styles.group}>
      {SORTS.map((sort) => (
        <button
          key={sort}
          data-active={value === sort}
          onClick={() => setValue(sort)}
        >
          {sort}
        </button>
      ))}
    </div>
  );
};

export default Sort;
