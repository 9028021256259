import React from 'react';
import { Link } from 'react-router-dom';
import { Rating } from '@material-ui/lab';

import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';
import { ReactComponent as WorkingHoursIcon } from 'assets/icons/working-hours.svg';
import NoAvatarSrc from 'assets/images/no-avatar.svg';
import { IMapClinicData } from 'redux/mapSlice';
import { useAppSelector } from 'redux/reduxHooks';

import styles from './ClinicInfo.module.scss';
import { getDayinWeek } from './utils';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import moment from 'moment';

interface Props {
  clinicData: IMapClinicData;
  onViewClinicAvailability: (id: string) => void;
}

export type ClinicInfoComponent = React.FC<Props>;

const ClinicInfo: ClinicInfoComponent = ({
  clinicData,
  onViewClinicAvailability,
}) => {
  const {
    id,
    name,
    city,
    address,
    distance,
    avatar,
    totalScore,
    workingHours,
  } = clinicData;
  const filterDate = useAppSelector((state) => state.filterSlice.date);
  const currentDay = getDayinWeek(filterDate);

  const onImageError = (e: any) => {
    e.target.src = `${NoAvatarSrc}`;
  };

  const renderAvatar = () => {
    return (
      <div className={styles['avatar-wrapper']}>
        <img
          src={avatar || NoAvatarSrc}
          alt="clinic-avatar"
          className={styles['avatar']}
          onError={onImageError}
        />
      </div>
    );
  };
  const renderNameRatings = () => {
    return (
      <div>
        <Link
          to={`/clinic?id=${id}`}
          className={`${styles['bold']} ${styles['name']}`}
        >
          {name}
        </Link>
        <div className={styles['flex-align-center']}>
          <Rating
            name="read-only"
            value={totalScore || 0}
            readOnly
            precision={0.5}
            size="medium"
            className={styles['rating-stars']}
          />
          {/* {totalScore > 0 && ( */}
          <span className={`${styles.bold} ${styles['average-score']}`}>
            {totalScore || ''}
          </span>
          {/* )} */}
        </div>
      </div>
    );
  };

  const renderAddress = () => {
    const isCityContainedByAddress = address
      ?.toLowerCase()
      .includes(city?.toLowerCase());

    return (
      <div className={`${styles['flex']} ${styles['contact-info-item']}`}>
        <LocationIcon className={styles['info-icon']} />
        <div className={styles['info-section']}>
          <span>
            <span className={styles['distance']}>{distance} km</span>
            {` • `}
            {isCityContainedByAddress ? `${address}` : `${address}, ${city}`}
          </span>
        </div>
      </div>
    );
  };

  const renderWorkingHour = () => {
    const workingHour =
      workingHours.find((item) => item.day === currentDay) || workingHours[0];
    return (
      <div className={`${styles['flex']} ${styles['contact-info-item']}`}>
        <WorkingHoursIcon className={styles['info-icon']} />
        <div>{`${moment(workingHour.day, 'dddd').format('ddd')} ${
          workingHour.time
        }`}</div>
      </div>
    );
  };

  const handleClickViewAvailability = () => {
    onViewClinicAvailability(id);
  };

  return (
    <div className={styles['container']}>
      <div className={styles['flex']}>
        {renderAvatar()}
        {renderNameRatings()}
      </div>
      <div className={styles['contact-info']}>
        {renderAddress()}
        {renderWorkingHour()}
      </div>
      <DentalButton
        variant="outlined"
        className={styles['view-availability-button']}
        onClick={handleClickViewAvailability}
      >
        View more
      </DentalButton>
    </div>
  );
};

export default ClinicInfo;
