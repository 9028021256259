import React from 'react';
import cx from 'classnames';
import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';

import styles from './ClinicInfo.module.scss';

interface IClinicLocationProps {
  name?: string;
  address: string;
  hideOnDesktop?: boolean;
}

const Location = ({ address, name, hideOnDesktop }: IClinicLocationProps) => {
  const className = cx([styles['contact-infos']], {
    [styles['hide-on-desktop']]: hideOnDesktop,
  });
  return (
    <div className={className}>
      <LocationIcon className={styles.icon} />
      {name ? (
        <div className={styles['inline-block']}>
          <span className={styles.bold}>
            {name}
            {` • `}
          </span>
          <span>{address}</span>
        </div>
      ) : (
        <span>{address}</span>
      )}
    </div>
  );
};

export default Location;
