import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DayViewNavigation from 'components/DayViewNavigation/DayViewNavigation';
import { Divider } from 'components/DentalDivider';
import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';
import DayView from 'components/DayView/DayView';
import ReasonDropdownSection from 'components/ReasonDropdownSection/ReasonDropdownSection';
import { ITimeSlot } from 'interfaces/timeslotType';
import { getPractitionerAvailability } from 'services/APIs/getPractitionerAvailability';
import { getFilterDates } from 'utils/dates';
import { parseQueryUrl } from 'utils/parseQueryUrl';

import css from './BookNowPanel.module.scss';
import useIsMobile from 'hooks/useIsMobile';
import { getSlotsPer30MinutesForAvailability } from 'utils/updatedSlotsPer30Minutes';
import MonthViewBookNowPanel from 'components/MonthViewBookNowPanel/MonthViewBookNowPanel';
import {
  getDentistIdForSplitScheduling,
  getOperatoryId,
} from 'pages/UpdatedSearchResultPage/utils';
import { PATIENT_EXAM_AND_CLEANING } from 'components/DentalMap/utils/constant';
import { IPractitionerClinics } from 'interfaces/practitionerDetailsTypes';
import { renderToast } from 'components/CustomToast/CustomToast';

interface Props {
  practitionerId: string;
  practitionerServicesOptions: {
    id: string;
    title: string;
    slug: string;
  }[];
  clinicData: IPractitionerClinics;
  onClickPractitionerTimeSlot: (data: any) => void;
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}

const BookNowPanel: React.FC<Props> = ({
  practitionerId,
  practitionerServicesOptions,
  onClickPractitionerTimeSlot,
  date,
  setDate,
  clinicData,
}) => {
  const clinicId = clinicData.clinicId;
  const clinicTimezone = clinicData.timezone;

  const location = useLocation();

  const { serviceSlugNameUrl } = parseQueryUrl(location);

  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);

  const [service, setService] = useState(() => {
    if (serviceSlugNameUrl) {
      const isAvailableService = practitionerServicesOptions.some(
        (item) => item.slug === serviceSlugNameUrl
      );

      if (!isAvailableService) {
        renderToast({
          message: `The reason you are trying to book an appointment for is currently not available. Please choose another reason or contact ${clinicData.name} for more information`,
        });

        return practitionerServicesOptions[0];
      }

      return (
        practitionerServicesOptions.find(
          (svc) => svc.slug === serviceSlugNameUrl
        ) || practitionerServicesOptions[0]
      );
    }

    // Without service slug name url
    return (
      practitionerServicesOptions.find(
        (svc) => svc.title === PATIENT_EXAM_AND_CLEANING
      ) || practitionerServicesOptions[0]
    );
  });

  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>();

  const [isPractitionerTimeSlotLoading, setIsPractitionerTimeSlotLoading] =
    useState(true);

  const [isDayView, setIsDayView] = useState(true);

  const isMobile = useIsMobile();

  useEffect(() => {
    fetchPractitionerAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, date, isDayView]);

  const fetchPractitionerAvailability = async () => {
    setIsPractitionerTimeSlotLoading(true);

    const res = await getPractitionerAvailability({
      clinicId: clinicId,
      dates: getFilterDates(date),
      practitionerId: practitionerId,
      serviceId: service.id,
      timezone: clinicTimezone,
      timeBlocks: [0],
    });

    setTimeSlots(res);

    setIsPractitionerTimeSlotLoading(false);
  };

  const toggleOpenServiceDropdown = (value: boolean) =>
    setIsServiceDropdownOpen(value);

  const handleChangeServiceOption = (option: {
    id: string;
    title: string;
    duration: number;
    slug: string;
  }) => {
    setIsPractitionerTimeSlotLoading(true);
    setService(option);
  };

  const clickPractitionerTimeSlot = (
    timeSlot: number,
    appointmentDate: string
  ) => {
    const operatoryId = getOperatoryId({
      date: appointmentDate,
      timeSlot,
      doctorId: practitionerId,
      doctors: [
        {
          id: practitionerId,
          availableBlocks: timeSlots || [],
        },
      ],
    });

    const dentist = getDentistIdForSplitScheduling({
      date: appointmentDate,
      timeSlot,
      doctorId: practitionerId,
      doctors: [
        {
          id: practitionerId,
          availableBlocks: timeSlots || [],
        },
      ],
    });

    onClickPractitionerTimeSlot({
      service: {
        id: service.id,
        title: service.title,
      },
      operatoryId,
      appointmentDate,
      timeSlot,
      clinicId,
      dentist,
    });
  };

  const onNavigateToDayView = (dateStr: string) => {
    setIsDayView(true);

    const parsedDate = moment(dateStr, 'YYYY-MM-DD').toDate().toDateString();
    setDate(parsedDate);
  };

  const handleDateUpdate = (newDate: string) => {
    setIsPractitionerTimeSlotLoading(true);
    setDate(newDate);
  };

  const parsedDate = useMemo(() => {
    if (date && !moment(date, 'YYYY-MM-DD').isValid()) {
      return moment(Date.parse(date)).format('YYYY-MM-DD');
    }
    return date;
  }, [date]);

  const updatedSlotsPer30MinutesForPractitioner =
    getSlotsPer30MinutesForAvailability(timeSlots, clinicData.slotInterval);

  return (
    <div className={css['container']}>
      <h3 className={css['title']}>Schedule Online</h3>
      <div className={css['section-container']}>
        <ReasonDropdownSection
          isOpen={isServiceDropdownOpen}
          options={practitionerServicesOptions}
          optionValue={service}
          onChangeIsOpen={toggleOpenServiceDropdown}
          onChangeServiceOption={handleChangeServiceOption}
          isSingular
        />
      </div>
      <div className={css['navigation-container']}>
        {isDayView ? (
          <DayViewNavigation date={date} onDateUpdate={handleDateUpdate} />
        ) : (
          <MonthViewNavigation date={parsedDate} setDate={handleDateUpdate} />
        )}
        <div className={css['navigation-section']}>
          <button
            className={`${css['navigation-btn']} ${
              !isDayView && css['navigation-btn-inactive']
            }`}
            onClick={() => setIsDayView(true)}
          >
            {isMobile ? 'Day' : 'Day View'}
          </button>
          <button
            className={`${css['navigation-btn']} ${
              isDayView && css['navigation-btn-inactive']
            }`}
            onClick={() => setIsDayView(false)}
          >
            {isMobile ? 'Month' : 'Month View'}
          </button>
        </div>
      </div>
      <Divider orientation="horizontal" className={css['divider']} />
      {isDayView ? (
        <DayView
          isLoading={isPractitionerTimeSlotLoading}
          date={date}
          isClinicSearch={false}
          timeSlots={updatedSlotsPer30MinutesForPractitioner}
          onClickPractitionerTimeSlot={clickPractitionerTimeSlot}
          practitionerId={practitionerId}
          serviceId={service.id}
          onDateUpdate={handleDateUpdate}
        />
      ) : (
        <MonthViewBookNowPanel
          isLoading={isPractitionerTimeSlotLoading}
          setIsLoading={(isLoading: boolean) => {
            setIsPractitionerTimeSlotLoading(isLoading);
          }}
          clinicId={clinicId}
          clinicTimezone={clinicTimezone}
          serviceId={service.id}
          practitionerId={practitionerId}
          date={parsedDate}
          onNavigateToDayView={onNavigateToDayView}
          slotInterval={clinicData.slotInterval}
        />
      )}
    </div>
  );
};

export default BookNowPanel;
