import { NavigationBar } from 'components/NavigationBar';
import { Header } from 'components/Layout';

import styles from './ForgotPasswordPage.module.scss';
import ForgotPasswordForm from 'components/ForgotPasswordForm/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  return (
    <div className={styles.container}>
      <Header hasBoxShadow>
        <NavigationBar />
      </Header>

      <div className={styles.body}>
        <ForgotPasswordForm />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
