import React from 'react';
import useIsMobile from 'hooks/useIsMobile';

import PaginationController from 'components/PaginationController/PaginationController';
import { PractitionerCard } from 'components/PractitionerCard';
import MobilePractitionerCard from './MobilePractitionerCard/MobilePractitionerCard';
import { IPractitioner } from 'interfaces/practitionerTypes';

import styles from './ClinicPractitioners.module.scss';
import { IClinicData } from 'redux/clinicDetailsSlice';

interface Props {
  clinic: IClinicData;
  practitionerData: {
    ids: string[];
    data: {
      [key: string]: IPractitioner;
    };
  };
  totalPractitioners: number;
  onClickRequest?: (id: string) => void;
  currentPage: number;
  pageCount: number;
  onChangePage: (page: number) => void;
  isRenderInsideDetailPage: boolean;
}

export type ClinicPractitionersComponent = React.FC<Props>;

const ClinicPractitioners: ClinicPractitionersComponent = ({
  practitionerData,
  totalPractitioners,
  currentPage,
  pageCount,
  onChangePage,
  isRenderInsideDetailPage,
  clinic,
}) => {
  const isMobile = useIsMobile();

  if (practitionerData.ids.length > 0) {
    return (
      <div className={styles['container']}>
        <div
          className={styles['title']}
        >{`Practitioners (${totalPractitioners})`}</div>
        {practitionerData.ids.map((id) =>
          isMobile ? (
            <MobilePractitionerCard
              key={id}
              practitioner={practitionerData.data[id]}
              clinic={clinic}
            />
          ) : (
            <PractitionerCard
              key={id}
              practitioner={practitionerData.data[id]}
              clinic={clinic}
              paddingTopOnly
              hideContacts
              hideReviews
              isRenderInsideDetailPage={isRenderInsideDetailPage}
            />
          )
        )}
        {!isMobile && (
          <PaginationController
            currentPage={currentPage}
            pageCount={pageCount}
            onChangePage={onChangePage}
          />
        )}
      </div>
    );
  }
  return null;
};

export default ClinicPractitioners;
