import { ReactComponent as UpArrowIcon } from 'assets/icons/chevron-up.svg';
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import DentalButton from '../DentalButton/DentalButton';
import styles from './BackToTopButton.module.scss';
import { useLocation } from 'react-router-dom';

interface Props {
  containerElement?: React.RefObject<HTMLDivElement>;
}

export type BackToTopButtonComponent = React.FC<Props>;

const BackToTopButton: BackToTopButtonComponent = ({ containerElement }) => {
  const [isContainerElementOver1500px, setIsContainerElementOver1500px] =
    useState(false);

  const [isButtonDisplayed, setIsButtonDisplayed] = useState(false);

  const observerElementRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const isInSearchResultPage = location.pathname.includes('result');

  const buttonStyle = cx({
    [styles['to-top-button']]: true,
    [styles['appear']]: isButtonDisplayed,
    [styles['search-result-position']]: isInSearchResultPage,
  });

  const handleScrollToTop = () => {
    const element = containerElement?.current || window;
    element.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const element = containerElement?.current || window;

    const handleGetScrollHeight = () => {
      const containerHeight =
        element instanceof HTMLDivElement
          ? element.scrollHeight
          : document.documentElement.scrollHeight;
      setIsContainerElementOver1500px(containerHeight > 1500);
      element.removeEventListener('scroll', handleGetScrollHeight);
    };
    element.addEventListener('scroll', handleGetScrollHeight);
  }, [containerElement]);

  useEffect(() => {
    if (!isContainerElementOver1500px) return;
    const observeElement = observerElementRef.current!;
    const rootElement = containerElement?.current;
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsButtonDisplayed(true);
          } else {
            setIsButtonDisplayed(false);
          }
        });
      },
      { root: rootElement }
    );
    intersectionObserver.observe(observeElement);
    return () => intersectionObserver.disconnect();
  }, [containerElement, isContainerElementOver1500px]);

  return (
    <>
      {isContainerElementOver1500px && (
        <div className={styles['observer']} ref={observerElementRef}></div>
      )}
      <DentalButton
        className={buttonStyle}
        variant="outlined"
        onClick={handleScrollToTop}
      >
        <div className={styles['label']}>
          <UpArrowIcon className={styles['icon']} />
        </div>
      </DentalButton>
    </>
  );
};

export default BackToTopButton;
