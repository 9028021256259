import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';

import styles from './SuccessOrInvalidPage.module.scss';
import { ROUTES } from 'routes/config';

interface IProps {
  message: string;
  isInvalid?: boolean;
  handleResendEmailVerification?: () => void;
  isButtonDisabled?: boolean;
  isButtonLoading?: boolean;
}

const SuccessOrInvalidPage = ({
  message,
  isInvalid,
  handleResendEmailVerification,
  isButtonDisabled,
  isButtonLoading,
}: IProps) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {isInvalid ? <CrossIcon /> : <ConfirmIcon />}
        <div className={styles.title}>{message}</div>
        {isInvalid ? (
          <>
            <DentalButton
              variant="contained"
              onClick={handleResendEmailVerification}
              className={`${styles.btn} ${
                isButtonLoading && styles['btn-loading']
              }`}
              disabled={isButtonDisabled}
              isLoading={isButtonLoading}
            >
              Resend email verification
            </DentalButton>
            <Link to={ROUTES.HOME} className={styles.home}>
              Home
            </Link>
          </>
        ) : (
          <DentalButton
            variant="contained"
            onClick={() => history.push(ROUTES.SIGN_IN_PAGE)}
            className={styles.btn}
          >
            Sign in to FirstIn
          </DentalButton>
        )}
      </div>
    </div>
  );
};

export default SuccessOrInvalidPage;
