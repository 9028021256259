import { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import styles from '../FilterList.module.scss';
import CustomPopper from '../CustomPopper/CustomPopper';
import Gender from './Gender/Gender';
import CustomPopperContent from '../CustomPopper/CustomPopperContent/CustomPopperContent';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { batch } from 'react-redux';
import { setSearchResultFilter } from 'redux/searchResultFilterSlice';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import { clearMapData } from 'redux/mapSliceV2';
import useIsMobile from 'hooks/useIsMobile';
import CustomDialog from '../CustomDialog/CustomDialog';

const GenderButton = () => {
  const isMobile = useIsMobile();
  const defaultGender = useAppSelector(
    (state) => state.searchResultFilterSlice.gender
  );
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] =
    useState<('male' | 'female' | 'non-binary')[]>(defaultGender);

  const onApplyClicked = () => {
    // PREVENT UPDATE IF NO CHANGED
    if (defaultGender === value) {
      return onClose();
    }

    batch(() => {
      setAnchorEl(null);
      dispatch(setSearchResultFilter({ gender: value }));
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
      dispatch(clearMapData());
    });
  };

  const onClose = () => {
    setValue(defaultGender);
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <button
          data-filter="gender"
          className={styles['btn']}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          data-active={defaultGender.length > 0}
          data-open={!!anchorEl}
        >
          Gender
        </button>
        {isMobile ? (
          <CustomDialog
            open={Boolean(anchorEl)}
            isResetBtnDisabled={value.length === 0}
            onResetClicked={() => setValue([])}
            onApplyClicked={onApplyClicked}
            onClose={onClose}
          >
            <CustomPopperContent title={'Gender'} onClose={onClose}>
              <Gender value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomDialog>
        ) : (
          <CustomPopper
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            isResetBtnDisabled={value.length === 0}
            onResetClicked={() => setValue([])}
            onApplyClicked={onApplyClicked}
          >
            <CustomPopperContent title={'Gender'} onClose={onClose}>
              <Gender value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomPopper>
        )}{' '}
      </div>
    </ClickAwayListener>
  );
};

export default GenderButton;
