import { useEffect } from 'react';
import getSearchQueryFromUrl from '../utils/getSearchDataFromUrl';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ROUTES } from 'routes/config';
import { setFilter } from 'redux/filterSlice';
import getPlacesDetailsFromLatLng from '../utils/getPlacesDetailsFromLatLng';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { getSessionLocation, saveSessionLocation } from 'utils/storage';
import { getTimezoneByPlaceDetails } from 'services/APIs/getTimezoneByPlaceDetails';

const usePopulateSearchParams = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const searchBarOptionsSlice = useAppSelector(
    (state) => state.searchBarOptionsSlice
  );

  const {
    service: { slugs },
  } = searchBarOptionsSlice;

  useEffect(() => {
    const populateSearchParams = async () => {
      const isSlugItemComputed = Object.keys(slugs).length > 0;

      if (!isSlugItemComputed) return;
      const searchQuery = getSearchQueryFromUrl();
      if (!searchQuery) {
        history.push(ROUTES.HOME);
        return;
      }

      const { date, lat, lng, service } = searchQuery;

      const isSearchDateInThePast = moment(date, 'YYYY-MM-DD').isBefore(
        moment(),
        'date'
      );

      const slugArray = Object.entries(slugs).find((item) => {
        const [, slug] = item;
        return slug === service;
      });

      if (isSearchDateInThePast) {
        history.push(ROUTES.HOME);
        return;
      }

      const sessionLocation = getSessionLocation();

      const isLocationSame =
        sessionLocation &&
        sessionLocation.placeId &&
        sessionLocation.name &&
        sessionLocation.lat === lat &&
        sessionLocation.lng === lng;

      if (isLocationSame) {
        const placeId = sessionLocation!.placeId;

        const name = sessionLocation!.name;

        const timezone = await getTimezoneByPlaceDetails(lat, lng);

        dispatch(
          setFilter({
            service: slugArray![0],
            date,
            location: {
              lat,
              lng,
              placeId,
              name,
              timezone,
            },
          })
        );
        return;
      }

      getPlacesDetailsFromLatLng(
        { lat, lng },
        async (placeName: any, placeId: any) => {
          const timezone = await getTimezoneByPlaceDetails(lat, lng);
          dispatch(
            setFilter({
              service: slugArray![0],
              date: date,
              location: {
                name: placeName,
                lat,
                lng,
                placeId: placeId,
                timezone: timezone,
              },
            })
          );
          saveSessionLocation({
            name: placeName,
            lat,
            lng,
            placeId: placeId,
          });
        }
      );
    };
    if (window.google?.maps) {
      populateSearchParams();
    }
  }, [dispatch, history, searchBarOptionsSlice.service.ids.length, slugs]);
};

export default usePopulateSearchParams;
