import { sortBy } from 'lodash';

const getNearestAvailForClinic = (
  doctors: {
    doctorId: string;
    nextAvailabilityDate?: string;
  }[]
) => {
  const nextAvail = sortBy(doctors, (doctor) => doctor.nextAvailabilityDate);
  return nextAvail[0];
};

export default getNearestAvailForClinic;
