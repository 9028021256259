import React, { FC } from 'react';

import { ITimeSlot } from 'interfaces/timeslotType';
import {
  MINUTE_OF_A_BLOCK,
  convertTime,
} from 'utils/convertMinutesIntoHoursOfDay';

import { toLongestArrayAvaiBlocksForPractitioner } from './utils/toLongestArrayAvaiBlocks';

import css from './DayView.module.scss';

interface DayViewPractitionerProps {
  timeSlots: ITimeSlot[];
  onClickTimeSlot: (timeSlot: number) => void;
  currentIndex: number;
}

const DayViewPractitioner: FC<DayViewPractitionerProps> = ({
  timeSlots,
  onClickTimeSlot,
  currentIndex,
}) => {
  const renderBlocks = toLongestArrayAvaiBlocksForPractitioner(
    timeSlots,
    currentIndex
  ).map((block, index) =>
    block ? (
      <button
        key={`${block}-${currentIndex + index}`}
        className={css['timeslot']}
        onClick={() => onClickTimeSlot(block)}
      >
        {convertTime(Number(block) * MINUTE_OF_A_BLOCK)}
      </button>
    ) : (
      <button key={`${currentIndex + index}`} className={css['timeslot-none']}>
        -
      </button>
    )
  );

  return <>{renderBlocks}</>;
};

export default DayViewPractitioner;
