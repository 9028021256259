import axios from 'axios';

export const sendOtpByPhoneNumber = async (phoneNumber: string) => {
  try {
    await axios.post<{ message: string }>(
      '/otp',
      { phoneNumber },
      { baseURL: process.env.REACT_APP_AXIOS_BE_URL }
    );
  } catch (error: any) {
    let message = 'Something went wrong. Please try again later';

    if (error.response.data) {
      const errorMessage = error.response.data.message;
      message =
        typeof errorMessage === 'string' ? errorMessage : errorMessage[0];
    }

    throw new Error(message);
  }
};

export const verifyOtpByCode = async (phoneNumber: string, code: string) => {
  try {
    const { data } = await axios.post<{ accessToken: string | null }>(
      '/otp/verify',
      { phoneNumber, code },
      { baseURL: process.env.REACT_APP_AXIOS_BE_URL }
    );

    return data.accessToken || '';
  } catch (error: any) {
    let message = 'Something went wrong. Please try again later';

    if (error.response.data) {
      const errorMessage = error.response.data.message;
      message =
        typeof errorMessage === 'string' ? errorMessage : errorMessage[0];
    }

    throw new Error(message);
  }
};
