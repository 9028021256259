import { fontSize } from 'utils/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-container': {
      fontSize: fontSize.s,
      fontFamily: 'Nexa',
    },
    '& .MuiPaper-root': {
      margin: 0,
      width: '100%',
      marginTop: 'auto',
    },
  },
});
