import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

import { Divider } from 'components/DentalDivider';
import { useAppSelector } from 'redux/reduxHooks';
import cx from 'classnames';
import styles from './PractitionerAvailability.module.scss';
import DayViewNavigation from 'components/DayViewNavigation/DayViewNavigation';
import DayView from 'components/DayView/DayView';
import { ITimeSlot } from 'interfaces/timeslotType';
import { getDateRange, getFilterDates } from 'utils/dates';
import { getSlotsPer30MinutesForAvailability } from 'utils/updatedSlotsPer30Minutes';
import { getWeeklyTimeSlots } from 'pages/SearchResultPage/PractitionerAvailability/utils/getWeeklyTimeSlots';
import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';
import Bio from 'pages/UpdatedSearchResultPage/ClinicList/ClinicAvailability/Bio/Bio';
import moment from 'moment';
import WorkingHours from 'pages/UpdatedSearchResultPage/ClinicList/ClinicAvailability/WorkingHours/WorkingHours';
import Info from 'pages/SearchResultPage/PractitionerAvailability/Info/Info';
import MonthView from 'components/MonthView/MonthView';
import DayCell from 'components/MonthView/DayCell/DayCell';
import { isDateInThePast } from 'utils/isDateInThePast';
import { getPractitionerAvailability } from 'services/APIs/getPractitionerAvailability';
import AvatarInfo from 'components/AvatarInfo/AvatarInfo';
import useIsMobile from 'hooks/useIsMobile';
import getTimeBlocksData from 'utils/getTimeBlocksData';
import {
  DentistInfo,
  getDentistIdForSplitScheduling,
  getOperatoryId,
} from 'pages/UpdatedSearchResultPage/utils';
import { IPractitionerResponse } from 'redux/practitionerSearchResultSlice';
import getPractitionerName from 'utils/getPractitionerName';

interface Props {
  practitioner: IPractitionerResponse;

  serviceId: string;
  searchingDate: string;
  onBookAppointment: (toBeBookedPractitioner: {
    id: string;
    timeSlot: number;
    operatoryId: string;
    date: string;
    dentist: DentistInfo;
  }) => void;
  isDefaultDayView: boolean;
}

const WEEK_COUNT = 6;

const PractitionerAvailability: React.FC<Props> = ({
  practitioner,
  serviceId,
  searchingDate,
  onBookAppointment,
  isDefaultDayView,
}) => {
  const {
    avatar,
    id: practitionerId,
    specialty: practitionerSpecialty,
    workingHours: practitionerWorkingHours,
    distance,
    clinic,
    reviewCount,
    totalScore: reviewScore,
    description,
  } = practitioner;
  const [date, setDate] = useState(searchingDate);

  const [isDayView, setIsDayView] = useState(isDefaultDayView);

  const [avaiBlocks, setAvaiBlocks] = useState<ITimeSlot[]>([]);

  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isMonthViewLoading, setIsMonthViewLoading] = useState(false);

  const locationTimezone = useAppSelector(
    (state) => state.filterSlice.location.timezone
  );

  const { timeBlocks } = useAppSelector(
    (state) => state.searchResultFilterSlice
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchDayViewTimeSlots = async () => {
      try {
        setIsLoading(true);
        const weeklyTimeSlots = await getWeeklyTimeSlots({
          practitionerId,
          dates: getFilterDates(date),
          serviceId,
          timezone: locationTimezone,
          cancelToken: cancelToken.token,
          timeBlocks: getTimeBlocksData(timeBlocks),
        });
        setAvaiBlocks(weeklyTimeSlots);
      } catch (e) {}
      setIsLoading(false);
    };
    if (!isDayView) return;
    fetchDayViewTimeSlots();
    return () => cancelToken.cancel();
  }, [
    date,
    isDayView,
    locationTimezone,
    practitionerId,
    serviceId,
    timeBlocks,
  ]);

  const handleDateUpdate = (newDate: string) => {
    setDate(newDate);
    setIsLoading(true);
  };

  const fetchAvailabilityInDateRange = async (
    datesInFuture: string[],
    callback: any
  ) => {
    const promises = [];
    const tmpDatesInFuture = [...datesInFuture];

    while (tmpDatesInFuture.length !== 0) {
      const ranges = tmpDatesInFuture.splice(0, 7);

      const dates = [ranges[0], ranges[ranges.length - 1]];
      promises.push(callback(dates));
    }

    const availabilities = await Promise.all(promises);

    return availabilities;
  };

  const fetchWeeklyTimeSlot = useCallback(
    async (startDate: Date, endDate: Date) => {
      setIsMonthViewLoading(true);
      const dateStrings = getDateRange(startDate, endDate);

      const datesInFuture = dateStrings.filter(
        (dateString) => !isDateInThePast(dateString)
      );

      if (practitionerId) {
        // Call weekly API of practitioner
        const availabilities = await fetchAvailabilityInDateRange(
          datesInFuture,
          (dates: string[]) =>
            getPractitionerAvailability({
              clinicId: clinic.id,
              serviceId,
              practitionerId,
              dates,
              timezone: locationTimezone,
              timeBlocks: getTimeBlocksData(timeBlocks),
            })
        );

        const result = availabilities.reduce((accum, currentValue) => {
          return accum.concat(currentValue);
        }, []);

        const updatedSlotsPer30Minutes =
          getSlotsPer30MinutesForAvailability(result);

        setTimeSlots(updatedSlotsPer30Minutes);
      }

      setIsMonthViewLoading(false);
    },
    [clinic.id, locationTimezone, practitionerId, serviceId, timeBlocks]
  );

  return (
    <div className={styles['container']}>
      <div className={styles['left']}>
        <div className={styles['clinic-info-wrapper']}>
          <AvatarInfo
            avatar={avatar}
            rating={reviewScore}
            reviewCount={reviewCount}
            name={getPractitionerName(practitioner)}
            distance={distance}
            specialties={practitionerSpecialty
              .map((item) => item.name)
              .join(' • ')}
          />
          <div className={styles['info']}>
            <Info
              name={getPractitionerName(practitioner)}
              specialties={practitionerSpecialty}
              clinicName={clinic.name}
              distance={distance}
            />
          </div>
        </div>

        <div className={styles['navigation-container']}>
          {isDayView ? (
            <DayViewNavigation
              date={date}
              onDateUpdate={(date) => {
                const formattedDate = moment(date).format('YYYY-MM-DD');
                setDate(formattedDate);
                setIsLoading(true);
              }}
            />
          ) : (
            <MonthViewNavigation date={date} setDate={setDate} />
          )}

          <div className={styles['navigation-section']}>
            <button
              className={cx({
                [styles['navigation-btn']]: true,
                [styles['navigation-btn-inactive']]: !isDayView,
              })}
              onClick={() => setIsDayView(true)}
            >
              {isMobile ? 'Day' : 'Day View'}
            </button>
            <button
              className={cx({
                [styles['navigation-btn']]: true,
                [styles['navigation-btn-inactive']]: isDayView,
              })}
              onClick={() => setIsDayView(false)}
            >
              {isMobile ? 'Month' : 'Month View'}
            </button>
          </div>
        </div>
        <Divider orientation="horizontal" className={styles['divider']} />

        {isDayView ? (
          <DayView
            isLoading={isLoading}
            date={date}
            onDateUpdate={handleDateUpdate}
            serviceId={serviceId}
            practitionerId={practitionerId}
            isClinicSearch={false}
            timeSlots={getSlotsPer30MinutesForAvailability(avaiBlocks)}
            onClickPractitionerTimeSlot={(timeSlot, appointmentDate) => {
              const operatoryId = getOperatoryId({
                date: appointmentDate,
                timeSlot,
                doctorId: practitionerId,
                doctors: [
                  {
                    id: practitionerId,
                    availableBlocks: isDayView ? avaiBlocks : timeSlots,
                  },
                ],
              });

              const dentist = getDentistIdForSplitScheduling({
                date: appointmentDate,
                timeSlot,
                doctorId: practitionerId,
                doctors: [
                  {
                    id: practitionerId,
                    availableBlocks: isDayView ? avaiBlocks : timeSlots,
                  },
                ],
              });

              onBookAppointment({
                id: practitionerId,
                date: appointmentDate,
                timeSlot,
                operatoryId,
                dentist,
              });
            }}
            headerClassName={styles['dayview-navigation-header']}
            bodyClassName={styles['dayview-navigation-body']}
            blurTopBgClassName={styles['dayview-navigation-blur-top-bg']}
            blurBottomBgClassName={styles['dayview-navigation-blur-bottom-bg']}
            iconClassName={styles['dayview-navigation-icon']}
          />
        ) : (
          <MonthView
            isRenderPast
            selectedDate={date}
            contentHeight={70 * WEEK_COUNT + 32}
            dateClick={(dateClickArg) => {
              setDate(dateClickArg.dateStr);
              setIsDayView(true);
              setIsLoading(true);
            }}
            dayCellContent={(props) => {
              const normalizedTimeSlots = timeSlots.map((item) => ({
                date: item.date,
                blocks: item.blocks,
              }));

              return (
                <DayCell
                  isLoading={isMonthViewLoading}
                  {...props}
                  timeslots={normalizedTimeSlots}
                />
              );
            }}
            fetchTimeSlots={fetchWeeklyTimeSlot}
          />
        )}
      </div>

      <div className={styles['right']}>
        {practitionerWorkingHours && (
          <WorkingHours
            workingHours={practitionerWorkingHours}
            activeDate={isDayView ? date : undefined}
          />
        )}
        <Bio description={description} />
      </div>
    </div>
  );
};

export default PractitionerAvailability;
