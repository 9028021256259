import { useEffect, useLayoutEffect, useState } from 'react';
import { Router, Switch, withRouter } from 'react-router-dom';
import axios from 'axios';

import { IRoute } from 'interfaces/routeTypes';
import { useAppDispatch } from 'redux/reduxHooks';
import { getUserProfileThunk } from 'redux/authSlice';
import {
  loadAuthToken,
  loadPreviousUrl,
  removeSocialUserData,
  savePreviousUrl,
} from 'utils/storage';

import PublicRoute from './PublicRoute';
import { publicRoutes, ROUTES } from './config';
import { getCountryCodeByIPThunk } from 'redux/bookingFormSlice';

function AppRoute(props: any) {
  const { history } = props;
  const dispatch = useAppDispatch();
  const [isShortLinkLoading, setIsShortLinkLoading] = useState(false);

  useLayoutEffect(() => {
    const shortLink = async (shortLinkCode: string) => {
      setIsShortLinkLoading(true);
      const { data } = await axios.get(`/short-link`, {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          code: shortLinkCode,
        },
      });

      window.location.href = data.redirectLink;
    };

    if (props.location.pathname.includes('/FI')) {
      shortLink(props.location.pathname.split('/')[1] || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUserAndCountry = async () => {
      await dispatch(getCountryCodeByIPThunk());
      const token = loadAuthToken();
      if (token && token.accessToken && token.refreshToken) {
        await dispatch(getUserProfileThunk());
      }
    };

    getUserAndCountry();
  }, [dispatch]);

  useEffect(() => {
    if (
      loadPreviousUrl() === ROUTES.SIGN_UP_PAGE &&
      history.location.pathname !== ROUTES.SIGN_UP_PAGE
    ) {
      removeSocialUserData();
    }
    savePreviousUrl(history.location.pathname);
  }, [history.location.pathname]);

  if (isShortLinkLoading) {
    return null;
  }

  return (
    <Router history={history}>
      <Switch>
        {publicRoutes.map((route: IRoute) => (
          <PublicRoute {...route} key={route.key} />
        ))}
      </Switch>
    </Router>
  );
}

export default withRouter(AppRoute);
