import React from 'react';
import ShowMoreText from 'react-show-more-text';

import styles from './AboutClinicInfomation.module.scss';

interface Props {
  clinicName: string;
  description: string | null;
  services: { id: string; name: string }[];
}

export type AboutClinicInfomationComponent = React.FC<Props>;

const AboutClinicInfomation: AboutClinicInfomationComponent = ({
  clinicName,
  description = '',
  services,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['description-title']}>{`About ${clinicName}`}</div>
      <div className={styles['description']}>
        <ShowMoreText
          lines={5}
          more="show more"
          less="show less"
          expanded={false}
          className={styles.link}
        >
          {description}
        </ShowMoreText>
      </div>
      <div className={styles['services-title']}>Services</div>
      <div className={styles['services']}>
        {services.map((service) => {
          return (
            <div className={styles['services-item']} key={service.id}>
              •
              <span className={styles['services-item-value']}>
                {service.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutClinicInfomation;
