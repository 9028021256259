import moment from 'moment';
import React, { ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import cx from 'classnames';

import { ReactComponent as LeftArrow } from 'assets/icons/left-darker.svg';
import { ReactComponent as RightArrow } from 'assets/icons/right-darker.svg';
import useIsMobile from 'hooks/useIsMobile';
import { convertToDate } from 'utils/dates';

import styles from './DayViewNavigation.module.scss';
import './DateSelector.module.scss';

interface Props {
  date: string;
  onDateUpdate: (newDate: string) => void;
  customInput?: ReactNode;
  amount?: number;
}

const convertDateString = (dateString: string) =>
  moment(convertToDate(dateString)).format('MMM DD');

const isToday = (someDate: string) => {
  const date = convertToDate(someDate);
  const currentDate = moment();
  const isToday = moment(date).isSame(currentDate, 'days');
  return isToday;
};

const isInThePast = (someDate: Date) => {
  const date = convertToDate(someDate);
  const currentDate = moment();
  const isBefore = moment(date).isBefore(currentDate, 'days');
  return isBefore;
};

const DayViewNavigation: React.FC<Props> = ({
  date,
  onDateUpdate,
  customInput,
  amount,
}) => {
  const prevClassName = cx({
    [styles['btn']]: true,
    [styles['disabled']]: isToday(date),
  });

  const isMobile = useIsMobile();

  const clickPrev = () => {
    const newDate = moment(convertToDate(date))
      .subtract(amount ? amount : isMobile ? 3 : 5, 'days')
      .toDate();
    if (!isInThePast(newDate)) {
      onDateUpdate(newDate.toDateString());
      return;
    }
    onDateUpdate(new Date().toDateString());
  };

  const clickNext = () => {
    onDateUpdate(
      new Date(
        moment(convertToDate(date))
          .add(amount ? amount : isMobile ? 3 : 5, 'days')
          .format()
      ).toDateString()
    );
  };
  return (
    <div className={styles['container']}>
      <button className={prevClassName} onClick={clickPrev}>
        <LeftArrow className={styles['btn-icon']} />
      </button>
      <div className="day-selector-container">
        <DatePicker
          selected={convertToDate(date)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateUpdate(convertToDate(date).toDateString());
            }
          }}
          minDate={new Date()}
          calendarClassName={styles['calendar']}
          customInput={
            customInput ? (
              customInput
            ) : (
              <div className={styles['content']}>
                {`${convertDateString(date)} - ${convertDateString(
                  moment(convertToDate(date))
                    .add(isMobile ? 2 : 4, 'days')
                    .format()
                )}`}
              </div>
            )
          }
          popperModifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
        />
      </div>
      <button className={styles['btn']} onClick={clickNext}>
        <RightArrow className={styles['btn-icon']} />
      </button>
    </div>
  );
};

export default DayViewNavigation;
