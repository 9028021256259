import React from 'react';

import PaginationController from 'components/PaginationController/PaginationController';
import { PractitionerReview } from 'components/PractitionerReview';
import { useAppSelector } from 'redux/reduxHooks';
import ReviewSummary from 'components/ReviewSummary/ReviewSummary';

import styles from './ReviewInformation.module.scss';
import useIsMobile from 'hooks/useIsMobile';

interface IReviewInformationProps {
  reviewIds: string[];
  currentPage: number;
  onChangePage: (page: number) => void;
  pageCount: number;
  isLoading: boolean;
}

function PractitionerReviews({
  isLoading,
  reviewIds,
  onChangePage,
  currentPage,
  pageCount,
}: IReviewInformationProps) {
  const { reviewsData, practitionerDetails } = useAppSelector(
    (state) => state.practitionerDetailsSlice
  );

  const isMobile = useIsMobile();

  const renderPractitionerReview = (
    totalScore: number,
    reviewCount: number,
    isLoading: boolean
  ) => {
    if (!isLoading && reviewCount > 0) {
      return (
        <>
          <ReviewSummary
            totalScore={totalScore}
            reviewCount={reviewCount}
            title="Practitioner Review"
          />
          {reviewIds.map((item, index) => (
            <PractitionerReview
              key={index}
              content={reviewsData.reviews.data[item].content}
              reviewer={{
                avatar: reviewsData.reviews.data[item].avatar,
                name: reviewsData.reviews.data[item].reviewer,
              }}
              score={reviewsData.reviews.data[item].score}
              createdAt={reviewsData.reviews.data[item].createdAt}
              source={reviewsData.reviews.data[item].source}
              isEvenIndex={(index + 1) % 2 === 0}
            />
          ))}
        </>
      );
    }
    return 'No Ratings';
  };

  return (
    <div className={styles.container}>
      {renderPractitionerReview(
        practitionerDetails.totalScore,
        practitionerDetails.reviewCount,
        isLoading
      )}
      {!isMobile && (
        <PaginationController
          currentPage={currentPage}
          pageCount={pageCount}
          onChangePage={onChangePage}
        />
      )}{' '}
    </div>
  );
}

export default PractitionerReviews;
