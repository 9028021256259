/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, {
  AutocompleteRenderOptionState,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import { useLocation } from 'react-router-dom';

import { setSearchBarFieldsIsOpen } from 'redux/searchBarSlice';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import CustomPopper from './CustomPopper/CustomPopper';
import useStyles from './useStyles';
import { FilterTitle } from 'interfaces/filterTypes';
import { orderBy } from 'lodash';
import { PATIENT_EXAM_AND_CLEANING } from 'components/DentalMap/utils/constant';

const MAX_CHARACTERS_COUNT = 50;
interface Option {
  title: string;
  id: string;
}
interface Props {
  options: Option[];
  title: FilterTitle;
  onSelectionChanged?: (key: string, value: string | null) => void;
  setIsActive?: (isActive: boolean) => void;
  placeHolder: string;
  isResultPage?: boolean;
  isAutoSelect?: boolean;
  onChangeServiceOption?: (option: { title: string }) => void;
  onOpen: () => void;
}

type OptionValue = Option | null;

export type AutoCompleteComponent = React.FC<Props>;

const AutoComplete: AutoCompleteComponent = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isOpen = useAppSelector((state) => state.searchBarSlice);
  const filters = useAppSelector((state) => state.filterSlice);
  const searchBarOptionsSlice = useAppSelector(
    (state) => state.searchBarOptionsSlice
  );
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState<string>(
    searchBarOptionsSlice.service.data[query.get(`${props.title}`) || ''] || ''
  );
  const [optionValue, setOptionValue] = useState<OptionValue>(null);
  const [isHightlighting, setIsHightlighting] = useState<boolean>(false);

  const styles = useStyles();
  let inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (props.options.length === 0) return;
    const defaultValue =
      props.options.find(
        (option) => option.title === PATIENT_EXAM_AND_CLEANING
      ) ?? null;
    setOptionValue(defaultValue);
  }, [props.options.length]);

  const {
    onSelectionChanged = () => {},
    setIsActive = () => {},
    onChangeServiceOption = () => {},
  } = props;

  useEffect(() => {
    if (props.title === 'location' && isOpen.location) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isOpen.location]);

  useEffect(() => {
    if (props.title === 'service' && isOpen.service) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isOpen.service]);

  useEffect(() => {
    if (optionValue) {
      inputRef.current?.blur();
      setInputValue(optionValue.title);
      onChangeServiceOption({ title: optionValue.title });
    }
  }, [optionValue]);

  useEffect(() => {
    if (props.options.length > 0) {
      if (props.options.length === 1 || props.isAutoSelect) {
        setOptionValue(props.options[0]);
        return;
      }
    }
  }, [props.options]);

  useEffect(() => {
    const defaultOption: OptionValue =
      (props.title === 'service' &&
        props.options.find(
          (item) =>
            item.title === searchBarOptionsSlice.service.data[filters.service]
        )) ||
      null;
    setOptionValue(defaultOption);
  }, []);

  const onChange = (e: any, value: OptionValue) => {
    setIsActive(true);
    setIsHightlighting(false);
    setOptionValue(value);
    if (value) {
      onSelectionChanged(props.title, value.id);
      onChangeServiceOption(value);
      if (props.title === 'service' && !props.isResultPage) {
        dispatch(
          setSearchBarFieldsIsOpen({
            date: true,
          })
        );
      }
    } else {
      setInputValue('');
      onChangeServiceOption({ title: '' });
      onSelectionChanged(props.title, value);
    }
  };

  const onClose = () => {
    if (optionValue) {
      setInputValue(optionValue.title);
    }
    if (props.title === 'location') {
      dispatch(
        setSearchBarFieldsIsOpen({
          location: false,
        })
      );
    }
    if (props.title === 'service') {
      dispatch(
        setSearchBarFieldsIsOpen({
          service: false,
        })
      );
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const onFocus = () => {
      setIsActive(true);
      setInputValue('');
    };

    const onBlur = () => {
      setIsHightlighting(false);
      if (!optionValue) {
        setIsActive(false);
      }
    };

    return (
      <TextField
        {...params}
        inputRef={(input) => {
          inputRef.current = input;
        }}
        placeholder={optionValue ? optionValue.title : props.placeHolder}
        variant="outlined"
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{
          ...params.inputProps,
          maxLength: MAX_CHARACTERS_COUNT,
          value: inputValue,
          readOnly: true,
        }}
      />
    );
  };

  const renderOption = (
    option: Option,
    state: AutocompleteRenderOptionState
  ) => {
    const firstCharacterIndex = option.title
      .toLocaleLowerCase()
      .indexOf(state.inputValue.toLowerCase());
    const lastCharacterIndex = firstCharacterIndex + state.inputValue.length;
    const parts = isHightlighting
      ? parse(option.title, [[firstCharacterIndex, lastCharacterIndex]])
      : parse(option.title, [[0, option.title.length - 1]]);

    return (
      <div>
        {isHightlighting ? (
          parts.map((part, index) => (
            <span
              key={index}
              className={
                part.highlight && isHightlighting ? styles.highlightText : ''
              }
            >
              {part.text}
            </span>
          ))
        ) : (
          <span>{option.title}</span>
        )}
      </div>
    );
  };

  return (
    <Autocomplete
      openOnFocus
      fullWidth
      loading={props.options.length <= 0}
      loadingText="Loading..."
      className={styles.root}
      classes={{
        paper: styles.paper,
      }}
      options={
        props.title !== 'service'
          ? orderBy(props.options, 'title')
          : props.options
      }
      getOptionSelected={(option, value) => option.title === value.title}
      popupIcon={<></>}
      closeIcon={null}
      onChange={onChange}
      onClose={onClose}
      value={optionValue}
      renderInput={renderInput}
      PopperComponent={CustomPopper}
      getOptionLabel={(option: { title: string }) => option.title}
      renderOption={renderOption}
      onOpen={props.onOpen}
    />
  );
};

export default AutoComplete;
