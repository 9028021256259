import { ReactComponent as ServicesIcon } from 'assets/icons/services.svg';
import { ReactComponent as LocationsIcon } from 'assets/icons/locations.svg';
import { ReactComponent as DateSelectorIcon } from 'assets/icons/date-picker.svg';
import { AutoComplete } from 'components/AutoComplete';
import { DateSelector } from 'components/DateSelector';
import { ESearchFields } from 'interfaces/filterTypes';
import LocationAutoComplete from 'components/AutoComplete/LocationAutoComplete';

interface ISearchFields {
  title: ESearchFields;
  icon: any;
  inputComponent: any;
  validation: Function;
  placeHolder?: string;
}

export const searchFields: ISearchFields[] = [
  {
    title: ESearchFields.service,
    icon: ServicesIcon,
    placeHolder: 'Select Service',
    inputComponent: AutoComplete,
    validation: (value?: string) => {
      if (value) {
        return true;
      }
      return false;
    },
  },
  {
    title: ESearchFields.date,
    icon: DateSelectorIcon,
    inputComponent: DateSelector,
    validation: (value?: string) => {
      if (value) {
        return true;
      }
      return false;
    },
  },
  {
    title: ESearchFields.location,
    icon: LocationsIcon,
    placeHolder: 'Select City, State',
    inputComponent: LocationAutoComplete,
    validation: (value?: string) => {
      if (value) {
        return true;
      }
      return false;
    },
  },
];
