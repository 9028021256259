import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Patient } from 'services/APIs';
import { getCountryCodeByIPThunk } from './bookingFormSlice';
import {
  AvailableBlockDentistForSplitScheduling,
  AvailableBlockOperatory,
  SearchingService,
} from 'interfaces/timeslotType';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';

export const DEFAULT_COUNTRY_CODE = 'ca';

export const STEPS = {
  PATIENT_INFO: '1.PATIENT_INFO',
  VERIFICATION: '2.VERIFICATION',
  SIGN_IN: '2.SIGN_IN',
  SIGN_UP: '2.SIGN_UP',
  CONTACT_PATIENT: '2.CONTACT_PATIENT',
  PATIENT_SCHEDULE: '3.PATIENT_SCHEDULE',
  MONTHVIEW_SELECTION: '4.MONTHVIEW_SELECTION',
  SLOT_SELECTION: '4.SLOT_SELECTION',
};

interface Step1 {
  phoneNumber: string;
  dob: string;
  countryCode: string;
}

interface Step2 {
  contactPatient: ContactPatient;
  signUp: SignUp;
}

export interface SlotsPerDate {
  [key: string]: FamilyTimeSlot[];
}

export interface FamilyTimeSlotBlockService {
  serviceId: string;
  childServiceId: string | null;
  dentists?: AvailableBlockDentistForSplitScheduling[];
  availableBlockOperatories?: AvailableBlockOperatory[];
  searchingService: SearchingService;
}

export interface FamilyTimeSlotBlock {
  value: number;
  services: FamilyTimeSlotBlockService[];
}

export interface FamilyTimeSlot {
  date: string;
  doctor: {
    id: string;
    isActive: boolean;
    firstName: string;
    lastName: string;
    name: string;
    slug: string;
    avatar: string | null;
  };
  isClosed: boolean;
  blocks: FamilyTimeSlotBlock[];
}

export interface SignUp {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  phoneNumber: string;
  countryCode: string;
}

interface ContactPatient {
  firstName: string;
  lastName: string;
  email: string;
  isFromPatientInfo?: boolean;
  serviceId?: string | null;
}

export interface BookingPatient {
  id?: string | null;
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phoneNumber: string;
  serviceId?: string | null;
  isDisabledService?: boolean;
}

export interface SelectedPatient extends BookingPatient {
  appointmentDate?: string | null;
  startTime?: string | null;
  doctorId?: string | null;
  operatoryId?: string | null;
  dentist?: DentistInfo | null;
  childServiceId?: string | null;
  isSlotSelected: boolean;
}

interface FamilyBookingState {
  cachedData: Omit<Step1, 'countryCode'>;
  step1: Step1;
  step2: Step2;
  pageStack: string[];
  isDone: boolean;
  patients: BookingPatient[];
  contactUser: ContactUser;
  selectedPatients: SelectedPatient[];
  slotsPerDate: SlotsPerDate | null;
  currentSelectedPatientIndex: number;
  currentSelectedDate: string | null;
  previousBookedPractitionerSlots: SlotsPerDate | null;
}

export interface ContactUser {
  id?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dob: string;
  serviceId?: string | null;
  isDisabledService?: boolean;
}

const initialState: FamilyBookingState = {
  cachedData: {
    phoneNumber: '',
    dob: '',
  },
  step1: {
    phoneNumber: '',
    dob: '',
    countryCode: DEFAULT_COUNTRY_CODE,
  },
  step2: {
    contactPatient: {
      firstName: '',
      lastName: '',
      email: '',
      isFromPatientInfo: false,
    },
    signUp: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      phoneNumber: '',
      countryCode: DEFAULT_COUNTRY_CODE,
    },
  },
  pageStack: [STEPS.PATIENT_INFO],
  isDone: false,
  patients: [],
  contactUser: {
    phoneNumber: '',
    dob: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  selectedPatients: [],
  slotsPerDate: null,
  // HERE
  currentSelectedDate: null,
  currentSelectedPatientIndex: 0,
  previousBookedPractitionerSlots: null,
};

const checkIsSamePatient = (
  firstPatient: BookingPatient,
  secondPatient: BookingPatient
) => {
  const isSameDob = moment(firstPatient.dob, [
    'YYYY-MM-DD',
    'MM/DD/YYYY',
  ]).isSame(moment(secondPatient.dob, ['YYYY-MM-DD', 'MM/DD/YYYY']));

  const isSamePatientInfo =
    isSameDob &&
    firstPatient.email === secondPatient.email &&
    firstPatient.firstName?.toLowerCase() ===
      secondPatient.firstName?.toLowerCase() &&
    firstPatient.lastName?.toLowerCase() ===
      secondPatient.lastName?.toLowerCase();

  return isSamePatientInfo;
};

const familyBookingSlice = createSlice({
  name: 'familyBooking',
  initialState,
  reducers: {
    resetFamilyBooking: (state) => {
      state.step1 = {
        ...initialState.step1,
        countryCode: state.step1.countryCode,
      };

      state.step2 = {
        contactPatient: {
          ...initialState.step2.contactPatient,
        },
        signUp: {
          ...initialState.step2.signUp,
          countryCode: state.step2.signUp.countryCode,
        },
      };

      state.cachedData = initialState.cachedData;
      state.pageStack = initialState.pageStack;
      state.isDone = initialState.isDone;
    },
    updateFBStep1: (state, action: PayloadAction<Partial<Step1>>) => {
      state.step1 = { ...state.step1, ...action.payload };
    },
    updateFBStep2: (state, action: PayloadAction<Partial<Step2>>) => {
      if (action.payload.contactPatient) {
        state.step2.contactPatient = action.payload.contactPatient;
      }
      if (action.payload.signUp) {
        state.step2.signUp = {
          ...state.step2.signUp,
          ...action.payload.signUp,
        };
      }
    },
    resetFBStep2SignUp: (state) => {
      state.step2.signUp = {
        ...initialState.step2.signUp,
        countryCode: state.step2.signUp.countryCode,
      };
    },
    resetStep2ContactPatient: (state) => {
      state.step2.contactPatient = initialState.step2.contactPatient;
    },

    updateFBCached: (
      state,
      action: PayloadAction<Omit<Step1, 'countryCode'>>
    ) => {
      state.cachedData = action.payload;
    },
    resetFBCached: (state) => {
      state.cachedData = initialState.cachedData;
    },
    updateFBStep: (state, action: PayloadAction<string[]>) => {
      state.pageStack = action.payload;
    },
    popFBStep: (state) => {
      state.pageStack.pop();
    },
    pushFBStep: (state, action: PayloadAction<string>) => {
      const pageStack = state.pageStack;
      const currentPage = pageStack[pageStack.length - 1];
      if (currentPage === STEPS.VERIFICATION) {
        pageStack.pop();
      }
      pageStack.push(action.payload);
    },
    replaceFBStep: (state, action: PayloadAction<string>) => {
      const pageStack = state.pageStack;
      pageStack[pageStack.length - 1] = action.payload;
    },
    updateFBIsDone: (state, action: PayloadAction<boolean>) => {
      state.isDone = action.payload;
    },
    updateContactUser: (state, action: PayloadAction<Partial<ContactUser>>) => {
      state.contactUser = {
        ...state.contactUser,
        ...action.payload,
      };
    },

    updatePatients: (state, action: PayloadAction<Patient[]>) => {
      const updatedData = action.payload.map((item) => {
        return {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          dob: item.dob,
          email: item.email,
          phoneNumber: item.phoneNumber,
        };
      });

      state.patients = updatedData;
    },

    updateBookingPatient: (state, action: PayloadAction<BookingPatient>) => {
      state.patients = state.patients.reduce(
        (accum: BookingPatient[], currentPatient: BookingPatient) => {
          const isSamePatientInfo = checkIsSamePatient(
            currentPatient,
            action.payload
          );

          const existingBookingPatient =
            (currentPatient.id !== null &&
              action.payload.id !== null &&
              currentPatient.id === action.payload.id) ||
            isSamePatientInfo;

          if (existingBookingPatient) {
            accum.push({
              ...currentPatient,
              ...action.payload,
            });
          } else {
            accum.push(currentPatient);
          }

          return accum;
        },
        []
      );
    },

    addBookingPatient: (state, action: PayloadAction<BookingPatient>) => {
      const newPatient = action.payload;
      const existingBookingPatient = state.patients.find((item) => {
        return checkIsSamePatient(item, newPatient);
      });

      if (!existingBookingPatient) {
        state.patients.push(action.payload);
      }
    },

    updateSelectedPatients: (
      state,
      action: PayloadAction<Omit<SelectedPatient, 'isSlotSelected'>[]>
    ) => {
      state.selectedPatients = action.payload.map((item) => ({
        ...item,
        dob: moment(item.dob, ['YYYY-MM-DD', 'MM/DD/YYYY']).format(
          'YYYY-MM-DD'
        ),
        isSlotSelected: false,
      }));
    },

    resetSelectedPatients: (state) => {
      state.selectedPatients = state.selectedPatients.map((item) => ({
        ...item,
        startTime: null,
        appointmentDate: null,
        isSlotSelected: false,
        doctorId: null,
        operatoryId: null,
        dentist: null,
      }));
    },

    updateSlotsPerDay: (state, action: PayloadAction<SlotsPerDate>) => {
      state.slotsPerDate = action.payload;
    },

    updatedCurrentSelectedDate: (state, action: PayloadAction<string>) => {
      state.currentSelectedDate = action.payload;
    },

    updatedCurrentSelectedPatientIndex: (
      state,
      action: PayloadAction<number>
    ) => {
      state.currentSelectedPatientIndex = action.payload;
    },

    updatedCurrentSelectedPatient: (
      state,
      action: PayloadAction<Partial<SelectedPatient>>
    ) => {
      state.selectedPatients[state.currentSelectedPatientIndex!] = {
        ...state.selectedPatients[state.currentSelectedPatientIndex!],
        ...action.payload,
        isSlotSelected: true,
      };
    },

    resetCurrentSelectedPatient: (state) => {
      state.selectedPatients[state.currentSelectedPatientIndex!] = {
        ...state.selectedPatients[state.currentSelectedPatientIndex!],
        doctorId: null,
        appointmentDate: null,
        startTime: null,
        operatoryId: null,
        dentist: null,
        isSlotSelected: false,
      };
    },

    removePreviousBookedPractitionerSlots: (
      state,
      action: PayloadAction<{
        doctorId: string;
        serviceId: string;
        currentSelectedDate: string;
      }>
    ) => {
      const { currentSelectedDate, doctorId, serviceId } = action.payload;

      const previousPractitionerSlots =
        state.previousBookedPractitionerSlots?.[currentSelectedDate]!;

      const practitioner = previousPractitionerSlots.find(
        (slot) => slot.doctor.id === doctorId
      )!;

      const isPractitionerDoingMultipleServices = practitioner.blocks.some(
        (block) => block.services.length > 1
      );

      if (!isPractitionerDoingMultipleServices) {
        const updatedPreviousBookedPractitionerSlots =
          previousPractitionerSlots.filter(
            (slot) => slot.doctor.id !== doctorId
          );

        updatedPreviousBookedPractitionerSlots.length === 0
          ? delete state.previousBookedPractitionerSlots![currentSelectedDate]
          : (state.previousBookedPractitionerSlots![currentSelectedDate] =
              updatedPreviousBookedPractitionerSlots);

        return;
      }

      const blocks = practitioner.blocks.filter((block) =>
        block.services.some((service) => service.serviceId === serviceId)
      );

      for (let index = 0; index < blocks.length; index++) {
        const block = blocks[index];
        block.services = block.services.filter(
          (service) => service.serviceId !== serviceId
        );
      }
    },

    updatePreviousBookedPractitionerSlots: (
      state,
      action: PayloadAction<FamilyTimeSlot>
    ) => {
      const previousPractitionerSlots =
        state.previousBookedPractitionerSlots?.[action.payload.date];

      if (!previousPractitionerSlots) {
        state.previousBookedPractitionerSlots = {
          [action.payload.date]: [action.payload],
        };
        return;
      }

      const samePractitioner = previousPractitionerSlots.find(
        (slot) => slot.doctor.id === action.payload.doctor.id
      )!;

      if (!samePractitioner) {
        previousPractitionerSlots.push(action.payload);
        return;
      }

      // UPDATE BLOCK TO INCLUDE NEW SERVICES
      action.payload.blocks.forEach((block) => {
        const blockValue = samePractitioner.blocks.find(
          (item) => item.value === block.value
        );

        if (!blockValue) {
          samePractitioner.blocks.push({
            value: block.value,
            services: block.services,
          });
          return;
        }

        blockValue.services.push(...block.services);
      });
    },

    resetPreviousBookedPractitionerSlots: (state) => {
      state.previousBookedPractitionerSlots =
        initialState.previousBookedPractitionerSlots;
    },

    clearBookingPatient: (state, action: PayloadAction<BookingPatient>) => {
      const removedBookingPatient = action.payload;

      const updatedPatients = state.patients.map((currentPatient) => {
        if (checkIsSamePatient(currentPatient, removedBookingPatient)) {
          return {
            ...currentPatient,
            serviceId: null,
          };
        }

        return {
          ...currentPatient,
        };
      });

      state.patients = updatedPatients;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get CountryCodeByIP
      .addCase(getCountryCodeByIPThunk.fulfilled, (state, action) => {
        state.step1.countryCode = action.payload;
        state.step2.signUp.countryCode = action.payload;
      });
  },
});

export const {
  resetFamilyBooking,
  updateFBStep1,
  updateFBStep2,
  resetFBStep2SignUp,
  resetStep2ContactPatient,
  updatePatients,
  updateFBCached,
  resetFBCached,
  updateFBStep,
  popFBStep,
  pushFBStep,
  replaceFBStep,
  updateFBIsDone,
  updateBookingPatient,
  addBookingPatient,
  clearBookingPatient,
  updateContactUser,
  updateSelectedPatients,
  updateSlotsPerDay,
  updatedCurrentSelectedDate,
  updatedCurrentSelectedPatientIndex,
  updatedCurrentSelectedPatient,
  resetCurrentSelectedPatient,
  resetSelectedPatients,
  resetPreviousBookedPractitionerSlots,
  removePreviousBookedPractitionerSlots,
  updatePreviousBookedPractitionerSlots,
} = familyBookingSlice.actions;

export default familyBookingSlice.reducer;
