import React from 'react';

import styles from './ProgressBar.module.scss';
import Step from './Step';

interface ProgressBarProps {
  page: number;
  options: { page: number; content: string; mobileContent: string }[];
}

const ProgressBar = ({ options, page }: ProgressBarProps) => {
  return (
    <div className={styles['container']}>
      {options.map((item) => (
        <Step
          key={item.content}
          stepNumber={item.page}
          currentPage={page}
          content={item.content}
          mobileContent={item.mobileContent}
          totalPages={options.length}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
