import { FC, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-up.svg';
import styles from './Dropdown.module.scss';
import { ModeOption } from '../utils/constants';

interface DropdownProps {
  options: ModeOption[];
  value: ModeOption;
  onChange: (modeOption: ModeOption) => void;
  modifiers?: object;
  defaultOpen?: boolean;
}

const Dropdown: FC<DropdownProps> = ({
  options,
  value,
  onChange,
  modifiers,
  defaultOpen,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!defaultOpen) return;
    setAnchorEl(buttonRef.current!);
  }, [defaultOpen]);

  return (
    <div className={styles.controller}>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div>
          <button
            className={styles['controller-view']}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            ref={buttonRef}
          >
            <span>{value.label}</span>
            <ArrowIcon className={styles['controller-view-icon']} />
          </button>
          <Popper
            placement="bottom"
            disablePortal={true}
            anchorEl={anchorEl}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: 'scrollParent',
              },
              hide: { enabled: false },
              ...modifiers,
            }}
            open={Boolean(anchorEl)}
          >
            <div className={styles['controller-dropdown']}>
              {options.map((option) => (
                <button
                  key={option.id}
                  className={styles['controller-dropdown-item']}
                  onClick={() => {
                    onChange(option);
                    setAnchorEl(null);
                  }}
                  data-active={option === value}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Dropdown;
