import { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import NoAvatarSrc from 'assets/images/no-avatar.svg';
import { ReactComponent as Dot } from 'assets/icons/dot.svg';
import styles from './ContactInformation.module.scss';
import { IPractitionerDetails } from 'interfaces/practitionerDetailsTypes';
import useIsMobile from 'hooks/useIsMobile';
import getPractitionerName from 'utils/getPractitionerName';
import { filterSpecialties } from 'utils/common';

interface IContactInformationProps {
  data: IPractitionerDetails;
}
interface IData {
  data: IPractitionerDetails;
}

interface IPractitionerNameProps {
  name: string;
  study: string | '';
}
interface IPractitionerAvatarProps {
  src: string;
}

export const Avatar = ({ src }: IPractitionerAvatarProps) => {
  return (
    <img
      src={`${src || NoAvatarSrc}`}
      alt="practitioner-avatar"
      className={styles['avatar']}
    />
  );
};

const PractitionerName = ({ name, study }: IPractitionerNameProps) => {
  return (
    <div className={`${styles.name} ${styles.bold}`}>
      <span>{name}</span>
      <span>{study ? `, ${study}` : null}</span>
    </div>
  );
};

const Information = ({ data }: IData) => {
  return (
    <div className={styles['flex-row']}>
      <div className={styles['container--left']}>
        <Avatar src={data.avatar} />
      </div>
      <div className={styles['container--right']}>
        <PractitionerName
          name={getPractitionerName(data)}
          study={data.study.join(',')}
        />
        <div className={`${styles['secondary-purple']} ${styles.bold}`}>
          {filterSpecialties(data.specialty).map((item, index) => (
            <span key={item.id}>
              {index !== 0 && <Dot className={styles['dot-icon']} />}
              <span>{item.name}</span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
  },
});

const ContactInformation: FC<IContactInformationProps> = (props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  return !isMobile ? (
    <div className={styles['container']}>
      <Information data={props.data} />
    </div>
  ) : (
    <Accordion classes={classes}>
      <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
        <Information data={props.data} />
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

export default ContactInformation;
