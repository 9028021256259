// THIS IS TO FIX ISSUE WHEN RUNNING JEST WITH MOMENT WHEN EXTENDED
import Moment from 'moment';
import { extendMoment } from 'moment-range';

// excluding from startDate for calculating availability
const moment = extendMoment(Moment as any);
const AVAILABILITY_DATE_RANGE = 4;

export const getDateRange = (start: Date, end: Date) => {
  const rangeDates = [];

  const range = moment.range(start, end);
  for (let day of range.by('days')) {
    rangeDates.push(day.format('YYYY-MM-DD'));
  }

  return rangeDates;
};

export const getFilterDates = (date?: string): string[] => {
  const currentMoment = date ? moment(convertToDate(date)) : moment();
  const startDate = currentMoment.format('YYYY-MM-DD');
  const endDate = currentMoment
    .add(AVAILABILITY_DATE_RANGE, 'days')
    .format('YYYY-MM-DD');
  return [startDate, endDate];
};

export const getRangeDateFrom = (
  startDate: string,
  amountDays: number
): string[] => {
  return (Array.from({ length: amountDays }) as undefined[]).reduce(
    (accumulator: string[], _, index: number) => {
      const date = moment(startDate, 'YYYY-MM-DD')
        .add(index + 1, 'days')
        .format('YYYY-MM-DD');
      accumulator.push(date);
      return accumulator;
    },
    [startDate]
  );
};

export const convertToDate = (dateString: string | Date) => {
  if (moment(dateString, 'YYYY-MM-DD', true).isValid()) {
    return moment(dateString, 'YYYY-MM-DD').toDate();
  }

  return moment(dateString).toDate();
};
