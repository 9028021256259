import styles from './Layout.module.scss';
import classNames from 'classnames';
import moment from 'moment';
import { ReactNode } from 'react';

interface ILayout {
  children: ReactNode;
  hasGreyBackgroundColor?: boolean;
  isOverFlowHidden?: boolean;
}
interface IContent extends ILayout {
  isHeaderFixed?: boolean;
}
interface IHeader extends ILayout {
  isFixed?: boolean;
  hasBoxShadow?: boolean;
  isSticky?: boolean;
  isSearchResultPage?: boolean;
}

export const Header = ({
  children,
  isFixed,
  hasBoxShadow,
  isSearchResultPage,
}: IHeader) => {
  const headerClassNames = classNames({
    [styles['header']]: true,
    [styles['fixed']]: isFixed,
    [styles['has-box-shadow']]: hasBoxShadow,
    [styles['search-result-page']]: isSearchResultPage,
  });
  return (
    <div className={headerClassNames} id="main-header">
      <div className={styles['container']}>{children}</div>
    </div>
  );
};

export const Footer = () => {
  const currentYear = moment().format('YYYY');

  const footerClassNames = classNames({
    [styles['footer']]: true,
  });

  return (
    <div className={footerClassNames}>
      <div
        className={styles['container']}
      >{`© ${currentYear} FirstIn Practice Solutions LTD. All right reserved.`}</div>
    </div>
  );
};

export const Content = ({ children, isHeaderFixed }: IContent) => {
  const contentClassNames = classNames({
    [styles['content']]: true,
    [styles['header-fixed']]: isHeaderFixed,
  });
  return (
    <div className={contentClassNames}>
      <div className={styles['container']}>{children}</div>
    </div>
  );
};

export const Layout = ({
  children,
  hasGreyBackgroundColor,
  isOverFlowHidden,
}: ILayout) => {
  const bodyClassNames = classNames({
    [styles['grey-background']]: hasGreyBackgroundColor,
    [styles['overflow-hidden']]: isOverFlowHidden,
  });
  return <div className={bodyClassNames}>{children}</div>;
};
