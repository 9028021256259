import React from 'react';
import cx from 'classnames';

import TimeSlots from 'components/TimeSlots/TimeSlots';
import { ReactComponent as DateAndTimeIcon } from 'assets/icons/date-and-time.svg';

import styles from './TimeSlotsContainer.module.scss';
import useIsMobile from 'hooks/useIsMobile';

interface Props {
  availableBlocks: { value: number; displayString: string }[];
  clickTimeSlot: (timeSlot: number) => void;
  viewAllAvailability: (event: any) => void;
}

const TimeSlotsContainer: React.FC<Props> = ({
  availableBlocks,
  clickTimeSlot,
  viewAllAvailability,
}) => {
  const wrapperClassName = cx({
    [styles['time-slots-wrapper']]: true,
  });

  const isMobile = useIsMobile();
  const DEFAULT_PER_PAGE = isMobile ? 12 : 8;

  const timeSlots = availableBlocks.slice(0, DEFAULT_PER_PAGE);

  return (
    <div className={styles['container']}>
      <div className={wrapperClassName}>
        <div className={styles['time-slots']}>
          {timeSlots.map((item, index) => (
            <div key={item.value}>
              <TimeSlots
                displayString={item.displayString}
                value={item.value}
                onClick={clickTimeSlot}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles['center']} onClick={viewAllAvailability}>
        <DateAndTimeIcon className={styles['center-icon']} />
        View more
      </div>
    </div>
  );
};

export default TimeSlotsContainer;
