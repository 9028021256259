import L from 'leaflet';

export const DEFAULT_ZOOM_LEVEL = 10;
export const FOCUS_ZOOM_LEVEL = 15;
export const POP_UP_MODAL_HEIGHT = 270;
export const FIT_BOUNDS_PADDING: L.PointExpression = [100, 100];
export const ERROR_CODES = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
};

export const PATIENT_EXAM_AND_CLEANING = 'Exam & Cleaning';

export const SPECIALIST = {
  HYGIENIST: 'Hygienist',
  DENTIST: 'Dentist',
};

export const ERROR_CODE = {
  KICK_BACK: 100, // Can not find available slot for hygienist and dentist
};
