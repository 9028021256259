import { isArraysHaveSameElements } from 'utils/checkTwoArraysHaveSameElements';
import { getSessionLocation } from 'utils/storage';

const getPlacesDetailsFromLatLng = (
  location: { lat: number; lng: number },
  callback: (placeName: any, placeId: any) => void
) => {
  const geocoder = new (window as any).google.maps.Geocoder();
  const latlng = {
    lat: location.lat,
    lng: location.lng,
  };

  const locationTypesUrlParams = getSessionLocation()?.types;
  geocoder.geocode({ location: latlng }, (results: any) => {
    if (results) {
      const placeDetails = results.find((item: any) => {
        const locationTypes = item.types;
        return isArraysHaveSameElements(locationTypesUrlParams, locationTypes);
      });
      callback(placeDetails?.formatted_address, placeDetails?.place_id);
    } else {
      window.alert('No results found');
    }
  });
};

export default getPlacesDetailsFromLatLng;
