import Rating from '@material-ui/lab/Rating';
import { isNil } from 'lodash';
import cx from 'classnames';
import NoAvatarSrc from 'assets/images/no-avatar.svg';
import { ReactComponent as NoAvatarImage } from 'assets/images/no-avatar.svg';
import { ReactComponent as EmptyStar } from 'assets/icons/empty-star.svg';

import styles from './CardAvatar.module.scss';

interface Props {
  avatarUrl: string | null;
  totalScore: number;
  reviewCount: number;
  hideReviews?: boolean;
  customStyle?: string;
}

export type AvatarComponent = React.FC<Props>;

export const renderRatingScore = (totalScore: number, reviewCount: number) => {
  let ratings = null;
  if (totalScore) {
    ratings = (
      <div className={styles['avatar-container--ratings']}>
        <span className={styles['avatar-container--ratings-score']}>
          {totalScore}
        </span>
        <div
          className={styles['display-none-on-mobile']}
        >{`(${reviewCount})`}</div>
      </div>
    );
  } else {
    ratings = (
      <div className={styles['avatar-container--ratings']}>No ratings</div>
    );
  }

  return (
    <div className={styles['ratings-wrapper']}>
      {!isNil(totalScore) && (
        <Rating
          name={totalScore ? 'read-only' : 'no-value'}
          value={totalScore || null}
          readOnly
          precision={0.5}
          emptyIcon={<EmptyStar />}
        />
      )}
      {ratings}
    </div>
  );
};

const CardAvatar: AvatarComponent = (props) => {
  const { avatarUrl, totalScore, reviewCount, hideReviews, customStyle } =
    props;
  const onImageError = (e: any) => {
    e.target.src = `${NoAvatarSrc}`;
  };

  const renderAvatarImage = () => {
    return avatarUrl ? (
      <img
        src={avatarUrl}
        alt="practitioner-avatar"
        className={cx(styles['avatar-container--img-wrapper'], customStyle)}
        onError={onImageError}
      />
    ) : (
      <NoAvatarImage
        className={cx(styles['avatar-container--img-wrapper'], customStyle)}
      />
    );
  };

  return (
    <div className={styles['avatar-container']}>
      {renderAvatarImage()}
      {!hideReviews && renderRatingScore(totalScore, reviewCount)}
    </div>
  );
};

export default CardAvatar;
