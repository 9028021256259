import React from 'react';
import { NavigationBar } from 'components/NavigationBar';
import { Header } from 'components/Layout';

import styles from './SignInPage.module.scss';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from 'redux/reduxHooks';
import SignInForm from 'components/BookingForm/Step2/SignInForm/SignInForm';

function SignInPage() {
  const { isLoggedIn } = useAppSelector((state) => state.authSlice);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles['upper-section']}>
      <Header hasBoxShadow>
        <NavigationBar />
      </Header>
      <div className={styles['body']}>
        <SignInForm />
      </div>
    </div>
  );
}

export default SignInPage;
