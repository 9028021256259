import React from 'react';
import cx from 'classnames';

import { ReactComponent as Dash } from 'assets/icons/horizontal-line.svg';

import styles from './Step.module.scss';

interface Props {
  stepNumber: number;
  currentPage: number;
  content: string;
  mobileContent: string;
  totalPages: number;
}

const Step: React.FC<Props> = ({
  stepNumber,
  currentPage,
  content,
  mobileContent,
  totalPages,
}) => {
  const circleClassName = cx({
    [styles['circle']]: true,
    [styles['highlighted']]: currentPage >= stepNumber,
  });
  return (
    <div className={styles['container']}>
      <div className={circleClassName}>{stepNumber}</div>
      <div className={styles['content']}>{content}</div>
      <div className={styles['mobileContent']}>{mobileContent}</div>
      {stepNumber !== totalPages && <Dash className={styles['dash']} />}
    </div>
  );
};

export default Step;
