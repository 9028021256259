import React, { FC } from 'react';
import styles from './Message.module.scss';
import cx from 'classnames';

import { ReactComponent as CheckCircleIcon } from 'assets/icons/check_circle.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

interface MessageProps {
  type: 'success' | 'warning';
  message: string;
}

const Message: FC<MessageProps> = ({ type = 'success', message }) => {
  const containerClassname = cx({
    [styles['container']]: true,
    [styles['container-warning']]: type === 'warning',
  });

  const iconClassname = cx({
    [styles['icon']]: true,
    [styles['icon-warning']]: type === 'warning',
  });

  return (
    <div className={containerClassname}>
      {type === 'success' ? (
        <CheckCircleIcon className={iconClassname} />
      ) : (
        <WarningIcon className={iconClassname} />
      )}
      <span>{message}</span>
    </div>
  );
};

export default Message;
