import React, { useState } from 'react';

import {
  IClinicBookingData,
  IPractitionerBookingData,
} from '../ClinicDetailsPage';

import { IClinicData, IClinicService } from 'redux/clinicDetailsSlice';

import SingleBooking from './SingleBooking/SingleBooking';
import FamilyBooking from './FamilyBooking/FamilyBooking';
import { MYSELF_OPTION } from './utils/constants';
import { useAppSelector } from 'redux/reduxHooks';

export interface IClinicServicesOption extends Omit<IClinicService, 'name'> {
  title: string;
}

interface Props {
  clinicData: IClinicData;
  clinicServicesOptions: IClinicServicesOption[];
  onClickClinicTimeSlot: (data: IClinicBookingData) => void;
  onClickPractitionerTimeSlot: (data: IPractitionerBookingData) => void;
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}

const BookNowPanel: React.FC<Props> = (props) => {
  const [mode, setMode] = useState(MYSELF_OPTION);

  const { isLoggedIn } = useAppSelector((state) => state.authSlice);

  const isSingleBooking = mode === MYSELF_OPTION;

  const isFamilyBookingEnabled =
    process.env.REACT_APP_IS_FAMILY_BOOKING_ENABLED === 'true';

  if (!isSingleBooking && isFamilyBookingEnabled) {
    return (
      <FamilyBooking
        key={`${isLoggedIn}`}
        mode={mode}
        setMode={setMode}
        clinicServicesOptions={props.clinicServicesOptions}
      />
    );
  }

  return <SingleBooking {...props} mode={mode} setMode={setMode} />;
};

export default BookNowPanel;
