import React from 'react';
import cx from 'classnames';

import { IPractitioner } from 'interfaces/practitionerTypes';
import CardAvatar from 'components/CardAvatar/CardAvatar';
import Details from './Details/Details';

import styles from './PractitionerCard.module.scss';
import TimeSlotsContainer from './TimeSlotsContainer/TimeSlotsContainer';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getSlotsPer30MinutesForCard } from 'utils/updatedSlotsPer30Minutes';
import { IClinicData } from 'redux/clinicDetailsSlice';

interface Props {
  practitioner: IPractitioner;
  paddingTopOnly?: boolean;
  hideContacts?: boolean;
  hideReviews?: boolean;
  onClickRequest?: (toBeBookedPractitioner: {
    id: string;
    timeSlot: number;
    date: string;
  }) => void;
  onCollapseText?: (topValue: number) => void;
  listContainerRef?: React.RefObject<HTMLDivElement>;
  pageScrollTopValueRef?: React.MutableRefObject<number>;
  viewAllAvailability?: (practitionerId: string) => void;
  date?: string;
  isRenderInsideDetailPage?: boolean;
  clinic: IClinicData;
}

export type PractitionerCardComponent = React.FC<Props>;

const PractitionerCard: PractitionerCardComponent = ({
  date = moment(new Date()).format('MMMM DD, YYYY'),
  practitioner,
  hideContacts,
  hideReviews,
  paddingTopOnly,
  listContainerRef,
  pageScrollTopValueRef,
  onCollapseText,
  viewAllAvailability = () => {},
  onClickRequest = () => {},
  isRenderInsideDetailPage = false,
  clinic,
}) => {
  const history = useHistory();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['padding-top-only']]: paddingTopOnly,
  });

  const clickTimeSlot = (timeSlot: number) => {
    onClickRequest({ id: practitioner.id, timeSlot: timeSlot, date: '' });
  };

  const clickViewAllAvailability = (event: any) => {
    event.stopPropagation();
    viewAllAvailability(practitioner.id);
  };

  const navigateToProfilePage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (e.target instanceof HTMLAnchorElement) return;
    const path = `/${clinic.slug}/${practitioner.slug}`;

    history.push(path);
  };

  const updatedSlots = getSlotsPer30MinutesForCard(
    practitioner.availableBlocks
  );

  return (
    <div className={containerClassName} onClick={navigateToProfilePage}>
      <div className={styles['practitioner-wrapper']}>
        <div className={styles['practitioner-info-wrapper']}>
          <CardAvatar
            avatarUrl={practitioner.avatar || ''}
            totalScore={practitioner.totalScore}
            reviewCount={practitioner.reviewCount}
            hideReviews={hideReviews}
          />
          <Details
            practitioner={practitioner}
            hideContacts={hideContacts}
            onCollapseText={onCollapseText}
            listContainerRef={listContainerRef}
            pageScrollTopValueRef={pageScrollTopValueRef}
            isRenderInsideDetailPage={isRenderInsideDetailPage}
            totalScore={practitioner.totalScore}
            reviewCount={practitioner.reviewCount}
          />
        </div>
        {!hideContacts && (
          <TimeSlotsContainer
            availableBlocks={updatedSlots}
            clickTimeSlot={clickTimeSlot}
            viewAllAvailability={clickViewAllAvailability}
          />
        )}
      </div>
    </div>
  );
};

export default PractitionerCard;
