import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';

import ClinicList from '../ClinicList/ClinicList';
import PractitionerList from '../PractitionerList/PractitionerList';
import DateSlider from '../DateSlider/DateSlider';
import Map from '../Map/Map';
import NoSearchResult from '../NoSearchResult/NoSearchResult';

import styles from './SearchResultContent.module.scss';
import { batch } from 'react-redux';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import { clearMapData } from 'redux/mapSliceV2';

interface SearchResultContentProps {
  isPracticeTab: boolean;
}

const SearchResultContent: FC<SearchResultContentProps> = ({
  isPracticeTab,
}) => {
  const { filterSlice } = useAppSelector((state) => state);

  const [isDayView, setIsDayView] = useState(true);

  const [isFilterShown, setIsFilterShown] = useState(false);

  const mainContentClassName = cx({
    [styles['content-container']]: true,
    [styles['top-shift']]: isFilterShown,
  });

  const formattedSearchDate = moment(filterSlice.date, 'YYYY-MM-DD').isValid()
    ? filterSlice.date
    : moment(filterSlice.date).format('YYYY-MM-DD');

  const [date, setDate] = useState(formattedSearchDate);

  const authSlice = useAppSelector((state) => state.authSlice);

  const { clinics, status: clinicStatus } = useAppSelector(
    (state) => state.clinicsSearchResultsSliceV2
  );

  const { practitioners, status: practitionerStatus } = useAppSelector(
    (state) => state.practitionerSearchResultSlice
  );

  const { numberOfAppliedFilters } = useAppSelector(
    (state) => state.searchResultFilterSlice
  );

  const dispatch = useAppDispatch();

  const updatedNavigatedDate = (navigatedDate: string) => {
    sessionStorage.removeItem('cachedDate');

    batch(() => {
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
      dispatch(clearMapData());
      setDate(navigatedDate);
    });
  };

  const isNoResult = isPracticeTab
    ? numberOfAppliedFilters === 0 &&
      clinics.length === 0 &&
      clinicStatus !== 'loading'
    : numberOfAppliedFilters === 0 &&
      practitioners.length === 0 &&
      practitionerStatus !== 'loading';

  useEffect(() => {
    const handleRemoveCachedDate = () => {
      sessionStorage.removeItem('cachedDate');
    };

    window.addEventListener('beforeunload', handleRemoveCachedDate);
    return () => {
      handleRemoveCachedDate();
      window.removeEventListener('beforeunload', handleRemoveCachedDate);
    };
  }, []);

  return (
    <>
      {isNoResult ? (
        <>
          <div className={styles['no-result-container']}>
            <span className={styles['no-result-text']}>
              <strong>No results</strong> in this area
            </span>
            <NoSearchResult isLoggedIn={authSlice.isLoggedIn} />
          </div>
        </>
      ) : (
        <>
          <DateSlider
            isDayView={isDayView}
            setIsDayView={setIsDayView}
            date={date}
            setDate={updatedNavigatedDate}
            isFilterShown={isFilterShown}
            setIsFilterShown={setIsFilterShown}
          />
          <div className={mainContentClassName}>
            <div className={styles['list']}>
              {isPracticeTab ? (
                <ClinicList
                  searchDate={formattedSearchDate}
                  navigatedDate={date}
                  isDayView={isDayView}
                  setDate={setDate}
                />
              ) : (
                <PractitionerList
                  searchDate={formattedSearchDate}
                  date={date}
                  isDayView={isDayView}
                  setDate={setDate}
                />
              )}
            </div>
          </div>
        </>
      )}
      <Map date={date} searchDate={formattedSearchDate} />
    </>
  );
};

export default SearchResultContent;
