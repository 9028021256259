import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import config from './config';
import { IPractitionerResponseData } from 'interfaces/axiosPractitionerResponseType';
import { concatFullName } from 'utils/common';

const firebaseApp = initializeApp(config);

interface IClinicPractitionersAxiosResponse {
  data: IPractitionerResponseData[];
  metadata: {
    total: number;
    limit: number;
    page: number;
  };
}

export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);

export const uploadFileAndGetUrl = (file: File): Promise<string> => {
  const { name } = file;
  const date = new Date();
  const fileName = `${date.getTime()}__${name}`;
  const uploadRef = ref(storage, `uploads/${fileName}`);
  return uploadBytes(uploadRef, file).then((snapshot) => {
    return getDownloadURL(snapshot.ref).then((url) => {
      return url;
    });
  });
};

export const getPractitionerByClinicId = async (clinicId: string) => {
  const practitioners: {
    avatar: string;
    name: string;
    specialty: string[];
    totalScore: number;
    reviewCount: number;
    id: string;
  }[] = [];
  const response = await axios.get<IClinicPractitionersAxiosResponse>(
    `clinics/${clinicId}/practitioners`,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: {
        sort: 'asc',
        sortBy: 'firstName',
      },
    }
  );
  if (response.data.data) {
    const { data } = response.data;
    data.forEach((item) => {
      practitioners.push({
        id: item.id,
        avatar: item.avatar,
        name: concatFullName(item.firstName, item.lastName),
        specialty: item.specialties.map((item) => item.name),
        totalScore: item.rating,
        reviewCount: item.reviewCount,
      });
    });
    return practitioners;
  }
  return practitioners;
};
