import { round } from 'lodash';
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { KILOMETER_IN_METER } from 'utils/constants/common';

import { IFilterState } from 'interfaces/filterTypes';
import { getRangeDateFrom } from 'utils/dates';
import {
  IWeeklyTimeSlotsClinicData,
  getClinicAvailability,
} from 'services/APIs/getClinicAvailability';
import { mergePractitionersAvaiBlocks } from 'pages/UpdatedSearchResultPage/utils/mergePractitionersAvaiBlocks';
import normalizeClinicAvaiBlocks from 'pages/UpdatedSearchResultPage/utils/normalizeClinicAvaiBlocks';
import getTimeBlocksData from 'utils/getTimeBlocksData';
import {
  AvailableBlockOperatory,
  SearchingService,
} from 'interfaces/timeslotType';

interface MonthlyTimeSlot {
  clinicId: string;
  timeSlots: {
    date: string;
    blocks: number[];
    isClosed: boolean;
  }[];
}

interface DailyTimeSlot {
  clinicId: string;
  timeSlots: {
    date: string;
    blocks: {
      block: number;
      practitionerIds: string[];
    }[];
  }[];
}

export interface IClinicListDataV2 {
  name: string;
  description: string | null;
  distance: number;
  avatar: string;
  photos: string[];
  services: { id: string; name: string; duration: number }[];
  email: string;
  address: string;
  availableBlocks: IAvailableBlocks[];
  city: string;
  doctors: {
    id: string;
    name: string;
    availableBlocks: {
      date: string;
      availableBlockOperatories: AvailableBlockOperatory[];
      blocks: number[];
      searchingService: SearchingService;
    }[];
  }[];
  state: string;
  phone: string;
  website: string;
  workingHours: {
    day: string;
    time: string;
  }[];
  reviewCount: number;
  rating: number;
  id: string;
  timezone: string;
  longitude: number;
  latitude: number;
  slug: string;
}

interface IClinicsSearchResultStateV2 {
  status: 'loading' | 'failure' | 'success' | 'loading-more';
  isLoadingMore: boolean;
  clinics: IClinicListDataV2[];
  hasMore: boolean | null;
  totalClinics: number;
  dailyTimeSlots: DailyTimeSlot[];
  monthlyTimeSlots: MonthlyTimeSlot[];
  page: number;
}

interface IAvailableBlocks {
  value: number;
  displayString: string;
  practitionerIds: string[];
}

interface IAxiosResponseData {
  metadata: {
    limit: number;
    page: number;
    total: number;
  };
  data: {
    id: string;
    name: string;
    slug: string;
    description: string;
    distance: number;
    avatar: string;
    address: string;
    location: {
      city: string;
      state: string;
    };
    email: string;
    phoneNumber: string;
    website: string;
    workingHours: { day: string; time: string }[];
    reviewCount: number;
    rating: number;
    photos: string[];
    timezone: string;
    services: { id: string; name: string; duration: number }[];
    doctors: {
      id: string;
      name: string;
      availableBlocks: {
        date: string;
        blocks: number[];
      }[];
    }[];
  }[];
}

interface Props {
  dates: string[];
  searchDate?: string;
  currentDate: string;
  lat: number;
  lng: number;
  page: number;
  serviceId: string;
  sortBy: 'rating' | 'distance' | null;
  gender: ('male' | 'female' | 'non-binary')[];
  isMonthView: boolean;
  minRating: number;
  timeBlocks: number[][];
}
const PER_PAGE = 5;
const WEEK_COUNT = 13;
const DAY_COUNT = 4;
const MAX_RATING = 5;
const MAX_DISTANCE_IN_METER = 20000;

const getMonthlyTimeSlots = async ({
  clinics,
  serviceId,
  timeBlocksData,
  gender,
}: any) => {
  const fetchAvailabilityInDateRange = async (
    datesInFuture: string[],
    callback: any
  ) => {
    const promises = [];
    const tmpDatesInFuture = [...datesInFuture];

    while (tmpDatesInFuture.length !== 0) {
      const ranges = tmpDatesInFuture.splice(0, 7);

      if (ranges[0] !== ranges[ranges.length - 1]) {
        const dates = [ranges[0], ranges[ranges.length - 1]];
        promises.push(callback(dates));
      }
    }

    const availabilities = await Promise.all(promises);

    return availabilities;
  };

  let result: MonthlyTimeSlot[] = [];

  for (let i = 0; i < clinics.length; i++) {
    let availabilities: IWeeklyTimeSlotsClinicData[] = [];

    const clinic = clinics[i];

    const startDate = clinic.doctors[0].availableBlocks[0].date;

    const datesInFuture = getRangeDateFrom(startDate, WEEK_COUNT);

    // Call weekly API of clinic
    availabilities = await fetchAvailabilityInDateRange(
      datesInFuture,
      (dates: string[]) =>
        getClinicAvailability({
          clinicId: clinic.id,
          serviceId,
          dates,
          timezone: clinic.timezone,
          timeBlocks: timeBlocksData,
          gender,
        })
    );

    const availabilitiesByPractitioners = availabilities.reduce(
      (accum: any, currentValue: any) => {
        if (currentValue?.doctors) {
          return accum.concat(currentValue.doctors);
        }

        return accum;
      },
      []
    );

    const availBlocks = mergePractitionersAvaiBlocks(
      availabilitiesByPractitioners
    );

    const closedDates = availabilitiesByPractitioners.reduce(
      (accum: any, doctor: any) => {
        const closedDates = doctor.availableBlocks
          .filter((item: any) => item.isClosed)
          .map((item: any) => item.date);

        return accum.concat(closedDates);
      },
      []
    );

    const monthlyTimeSlots = Object.keys(availBlocks.dateItems).reduce(
      (accum: any, date: any) => {
        const foundClosedDate = closedDates.find(
          (closedDate: string) => closedDate === date
        );

        accum.push({
          date,
          blocks: Object.keys(availBlocks.dateItems[date]),
          isClosed: foundClosedDate ? true : false,
        });
        return accum;
      },
      []
    );

    result.push({
      clinicId: clinic.id,
      timeSlots: monthlyTimeSlots,
    });
  }

  return result;
};

const getDailyTimeSlots = ({ clinics }: any) => {
  const result: DailyTimeSlot[] = [];

  for (let i = 0; i < clinics.length; i++) {
    const clinic = clinics[i];

    const availabilityDoctors = clinic ? clinic.doctors : [];

    const availBlocks = mergePractitionersAvaiBlocks(availabilityDoctors);
    const normalizedAvailBlocks = normalizeClinicAvaiBlocks(availBlocks);

    if (normalizedAvailBlocks.length === 0) {
      const startDate = clinic.doctors[0].availableBlocks[0].date;
      const tmpDates = getRangeDateFrom(startDate, DAY_COUNT);

      const timeSlots = tmpDates.map((item) => {
        return {
          date: item,
          blocks: [],
        };
      });
      result.push({
        clinicId: clinic.id,
        timeSlots,
      });
    } else {
      result.push({
        clinicId: clinic.id,
        timeSlots: normalizedAvailBlocks,
      });
    }
  }

  return result;
};

export const getClinicsListV2 = createAsyncThunk(
  'clinicsSearchResultV2/getClinicsList',
  async (
    {
      dates,
      searchDate,
      currentDate,
      serviceId,
      sortBy,
      lat,
      lng,
      page,
      isMonthView,
      minRating,
      gender,
      timeBlocks,
    }: Props,
    { getState }
  ) => {
    const { filterSlice } = getState() as {
      filterSlice: IFilterState;
    };

    let sort: string = '';
    let sortByParam: string = '';
    let distances = [0, MAX_DISTANCE_IN_METER];

    switch (sortBy) {
      case 'rating': {
        sort = 'desc,desc';
        sortByParam = 'rating,reviewCount';
        break;
      }
      case 'distance': {
        sort = 'asc';
        sortByParam = 'distance';
        break;
      }
      default:
        break;
    }

    const locationTimezone = filterSlice.location.timezone;

    const timeBlocksData = getTimeBlocksData(timeBlocks);

    const response = await axios.get<IAxiosResponseData>(
      '/clinics/weekly-timeslots',
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          timezone: locationTimezone,
          dates,
          searchDate,
          currentDate,
          limit: PER_PAGE * page,
          timeBlocks: timeBlocksData,
          page: 1,
          latitude: lat,
          longitude: lng,
          distances,
          serviceId: serviceId,
          rating: [minRating, MAX_RATING],
          includedNonRated: minRating === 0,
          genders: gender,
          ...(sortBy && { sort, sortBy: sortByParam }),
        },
      }
    );

    const metadata = response.data?.metadata;

    const clinics = response.data?.data || [];

    const normalizedClinics = clinics.reduce((accum: any, clinic) => {
      accum.push({
        id: clinic.id,
        slug: clinic.slug,
        name: clinic.name,
        description: clinic.description,
        distance: round(clinic.distance / KILOMETER_IN_METER, 1),
        avatar: clinic.avatar,
        address: clinic.address,
        city: clinic.location.city,
        state: clinic.location.state,
        email: clinic.email,
        phone: clinic.phoneNumber,
        website: clinic.website,
        workingHours: clinic.workingHours,
        reviewCount: clinic.reviewCount,
        rating: clinic.rating,
        photos: clinic.photos,
        timezone: clinic.timezone,
        services: clinic.services.map((item) => ({
          id: item.id,
          name: item.name,
          duration: item.duration,
        })),
        doctors: clinic.doctors.map((item) => ({
          id: item.id,
          name: item.name,
          availableBlocks: item.availableBlocks,
        })),
      });

      return accum;
    }, []);

    let monthlyTimeSlots: MonthlyTimeSlot[] = [];
    let dailyTimeSlots: DailyTimeSlot[] = [];

    if (isMonthView) {
      monthlyTimeSlots = await getMonthlyTimeSlots({
        clinics,
        serviceId,
        timeBlocksData,
        gender,
      });
    } else {
      dailyTimeSlots = getDailyTimeSlots({
        clinics,
      });
    }

    const result = {
      clinics: normalizedClinics,
      totalClinics: metadata.total,
      hasMore: metadata.total > metadata.page * metadata.limit,
      dailyTimeSlots,
      monthlyTimeSlots,
    };

    return result;
  }
);

const initialState: IClinicsSearchResultStateV2 = {
  clinics: [],
  hasMore: null,
  isLoadingMore: false,
  status: 'loading',
  totalClinics: 0,
  dailyTimeSlots: [],
  monthlyTimeSlots: [],
  page: 1,
};

const clinicsSearchResultsSliceV2 = createSlice({
  name: 'clinicsSearchResultV2',
  initialState,
  reducers: {
    clearClinicSearchDetailsV2: () => {
      return {
        ...initialState,
      };
    },
    setIsLoadingMore: (state) => {
      return { ...state, isLoadingMore: true, page: state.page + 1 };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClinicsListV2.fulfilled, (state, action) => {
      return {
        ...state,
        status: 'success',
        ...action.payload,
        isLoadingMore: false,
      };
    });
    builder.addCase(getClinicsListV2.pending, (state) => {
      return {
        ...state,
        status: state.isLoadingMore ? 'loading-more' : 'loading',
      };
    });
  },
});

export const { clearClinicSearchDetailsV2, setIsLoadingMore } =
  clinicsSearchResultsSliceV2.actions;
export default clinicsSearchResultsSliceV2.reducer;
