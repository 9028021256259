import { FC } from 'react';

import CardAvatar, {
  renderRatingScore,
} from 'components/CardAvatar/CardAvatar';
import { ReactComponent as LocationIcon } from 'assets/icons/locations.svg';

import styles from './AvatarInfo.module.scss';

interface AvatarInfoProps {
  avatar: string;
  rating: number;
  reviewCount: number;
  name: string;
  specialties?: string;
  distance: number;
}

const AvatarInfo: FC<AvatarInfoProps> = ({
  avatar,
  name,
  rating,
  reviewCount,
  specialties,
  distance,
}) => {
  return (
    <div className={styles['header']}>
      <CardAvatar
        avatarUrl={avatar}
        totalScore={rating}
        reviewCount={reviewCount}
        customStyle={styles['card-self']}
      />
      <div className={styles['detail']}>
        <h3 className={styles['detail-title']}>{name}</h3>
        {specialties && (
          <span className={styles['detail-specialty']}>{specialties}</span>
        )}
        <div className={styles['detail-info']}>
          {renderRatingScore(rating, reviewCount)}
          <div className={styles['detail-info']}>
            <LocationIcon className={styles['icon']} />
            <span className={styles.distance}> {distance} km</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarInfo;
