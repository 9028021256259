import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as NoSearchResultIcon } from 'assets/images/no-search-result.svg';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { ROUTES } from 'routes/config';

import styles from './NoSearchResult.module.scss';

interface IProps {
  isLoggedIn: boolean;
}

const NoSearchResult = ({ isLoggedIn }: IProps) => {
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <NoSearchResultIcon />
        <>
          <p className={styles['empty-text']}>
            Unfortunately, there are no practices within your area at this time.
            We’ll notify you as soon as we have a practice available.
          </p>
          {!isLoggedIn && (
            <DentalButton
              variant="outlined"
              className={styles['sign-up-btn']}
              onClick={() => history.push(ROUTES.SIGN_UP_PAGE)}
            >
              Sign up to be notified
            </DentalButton>
          )}
        </>
      </div>
    </div>
  );
};

export default NoSearchResult;
