import React from 'react';
import { FeatureImage } from 'components/FeatureImage';
import { NavigationBar } from 'components/NavigationBar';
import { SearchBar } from 'components/SearchBar';
import { Footer, Header } from 'components/Layout';
import { loadFilters } from 'utils/storage';
import { useAppSelector } from 'redux/reduxHooks';

import styles from './HomePage.module.scss';

function HomePage() {
  const filters = useAppSelector((state) => state.filterSlice);

  const getDate = () => {
    let date;
    if (filters.date) {
      date = filters.date;
    } else {
      const storedFilters = loadFilters();
      date = storedFilters?.date || null;
    }
    return date;
  };

  return (
    <div className={styles.container}>
      <div className={styles['upper-section']}>
        <Header>
          <NavigationBar hasFullLogo isAuthButton />
        </Header>
        <div className={styles['content-wrapper']}>
          <div className={styles['page-title-mobile']}>
            Book Healthcare Appointments Instantly Online
          </div>
          <SearchBar currentDate={getDate()} />
          <FeatureImage />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
