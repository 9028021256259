/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import L, { LatLngExpression } from 'leaflet';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import cx from 'classnames';

import { ReactComponent as CurrenLocationRedDot } from 'assets/icons/currentLocationRedDot.svg';
import {
  MAP_ATTRIBUTION,
  MAP_PROVIDER_URL,
  MILES_IN_METER,
} from 'utils/constants/common';

import { DEFAULT_ZOOM_LEVEL, FOCUS_ZOOM_LEVEL } from './utils/constant';
import { fitAllMarkers, zoomInMarkerWithOffSet } from './utils/mapActions';
import MarkderActive from './MarkerActive';
import MarkerInactive from './MarkerInactive';
import CurrentLocationMarker from './CurrentLocationMarker';
import './Map.scss';
import { IMapClinicData } from 'redux/mapSlice';
import { useAppSelector } from 'redux/reduxHooks';

interface IChangeView {
  center: LatLngExpression | null;
  zoom: number;
  bounds: [number, number][];
}

interface Props {
  page: number;
  zoom: number;
  allLocationIds: string[];
  activeLocationId: string;
  quantityBadgeValue: number;
  locationData: {
    [key: string]: IMapClinicData;
  } | null;
  isChoosingCurrentLocation: boolean;
  currentLocationCoordinates: [number, number];
  setZoom: (zoom: number) => void;
  setPage: (page: number) => void;
  getBounds: () => [number, number][];
  setActiveLocationId: (id: string) => void;
}

export type ChangeViewComponent = React.FC<IChangeView>;

export type MapComponent = React.FC<Props>;

const ACCEPTANCE_RADIUS_IN_METERS = 20;

const Dot = () => {
  return <CurrenLocationRedDot />;
};

const ChangeView: ChangeViewComponent = ({ center, zoom, bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (zoom <= DEFAULT_ZOOM_LEVEL) {
      fitAllMarkers(map, bounds);
    }

    if (center) {
      zoomInMarkerWithOffSet(map, center, zoom);
    }
  }, [center, zoom]);

  return null;
};

const Map: MapComponent = (props) => {
  const {
    zoom,
    setZoom,
    currentLocationCoordinates,
    isChoosingCurrentLocation,
  } = props;

  const isShownOrHidden = useAppSelector(
    (state) => state.showMapMobileSlice.isShownOrHidden
  );

  const clinicsWithIn20MetersFromCurrentLocation = useMemo(() => {
    return props.locationData
      ? props.allLocationIds.filter(
          (item) =>
            props.locationData![item].distance * MILES_IN_METER <
            ACCEPTANCE_RADIUS_IN_METERS
        )
      : [];
  }, [props.locationData, props.allLocationIds]);

  const center = useMemo(() => {
    return props.locationData
      ? props.locationData[props.activeLocationId]?.coordinates
      : null;
  }, [props.locationData, props.activeLocationId, props.allLocationIds]);

  useEffect(() => {
    if (clinicsWithIn20MetersFromCurrentLocation.length === 1) {
      props.setPage(1);
      props.setActiveLocationId(clinicsWithIn20MetersFromCurrentLocation[0]);
      setZoom(FOCUS_ZOOM_LEVEL);
    }
  }, [clinicsWithIn20MetersFromCurrentLocation]);

  const currentLocationMarker = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<CurrentLocationMarker />),
  });

  const currentLocationRedDot = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<Dot />),
  });

  const marker = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<MarkerInactive />),
  });

  const markerActive = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(
      <MarkderActive practitionerQuantity={props.quantityBadgeValue} />
    ),
    iconSize: [50, 50],
    iconAnchor: [19, 32],
  });

  const isLessThan20Meters = (distanceInMiles: number) => {
    return distanceInMiles * MILES_IN_METER < ACCEPTANCE_RADIUS_IN_METERS;
  };

  const renderCurrentLocationMarker = () => {
    return (
      <Marker
        position={currentLocationCoordinates}
        icon={
          isChoosingCurrentLocation
            ? currentLocationRedDot
            : currentLocationMarker
        }
        eventHandlers={{
          click: () => {},
        }}
      ></Marker>
    );
  };

  const renderMarker = () => {
    return props.allLocationIds.map((locationId) => {
      if (locationId === props.activeLocationId) {
        return (
          <Marker
            key={locationId}
            position={props.locationData![locationId].coordinates}
            icon={markerActive}
            eventHandlers={{
              click: () => {},
            }}
          ></Marker>
        );
      }

      return (
        <Marker
          key={locationId}
          position={props.locationData![locationId].coordinates}
          icon={
            isLessThan20Meters(props.locationData![locationId].distance)
              ? currentLocationMarker
              : marker
          }
          eventHandlers={{
            click: (e) => {
              props.setPage(1);
              props.setActiveLocationId(locationId);
              setZoom(FOCUS_ZOOM_LEVEL);
            },
          }}
        ></Marker>
      );
    });
  };

  const mapContainerClassName = cx({
    map__container: true,
    hide_map: !isShownOrHidden,
  });

  const bounds = props.getBounds();

  useEffect(() => {
    const zoomIn = document.getElementsByClassName(
      'leaflet-control-zoom-in'
    )[0];

    const zoomOut = document.getElementsByClassName(
      'leaflet-control-zoom-out'
    )[0];

    if (zoomIn) {
      zoomIn.innerHTML = '';
    }

    if (zoomOut) {
      zoomOut.innerHTML = '';
    }
  }, []);

  return (
    <div className={mapContainerClassName}>
      <MapContainer zoom={zoom}>
        <ChangeView center={center} zoom={zoom} bounds={bounds} />
        <TileLayer attribution={MAP_ATTRIBUTION} url={MAP_PROVIDER_URL} />
        {props.locationData && renderMarker()}
        {clinicsWithIn20MetersFromCurrentLocation.length <= 0 &&
          renderCurrentLocationMarker()}
      </MapContainer>
      {props.activeLocationId && props.children}
    </div>
  );
};

export default Map;
