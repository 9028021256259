import { FC } from 'react';
import { calculateBlocks } from 'utils/getTimeBlockFromTImeString';
import styles from '../../FilterList.module.scss';
import mainStyles from './TimeOfDay.module.scss';

const TIME_OF_DAYS = [
  {
    label: 'morning',
    description: 'Before 12PM',
    value: [calculateBlocks('00:00:00'), calculateBlocks('11:59:00')],
  },
  {
    label: 'afternoon',
    description: '12PM-5PM',
    value: [calculateBlocks('12:00:00'), calculateBlocks('16:59:00')],
  },
  {
    label: 'evening',
    description: 'After 5PM',
    value: [calculateBlocks('17:00:00'), calculateBlocks('23:59:00')],
  },
];

interface TimeOfDayProps {
  value: number[][];
  setValue: React.Dispatch<React.SetStateAction<number[][]>>;
}

const TimeOfDay: FC<TimeOfDayProps> = ({ value, setValue }) => {
  const isTimeOfDayChosen = (timeOfDayValue: number[]) => {
    const isTimeOfDayFound = value.find((v) => v[0] === timeOfDayValue[0]);
    return Boolean(isTimeOfDayFound);
  };

  const handleOnChange = (timeOfDayValue: number[]) => {
    if (!isTimeOfDayChosen(timeOfDayValue)) {
      return setValue((value) => [...value, timeOfDayValue]);
    }
    const updatedValue = value.filter((v) => v[0] !== timeOfDayValue[0]);
    setValue(updatedValue);
  };

  return (
    <div className={`${styles.group} ${mainStyles.container}`}>
      {TIME_OF_DAYS.map((timeOfDay) => (
        <button
          className={mainStyles['group-container']}
          key={timeOfDay.label}
          data-active={isTimeOfDayChosen(timeOfDay.value)}
          onClick={() => handleOnChange(timeOfDay.value)}
        >
          <span>{timeOfDay.label}</span>
          <span>{timeOfDay.description}</span>
        </button>
      ))}
    </div>
  );
};

export default TimeOfDay;
