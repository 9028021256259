import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';

import styles from './SuccessOrInvalidPage.module.scss';
import { ROUTES } from 'routes/config';

interface IProps {
  message: string;
}

const SuccessOrInvalidPage = ({ message }: IProps) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ConfirmIcon />
        <div className={styles.title}>{message}</div>
        <DentalButton
          variant="contained"
          onClick={() => history.push(ROUTES.HOME)}
        >
          Go to FirstIn
        </DentalButton>
      </div>
    </div>
  );
};

export default SuccessOrInvalidPage;
