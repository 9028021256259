import { Header, Layout } from 'components/Layout';
import { NavigationBar } from 'components/NavigationBar';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { unsubscribeOnlineIntro } from 'services/APIs';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import styles from './UnsubscribePage.module.scss';
import UnsubscribeSuccessfullyPage from './UnsubscribeSuccessfullyPage';

const UnsubscribePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmedSuccessfully, setIsConfirmedSuccessfully] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const token = query.get('token');

  useEffect(() => {
    if (!email || !token) {
      history.replace('/404');
    }
  }, [email, history, token]);

  const onConfirm = async () => {
    setIsLoading(true);

    const data = await unsubscribeOnlineIntro(token, email);
    if (typeof data === 'object') {
      setIsConfirmedSuccessfully(true);
    }

    if ((typeof data === 'number' && data === 404) || !data) {
      history.replace('/404');
    }

    setIsLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header hasBoxShadow>
            <NavigationBar hasFullLogo />
          </Header>
        </div>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <div className={styles['content-container']}>
            {isConfirmedSuccessfully ? (
              <UnsubscribeSuccessfullyPage />
            ) : (
              <div className={styles['confirm-container']}>
                <h3 className={styles['confirm-header']}>
                  Unsubscribe from FirstIn
                </h3>
                <div className={styles['confirm-body']}>
                  <div className={styles['confirm-description']}>
                    <div>We're sorry to see you go!</div>
                    <div>
                      After unsubscribing, you will no longer receive any
                      promotional emails from FirstIn.
                    </div>
                  </div>
                  <DentalButton
                    variant="contained"
                    onClick={onConfirm}
                    className={styles['confirm-btn']}
                  >
                    Confirm
                  </DentalButton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UnsubscribePage;
