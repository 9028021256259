import React from 'react';
import cx from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

import styles from './SwitchTabBar.module.scss';

interface Props {
  isPractitionerTab: boolean;
  onChangeTab: (value: boolean) => void;
  onClosePopup: () => void;
}

export type SwitchTabBarComponent = React.FC<Props>;

const SwitchTabBar: SwitchTabBarComponent = ({
  isPractitionerTab,
  onChangeTab,
  onClosePopup,
}) => {
  const practitionersBtnClassName = cx({
    [styles['button']]: true,
    [styles['active']]: isPractitionerTab,
  });
  const clinicsBtnClassName = cx({
    [styles['button']]: true,
    [styles['active']]: !isPractitionerTab,
  });
  return (
    <div className={styles['container']}>
      <div className={styles['flex']}>
        <button
          className={clinicsBtnClassName}
          onClick={() => onChangeTab(false)}
        >
          Practice Info
        </button>
        <button
          className={practitionersBtnClassName}
          onClick={() => onChangeTab(true)}
        >
          Practitioners
        </button>
      </div>
      <button onClick={onClosePopup}>
        <CloseIcon className={styles['close-icon']} />
      </button>
    </div>
  );
};

export default SwitchTabBar;
