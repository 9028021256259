import { FC, ReactNode } from 'react';
import toast from 'react-hot-toast';
import styles from './CustomToast.module.scss';
import cx from 'classnames';
import { ReactComponent as RemoveCircleIcon } from 'assets/icons/remove_circle.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check_circle.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning_toast.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_toast.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface ToastProps {
  id: string;
  message: string | ReactNode;
  type: 'success' | 'warning' | 'error' | 'info';
  isButtonIncluded?: boolean;
  buttonTitle?: string;
  onButtonClicked?: () => void;
}

const Toast: FC<ToastProps> = ({
  id,
  message,
  type,
  buttonTitle,
  isButtonIncluded,
  onButtonClicked,
}) => {
  const mainClassName = cx({
    [styles.success]: type === 'success',
    [styles.error]: type === 'error',
    [styles.warning]: type === 'warning',
    [styles.info]: type === 'info',
  });

  return (
    <div className={mainClassName}>
      <div className={styles.container}>
        {type === 'success' && (
          <CheckCircleIcon className={styles['success-icon']} />
        )}
        {type === 'error' && (
          <RemoveCircleIcon className={styles['error-icon']} />
        )}
        {type === 'warning' && (
          <WarningIcon className={styles['warning-icon']} />
        )}
        {type === 'info' && <InfoIcon className={styles['info-icon']} />}
        <div className={styles['flex-1']}>
          <div className={styles['main-section']}>
            <span className={styles['text']}>{message}</span>
            <button
              className={styles['close-icon']}
              onClick={() => toast.dismiss(id)}
            >
              <CloseIcon />
            </button>
          </div>

          {isButtonIncluded && (
            <button
              className={styles['extra-button']}
              onClick={() => {
                onButtonClicked?.();
                toast.dismiss(id);
              }}
            >
              {buttonTitle}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const renderToast = ({
  message,
  type = 'error',
}: {
  message: string | ReactNode;
  type?: 'success' | 'warning' | 'error' | 'info';
}) => {
  toast((t) => {
    if (type === 'warning' || type === 'error') {
      t.duration = 8000;
    } else {
      t.duration = 3000;
    }

    return <Toast id={t.id} message={message} type={type} />;
  });
};

export { renderToast };

export default Toast;
