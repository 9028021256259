import momentTz from 'moment-timezone';
import { range } from 'lodash';
import { MINUTE_OF_A_BLOCK } from './convertMinutesIntoHoursOfDay';

export const calculateBlocks = (time: string) => {
  const timeInMinutes = momentTz.duration(time).asMinutes();
  return Math.floor(timeInMinutes / MINUTE_OF_A_BLOCK);
};

export const calculateRangeBlock = (startTime: string, endTime: string) => {
  return range(calculateBlocks(startTime), calculateBlocks(endTime));
};
