import React from 'react';
import { Rating } from '@material-ui/lab';

import styles from './ReviewSummary.module.scss';

interface IReviewSummaryProps {
  totalScore: number;
  reviewCount: number;
  title: string;
}

const ReviewSummary = ({
  totalScore,
  reviewCount,
  title,
}: IReviewSummaryProps) => {
  return (
    <div
      className={`${styles['flex-row']} ${styles['review-summary-container']}`}
    >
      <div
        className={`${styles['bold']} ${styles['large-text']} ${styles['text-secondary-purple']}`}
      >
        {title}
      </div>
      <div className={styles['flex-row']}>
        <Rating
          name="read-only"
          value={totalScore}
          readOnly
          precision={0.5}
          size="large"
          className={styles['rating-stars']}
        />
        <span className={`${styles['bold']} ${styles['large-text']}`}>
          {totalScore}
        </span>
        <span>{`(${reviewCount} reviews)`}</span>
      </div>
    </div>
  );
};

export default ReviewSummary;
