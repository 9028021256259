import React, { useEffect, useState, useRef } from 'react';
import {
  useLocation,
  withRouter,
  RouteComponentProps,
  useParams,
} from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import BackToTopButton from 'components/Buttons/BackToTopButton/BackToTopButton';
import { Content, Header, Layout } from 'components/Layout/index';
import { NavigationBar } from 'components/NavigationBar';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import {
  getPractitionerDetails,
  clearPractitionerDetails,
  getPractitionerReviews,
} from 'redux/practitionerDetailsSlice';

import ContactInformation from './ContactInformation';
import BioInformation from './BioInformation';
import PractitionerReviews from './PractitionerReviews';
import styles from './PractitionerDetailsPage.module.scss';
import BookNowPanel from './BookNowPanel/BookNowPanel';
import ClinicInfo from 'components/ClinicInfo/ClinicInfo';
import useIsMobile from 'hooks/useIsMobile';
import BookingForm from 'components/BookingForm/BookingForm';
import { ROUTES } from 'routes/config';
import moment from 'moment';
import InvalidPage from 'pages/InvalidPage/InvalidPage';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';
import getPractitionerName from 'utils/getPractitionerName';

const DEFAULT_REIVEWS_PAGE = 1;

const DEFAULT_SORT = 'Newest';

interface IPractitionerBookingData {
  timeSlot: number;
  service: { id: string; title: string; duration: number };
  appointmentDate: string;
  clinicId: string;
  operatoryId: string;
  dentist: DentistInfo;
}

const PractitionerDetailsPage = ({ history }: RouteComponentProps) => {
  const location = useLocation();
  const params = useParams<{
    practitionerSlugName: string;
    clinicSlugName: string;
  }>();

  const query = new URLSearchParams(location.search);
  const practitionerSlugName = params.practitionerSlugName;
  const clinicSlugName = params.clinicSlugName;

  const dispatch = useAppDispatch();

  const { practitionerDetails, reviewsData, isLoading, isError } =
    useAppSelector((state) => state.practitionerDetailsSlice);

  const isFirstRender = useRef(true);

  const [bookingInformation, setBookingInformation] =
    useState<IPractitionerBookingData | null>(null);

  const [reviewPage, setReviewPage] = useState(DEFAULT_REIVEWS_PAGE);

  const filterSliceDate =
    useAppSelector((state) => state.filterSlice.date) ||
    moment().format('YYYY-MM-DD');

  const queryUrlDate = query.get('date');

  const currentDate =
    queryUrlDate && moment(queryUrlDate, 'YYYY-MM-DD').isSameOrAfter(moment())
      ? queryUrlDate
      : filterSliceDate;

  const isMobile = useIsMobile();

  const [date, setDate] = useState(currentDate);

  useEffect(() => {
    if (!practitionerSlugName) {
      history.push(ROUTES.HOME);
    } else {
      dispatch(getPractitionerDetails(practitionerSlugName));
    }
    return () => {
      dispatch(clearPractitionerDetails());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getPractitionerReviews({
        slug: practitionerSlugName,
        page: reviewPage,
        sortBy: DEFAULT_SORT,
      })
    );
    isFirstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewPage]);

  useEffect(() => {
    if (isLoading || isError) return;
    if (practitionerDetails.clinic.slug === clinicSlugName) return;

    history.replace({
      pathname: history.location.pathname.replace(
        clinicSlugName,
        practitionerDetails.clinic.slug
      ),
    });
  }, [
    isLoading,
    isError,
    practitionerDetails?.clinic?.slug,
    clinicSlugName,
    history,
  ]);

  const handleChangeReviewPage = (page: number) => {
    setReviewPage(page);
  };

  const handleBookClinicAppointment = (data: any) => {
    setBookingInformation(data);
  };

  const closePractitionerBookingForm = () => {
    setBookingInformation(null);
  };

  const getPractitionerServices = () => {
    const optionsList: { id: string; title: string; slug: string }[] =
      practitionerDetails.services.reduce((accum: any, currentValue: any) => {
        accum.push({
          id: currentValue.id,
          title: currentValue.name,
          slug: currentValue.slug,
        });
        return accum;
      }, []);

    return optionsList;
  };

  const renderNavbar = () => {
    return <NavigationBar isAuthButton />;
  };

  const renderBookingFormDialog = (
    bookingInformation: IPractitionerBookingData
  ) => {
    const clinic = practitionerDetails.clinic;

    const tmpPractitioner = {
      study: practitionerDetails.study.join(', '),
      name: getPractitionerName(practitionerDetails),
      avatar: practitionerDetails.avatar,
    };

    const service = bookingInformation.service;
    const serviceName = service.title;

    return (
      <BookingForm
        date={bookingInformation.appointmentDate}
        timeBlock={bookingInformation.timeSlot}
        serviceId={service.id}
        clinicId={bookingInformation.clinicId}
        practitionerId={practitionerDetails.id}
        operatoryId={bookingInformation.operatoryId}
        dentist={bookingInformation.dentist}
        bookingSummaryInfo={{
          avatar: tmpPractitioner.avatar,
          title: `${tmpPractitioner.name}${
            tmpPractitioner.study ? `, ${tmpPractitioner.study}` : ''
          }`,
          clinicName: clinic?.name || '',
          clinicAddress: clinic?.address || '',
          clinicEmail: clinic?.email || '',
          clinicPhoneNumber: clinic?.phoneNumber || '',
          serviceTitle: `${serviceName} • ${clinic?.name || ''}`,
        }}
        closeBookingDialog={closePractitionerBookingForm}
        isPreferredDoctor={true}
      />
    );
  };

  if (bookingInformation !== null && !isLoading) {
    return <Layout>{renderBookingFormDialog(bookingInformation)}</Layout>;
  }

  if (isError) {
    return <InvalidPage />;
  }

  return (
    <Layout>
      <Header hasBoxShadow>{renderNavbar()}</Header>
      <Content>
        <div className={styles['container']}>
          {!isLoading ? (
            <>
              <BackToTopButton />
              <ContactInformation data={practitionerDetails}>
                <ClinicInfo
                  data={{
                    name: practitionerDetails.clinic.name,
                    address: practitionerDetails.clinic.address,
                    workingHours: practitionerDetails.workingHours,
                  }}
                  currentDate={date}
                />
              </ContactInformation>
              <div className={styles.wrapper}>
                <div className={styles['wrapper-left']}>
                  <BookNowPanel
                    practitionerServicesOptions={getPractitionerServices()}
                    practitionerId={practitionerDetails.id}
                    clinicData={practitionerDetails.clinic}
                    onClickPractitionerTimeSlot={handleBookClinicAppointment}
                    date={date}
                    setDate={setDate}
                  />
                  {isMobile && (
                    <PractitionerReviews
                      reviewIds={reviewsData.reviews.ids}
                      currentPage={reviewPage}
                      onChangePage={handleChangeReviewPage}
                      pageCount={reviewsData.totalPages}
                      isLoading={reviewsData.isReviewsLoading}
                    />
                  )}
                  <BioInformation data={practitionerDetails} />
                </div>
                {!isMobile && (
                  <div className={styles['wrapper-right']}>
                    <ClinicInfo
                      data={{
                        name: practitionerDetails.clinic.name,
                        address: practitionerDetails.clinic.address,
                        workingHours: practitionerDetails.workingHours,
                      }}
                      currentDate={date}
                    />
                    <PractitionerReviews
                      reviewIds={reviewsData.reviews.ids}
                      currentPage={reviewPage}
                      onChangePage={handleChangeReviewPage}
                      pageCount={reviewsData.totalPages}
                      isLoading={reviewsData.isReviewsLoading}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <CircularProgress size={48} className={styles['loading-circle']} />
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default withRouter(PractitionerDetailsPage);
