import React, { Dispatch, FC } from 'react';
import cx from 'classnames';

import styles from './SwitchTabBar.module.scss';

interface SwitchTabBarProps {
  isPracticeTab: boolean;
  setIsPracticeTab: Dispatch<React.SetStateAction<boolean>>;
}

const SwitchTabBar: FC<SwitchTabBarProps> = ({
  isPracticeTab,
  setIsPracticeTab,
}) => {
  const practitionersBtnClassName = cx({
    [styles['button']]: true,
    [styles['active']]: !isPracticeTab,
  });
  const clinicsBtnClassName = cx({
    [styles['button']]: true,
    [styles['active']]: isPracticeTab,
  });

  return (
    <div className={styles['container']}>
      <button
        className={clinicsBtnClassName}
        onClick={() => setIsPracticeTab(true)}
      >
        Practices
      </button>
      <button
        className={practitionersBtnClassName}
        onClick={() => {
          setIsPracticeTab(false);
        }}
      >
        Practitioners
      </button>
    </div>
  );
};

export default SwitchTabBar;
