import { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import CustomPopper from '../CustomPopper/CustomPopper';
import Sort from './Sort/Sort';
import CustomPopperContent from '../CustomPopper/CustomPopperContent/CustomPopperContent';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { setSearchResultFilter } from 'redux/searchResultFilterSlice';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import { batch } from 'react-redux';

import styles from '../FilterList.module.scss';
import useIsMobile from 'hooks/useIsMobile';
import CustomDialog from '../CustomDialog/CustomDialog';

const SortButton = () => {
  const isMobile = useIsMobile();

  const defaultSort = useAppSelector(
    (state) => state.searchResultFilterSlice.sort
  );
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useState<'rating' | 'distance' | null>(defaultSort);

  const onClose = () => {
    setValue(defaultSort);
    setAnchorEl(null);
  };

  const onApplyClicked = () => {
    // PREVENT UPDATE IF NO CHANGED
    if (defaultSort === value) {
      return onClose();
    }
    batch(() => {
      setAnchorEl(null);
      dispatch(setSearchResultFilter({ sort: value }));
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
    });
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <button
          data-filter="sort"
          className={styles['btn']}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          data-active={!!defaultSort}
          data-open={!!anchorEl}
        >
          Sort
        </button>
        {isMobile ? (
          <CustomDialog
            isResetBtnDisabled={!value}
            onResetClicked={() => setValue(null)}
            onApplyClicked={onApplyClicked}
            open={Boolean(anchorEl)}
            onClose={onClose}
          >
            <CustomPopperContent title="Sort" onClose={onClose}>
              <Sort value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomDialog>
        ) : (
          <CustomPopper
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            isResetBtnDisabled={!value}
            onResetClicked={() => setValue(null)}
            onApplyClicked={onApplyClicked}
          >
            <CustomPopperContent title="Sort" onClose={onClose}>
              <Sort value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomPopper>
        )}{' '}
      </div>
    </ClickAwayListener>
  );
};

export default SortButton;
