import React from 'react';

import SignedInAvatar from 'components/SignedInAvatar/SignedInAvatar';
import { IClinicData } from 'redux/clinicDetailsSlice';
import { useAppSelector } from 'redux/reduxHooks';
import useIsMobile from 'hooks/useIsMobile';
import NoAvatarScr from 'assets/images/no-avatar.svg';

import styles from './HeaderSectionIframe.module.scss';

const HeaderSectionIframe = ({ clinicData }: { clinicData: IClinicData }) => {
  const { isLoggedIn } = useAppSelector((state) => state.authSlice);
  const isMobile = useIsMobile();

  return (
    <div className={styles['container']}>
      {isLoggedIn && <SignedInAvatar />}
      {isMobile && (
        <img
          src={clinicData.avatar ? clinicData.avatar : NoAvatarScr}
          alt="booking-avatar"
          className={styles['avatar']}
        />
      )}
    </div>
  );
};

export default HeaderSectionIframe;
