import React from 'react';
import cx from 'classnames';

import styles from './TimeSlots.module.scss';

interface Props {
  displayString: string;
  value: number;
  onClick: (timeSlot: number) => void;
  size?: 'small' | 'large' | 'medium';
  isHighlight?: boolean;
}

const TimeSlots: React.FC<Props> = ({
  displayString,
  value,
  onClick,
  size = 'large',
  isHighlight = false,
}) => {
  const classNames = cx({
    [styles['container']]: true,
    [styles['small']]: size === 'small',
    [styles['medium']]: size === 'medium',
    [styles['highlight']]: isHighlight,
  });
  const click = (event: any) => {
    event.stopPropagation();
    onClick(value);
  };
  return (
    <button className={classNames} onClick={click}>
      {displayString}
    </button>
  );
};

export default TimeSlots;
