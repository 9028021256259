import React from 'react';
import Location from './Location';
import WorkingHours from './WorkingHours';

import styles from './ClinicInfo.module.scss';

interface Props {
  data: {
    name?: string;
    address: string;
    workingHours: { day: string; time: string }[];
  };
  currentDate: string;
}

export type ClinicInfoComponent = React.FC<Props>;

const ClinicInfo: ClinicInfoComponent = ({ data, currentDate }) => {
  return (
    <div className={styles['container']}>
      <Location address={data.address} name={data.name} />
      {data.workingHours && (
        <WorkingHours
          workingHours={data.workingHours}
          currentDate={currentDate}
        />
      )}
    </div>
  );
};

export default ClinicInfo;
