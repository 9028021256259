import { MINUTE_OF_A_BLOCK } from './convertMinutesIntoHoursOfDay';

const getIntervalStep = (slotInterval?: number) => {
  const duration = slotInterval || 30;

  const durationExcludeFinalBlock = duration - 5;

  const step = durationExcludeFinalBlock / MINUTE_OF_A_BLOCK;

  return step;
};

export default getIntervalStep;
