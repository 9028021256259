import axios, { CancelToken } from 'axios';
import moment from 'moment';
import {
  AvailableBlockDentistForSplitScheduling,
  AvailableBlockOperatory,
  SearchingService,
} from 'interfaces/timeslotType';

interface IParams {
  clinicId: string;
  dates: string[];
  serviceId: string;
  practitionerId: string;
  timezone: string;
  timeBlocks: number[] | string[];
  code?: string;
  serviceSlugName?: string;
  cancelToken?: CancelToken;
}

interface IResponse {
  id: string;
  clinic: {
    id: string;
    availableBlocks: {
      date: string;
      availableBlockOperatories: AvailableBlockOperatory[];
      blocks: number[];
      dentists: AvailableBlockDentistForSplitScheduling[];
      searchingService: SearchingService;
    }[];
  };
}

export const getPractitionerAvailability = async ({
  dates,
  serviceId,
  practitionerId,
  timezone,
  timeBlocks,
  code,
  serviceSlugName,
  cancelToken,
}: IParams) => {
  const res = await axios.get<IResponse[]>(
    `practitioners/${practitionerId}/weekly-timeslots`,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: {
        id: practitionerId,
        dates: dates,
        serviceId: serviceId,
        currentDate: moment().format(),
        timeBlocks,
        timezone,
        ...(code && { code }),
        ...(serviceSlugName && { serviceSlugName }),
      },
      cancelToken,
    }
  );
  return res.data[0]?.clinic?.availableBlocks || [];
};
