import { orderBy } from 'lodash';

const DEFAULT_RESULT = {
  point: 0,
  isOptimized: false,
};

const findNextSkipElement = (
  blocks: number[],
  selectedSlot: number,
  serviceDuration: number
) => {
  let nextSkipAppointment = 0;

  const selectedSlotIndex = blocks.indexOf(selectedSlot);
  const nextAvailableBlocks = blocks.slice(selectedSlotIndex + serviceDuration);

  for (let i = 0; i < nextAvailableBlocks.length; i++) {
    const element = nextAvailableBlocks[i];
    const nextElement = element + 1;

    if (!nextSkipAppointment && i === nextAvailableBlocks.length - 1) {
      nextSkipAppointment = element;
    }

    if (nextAvailableBlocks.includes(nextElement)) {
      continue;
    }

    nextSkipAppointment = element;
    break;
  }

  return nextSkipAppointment;
};

const findPreviousSkipElement = (blocks: number[], selectedSlot: number) => {
  let previousSkipAppointment = 0;

  const selectedSlotIndex = blocks.indexOf(selectedSlot);
  const prevAvailableBlocks = blocks.slice(0, selectedSlotIndex);

  for (let i = prevAvailableBlocks.length - 1; i >= 0; i--) {
    const element = prevAvailableBlocks[i];
    const previousElement = element - 1;

    if (!previousSkipAppointment && i === 0) {
      previousSkipAppointment = element;
    }

    if (prevAvailableBlocks.includes(previousElement)) {
      continue;
    }

    previousSkipAppointment = element;
    break;
  }

  return previousSkipAppointment;
};

const findMostOptimizedSlotWithoutGaps = (
  blocks: number[],
  selectedSlot: number,
  serviceDuration: number
) => {
  const result = { ...DEFAULT_RESULT };

  const isFirstElementSelected = blocks[0] === selectedSlot;
  const isLastElementSelected = blocks[blocks.length - 1] === selectedSlot;

  const isPreviousSlotBlocked =
    !blocks.includes(selectedSlot - 1) && !isFirstElementSelected;
  const isNextSlotBlocked =
    !blocks.includes(selectedSlot + 1) && !isLastElementSelected;

  if (isPreviousSlotBlocked) {
    const previousSkipAppointment = findPreviousSkipElement(
      blocks,
      selectedSlot
    );
    result.point = selectedSlot - previousSkipAppointment;
    result.isOptimized = true;
  }

  if (isNextSlotBlocked) {
    const nextSkipAppointment = findNextSkipElement(
      blocks,
      selectedSlot,
      serviceDuration
    );
    result.point = nextSkipAppointment - selectedSlot;
    result.isOptimized = true;
  }

  if (isPreviousSlotBlocked && isNextSlotBlocked) {
    result.point = -3;
    result.isOptimized = true;
  }

  return result;
};

const findMostOptimizedSlotWithGaps = (
  selectedSlot: number,
  blocks: number[],
  serviceDuration: number
) => {
  const result = { ...DEFAULT_RESULT };

  const previousSkipAppointment = findPreviousSkipElement(blocks, selectedSlot);

  const nextSkipAppointment = findNextSkipElement(
    blocks,
    selectedSlot,
    serviceDuration
  );

  if (previousSkipAppointment !== 0) {
    result.isOptimized = true;
    result.point += selectedSlot - previousSkipAppointment;
  }

  if (nextSkipAppointment !== 0) {
    result.isOptimized = true;
    result.point += nextSkipAppointment - selectedSlot;
  }

  return result;
};

const getIsBlockOptimized = (
  selectedSlot: number,
  blocks: number[],
  serviceDuration: number
) => {
  if (!blocks.includes(selectedSlot)) {
    return DEFAULT_RESULT;
  }

  const sortedBlocks = orderBy(blocks, (block) => block, 'asc');

  const resultWithoutGaps = findMostOptimizedSlotWithoutGaps(
    sortedBlocks,
    selectedSlot,
    serviceDuration
  );

  if (resultWithoutGaps.isOptimized) {
    return resultWithoutGaps;
  }

  const resultWithGaps = findMostOptimizedSlotWithGaps(
    selectedSlot,
    sortedBlocks,
    serviceDuration
  );

  return resultWithGaps;
};

export default getIsBlockOptimized;
