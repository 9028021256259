import { makeStyles } from '@material-ui/core/styles';
import { fontSize } from 'utils/theme';

export default makeStyles({
  text: {
    alignSelf: 'center',
    '& .MuiPaginationItem-root': {
      fontSize: fontSize.s,
      lineHeight: '2.1rem',
      fontFamily: 'Nexa',
      padding: '0.2rem 0 0 0',
    },
  },
});
