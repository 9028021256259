import { Popper, PopperProps } from '@material-ui/core';
import useIsMobile from 'hooks/useIsMobile';
import useStyles from './useStyles';

const CustomPopper: React.FC<PopperProps> = (props) => {
  const styles = useStyles();
  const isMobile = useIsMobile();
  const offset = isMobile ? '-51, 19' : '-40, 23';
  return (
    <Popper
      {...props}
      className={styles.customPopper}
      placement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset,
        },
      }}
    />
  );
};

export default CustomPopper;
