import { makeStyles } from '@material-ui/core/styles';
import { MOBILE_SCREEN_WIDTH } from 'utils/screens';
import { primaryPurple, fontSize, colors } from 'utils/theme';

export default makeStyles({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: 'Nexa',
      fontSize: fontSize.s,
      lineHeight: '2.1rem',
      backgroundColor: colors.white,
      borderColor: colors.neutralGrey,
      borderRadius: '8px',
      cursor: 'pointer',
      [`@media (max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        paddingLeft: '16px',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      padding: '0.4rem 1.6rem 0 0',

      [`@media (max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        paddingRight: '9px !important',

        '& .MuiAutocomplete-popupIndicator': {
          paddingTop: '0px !important',
        },
      },
    },
    '& .MuiInput-formControl': {
      margin: '1rem 0 0.8rem 0',
      paddingLeft: '1.4rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.5px solid transparent',
      boxShadow: '0px 0px 5px 2px rgba(220, 220, 220, 0.5)',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      boxShadow: '0px 0px 5px 2px rgba(176, 27, 174, 0.2)',
      border: `0.5px solid ${colors.main}`,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      boxShadow: '0px 0px 5px 2px rgba(176, 27, 174, 0.2)',
      border: `0.5px solid ${colors.main}`,
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Nexa',
      fontSize: fontSize.xs,
      lineHeight: '1.8rem',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
      '& .MuiAutocomplete-input': {
        padding: '0.5rem 0 !important',
      },

      [`@media (max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        paddingRight: '40px !important',
      },
    },
  },
  highlightText: {
    color: primaryPurple,
  },
  customPopper: {
    zIndex: 1300,
    '& .MuiAutocomplete-paper': {
      borderRadius: '2rem',
      border: `0.05rem solid ${colors.lightGrey}`,
      width: '40rem',
      maxHeight: '36.8rem',
      overflow: 'auto',
      fontFamily: 'Nexa',
      fontSize: fontSize.s,
      lineHeight: '2.4rem',
      [`@media (max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        // This is calculated by
        width: `calc(100% * 2 + 2.8rem)`,
        borderRadius: '0px',
        border: '0px',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: colors.lightMidGrey,
        borderRadius: '2rem',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
        marginTop: '8px',
        marginBottom: '8px',
      },
      '& [aria-selected="true"]': {
        backgroundColor: colors.white,
        color: primaryPurple,
      },
      '& .MuiAutocomplete-option:hover': {
        backgroundColor: colors.lightGreyf9f9f9,
        color: primaryPurple,
      },
      '& .MuiAutocomplete-option > div': {
        width: '100%',
      },
      '& .MuiAutocomplete-listbox': {
        overflow: 'visible',
      },
    },
  },
  shiftPopper: {
    left: '-48% !important',
  },
  shiftPopperIframe: {
    left: '-8% !important',
  },
  shiftPopperReasonIframe: {
    left: '-20% !important',

    '& .MuiAutocomplete-paper': {
      width: '35rem !important',
    },
  },
  fullWidth: {
    '& .MuiAutocomplete-paper': {
      width: '100%',
    },
  },
  forceFullWidth: {
    width: '90% !important',
    left: '-12px !important',
  },
  spacingContent: {
    '& .MuiAutocomplete-paper': {
      '& .MuiAutocomplete-listbox': {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
          minHeight: 'unset',
        },
      },
    },
  },
});
