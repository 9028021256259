import { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import styles from '../FilterList.module.scss';
import CustomPopper from '../CustomPopper/CustomPopper';
import TimeOfDay from './TimeOfDay/TimeOfDay';
import CustomPopperContent from '../CustomPopper/CustomPopperContent/CustomPopperContent';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { batch } from 'react-redux';
import { setSearchResultFilter } from 'redux/searchResultFilterSlice';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import { clearMapData } from 'redux/mapSliceV2';
import useIsMobile from 'hooks/useIsMobile';
import CustomDialog from '../CustomDialog/CustomDialog';

const TimeOfDayButton = () => {
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const defaultTimeBlocks = useAppSelector(
    (state) => state.searchResultFilterSlice.timeBlocks
  );

  const dispatch = useAppDispatch();

  const [value, setValue] = useState(defaultTimeBlocks);

  const onClose = () => {
    setAnchorEl(null);
    setValue(defaultTimeBlocks);
  };

  const onApplyClicked = () => {
    // PREVENT UPDATE IF NO CHANGED
    if (defaultTimeBlocks === value) {
      return onClose();
    }

    batch(() => {
      setAnchorEl(null);
      dispatch(setSearchResultFilter({ timeBlocks: value }));
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
      dispatch(clearMapData());
    });
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <button
          data-filter="time"
          className={styles['btn']}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          data-open={!!anchorEl}
          data-active={defaultTimeBlocks.length > 0}
        >
          Time
        </button>
        {isMobile ? (
          <CustomDialog
            open={Boolean(anchorEl)}
            isResetBtnDisabled={value.length === 0}
            onResetClicked={() => setValue([])}
            onApplyClicked={onApplyClicked}
            onClose={onClose}
          >
            <CustomPopperContent title="Time of day" onClose={onClose}>
              <TimeOfDay value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomDialog>
        ) : (
          <CustomPopper
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            isResetBtnDisabled={value.length === 0}
            onResetClicked={() => setValue([])}
            onApplyClicked={onApplyClicked}
          >
            <CustomPopperContent title="Time of day" onClose={onClose}>
              <TimeOfDay value={value} setValue={setValue} />
            </CustomPopperContent>
          </CustomPopper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TimeOfDayButton;
