import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { ReactComponent as ExpandIcon } from 'assets/icons/triangle-up.svg';
import { ReactComponent as WorkingHoursIcon } from 'assets/icons/working-hours.svg';

import styles from './ClinicInfo.module.scss';
import { convertToDate } from 'utils/dates';

interface IClinicWorkingHours {
  workingHours: {
    day: string;
    time: string;
  }[];
  currentDate: string;
}

const arrangeWorkingHoursArray = (
  workingHours: {
    day: string;
    time: string;
  }[],
  currentDate: string
) => {
  const fullDay = moment(convertToDate(currentDate)).format('dddd');
  const firstItem = workingHours.find((item) => item.day === fullDay);

  const initialAccum = firstItem ? [firstItem] : [];

  return workingHours.reduce((accum, currentValue) => {
    if (currentValue.day !== fullDay) {
      accum.push(currentValue);
    }

    return accum;
  }, initialAccum);
};

const WorkingHours = ({ workingHours, currentDate }: IClinicWorkingHours) => {
  const [isExpandWorkingHours, setIsExpandWorkingHours] = useState(false);
  const toggleExpandWorkingHours = () => {
    setIsExpandWorkingHours((prev) => !prev);
  };

  const workingHoursList = arrangeWorkingHoursArray(workingHours, currentDate);

  const expandIconClassName = cx({
    [styles['expand-icon']]: true,
    [styles['expanded']]: isExpandWorkingHours,
  });
  const firstItemClassName = cx({
    [styles['grid-item-right']]: true,
    [styles['flex-row']]: true,
  });
  const workingHoursContainerClassName = cx({
    [styles['hide-on-mobile']]: true,
    [styles['contact-infos']]: true,
  });
  const firstItem = workingHoursList.splice(0, 1)[0];

  const getSortWorkingHour = () => {
    const dayAfterFirstItem = workingHoursList.filter(
      (workingHour) =>
        moment(workingHour.day, 'dddd').isoWeekday() >
        moment(firstItem.day, 'dddd').isoWeekday()
    );
    const dayBeforeFirstItem = workingHoursList.filter(
      (workingHour) =>
        moment(workingHour.day, 'dddd').isoWeekday() <
        moment(firstItem.day, 'dddd').isoWeekday()
    );
    return [...dayAfterFirstItem, ...dayBeforeFirstItem];
  };

  return (
    <div className={workingHoursContainerClassName}>
      <WorkingHoursIcon className={styles['icon']} />
      <div className={styles['grid']}>
        <div>{firstItem.day}</div>
        <div className={firstItemClassName}>
          {firstItem.time}
          <ExpandIcon
            className={expandIconClassName}
            onClick={toggleExpandWorkingHours}
          />
        </div>
        {isExpandWorkingHours &&
          getSortWorkingHour().map((item, index) => {
            return (
              <React.Fragment key={`${index}`}>
                <div>{item.day}</div>
                <div>{item.time}</div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default WorkingHours;
