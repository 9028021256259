import React, { useState } from 'react';
import { Divider } from '@material-ui/core';

import cx from 'classnames';
import moment from 'moment';

import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';
import DayViewNavigation from 'components/DayViewNavigation/DayViewNavigation';
import styles from './ClinicAvailability.module.scss';
import WorkingHours from './WorkingHours/WorkingHours';
import Bio from './Bio/Bio';
import Info from './Info/Info';
import ClinicDayOrMonthViewAvailability from '../ClinicDayOrMonthViewAvailability/ClinicDayOrMonthViewAvailability';
import useIsMobile from 'hooks/useIsMobile';
import AvatarInfo from 'components/AvatarInfo/AvatarInfo';
import { convertToDate } from 'utils/dates';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';

interface IProps {
  navigatedDate: string;
  serviceId: string;
  distance: number;
  clinicData: any;
  defaultIsDayView?: boolean;
  onBookAppointment: (param: {
    date: string;
    timeSlot: number;
    operatoryId: string;
    doctorId: string;
    dentist: DentistInfo;
  }) => void;
}

const ClinicAvailability = ({
  clinicData,
  serviceId,
  navigatedDate,
  defaultIsDayView = true,
  onBookAppointment,
}: IProps) => {
  const [dateValue, setDateValue] = useState<string>(navigatedDate);

  const [isDayView, setIsDayView] = useState(defaultIsDayView);

  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useIsMobile();

  return (
    <div className={styles['container']}>
      <div className={styles['left']}>
        <div className={styles['clinic-info-wrapper']}>
          <AvatarInfo
            avatar={clinicData.avatar}
            rating={clinicData.rating}
            reviewCount={clinicData.reviewCount}
            name={clinicData.name}
            distance={clinicData.distance}
          />
          <div className={styles['info']}>
            <Info
              clinicName={clinicData.name}
              clinicAddress={clinicData.address}
              distance={clinicData.distance}
            />
          </div>
        </div>

        <div className={styles['navigation-container']}>
          {isDayView ? (
            <DayViewNavigation
              date={dateValue}
              onDateUpdate={(date) => {
                // TODO: Refactor to format using YYYY-MM-DD in DayViewNavigation
                let formattedDate = date;

                if (moment(date, 'YYYY-MM-DD').isValid()) {
                  formattedDate = moment(date, 'YYYY-MM-DD').format(
                    'YYYY-MM-DD'
                  );
                } else {
                  formattedDate = moment(convertToDate(date)).format(
                    'YYYY-MM-DD'
                  );
                }

                setIsLoading(true);
                setDateValue(formattedDate);
              }}
            />
          ) : (
            <MonthViewNavigation date={dateValue} setDate={setDateValue} />
          )}

          <div className={styles['navigation-section']}>
            <button
              className={cx({
                [styles['navigation-btn']]: true,
                [styles['navigation-btn-inactive']]: !isDayView,
              })}
              onClick={() => setIsDayView(true)}
            >
              {isMobile ? 'Day' : 'Day View'}
            </button>
            <button
              className={cx({
                [styles['navigation-btn']]: true,
                [styles['navigation-btn-inactive']]: isDayView,
              })}
              onClick={() => setIsDayView(false)}
            >
              {isMobile ? 'Month' : 'Month View'}
            </button>
          </div>
        </div>
        <Divider orientation="horizontal" className={styles['divider']} />

        <ClinicDayOrMonthViewAvailability
          isDayView={isDayView}
          setIsDayView={setIsDayView}
          clinicId={clinicData.id}
          serviceId={serviceId}
          fromDate={dateValue}
          setDateValue={setDateValue}
          clinicTimezone={clinicData.timezone}
          onBookAppointment={onBookAppointment}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>

      <div className={styles['right']}>
        <WorkingHours
          workingHours={clinicData.workingHours}
          activeDate={isDayView ? dateValue : undefined}
        />
        <Bio description={clinicData.description} />
      </div>
    </div>
  );
};

export default ClinicAvailability;
