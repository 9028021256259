import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { IPractitionerDetails } from 'interfaces/practitionerDetailsTypes';
import { IPractitionerResponseData } from 'interfaces/axiosPractitionerResponseType';
import { ALLOWED_PRACTITIONER_STUDIES } from 'utils/constants/common';
import { concatFullName } from 'utils/common';
import { PATIENT_EXAM_AND_CLEANING } from 'components/DentalMap/utils/constant';

export interface IReview {
  content: string;
  avatar: string;
  reviewer: string;
  score: number;
  createdAt: string;
  source: string;
}

export interface IReviewsState {
  ids: string[];
  data: {
    [key: string]: IReview;
  };
}
interface IPractitionerDetailsState {
  isLoading: boolean;
  isError: boolean;
  practitionerDetails: IPractitionerDetails;
  reviewsData: {
    isReviewsLoading: boolean;
    totalPages: number;
    reviews: IReviewsState;
  };
}

interface IReviewAxiosResponse {
  data: {
    avatar: string;
    content: string;
    createdAt: string;
    id: string;
    name: string;
    rating: number;
    source: string;
    updatedAt: string | null;
  }[];
  metadata: {
    limit: number;
    page: number;
    total: number;
  };
}

const REVIEWS_PER_PAGE = 5;

export const getPractitionerDetails = createAsyncThunk(
  'pratitionerDetails/getPractitioner',
  async (slug: string) => {
    try {
      const practitionerDetails = {} as IPractitionerDetails;
      const response = await axios.get<IPractitionerResponseData>(
        `/practitioners/${slug}`,
        {
          baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        }
      );
      if (response.data) {
        const { data } = response;
        practitionerDetails.id = data.id;
        practitionerDetails.avatar = data.avatar;
        practitionerDetails.name = concatFullName(
          data.firstName,
          data.lastName
        );
        practitionerDetails.practice = data.practice;
        practitionerDetails.description = data.bio;
        practitionerDetails.clinic = {
          clinicId: data.clinic.id,
          name: data.clinic.name,
          address: data.clinic.address,
          email: data.clinic.email,
          phoneNumber: data.clinic.phoneNumber,
          workingHours: data.clinic.workingHours,
          timezone: data.clinic.timezone,
          slug: data.clinic.slug,
          slotInterval: data.clinic.slotInterval,
        };
        practitionerDetails.specialty =
          data.specialties && Array.isArray(data.specialties)
            ? data.specialties
            : [];
        practitionerDetails.study = data.studies
          ? data.studies
              .map((item) => item.name)
              .filter((study) => ALLOWED_PRACTITIONER_STUDIES.includes(study))
          : [];
        practitionerDetails.totalScore = data.rating;
        practitionerDetails.reviewCount = data.reviewCount;
        practitionerDetails.services = data.services.map((item) => ({
          id: item.id,
          name: item.name,
          clinicId: item.clinicId,
          slug: item.slug,
        }));
        practitionerDetails.workingHours = data.workingHours;
        practitionerDetails.firstName = data.firstName;
        practitionerDetails.title = data.title;
        practitionerDetails.specialist = data.specialist;
        return {
          practitionerDetails: practitionerDetails,
        };
      } else {
        throw Error('Not exist');
      }
    } catch (e) {
      throw e;
    }
  }
);

export const getPractitionerReviews = createAsyncThunk(
  'pratitionerDetails/getPractitionerReviews',
  async ({
    slug,
    page,
    sortBy,
  }: {
    slug: string;
    page: number;
    sortBy: string;
  }) => {
    const reviewsData: IReviewsState = { ids: [], data: {} };
    let totalPages = 0;
    let sortField: 'createdAt' | 'rating';
    let sortOrder: 'asc' | 'desc';
    switch (sortBy) {
      case 'Highest rated': {
        sortField = 'rating';
        sortOrder = 'desc';
        break;
      }
      case 'Lowest rated': {
        sortField = 'rating';
        sortOrder = 'asc';
        break;
      }
      case 'Oldest': {
        sortField = 'createdAt';
        sortOrder = 'asc';
        break;
      }
      case 'Newest': {
        sortField = 'createdAt';
        sortOrder = 'desc';
        break;
      }
      default:
        sortField = 'createdAt';
        sortOrder = 'desc';
    }
    const response = await axios.get<IReviewAxiosResponse>(
      `/practitioners/${slug}/reviews`,
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          sort: sortOrder,
          sortBy: sortField,
          page: page,
          limit: REVIEWS_PER_PAGE,
        },
      }
    );
    if (response.data.data) {
      response.data.data.forEach((item) => {
        reviewsData.ids.push(item.id);
        reviewsData.data[item.id] = {
          reviewer: item.name,
          avatar: item.avatar,
          content: item.content,
          score: item.rating,
          createdAt: item.createdAt,
          source: item.source,
        };
      });
      totalPages = Math.ceil(response.data.metadata.total / REVIEWS_PER_PAGE);
    }

    return {
      totalPages: totalPages,
      reviews: reviewsData,
    };
  }
);

const initialState: IPractitionerDetailsState = {
  practitionerDetails: {} as IPractitionerDetails,
  reviewsData: {
    isReviewsLoading: true,
    totalPages: 0,
    reviews: {
      ids: [],
      data: {},
    },
  },
  isLoading: true,
  isError: false,
};

const practitionerDetailsSlice = createSlice({
  name: 'pratitionerDetails',
  initialState,
  reducers: {
    clearPractitionerDetails: () => {
      return {
        ...initialState,
      };
    },
    updatePractitionerExamCleaningServiceId: (
      state,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        practitionerDetails: {
          ...state.practitionerDetails,
          services: state.practitionerDetails.services.map((service) => {
            if (service.name === PATIENT_EXAM_AND_CLEANING) {
              return {
                ...service,
                id: action.payload,
              };
            }
            return service;
          }),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPractitionerDetails.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: false,
        ...action.payload,
      };
    });
    builder.addCase(getPractitionerDetails.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getPractitionerDetails.rejected, (state, action) => {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    });
    builder.addCase(getPractitionerReviews.fulfilled, (state, action) => {
      return {
        ...state,
        reviewsData: {
          ...state.reviewsData,
          isReviewsLoading: false,
          ...action.payload,
        },
      };
    });
  },
});

export const {
  clearPractitionerDetails,
  updatePractitionerExamCleaningServiceId,
} = practitionerDetailsSlice.actions;
export default practitionerDetailsSlice.reducer;
