import axios, { CancelToken } from 'axios';
import moment from 'moment';
import {
  AvailableBlockOperatory,
  SearchingService,
} from 'interfaces/timeslotType';

interface IParams {
  clinicId: string;
  dates: string[];
  serviceId: string;
  timezone: string;
  timeBlocks: number[] | string[];
  gender?: string[];
  code?: string;
  serviceSlugName?: string;
  cancelToken?: CancelToken;
}

export interface IWeeklyTimeSlotsClinicData {
  id: string;
  doctors: {
    id: string;
    availableBlocks: {
      date: string;
      availableBlockOperatories?: AvailableBlockOperatory[];
      blocks: number[];
      searchingService: SearchingService;
    }[];
  }[];
  slotInterval: number;
}

export const getClinicAvailability = async ({
  clinicId,
  dates,
  serviceId,
  timezone,
  timeBlocks,
  gender,
  code,
  serviceSlugName,
  cancelToken,
}: IParams) => {
  const res = await axios.get<IWeeklyTimeSlotsClinicData[]>(
    `clinics/${clinicId}/weekly-timeslots`,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: {
        id: clinicId,
        dates: dates,
        serviceId: serviceId,
        currentDate: moment().format(),
        timeBlocks,
        timezone,
        ...(gender && { genders: gender }),
        ...(code && { code }),
        ...(serviceSlugName && { serviceSlugName }),
      },
      cancelToken,
    }
  );

  return res.data[0];
};
