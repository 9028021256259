import React, { FC } from 'react';
import { ReactComponent as DotIcon } from 'assets/icons/dot.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/green-check.svg';

import styles from './PasswordGuidance.module.scss';

interface PasswordGuidanceProps {
  currentPassword: string;
}

export const VALIDATIONS = [
  {
    title: 'At least 8 characters',
    pattern: /^.{8,}$/,
  },
  {
    title: 'At least 1 upper case letter (A-Z)',
    pattern: /^(?=.*[A-Z])(?=.*[a-zA-Z]).+$/,
  },
  {
    title: 'At least 1 number (0-9)',
    pattern: /^(?=.*\d).+$/,
  },
];

const PasswordGuidance: FC<PasswordGuidanceProps> = ({ currentPassword }) => {
  return (
    <div className={styles.guidance}>
      <div className={styles.title}>Your password must contain:</div>
      <ul className={styles['validation-container']}>
        {VALIDATIONS.map(({ title, pattern }, index) => {
          const isValid = pattern.test(currentPassword);
          return (
            <li
              className={isValid ? styles.success : styles.error}
              key={`validation-${index}`}
            >
              {isValid ? <CheckIcon stroke="#1A9901" /> : <DotIcon />}
              <div className={styles.errorName}>{title}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PasswordGuidance;
