import React, { useEffect, useState } from 'react';

import DentalMap from 'components/DentalMap/Map';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import MarkerPopup from 'pages/SearchResultPage/Map/MarkerPopup/MarkerPopup';
import { getMapClinicsAndPractitioners } from 'redux/mapSliceV2';
import useIsMobile from 'hooks/useIsMobile';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { resetShownOrHiddenMapOnMobile } from 'redux/showMapMobileSlice';

interface Props {
  date: string;
  searchDate: string;
}

const START_PAGE = 1;
const DEFAULT_ZOOM_LEVEL = 10;

export type MapComponent = React.FC<Props>;

const MapPanel: MapComponent = ({ date, searchDate }) => {
  const { gender, minRating, timeBlocks, numberOfAppliedFilters } =
    useAppSelector((state) => state.searchResultFilterSlice);

  const history = useHistory();

  const { isShownOrHidden } = useAppSelector(
    (state) => state.showMapMobileSlice
  );

  const {
    location: { lat, lng, timezone },
    service,
  } = useAppSelector((state) => state.filterSlice);

  const clinics = useAppSelector((state) => state.mapSlice2.clinics.data);
  const allLocationIds = useAppSelector((state) => state.mapSlice2.clinics.ids);
  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getMapData = () => {
      const dayCount = isMobile ? 2 : 4;
      const endDate = moment(date, 'YYYY-MM-DD')
        .add(dayCount, 'days')
        .format('YYYY-MM-DD');
      const dates = [date, endDate];
      closePopup();
      dispatch(
        getMapClinicsAndPractitioners({
          dates,
          lat,
          lng,
          serviceId: service,
          currentDate: moment().format(),
          searchDate,
          minRating,
          gender,
          timeBlocks,
        })
      );
    };
    getMapData();
  }, [
    date,
    dispatch,
    gender,
    isMobile,
    lat,
    lng,
    minRating,
    searchDate,
    service,
    timeBlocks,
    timezone,
  ]);

  useEffect(() => {
    if (!isMobile) return;
    if (!isShownOrHidden) return;
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'initial';
      dispatch(resetShownOrHiddenMapOnMobile());
    };
  }, [dispatch, isMobile, isShownOrHidden]);

  const currentLocationData = useAppSelector(
    (state) => state.filterSlice.location
  );

  const [activeClinicId, setActiveClinicId] = useState('');

  const [page, setPage] = useState(START_PAGE);

  const [zoom, setZoom] = useState(DEFAULT_ZOOM_LEVEL);

  const closePopup = () => {
    setActiveClinicId('');
    setZoom(DEFAULT_ZOOM_LEVEL);
  };

  const getBounds: () => [number, number][] = () => {
    let allMarkerCoordinates: [number, number][] = [];
    Object.keys(clinics).forEach((item) => {
      allMarkerCoordinates.push(clinics[item].coordinates);
    });
    return [
      ...allMarkerCoordinates,
      [currentLocationData.lat, currentLocationData.lng],
    ];
  };

  const onNavigateProfilePage = ({
    id,
    isClinicTab,
  }: {
    id: string;
    isClinicTab?: boolean;
  }) => {
    const url = `/${
      isClinicTab ? 'clinic' : 'practitioner'
    }?id=${id}&date=${encodeURIComponent(date)}&serviceId=${encodeURIComponent(
      service
    )}`;
    history.push(url);
  };

  if (currentLocationData.lat === 0 && currentLocationData.lng === 0) {
    return null;
  }

  return (
    <DentalMap
      page={page}
      zoom={zoom}
      getBounds={getBounds}
      currentLocationCoordinates={[
        currentLocationData.lat,
        currentLocationData.lng,
      ]}
      locationData={Object.keys(clinics).length !== 0 ? clinics : null}
      isChoosingCurrentLocation={currentLocationData.placeId === 'current'}
      allLocationIds={allLocationIds}
      activeLocationId={activeClinicId}
      setPage={(page: number) => setPage(page)}
      setZoom={(value: number) => setZoom(value)}
      setActiveLocationId={(id: string) => setActiveClinicId(id)}
      quantityBadgeValue={clinics[activeClinicId]?.practitionerIds.length}
    >
      <MarkerPopup
        key={`${isMobile} ${numberOfAppliedFilters}`}
        page={page}
        setPage={setPage}
        onClosePopup={closePopup}
        activeClinic={clinics[activeClinicId]}
        onViewClinicAvailability={(id) => {
          onNavigateProfilePage({ id, isClinicTab: true });
        }}
        practitionerIds={clinics[activeClinicId]?.practitionerIds || []}
        onViewPractitionerAvailability={(id) => {
          onNavigateProfilePage({ id, isClinicTab: false });
        }}
      />
    </DentalMap>
  );
};

export default MapPanel;
