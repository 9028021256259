import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IShowMapState {
  isShownOrHidden: boolean;
  isPractitionerTab: boolean;
}

const initialState: IShowMapState = {
  isShownOrHidden: false,
  isPractitionerTab: true,
};

export const showMapMobileSlice = createSlice({
  name: 'showMap',
  initialState,
  reducers: {
    setShownOrHiddenMapOnMobile: (
      state,
      action: PayloadAction<Partial<IShowMapState>>
    ) => {
      return { ...state, ...action.payload };
    },
    resetShownOrHiddenMapOnMobile: () => {
      return { ...initialState };
    },
  },
});

export const { setShownOrHiddenMapOnMobile, resetShownOrHiddenMapOnMobile } =
  showMapMobileSlice.actions;
export default showMapMobileSlice.reducer;
