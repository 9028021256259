import { AvailableBlockOperatory } from 'interfaces/timeslotType';

const getChairOptimizationForExamCleaning = ({
  id,
  availableBlock,
  selectedSlot,
}: {
  id: string;
  availableBlock: {
    date: string;
    availableBlockOperatories?: AvailableBlockOperatory[] | undefined;
    blocks: number[];
  };
  selectedSlot: number;
}) => {
  const operatoryIds =
    availableBlock.availableBlockOperatories
      ?.filter((item) => item.blocks.includes(selectedSlot))
      .map((item) => item.operatoryId) ?? [];
  return { doctorId: id, operatoryIds };
};

export default getChairOptimizationForExamCleaning;
