import React from 'react';

import NoAvatarScr from 'assets/images/no-avatar.svg';
import cx from 'classnames';

import styles from './BookingSummary.module.scss';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface IProps {
  avatar: string;
  title: string;
  serviceTitle?: string;
  bookingDateTime?: string;
  clinicAddress: string;
  description?: string;
}

const splitString = (input: string) => {
  // Display service and practice of practitioner
  if (input.includes(' • ')) {
    const [service, practice] = input.split(' • ');
    return (
      <div className={styles['mobileInfoWrapper']}>
        <div className={styles['info']}>{service}</div>
        <div className={styles['info']}>{practice}</div>
      </div>
    );
  }
  // Display service for practice on mobile
  return (
    <div className={styles['mobileInfoWrapper']}>
      <div className={styles['info']}>{input}</div>
    </div>
  );
};

const BookingSummary = (props: IProps) => {
  const {
    avatar,
    title,
    serviceTitle,
    bookingDateTime,
    clinicAddress,
    description,
  } = props;

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  return (
    <div className={containerClassName}>
      <div className={styles.wrapper}>
        <div className={styles.firstSection}>
          {clinicAddress && (
            <div className={styles.mainTitle}>Review Appointment</div>
          )}
          <img
            src={avatar ? avatar : NoAvatarScr}
            alt="booking-avatar"
            className={styles['avatar']}
          />
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles['infoWrapper']}>
          <div className={styles['info']}>{serviceTitle}</div>
        </div>
        {serviceTitle && splitString(serviceTitle || '')}
        <div className={styles.bookingDateTime}>{bookingDateTime}</div>
        <div className={styles.bookingDateTime}>{clinicAddress}</div>
        <div className={styles.bookingDateTime}>{description}</div>
      </div>
    </div>
  );
};

export default BookingSummary;
