import axios from 'axios';
import {
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import moment from 'moment';
import { auth } from 'services/firebase';
import {
  FACEBOOK_BASE_API,
  GOOGLE_ADD_BIRTHDAY_READ,
  GOOGLE_ADD_EMAIL_READ,
  GOOGLE_ADD_PROFILE_READ,
  GOOGLE_PEOPLE_API,
  PROVIDER_ID,
} from 'utils/constants/common';

export const signInGoogleWithPopUp = async (provider: GoogleAuthProvider) => {
  provider.addScope(GOOGLE_ADD_EMAIL_READ);
  provider.addScope(GOOGLE_ADD_PROFILE_READ);
  provider.addScope(GOOGLE_ADD_BIRTHDAY_READ);
  const result = await signInWithPopup(auth, provider);
  return result;
};

export const getUserEmailFromSocial = (
  result: UserCredential,
  providerId: string
) => {
  const email =
    result.user.email ||
    result.user.providerData.filter((item) => item.providerId === providerId)[0]
      ?.email ||
    '';

  return email;
};

export const getUserDataFromGoogle = async (result: UserCredential) => {
  const credential = GoogleAuthProvider.credentialFromResult(result);
  const oathAccessToken = credential?.accessToken;
  const config = {
    headers: { Authorization: `Bearer ${oathAccessToken}` },
  };
  const peopleAPIRes = await axios.get(GOOGLE_PEOPLE_API, config);

  const birthday = peopleAPIRes.data.birthdays
    ? peopleAPIRes.data.birthdays[0].date
    : undefined;

  const dob = birthday
    ? moment(
        `${birthday.month}/${birthday.day}/${birthday.year}`,
        'MM/D/YYYY'
      ).format('MM/DD/YYYY')
    : '';

  const email = getUserEmailFromSocial(result, PROVIDER_ID.GOOGLE);

  return {
    email,
    firstName: peopleAPIRes.data.names[0].givenName,
    lastName: peopleAPIRes.data.names[0].familyName,

    phoneNumber: peopleAPIRes.data.phoneNumbers
      ? peopleAPIRes.data.phoneNumbers[0]
      : '',
    dob,
  };
};

export const getUserDataFromFacebook = async (result: UserCredential) => {
  const credential = GoogleAuthProvider.credentialFromResult(result);
  const oathAccessToken = credential?.accessToken;
  const config = {
    headers: { Authorization: `Bearer ${oathAccessToken}` },
  };
  const FacebookAPIRes = await axios.get(FACEBOOK_BASE_API, config);

  const email = getUserEmailFromSocial(result, PROVIDER_ID.FACEBOOK);

  return {
    email,
    firstName: FacebookAPIRes.data.first_name,
    lastName: FacebookAPIRes.data.last_name,
  };
};
