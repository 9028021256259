import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      height: '2.6rem',
      width: '2.6rem',
      '&:checked': {
        color: '#b01bae',
      },
    },
  },
});

const CustomRadio = (props: any) => {
  const classes = useStyles();
  return <Radio className={classes.root} {...props} />;
};

export default CustomRadio;
