interface IParams {
  googleTagManagerId: string | null;
  isLoadedInsideIframe: boolean;
  website: string;
  payload: {
    event: (typeof EVENT)[keyof typeof EVENT];
    data?: any;
  };
}

export const EVENT = {
  FORM_STARTED: 'firstin_form_started' as const,
  APPOINTMENT_SLOT_SELECTED: 'firstin_appointment_slot_selected' as const,
  FINAL_SLOT_SELECTED: 'firstin_final_slot_selected' as const,
  NUMBER_CONFIRMED: 'firstin_number_confirmed' as const,
  PERSONAL_DETAIL_FILLED: 'firstin_personal_detail_filled' as const,
  FORM_SUBMITTED: 'firstin_form_submitted' as const,
};

const shouldForwardEvent = (params: IParams) => {
  const { googleTagManagerId, isLoadedInsideIframe, website } = params;

  return googleTagManagerId && isLoadedInsideIframe && website;
};

const forwardEvent = (params: IParams) => {
  const isEventForward = shouldForwardEvent(params);
  if (!isEventForward) return;

  window.parent.postMessage(params.payload, params.website);
};

export default forwardEvent;
