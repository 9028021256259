import useIsMobile from 'hooks/useIsMobile';
import { useEffect, useState } from 'react';

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up' | null>(
    null
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isMobile) return;

    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const documentHeight = document.body.scrollHeight;
      const currentScroll = scrollY + window.innerHeight;
      const marginTop = 160;
      // ENSURE SCROLL HAS PAST CONTENT AREA
      if (scrollY < 150) {
        return setScrollDirection(null);
      }

      // ENSURE SCROLL TO BOTTOM DOES NOT TRIGGER
      if (currentScroll > documentHeight + marginTop) {
        return setScrollDirection('down');
      }

      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDirection) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [isMobile, scrollDirection]);

  return scrollDirection;
};

export default useScrollDirection;
