import React, { FC, useRef, useState } from 'react';

import { checkEmail } from 'services/APIs';
import { emailValidation } from 'utils/inputValidation';
import FormTextInput from '../FormTextInput';

interface EmailInputProps {
  control: any;
  trigger: any;
  isEmailChecking: boolean;
  setIsEmailChecking: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailInput: FC<EmailInputProps> = ({
  control,
  trigger,
  isEmailChecking,
  setIsEmailChecking,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const checkEmailTimeout = useRef<NodeJS.Timer>();

  return (
    <FormTextInput
      autoComplete="email"
      type="text"
      name="email"
      label="Email address"
      control={control}
      placeholder="Input your email address"
      required="This field is required"
      rules={{
        onChange: (event: any) => {
          const value = event.target.value;

          if (checkEmailTimeout.current) {
            clearInterval(checkEmailTimeout.current);
          }

          if (!emailValidation(value)) return;

          setIsEmailChecking(true);
          const timeoutId = setTimeout(async () => {
            const { data, error } = await checkEmail(value);

            let errorMessage = '';

            if (data?.isExistingEmail) {
              errorMessage = 'Email is already registered';
            }

            if (error?.response?.status === 400) {
              errorMessage = 'Invalid email address';
            }

            setIsEmailChecking(false);
            setErrorMessage(errorMessage);

            trigger('email');
          }, 700);

          checkEmailTimeout.current = timeoutId;
        },
        validate: (value: string) => {
          if (!emailValidation(value)) {
            return 'Invalid email address';
          }
          if (!isEmailChecking && errorMessage) {
            return errorMessage;
          }
        },
      }}
    />
  );
};

export default EmailInput;
