import cx from 'classnames';
import styles from './InputContainer.module.scss';
import { Asterix } from '../HelperText/HelperText';

interface Props {
  error?: string;
  isSubField?: boolean;
  title?: string;
  required?: string;
}

const InputContainer: React.FC<Props> = (props) => {
  const { error = '', isSubField = false, title, required } = props;
  const isShowAsterisk = required !== '' || (!isSubField && error !== '');

  return (
    <div
      className={cx({
        [styles['input_container']]: true,
        [styles['sub-field']]: isSubField,
      })}
    >
      {title && required && (
        <p
          className={cx({
            [styles['input_title']]: true,
            [styles['sub-field']]: isSubField,
          })}
        >
          {title}{' '}
          <span>{isShowAsterisk && <Asterix error={required || error} />}</span>
        </p>
      )}

      {props.children}
    </div>
  );
};

export default InputContainer;
