import axios, { CancelToken } from 'axios';
import moment from 'moment';
import {
  AvailableBlockDentistForSplitScheduling,
  AvailableBlockOperatory,
  SearchingService,
} from 'interfaces/timeslotType';

interface IWeeklyTimeSlotResponse {
  clinic: {
    id: string;
    availableBlocks: {
      date: string;
      availableBlockOperatories?: AvailableBlockOperatory[];
      blocks: number[];
      dentists?: AvailableBlockDentistForSplitScheduling[];
      searchingService: SearchingService;
    }[];
  };
}

interface IGetPractitionersDataParams {
  practitionerId: string;
  dates: string[];
  serviceId: string;
  cancelToken: CancelToken;
  timezone: string;
  timeBlocks: number[] | string[];
}

export const getWeeklyTimeSlots = async ({
  practitionerId,
  dates,
  serviceId,
  cancelToken,
  timezone,
  timeBlocks,
}: IGetPractitionersDataParams) => {
  const res = await axios.get<IWeeklyTimeSlotResponse[]>(
    `/practitioners/${practitionerId}/weekly-timeslots`,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      cancelToken: cancelToken,
      params: {
        id: practitionerId,
        dates: dates,
        serviceId: serviceId,
        currentDate: moment().format(),
        timezone,
        timeBlocks,
      },
    }
  );
  return res.data[0]?.clinic?.availableBlocks || [];
};
