import React from 'react';

import styles from './TotalCount.module.scss';

interface Props {
  title: string;
  nearByLocation: string;
}

const TotalCount: React.FC<Props> = ({ title, nearByLocation }) => {
  return (
    <div className={styles['container']}>
      <span className={styles.bold}>{title}</span>
      <span className={styles.location}>{nearByLocation}</span>
    </div>
  );
};

export default TotalCount;
