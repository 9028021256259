import axios from 'axios';

export const getTimezoneByPlaceDetails = async (lat: number, lng: number) => {
  const { data } = await axios.get('/gmap/timezone', {
    baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    params: {
      lat,
      lng,
    },
  });

  const timezone = data?.timeZoneId || null;
  return timezone;
};
