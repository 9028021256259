import { FC } from 'react';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';

import styles from '../../FilterList.module.scss';

const RATINGS = ['3.0', '3.5', '4.0', '4.5'];

interface RatingProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const Rating: FC<RatingProps> = ({ value, setValue }) => {
  return (
    <div className={styles.group}>
      {RATINGS.map((rating) => (
        <button
          key={rating}
          data-active={parseFloat(rating) === value}
          onClick={() => setValue(parseFloat(rating))}
        >
          <span>{rating}</span>
          <span>
            <StarIcon className={styles.icon} />
          </span>
        </button>
      ))}
    </div>
  );
};

export default Rating;
