const getServiceName = (
  serviceSlugUrl: string | null,
  service: {
    data: { [key: string]: string };
    slugs: { [key: string]: string };
  }
) => {
  if (!serviceSlugUrl) return '';

  const slugArray = Object.entries(service.slugs).find((item) => {
    const [, slug] = item;
    return slug === serviceSlugUrl;
  });

  if (!slugArray) return '';

  return service.data[slugArray[0]];
};

export default getServiceName;
