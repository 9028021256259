import React from 'react';
import { Divider } from 'components/DentalDivider';
import PractitionerInfo from 'pages/UpdatedSearchResultPage/CardInfo/Info/PractitionerInfo/PractitionerInfo';

import styles from './Info.module.scss';
import { Specialty } from 'interfaces/specialty';

interface Props {
  name: string;
  specialties: Specialty[];
  distance: number;
  clinicName: string;
}

const Info: React.FC<Props> = ({ name, specialties, distance, clinicName }) => {
  return (
    <div className={styles['container']}>
      <h3 className={styles['name']}>{name}</h3>
      <Divider orientation="horizontal" className={styles['divider']} />
      <PractitionerInfo
        isInsideAvailabilityModal
        specialty={specialties}
        practiceName={clinicName}
        distance={distance}
      />
    </div>
  );
};

export default Info;
