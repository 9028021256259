import moment from 'moment';
import { range } from 'lodash';
import styles from './DayView.module.scss';
import {
  MINUTE_OF_A_BLOCK,
  convertTime,
} from 'utils/convertMinutesIntoHoursOfDay';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import useIsMobile from 'hooks/useIsMobile';

interface DayViewPractitionerProps {
  date: string;
  blocks: number[];
}

export interface DayViewClinicProps {
  date: string;
  blocks: {
    block: number;
    practitionerIds: string[];
  }[];
}

type DayViewProps = DayViewPractitionerProps[] | DayViewClinicProps[];

const isBlockClinic = (
  blocks:
    | number[]
    | {
        block: number;
        practitionerIds: string[];
      }[]
): blocks is number[] => {
  return typeof blocks[0] === 'number';
};

const DayView = (props: {
  data: DayViewProps;
  onClickViewMore: () => void;
  onClickSlot: (date: string, slot: number, practitionerId?: string) => void;
}) => {
  const isMobile = useIsMobile();
  const numberOfColumns = isMobile ? 5 : 4;
  return (
    <>
      <div className={styles['timeslot-header']}>
        {props.data.map((value) => (
          <div key={value.date} className={styles['timeslot-cell']}>
            <span>{moment(value.date, 'YYYY-MM-DD').format('ddd')}</span>
            <span>{moment(value.date, 'YYYY-MM-DD').format('DD')}</span>
          </div>
        ))}
      </div>
      <div className={styles['timeslot-body']}>
        {props.data.map((column) => (
          <div key={column.date} className={styles['timeslot-body-container']}>
            {column.blocks.length === 0 &&
              range(numberOfColumns).map((_, index) => (
                <button
                  key={`button-${column.date}-${index}`}
                  className={styles['timeslot-cell']}
                  data-empty="true"
                >
                  -
                </button>
              ))}
            {column.blocks.length > 0 &&
              range(numberOfColumns).map((_, index) => {
                const value = isBlockClinic(column.blocks)
                  ? column.blocks[index]
                  : column.blocks[index]?.block;

                return (
                  <button
                    key={`button-${column.date}-${index}`}
                    className={styles['timeslot-cell']}
                    data-empty={!value}
                    {...(value && { 'data-date': column.date })}
                    onClick={() => {
                      if (!value) return;
                      props.onClickSlot(
                        column.date,
                        value,
                        isBlockClinic(column.blocks)
                          ? undefined
                          : column.blocks[index].practitionerIds[0]
                      );
                    }}
                  >
                    {value
                      ? convertTime(Number(value) * MINUTE_OF_A_BLOCK)
                      : '-'}
                  </button>
                );
              })}
          </div>
        ))}

        <div className={styles['blur-background']}>
          <DentalButton
            variant="outlined"
            className={styles['view-more-btn']}
            onClick={(event) => {
              event.stopPropagation();
              props.onClickViewMore();
            }}
          >
            View more
          </DentalButton>
        </div>
      </div>
    </>
  );
};

export default DayView;
