import { batch } from 'react-redux';

import SortButton from './SortButton/SortButton';
import GenderButton from './GenderButton/GenderButton';
import RatingButton from './RatingButton/RatingButton';
import TimeOfDayButton from './TimeOfDayButton/TimeOfDayButton';
import AllFilterButton from './AllFilterButton/AllFilterButton';

import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { resetSearchResultFilter } from 'redux/searchResultFilterSlice';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';

import styles from './FilterList.module.scss';

const FilterList = () => {
  const { numberOfAppliedFilters } = useAppSelector(
    (state) => state.searchResultFilterSlice
  );

  const dispatch = useAppDispatch();

  const handleResetFilter = () => {
    batch(() => {
      dispatch(resetSearchResultFilter());
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
    });
  };

  return (
    <div className={styles.container}>
      <SortButton />
      <GenderButton />
      <RatingButton />
      <TimeOfDayButton />
      <AllFilterButton />
      {numberOfAppliedFilters > 0 && (
        <button className={styles['reset-btn']} onClick={handleResetFilter}>
          Reset all
        </button>
      )}
    </div>
  );
};

export default FilterList;
