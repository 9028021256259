import moment from 'moment';
import React from 'react';
import Field from '../Field/Field';

import styles from './BookingInfo.module.scss';

interface Props {
  title: string;
  address: string;
  appointmentDate: string;
  startTime: string;
  service: string;
}

const BookingInfo: React.FC<Props> = ({
  title,
  service,
  address,
  startTime,
  appointmentDate,
}) => {
  return (
    <div className={styles['container']}>
      You have sent a booking request for
      <div className={styles['title']}>{title}</div>
      <Field title="Address" value={address} />
      {service && <Field title="Service" value={service} />}
      <Field
        title="Date"
        value={moment(appointmentDate).format('dddd, MMM DD YYYY')}
      />
      {startTime && <Field title="Time" value={startTime} />}
    </div>
  );
};

export default BookingInfo;
