import { InputAdornment, TextField } from '@material-ui/core';
import { HelperText } from 'components/Form/HelperText/HelperText';
import InputContainer from 'components/Form/InputContainer/InputContainer';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import {
  isValidPhoneNumber,
  AsYouType,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';
import useStyles from 'components/Form/Inputs/TextInput/useStyles';

import { ReactComponent as PasswordShowIcon } from 'assets/icons/password-show.svg';
import { ReactComponent as PasswordHideIcon } from 'assets/icons/password-hide.svg';
import moment from 'moment';

const FormTextInput = ({
  name,
  control,
  defaultValue = '',
  maxCharacter = 100,
  required,
  rules,
  label,
  isPhoneNumberField = false,
  mask,
  onChange = () => {},
  type,
  extraErrorMessage,
  disabled,
  autoComplete,
  ...props
}: any) => {
  const classes = useStyles(props.isSubField);
  const isMobile = useIsMobile();
  const inputRef = React.useRef(null);

  const [isShownPassword, setIsShownPassword] = useState(false);
  const [customType, setCustomType] = useState(type);

  const handleClickShowPassword = () => {
    setIsShownPassword(!isShownPassword);
  };

  useEffect(() => {
    if (type === 'password') {
      setCustomType(isShownPassword ? 'text' : 'password');
    }
  }, [isShownPassword, type]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required,
        validate: (value) => {
          if (!isPhoneNumberField) {
            return true;
          }
          if (!(value[0] === '+')) {
            return 'Phone number does not contain an international calling code';
          }
          if (isValidPhoneNumber(value)) {
            return true;
          }
          if (validatePhoneNumberLength(value) === 'TOO_LONG') {
            return 'Phone number is too long';
          }
          if (validatePhoneNumberLength(value) === 'TOO_SHORT') {
            return 'Phone number is too short';
          }
          if (!isValidPhoneNumber(value)) {
            return 'Phone number is not valid';
          }
        },
        ...rules,
      }}
      render={({ field, fieldState }) => {
        const convertDate = (input: string) => {
          if (!input) return input;

          let tmpInput = input;
          if (moment(input, 'MM/DD/YYYY', true).isValid()) {
            tmpInput = input;
          } else if (moment(input, 'DD/MM/YYYY', true).isValid()) {
            tmpInput = moment(input, 'DD/MM/YYYY').format('MM/DD/YYYY');
          }

          const [month, day, year] = tmpInput.split('/');
          if (month && day && year) return `${year}-${month}-${day}`;
          else return '';
        };
        const convertDateToDisplay = (input: string) => {
          if (!input) return input;

          let tmpInput = input;
          if (moment(input, 'MM/DD/YYYY', true).isValid()) {
            tmpInput = input;
          } else if (moment(input, 'DD/MM/YYYY', true).isValid()) {
            tmpInput = moment(input, 'DD/MM/YYYY').format('MM/DD/YYYY');
          }

          const [month, day, year] = tmpInput.split('/');
          if (month && day && year) return `${month}/${day}/${year}`;
          else return `MM/DD/YYYY`;
        };
        const onChange = (
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          field: ControllerRenderProps<FieldValues, any>
        ) => {
          if (isPhoneNumberField) {
            field.onChange(new AsYouType().input(e.target.value));
          } else if (customType === 'date' && isMobile) {
            let tmpInput = e.target.value;
            if (moment(tmpInput, 'MM/DD/YYYY', true).isValid()) {
              tmpInput = moment(tmpInput, 'MM/DD/YYYY').format('YYYY-MM-DD');
            } else if (moment(tmpInput, 'DD/MM/YYYY', true).isValid()) {
              tmpInput = moment(tmpInput, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }

            const [year, month, day] = tmpInput.split('-');
            field.onChange(`${month}/${day}/${year}`);
          } else {
            field.onChange(e.target.value);
          }
        };

        const isShowHelperText =
          fieldState.invalid && !props.isSubField && fieldState.error?.message;

        return (
          <InputContainer
            title={label}
            isSubField={props.isSubField}
            error={fieldState.error?.message}
            required={required}
          >
            {customType === 'date' && isMobile ? (
              <div
                className={
                  isShowHelperText
                    ? classes.dateFieldWrapperWithHelperText
                    : classes.dateFieldWrapperWithoutHelperText
                }
              >
                <div className={classes.datePickerInput}>
                  <TextField
                    required
                    id={name}
                    error={fieldState.invalid}
                    className={classes.input}
                    fullWidth
                    variant="outlined"
                    placeholder={props.placeholder}
                    FormHelperTextProps={{ component: 'div' } as any}
                    helperText={
                      fieldState.invalid &&
                      !props.isSubField &&
                      fieldState.error?.message && (
                        <HelperText error={fieldState.error.message} />
                      )
                    }
                    {...field}
                    inputProps={{
                      maxLength: maxCharacter,
                      value: convertDate(field.value),
                      ref: inputRef,
                      readOnly: true,
                    }}
                    onClick={props.onClick}
                  />
                </div>
                <div className={classes.datePickerTextDisplayer}>
                  <TextField
                    type="text"
                    required
                    error={fieldState.invalid}
                    className={classes.input}
                    fullWidth
                    variant="outlined"
                    placeholder={props.placeholder}
                    FormHelperTextProps={{ component: 'div' } as any}
                    {...field}
                    onChange={(e) => {
                      onChange(e, field);
                    }}
                    inputProps={{
                      autoComplete,
                      name: autoComplete,
                      maxLength: maxCharacter,
                      value: convertDateToDisplay(field.value),
                      ref: inputRef,
                    }}
                  />
                </div>
              </div>
            ) : mask ? (
              <InputMask
                mask={mask}
                maskChar=""
                value={field.value}
                disabled={disabled}
                onChange={(e) => onChange(e, field)}
                onBlur={field.onBlur}
                ref={field.ref}
              >
                {() => (
                  <TextField
                    autoComplete={autoComplete}
                    type={customType === 'date' ? 'text' : customType} //In desktop, we will have type == text, because we don't need datepicker
                    required
                    id={name}
                    error={fieldState.invalid}
                    className={classes.input}
                    fullWidth
                    variant="outlined"
                    placeholder={props.placeholder}
                    FormHelperTextProps={{ component: 'div' } as any}
                    helperText={
                      fieldState.invalid &&
                      !props.isSubField &&
                      fieldState.error?.message && (
                        <HelperText error={fieldState.error.message} />
                      )
                    }
                  />
                )}
              </InputMask>
            ) : (
              <TextField
                autoComplete={autoComplete}
                type={customType === 'date' ? 'text' : customType}
                required
                disabled={disabled}
                id={name}
                error={fieldState.invalid}
                className={classes.input}
                fullWidth
                variant="outlined"
                placeholder={props.placeholder}
                FormHelperTextProps={{ component: 'div' } as any}
                helperText={
                  extraErrorMessage
                    ? extraErrorMessage
                    : fieldState.invalid &&
                      !props.isSubField &&
                      fieldState.error?.message && (
                        <HelperText error={fieldState.error.message} />
                      )
                }
                {...field}
                onChange={(e) => onChange(e, field)}
                inputProps={{
                  maxLength: maxCharacter,
                }}
                InputProps={
                  type === 'password'
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            {isShownPassword ? (
                              <PasswordHideIcon
                                className={classes.icon}
                                onClick={handleClickShowPassword}
                              />
                            ) : (
                              <PasswordShowIcon
                                className={classes.icon}
                                onClick={handleClickShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }
                    : undefined
                }
              />
            )}
          </InputContainer>
        );
      }}
    ></Controller>
  );
};

export default FormTextInput;
