import styles from './PoweredBySection.module.scss';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
const PoweredBySection = () => {
  return (
    <div className={styles.container}>
      <span>Powered by</span>
      <Logo />
    </div>
  );
};

export default PoweredBySection;
