import React from 'react';
import { Divider } from 'components/DentalDivider';

import styles from './PatientSections.module.scss';
import Field from '../Field/Field';
import moment from 'moment';
import { convertToDate } from 'utils/dates';

interface Props {
  order: number;
  service: string;
  practitioner: string;
  name: string;
  dob: string;
  useInsurance: boolean;
  isOnePatient: boolean;
  startTime: string;
}

const PatientSections: React.FC<Props> = ({
  dob,
  name,
  order,
  service,
  startTime,
  practitioner,
  isOnePatient,
  useInsurance,
}) => {
  return (
    <div className={styles['container']}>
      <Divider className={styles['divider']} orientation="horizontal" />
      <div className={styles['title']}>
        {isOnePatient ? 'Patient Details' : `Patient ${order}`}
      </div>
      {service && <Field title="Service" value={service} />}
      {practitioner && <Field title="Practitioner" value={practitioner} />}
      {startTime && <Field title="Time" value={startTime} />}
      <Field title="Patient's full name" value={name} />
      <Field
        title="Date of birth"
        value={moment(convertToDate(dob)).format('MMM DD, YYYY')}
      />
      <Field title="Insurance coverage" value={useInsurance ? 'Yes' : 'No'} />
    </div>
  );
};

export default PatientSections;
