import React, { useState } from 'react';

import NoAvatar from 'assets/images/no-avatar.svg';
import { useAppSelector } from 'redux/reduxHooks';
import { IMapClinicData } from 'redux/mapSlice';

import ClinicInfo from '../MarkerPopupComponents/ClinicInfo';
import PractitionerInfo from '../MarkerPopupComponents/PractitionerInfo';
import styles from './MarkerPopup.module.scss';
import SwitchTabBar from '../MarkerPopupComponents/SwitchTabBar';
import useIsMobile from 'hooks/useIsMobile';
import getPractitionerName from 'utils/getPractitionerName';

interface Props {
  page: number;
  onClosePopup: () => void;
  practitionerIds: string[];
  activeClinic: IMapClinicData;
  setPage: (page: number) => void;
  onViewClinicAvailability: (id: string) => void;
  onViewPractitionerAvailability: (id: string) => void;
}

export type MarkerPopupComponent = React.FC<Props>;

const MarkerPopup: MarkerPopupComponent = ({
  page,
  setPage,
  onClosePopup,
  activeClinic,
  practitionerIds,
  onViewClinicAvailability,
  onViewPractitionerAvailability,
}) => {
  const [isPractitionerTab, setIsPractitionerTab] = useState(false);

  const practitionersData = useAppSelector(
    (state) => state.mapSlice2.practitioners
  );

  const practitioners = practitionerIds.map((item) => practitionersData[item]);

  const practitionerData = practitioners[page - 1];

  const handleChangeTab = (value: boolean) => {
    setIsPractitionerTab(value);
  };

  const isMobile = useIsMobile();

  return (
    <div className={styles['container']}>
      <SwitchTabBar
        isPractitionerTab={isPractitionerTab}
        onChangeTab={handleChangeTab}
        onClosePopup={onClosePopup}
      />
      {isPractitionerTab &&
        (isMobile ? (
          <div className={styles['practitioner-list']}>
            {practitioners.map((practitioner, index) => (
              <PractitionerInfo
                key={practitioner.id}
                id={practitioner.id}
                name={`${getPractitionerName(practitioner)}${
                  practitioner.study.length > 0
                    ? `, ${practitioner.study.join(',')}`
                    : ''
                }`}
                specialty={practitioner.specialty}
                averageScore={practitioner.totalScore}
                reviewQuantity={practitioner.reviewCount}
                avatar={practitioner.avatar || NoAvatar}
                currPage={index + 1}
                totalPage={practitioners.length}
                onPageChange={(page) => setPage(page)}
                onViewPractitionerAvailability={onViewPractitionerAvailability}
              />
            ))}
          </div>
        ) : (
          <PractitionerInfo
            id={practitionerData.id}
            name={`${getPractitionerName(practitionerData)}${
              practitionerData.study.length > 0
                ? `, ${practitionerData.study.join(',')}`
                : ''
            }`}
            specialty={practitionerData.specialty}
            averageScore={practitionerData.totalScore}
            reviewQuantity={practitionerData.reviewCount}
            avatar={practitionerData.avatar || NoAvatar}
            currPage={page}
            totalPage={practitioners.length}
            onPageChange={(page) => setPage(page)}
            onViewPractitionerAvailability={onViewPractitionerAvailability}
          />
        ))}
      {!isPractitionerTab && activeClinic && (
        <ClinicInfo
          clinicData={activeClinic}
          onViewClinicAvailability={onViewClinicAvailability}
        />
      )}
    </div>
  );
};

export default MarkerPopup;
