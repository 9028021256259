import { makeStyles } from '@material-ui/core/styles';
import { primaryPurple, fontSize } from 'utils/theme';

export default makeStyles({
  root: {
    '& :hover': {
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0 0 0 0.6rem !important',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
      {
        right: '0',
      },
    '& .MuiAutocomplete-inputRoot': {
      marginBottom: '0.4rem',
      fontFamily: 'Nexa',
      fontSize: fontSize.s,
      lineHeight: '2.1rem',
      '& .MuiAutocomplete-input': {
        padding: '0 !important',
      },
    },
  },

  paper: {
    overscrollBehavior: 'contain',
  },

  highlightText: {
    color: primaryPurple,
  },
});
