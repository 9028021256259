import React from 'react';
import moment from 'moment';
import { ReactComponent as WorkingHoursIcon } from 'assets/icons/working-hours.svg';

import { CLOSED, HOLIDAY } from 'utils/constants/common';

import styles from './WorkingHours.module.scss';

interface IClinicWorkingHours {
  workingHours: {
    day: string;
    time: string;
  }[];
  activeDate?: string;
}

const DAYS_OF_WEEK_BY_ORDER = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const WorkingHours = ({ workingHours, activeDate }: IClinicWorkingHours) => {
  const activeDay = activeDate
    ? moment(activeDate, 'YYYY-MM-DD').format('dddd')
    : null;

  const workingHoursList = DAYS_OF_WEEK_BY_ORDER.map((day) => {
    const foundWorkingHourByDay = workingHours.find((item) => item.day === day);

    return {
      day,
      time: foundWorkingHourByDay?.time || '',
      isActive: activeDay === day,
    };
  });

  return (
    <div className={styles['contact-infos']}>
      <WorkingHoursIcon className={styles['icon']} />
      <div className={styles['grid']}>
        {workingHoursList.map((item, index) => {
          return (
            <React.Fragment key={`${index}`}>
              <div className={item.isActive ? styles['bold'] : ''}>
                {item.day}
              </div>
              <div className={item.isActive ? styles['bold'] : ''}>
                {[CLOSED, HOLIDAY].includes(item.time) ? CLOSED : item.time}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default WorkingHours;
