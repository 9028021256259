import React, { useState, useEffect, useCallback, Dispatch } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { sendOtpByPhoneNumber } from 'services/APIs/otp';

import styles from './CountDownTimer.module.scss';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface Props {
  phoneNumber: string;
  onError: (message: string) => void;
  setIsExpired: Dispatch<React.SetStateAction<boolean>>;
  setIsChecked: (value: boolean) => void;
}

const COUNT_DOWN_FROM = 180;

const CountDownTimer = ({
  onError,
  phoneNumber,
  setIsExpired,
  setIsChecked,
}: Props) => {
  const [second, setSecond] = useState(COUNT_DOWN_FROM);
  const [isTimerRun, setIsTimerRun] = useState(false);

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const resendBtnClassNames = cx({
    [styles['resend-btn']]: true,
    [styles['bold']]: true,
    [styles['disabled']]: second !== 0,
  });

  const sendOtp = useCallback(async () => {
    try {
      if (phoneNumber) {
        setIsChecked(true);
        await sendOtpByPhoneNumber(phoneNumber);
        setIsTimerRun(true);
      }
    } catch (error: any) {
      onError('Something went wrong, please try again.');
    } finally {
      setIsChecked(false);
    }
  }, [onError, phoneNumber, setIsChecked]);

  const onClickResend = () => {
    if (second === 0) {
      setSecond(COUNT_DOWN_FROM);
      sendOtp();
      setIsExpired(false);
    }
  };

  useEffect(() => {
    sendOtp();
  }, [sendOtp]);

  useEffect(() => {
    if (!isTimerRun) return;
    const interval = setInterval(() => {
      setSecond((second) => {
        if (second === 0) {
          setIsTimerRun(false);
          setIsExpired(true);
          return 0;
        }
        return second - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [isTimerRun, setIsExpired]);

  const duration = moment.duration(second, 'second');

  return (
    <div className={containerClassName}>
      <div>
        Expires in{' '}
        <span className={`${styles['bold']} ${styles['fixed-width']}`}>
          {moment(duration.asMilliseconds()).format('mm:ss')}
        </span>{' '}
        minutes.
      </div>
      <div>
        Didn't receive your code?{' '}
        <button className={resendBtnClassNames} onClick={onClickResend}>
          Resend
        </button>
      </div>
    </div>
  );
};

export default CountDownTimer;
