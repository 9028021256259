import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { getClinicAvailability } from 'services/APIs/getClinicAvailability';
import { isDateInThePast } from 'utils/isDateInThePast';
import { getDateRange } from 'utils/dates';
import { getSlotsPer30MinutesForAvailability } from 'utils/updatedSlotsPer30Minutes';
import { mergePractitionersAvaiBlocks } from 'pages/UpdatedSearchResultPage/utils/mergePractitionersAvaiBlocks';
import DayView from 'components/DayView/DayView';

import styles from './ClinicDayOrMonthViewAvailability.module.scss';
import MonthView from 'components/MonthView/MonthView';
import DayCell from 'components/MonthView/DayCell/DayCell';
import { useAppSelector } from 'redux/reduxHooks';
import getTimeBlocksData from 'utils/getTimeBlocksData';
import {
  DentistInfo,
  getDentistIdForSplitScheduling,
  getOperatoryId,
} from 'pages/UpdatedSearchResultPage/utils';

interface IProps {
  isDayView: boolean;
  clinicId: string;
  serviceId: string;
  clinicTimezone: string;
  fromDate: string;
  setDateValue: (date: string) => void;
  setIsDayView: React.Dispatch<React.SetStateAction<boolean>>;
  onBookAppointment: (param: {
    date: string;
    timeSlot: number;
    operatoryId: string;
    doctorId: string;
    dentist: DentistInfo;
  }) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const WEEK_COUNT = 6;

const ClinicDayOrMonthViewWrapper = ({
  isDayView,
  clinicId,
  serviceId,
  clinicTimezone,
  fromDate,
  setDateValue,
  setIsDayView,
  onBookAppointment,
  isLoading,
  setIsLoading,
}: IProps) => {
  const [timeslots, setTimeslots] = useState([]);
  const [closedDates, setClosedDates] = useState([]);
  const [isMonthViewLoading, setIsMonthViewLoading] = useState(false);

  const { timeBlocks, gender } = useAppSelector(
    (state) => state.searchResultFilterSlice
  );

  const [dayViewTimeSlotsByAvail, setDayViewTimeSlotsByAvail] = useState<any>();

  const fetchAvailabilityInDateRange = async (
    datesInFuture: string[],
    callback: any
  ) => {
    const promises = [];
    const tmpDatesInFuture = [...datesInFuture];

    while (tmpDatesInFuture.length !== 0) {
      const ranges = tmpDatesInFuture.splice(0, 7);

      if (ranges[0] !== ranges[ranges.length - 1]) {
        const dates = [ranges[0], ranges[ranges.length - 1]];
        promises.push(callback(dates));
      }
    }

    const availabilities = await Promise.all(promises);

    return availabilities;
  };

  const fetchWeeklyTimeSlotForMonthView = useCallback(
    async (startDate: Date, endDate: Date) => {
      setIsMonthViewLoading(true);
      const dateStrings = getDateRange(startDate, endDate);

      const datesInFuture = dateStrings.filter(
        (dateString) => !isDateInThePast(dateString)
      );

      // Call weekly API of clinic
      const availabilities = await fetchAvailabilityInDateRange(
        datesInFuture,
        (dates: string[]) =>
          getClinicAvailability({
            clinicId,
            serviceId,
            dates,
            timezone: clinicTimezone,
            timeBlocks: getTimeBlocksData(timeBlocks),
            gender,
          })
      );

      const availabilitiesByPractitioners = availabilities.reduce(
        (accum: any, currentValue: any) => {
          if (currentValue?.doctors) {
            return accum.concat(currentValue.doctors);
          }

          return accum;
        },
        []
      );

      const availBlocks = mergePractitionersAvaiBlocks(
        availabilitiesByPractitioners
      );

      const closedDates = availabilitiesByPractitioners.reduce(
        (accum: any, doctor: any) => {
          const closedDates = doctor.availableBlocks
            .filter((item: any) => item.isClosed)
            .map((item: any) => item.date);

          return accum.concat(closedDates);
        },
        []
      );

      const result = Object.keys(availBlocks.dateItems).reduce(
        (accum: any, date: any) => {
          accum.push({
            date,
            blocks: Object.keys(availBlocks.dateItems[date]),
          });
          return accum;
        },
        []
      );

      setIsMonthViewLoading(false);
      setTimeslots(result);
      setClosedDates(closedDates);
    },
    [clinicId, clinicTimezone, gender, serviceId, timeBlocks]
  );

  useEffect(() => {
    const fetchWeeklyTimeSlotForDayView = async () => {
      setIsLoading(true);
      const DAY_COUNT = 4;

      const endDate = moment(fromDate, 'YYYY-MM-DD')
        .add(DAY_COUNT, 'days')
        .format('YYYY-MM-DD');
      const dates = [fromDate, endDate];

      const availability = await getClinicAvailability({
        clinicId,
        serviceId,
        dates,
        timezone: clinicTimezone,
        timeBlocks: getTimeBlocksData(timeBlocks),
        gender,
      });

      const availabilityDoctors = availability ? availability.doctors : [];

      const avaiBlocksPractitionerPer30Minutes = availabilityDoctors.map(
        (doctor: any) => ({
          ...doctor,
          availableBlocks: getSlotsPer30MinutesForAvailability(
            doctor.availableBlocks
          ),
        })
      );

      if (avaiBlocksPractitionerPer30Minutes.length === 0) {
        setDayViewTimeSlotsByAvail([]);
      } else {
        setDayViewTimeSlotsByAvail({
          id: clinicId,
          doctors: avaiBlocksPractitionerPer30Minutes,
        });
      }
      setIsLoading(false);
    };

    if (!isDayView) return;

    fetchWeeklyTimeSlotForDayView();
  }, [
    clinicId,
    clinicTimezone,
    fromDate,
    gender,
    isDayView,
    serviceId,
    setIsLoading,
    timeBlocks,
  ]);

  return isDayView ? (
    <DayView
      isLoading={isLoading}
      date={fromDate}
      onDateUpdate={(newDate) => setDateValue(newDate)}
      serviceId={serviceId}
      clinicId={clinicId}
      isClinicSearch={true}
      clinicData={dayViewTimeSlotsByAvail}
      onClickClinicTimeSlot={(timeSlot, date, practitionerIds) => {
        const doctorId = practitionerIds[0];

        const operatoryId = getOperatoryId({
          date,
          doctorId,
          timeSlot,
          doctors: dayViewTimeSlotsByAvail.doctors,
        });

        const dentist = getDentistIdForSplitScheduling({
          date,
          doctorId: null,
          timeSlot,
          doctors: dayViewTimeSlotsByAvail.doctors,
        });

        onBookAppointment({
          date,
          timeSlot,
          doctorId,
          operatoryId,
          dentist,
        });
      }}
      headerClassName={styles['dayview-navigation-header']}
      bodyClassName={styles['dayview-navigation-body']}
      blurTopBgClassName={styles['dayview-navigation-blur-top-bg']}
      blurBottomBgClassName={styles['dayview-navigation-blur-bottom-bg']}
      iconClassName={styles['dayview-navigation-icon']}
    />
  ) : (
    <MonthView
      isRenderPast
      selectedDate={fromDate}
      contentHeight={70 * WEEK_COUNT + 32}
      fetchTimeSlots={fetchWeeklyTimeSlotForMonthView}
      dateClick={(dateClickArg) => {
        setDateValue(dateClickArg.dateStr);
        setIsDayView(true);
        setIsLoading(true);
      }}
      dayCellContent={(props) => {
        return (
          <DayCell
            isLoading={isMonthViewLoading}
            {...props}
            timeslots={timeslots}
            closedDates={closedDates}
          />
        );
      }}
    />
  );
};

export default ClinicDayOrMonthViewWrapper;
