import { FC } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './CustomPopperContent.module.scss';
interface CustomPopperContentProps {
  title: string;
  onClose: () => void;
}

const CustomPopperContent: FC<CustomPopperContentProps> = ({
  title,
  children,
  onClose,
}) => {
  return (
    <div className={styles['main-section']}>
      <div className={styles['header']}>
        <h3>{title}</h3>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className={styles['body']}>{children}</div>
    </div>
  );
};

export default CustomPopperContent;
