import React from 'react';
import cx from 'classnames';
import ShowMoreText from 'react-show-more-text';
import { Rating } from '@material-ui/lab';

import NoAvatarSrc from 'assets/images/no-avatar.svg';
import { ReactComponent as GoogleReviewsIcon } from 'assets/icons/google.svg';
import { ReactComponent as RateMdsReviewsIcon } from 'assets/icons/rateMD.svg';

import styles from './PractitionerReview.module.scss';
import moment from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import useIsMobile from 'hooks/useIsMobile';
import { convertToDate } from 'utils/dates';

interface IPractitionerReviewProps {
  score: number;
  content: string;
  reviewer: {
    avatar: string;
    name: string;
  };
  createdAt?: string;
  source?: string;
  isEvenIndex: boolean;
}

const useStyles = makeStyles({
  accordianRoot: {
    boxShadow: 'none',
    padding: '2.4rem 0',
    '&::before': {
      display: 'none',
    },
  },
  accordianSummaryRoot: {
    paddingLeft: 0,
    minHeight: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: '0 2.4rem',
    },
  },
  accordianDetailRoot: {
    padding: '0.8rem 2.4rem 0 5rem',
  },
});

const PractitionerReview = ({
  content,
  reviewer: { avatar, name = 'Anonymous' },
  score,
  createdAt = '',
  source = 'google',
  isEvenIndex,
}: IPractitionerReviewProps) => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['has-background-color']]: !isEvenIndex,
    [styles['has-padding']]: !content && isMobile,
  });

  const handleImgError = (e: any) => {
    e.target.src = `${NoAvatarSrc}`;
  };

  const renderContent = (
    <div className={containerClassName}>
      <div className={styles.header}>
        <img
          src={avatar}
          alt="reviewer-avatar"
          className={styles.avatar}
          onError={handleImgError}
        />
        <div className={styles['name-container']}>
          <div className={styles['flex']}>
            <span className={styles['name']}>{name ? name : 'Anonymous'}</span>
          </div>
          <div className={styles['review-source']}>
            {source === 'google' && (
              <>
                <GoogleReviewsIcon className={styles['icon']} />
                <div>Google review</div>
              </>
            )}
            {source === 'ratemds' && (
              <>
                <RateMdsReviewsIcon className={styles['icon']} />
                <div>RateMDs review</div>
              </>
            )}
          </div>
          <div className={styles['flex-align-center']}>
            <Rating
              name="read-only"
              value={score}
              readOnly
              precision={0.5}
              size="medium"
            />
            <span className={styles['created-at']}>
              {moment(convertToDate(createdAt)).format('MMMM DD, YYYY')}
            </span>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className={styles.content}>
          <ShowMoreText
            lines={3}
            more="show more"
            less="show less"
            expanded={false}
          >
            {content}
          </ShowMoreText>
        </div>
      )}
    </div>
  );

  if (isMobile && content) {
    return (
      <Accordion
        classes={{ root: classes.accordianRoot }}
        className={!isEvenIndex ? styles['has-background-color'] : ''}
      >
        <AccordionSummary
          classes={{ root: classes.accordianSummaryRoot }}
          expandIcon={<ExpandMore fontSize="large" />}
        >
          {renderContent}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordianDetailRoot }}>
          {content}
        </AccordionDetails>
      </Accordion>
    );
  }

  return renderContent;
};

export default PractitionerReview;
