import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import {
  IClinicListDataV2,
  clearClinicSearchDetailsV2,
  getClinicsListV2,
  setIsLoadingMore,
} from 'redux/clinicsSearchResultsSliceV2';
import Modal from 'components/Modal/Modal';
import CardInfo from '../CardInfo/CardInfo';
import TotalCount from '../TotalCount/TotalCount';

import ClinicInfo from '../CardInfo/Info/ClinicInfo/ClinicInfo';
import DayView from '../CardInfo/DayView/DayView';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import ClinicAvailability from './ClinicAvailability/ClinicAvailability';

import styles from './ClinicList.module.scss';
import MonthView from 'components/MonthView/MonthView';
import DayCell from 'components/MonthView/DayCell/DayCell';
import BookingForm from 'components/BookingForm/BookingForm';
import useIsMobile from 'hooks/useIsMobile';
import { resetSearchResultFilter } from 'redux/searchResultFilterSlice';
import { batch } from 'react-redux';
import { clearMapData } from 'redux/mapSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import {
  DentistInfo,
  getDentistIdForSplitScheduling,
  getOperatoryId,
} from '../utils';

interface ClinicListProps {
  searchDate: string;
  navigatedDate: string;
  isDayView: boolean;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}

const WEEK_COUNT = 2;

const ClinicList = (props: ClinicListProps) => {
  const { searchDate, navigatedDate, isDayView, setDate } = props;

  const [viewAllAvailabilityClinicId, setViewAllAvailabilityClinicId] =
    useState<string | null>(null);

  const [selectedDate, setSelectedDate] = useState('');

  const [selectedSlot, setSelectedSlot] = useState<{
    date: string;
    timeSlot: number;
    clinic: IClinicListDataV2;
    practitionerId: string;
    operatoryId: string;
    dentist: DentistInfo;
  } | null>(null);

  const dispatch = useAppDispatch();

  const history = useHistory();

  const isMobile = useIsMobile();

  const filterSlice = useAppSelector((state) => state.filterSlice);

  const searchBarOptionsSlice = useAppSelector(
    (state) => state.searchBarOptionsSlice
  );

  const clinicsSearchResultsSliceV2 = useAppSelector(
    (state) => state.clinicsSearchResultsSliceV2
  );

  const { sort, minRating, gender, timeBlocks, numberOfAppliedFilters } =
    useAppSelector((state) => state.searchResultFilterSlice);

  const {
    clinics,
    totalClinics,
    status,
    dailyTimeSlots,
    monthlyTimeSlots,
    hasMore,
    page,
  } = clinicsSearchResultsSliceV2;

  useEffect(() => {
    const getData = async () => {
      const dayCount = isMobile ? 2 : 4;
      const endDate = moment(navigatedDate, 'YYYY-MM-DD')
        .add(dayCount, 'days')
        .format('YYYY-MM-DD');
      const dates = [navigatedDate, endDate];

      const { payload } = (await dispatch(
        getClinicsListV2({
          searchDate: navigatedDate === searchDate ? searchDate : undefined,
          currentDate: moment().format(),
          dates,
          lat: filterSlice.location.lat,
          lng: filterSlice.location.lng,
          page,
          sortBy: sort,
          serviceId: filterSlice.service,
          isMonthView: !isDayView,
          timeBlocks,
          minRating,
          gender,
        })
      )) as any;

      if (numberOfAppliedFilters > 0) return;

      if (searchDate !== navigatedDate) return;

      const firstDateOfBlock =
        payload.clinics[0]?.doctors[0].availableBlocks[0]?.date;

      if (firstDateOfBlock === searchDate) return;

      sessionStorage.setItem('cachedDate', searchDate);

      if (firstDateOfBlock) {
        setDate(firstDateOfBlock);
      }
    };

    getData();
  }, [
    dispatch,
    filterSlice.location.lat,
    filterSlice.location.lng,
    filterSlice.service,
    gender,
    isDayView,
    isMobile,
    minRating,
    navigatedDate,
    numberOfAppliedFilters,
    page,
    searchDate,
    setDate,
    sort,
    timeBlocks,
  ]);

  const renderClinicAvailability = () => {
    const clinic = clinics.find(
      (item) => item.id === viewAllAvailabilityClinicId
    );

    if (!clinic) {
      return null;
    }

    return (
      <ClinicAvailability
        clinicData={clinic}
        navigatedDate={selectedDate ? selectedDate : navigatedDate}
        distance={clinic!.distance}
        serviceId={filterSlice.service}
        defaultIsDayView={selectedDate ? true : isDayView}
        onBookAppointment={({
          date,
          timeSlot,
          doctorId,
          operatoryId,
          dentist,
        }) => {
          setSelectedSlot({
            timeSlot,
            practitionerId: doctorId,
            date,
            clinic,
            operatoryId,
            dentist,
          });
        }}
      />
    );
  };

  const navigateToProfilePage = ({
    event,
    slug,
  }: {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    slug: string;
  }) => {
    if (event.target instanceof HTMLAnchorElement) return;

    const path = `/${slug}?date=${encodeURIComponent(
      navigatedDate
    )}&service=${encodeURIComponent(
      searchBarOptionsSlice.service.slugs[filterSlice.service]
    )}`;

    history.push(path);
  };

  const handleResetAllFilters = () => {
    batch(() => {
      dispatch(resetSearchResultFilter());
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
      dispatch(clearMapData());
    });
  };

  if (!navigatedDate || (clinics.length === 0 && status === 'loading')) {
    return (
      <div className={styles['circular-loader-wrapper']}>
        <CircularProgress size={48} className={styles['circular-loader']} />;
      </div>
    );
  }

  if (selectedSlot) {
    const serviceTitle =
      searchBarOptionsSlice.service.data[filterSlice.service];
    const serviceName = serviceTitle;

    return (
      <BookingForm
        date={selectedSlot.date}
        timeBlock={selectedSlot.timeSlot}
        clinicId={selectedSlot.clinic.id}
        serviceId={filterSlice.service}
        practitionerId={selectedSlot.practitionerId}
        operatoryId={selectedSlot.operatoryId}
        dentist={selectedSlot.dentist}
        bookingSummaryInfo={{
          avatar: selectedSlot.clinic.avatar,
          title: selectedSlot.clinic.name,
          clinicName: selectedSlot.clinic.name,
          clinicAddress: selectedSlot.clinic.address,
          clinicEmail: selectedSlot.clinic.email,
          clinicPhoneNumber: selectedSlot.clinic.phone,
          serviceTitle: `${serviceName}`,
        }}
        closeBookingDialog={() => setSelectedSlot(null)}
      />
    );
  }

  if (clinics.length === 0 && numberOfAppliedFilters > 0) {
    return (
      <div className={styles['filter-no-result']}>
        <span>No results found for your filters.</span>
        <button onClick={handleResetAllFilters}>Reset all filters</button>
      </div>
    );
  }

  return (
    <>
      <div>
        {sessionStorage.getItem('cachedDate') ? (
          <>
            <div className={styles['no-result']}>
              No results found for{' '}
              {moment(
                sessionStorage.getItem('cachedDate'),
                'YYYY-MM-DD'
              ).format('ddd, MMM D')}
            </div>
            <div className={styles['latest-availability']}>
              See our latest availability on{' '}
              {moment(navigatedDate, 'YYYY-MM-DD').format('ddd, MMM D')}
            </div>
          </>
        ) : (
          <TotalCount
            title={`${totalClinics} Practice(s)`}
            nearByLocation={`nearby ${filterSlice.location.name}`}
          />
        )}

        {clinics.map((clinic) => {
          const dailyTimeSlotsByClinic =
            dailyTimeSlots.find((item) => item.clinicId === clinic.id)
              ?.timeSlots || [];

          const monthlyTimeSlotsByClinic =
            monthlyTimeSlots.find((item) => item.clinicId === clinic.id)
              ?.timeSlots || [];

          const isCardLoading = status === 'loading';
          const isDayViewLoading = status !== 'loading' && isDayView;
          const isMonthViewLoading = status !== 'loading' && !isDayView;

          return (
            <CardInfo
              key={clinic.id}
              avatar={clinic.avatar}
              rating={clinic.rating}
              reviewCount={clinic.reviewCount}
              name={clinic.name}
              distance={clinic.distance}
              detailContent={
                <div className={styles['body-container']}>
                  <ClinicInfo
                    distance={clinic.distance}
                    workingHours={clinic.workingHours}
                    date={navigatedDate}
                  />
                  <div className={styles['line']} />
                  <span className={styles['text']}>{clinic.description}</span>
                </div>
              }
              customStyle={styles.card}
              navigateToProfilePage={(event) =>
                navigateToProfilePage({
                  event,
                  slug: clinic.slug,
                })
              }
            >
              <>
                {isCardLoading && (
                  <div className={styles['card-loading']}>
                    <CircularProgress
                      size={48}
                      className={styles['circular-loader']}
                    />
                  </div>
                )}

                {isDayViewLoading && (
                  <div className={styles['day-view-wrapper']}>
                    <DayView
                      data={dailyTimeSlotsByClinic}
                      onClickViewMore={() => {
                        setViewAllAvailabilityClinicId(clinic.id);
                      }}
                      onClickSlot={(date, timeSlot, practitionerId) => {
                        const doctorId = practitionerId!;

                        const operatoryId = getOperatoryId({
                          date,
                          doctorId,
                          timeSlot,
                          doctors: clinic.doctors,
                        });

                        const dentist = getDentistIdForSplitScheduling({
                          date,
                          doctorId: null,
                          timeSlot,
                          doctors: clinic.doctors,
                        });

                        setSelectedSlot({
                          clinic,
                          date,
                          timeSlot,
                          practitionerId: doctorId,
                          operatoryId,
                          dentist,
                        });
                      }}
                    />
                  </div>
                )}

                {isMonthViewLoading && (
                  <div className={styles['month-view-wrapper']}>
                    <MonthView
                      selectedDate={navigatedDate}
                      contentHeight={70 * WEEK_COUNT + 32}
                      weekCount={WEEK_COUNT}
                      isRenderPast={false}
                      dayCellContent={(props) => {
                        const closedDates = monthlyTimeSlotsByClinic
                          .filter((item) => item.isClosed)
                          .map((item) => item.date);

                        return (
                          <DayCell
                            {...props}
                            timeslots={monthlyTimeSlotsByClinic}
                            closedDates={closedDates}
                          />
                        );
                      }}
                      dateClick={(dateClickArg) => {
                        setSelectedDate(dateClickArg.dateStr);
                        setViewAllAvailabilityClinicId(clinic.id);
                      }}
                    />

                    <div className={styles['show-more-button-wrapper']}>
                      <DentalButton
                        size="large"
                        variant="outlined"
                        className={styles['view-more-btn']}
                        onClick={() => {
                          setViewAllAvailabilityClinicId(clinic.id);
                        }}
                      >
                        View more
                      </DentalButton>
                    </div>
                  </div>
                )}
              </>
            </CardInfo>
          );
        })}
      </div>

      <div className={styles['center-content']}>
        {hasMore && (
          <DentalButton
            size="large"
            variant="outlined"
            onClick={() => {
              dispatch(setIsLoadingMore());
            }}
            isLoading={status === 'loading-more'}
            className={styles['load-more-button']}
          >
            Load more
          </DentalButton>
        )}
        {!hasMore && clinics.length > 0 && (
          <div className={styles['no-more-result']}>No more search results</div>
        )}
      </div>

      {clinics.length !== 0 && (
        <Modal
          open={viewAllAvailabilityClinicId !== null}
          onClose={() => {
            setViewAllAvailabilityClinicId(null);
            setSelectedDate('');
          }}
          customStyle={styles['modal-wrapper']}
        >
          {renderClinicAvailability()}
        </Modal>
      )}
    </>
  );
};

export default memo(ClinicList);
