import React from 'react';

import AutoComplete from 'components/AppointmentInfoCard/Service/AutoComplete';

import css from './PractitionerDropdownSection.module.scss';

interface Props {
  isOpen: boolean;
  options: {
    id: string;
    title: string;
    avatar: string;
    rating: number;
    reviewCount: number;
    specialties: string[];
  }[];
  optionValue: { id: string; title: string };
  onChangeIsOpen: (isOpen: boolean) => void;
  onChangePractitionerOption: (option: {
    id: string;
    title: string;
    duration: number;
  }) => void;
  defaultOption: { id: string; title: string };
}

const ReasonSection: React.FC<Props> = ({
  isOpen,
  options,
  optionValue,
  defaultOption,
  onChangeIsOpen,
  onChangePractitionerOption,
}) => {
  return (
    <div className={css['container']}>
      <h4 className={css['title']}>Practitioner</h4>
      <AutoComplete
        isOpen={isOpen}
        options={options}
        optionValue={optionValue}
        onChangeIsOpen={onChangeIsOpen}
        onChangeServiceOption={onChangePractitionerOption}
        defaultOption={defaultOption}
        height="2.1rem"
        isPractitionerDropdown
      />
    </div>
  );
};

export default ReasonSection;
