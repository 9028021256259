import React from 'react';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

import styles from './Modal.module.scss';
import useIsMobile from 'hooks/useIsMobile';
import { isNil } from 'lodash';

interface Props {
  open: boolean;
  customStyle?: string;
  onClose: () => void;
  isCloseIconDisplayed?: boolean;
  isNotFullScreen?: boolean;
}

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: '1rem',
      border: '1px solid #E8E8E8',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'unset',
    },
  },
});

const Modal: React.FC<Props> = ({
  open,
  children,
  customStyle,
  onClose,
  isCloseIconDisplayed = true,
  isNotFullScreen,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
      fullScreen={!isNil(isNotFullScreen) ? !isNotFullScreen : isMobile}
      className={classes.root}
    >
      <div className={`${styles['container']} ${customStyle}`}>
        {isCloseIconDisplayed && (
          <CloseIcon className={styles['close-icon']} onClick={onClose} />
        )}
        {children}
      </div>
    </Dialog>
  );
};

export default Modal;
