import { FC, useState } from 'react';
import { Dialog } from '@material-ui/core';
import Picker, { PickerValue } from 'react-mobile-picker';
import moment from 'moment';

import styles from './MobileDatePicker.module.scss';
import getDayArray from './util/getDayArray';
interface MobileDatePickerProps {
  isOpen: boolean;
  onClose: () => void;
  initialValue?: PickerValue;
  onUpdated: (newValue: PickerValue) => void;
}

const MobileDatePicker: FC<MobileDatePickerProps> = ({
  isOpen,
  onClose,
  onUpdated,
  initialValue = {
    day: '15',
    month: '07',
    year: '1998',
  },
}) => {
  const getDefaultValue = () => {
    const { day, month, year } = initialValue;

    return {
      year: moment(year, 'YYYY').format('YYYY'),
      month: moment(month, 'MM').format('MM'),
      day: moment(day, 'DD').format('DD'),
    };
  };

  const [value, setValue] = useState<PickerValue>(() => getDefaultValue());

  const onChange = (newValue: PickerValue, key: string) => {
    if (key === 'day') {
      setValue(newValue);
      return;
    }

    const { year, month } = newValue;
    const newDayArray = getDayArray(Number(year), Number(month));
    const newDay = newDayArray.includes(newValue.day)
      ? newValue.day
      : newDayArray[newDayArray.length - 1];
    const updatedValue = { ...newValue, day: newDay };
    setValue(updatedValue);
  };

  const onDone = () => {
    onUpdated(value);
    onClose();
  };

  const get100YearRange = () => {
    const ranges = Array.from(
      { length: 100 },
      (_, i) =>
        `${moment()
          .subtract(100 - (i + 1), 'y')
          .format('YYYY')}`
    );
    return ranges;
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      classes={{ root: styles.root }}
      onClick={(e) => {
        const isModalClickOutside = (
          e.target as HTMLDivElement
        ).classList.contains('MuiBackdrop-root');
        if (!isModalClickOutside) return;
        onClose();
      }}
    >
      <div className={styles['modal-container']}>
        <div className={styles['header']}>
          <h3>Select Date of Birth</h3>
          <button onClick={onDone}>Done</button>
        </div>
        <div className={styles['body']}>
          <Picker
            value={value}
            onChange={onChange}
            wheelMode="natural"
            className={styles['picker-container']}
            itemHeight={42}
            height={300}
          >
            <Picker.Column name="month" className={styles['column']}>
              {Array.from({ length: 12 }, (_, i) =>
                `${i + 1}`.padStart(2, '0')
              ).map((month) => (
                <Picker.Item
                  key={month}
                  value={month}
                  className={styles['item']}
                >
                  {({ selected }) => (
                    <div className={selected ? styles['selected'] : ''}>
                      {moment(month, 'M').format('MMMM')}
                    </div>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
            <Picker.Column name="day" className={styles['column']}>
              {getDayArray(Number(value.year), Number(value.month)).map(
                (day) => (
                  <Picker.Item key={day} value={day} className={styles['item']}>
                    {({ selected }) => (
                      <div className={selected ? styles['selected'] : ''}>
                        {day}
                      </div>
                    )}
                  </Picker.Item>
                )
              )}
            </Picker.Column>
            <Picker.Column name="year" className={styles['column']}>
              {get100YearRange().map((year) => (
                <Picker.Item key={year} value={year} className={styles['item']}>
                  {({ selected }) => (
                    <div className={selected ? styles['selected'] : ''}>
                      {year}
                    </div>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
          </Picker>
        </div>
      </div>
    </Dialog>
  );
};

export default MobileDatePicker;
