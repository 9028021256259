import moment from 'moment';
import { convertToDate } from 'utils/dates';

const convertDateBlocks = (date?: string, isMobile?: boolean) => {
  const dateBlocks = Array.from({ length: isMobile ? 3 : 5 }).map(
    (_, index) => {
      const currentMoment = date ? moment(convertToDate(date)) : moment();
      const [labelDayOfWeek, labelDayOfMonth] = currentMoment
        .add(index, 'days')
        .format('ddd-DD')
        .split('-');
      const timeDate = currentMoment.format('YYYY-MM-DD');
      return { timeDate, labelDayOfWeek, labelDayOfMonth };
    }
  );
  return dateBlocks;
};

export default convertDateBlocks;
