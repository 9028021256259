import { FC } from 'react';
import { Popper, PopperProps } from '@material-ui/core';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';

import styles from './CustomPopper.module.scss';

interface CustomPopperProps extends PopperProps {
  isResetBtnDisabled?: boolean;
  isAllFilter?: boolean;
  onResetClicked?: () => void;
  onApplyClicked?: () => void;
}

const CustomPopper: FC<CustomPopperProps> = ({
  children,
  isResetBtnDisabled,
  isAllFilter = false,
  onResetClicked,
  onApplyClicked,
  ...props
}) => {
  return (
    <Popper
      placement="bottom-start"
      disablePortal={true}
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: false,
          boundariesElement: 'scrollParent',
        },
        hide: { enabled: false },
      }}
      {...props}
    >
      <div className={styles['dropdown-menu']}>
        {children}
        <div className={styles['action']}>
          <DentalButton
            variant="text"
            className={styles['reset-btn']}
            disabled={isResetBtnDisabled}
            onClick={onResetClicked}
          >
            {isAllFilter ? 'Reset all' : 'Reset'}
          </DentalButton>
          <DentalButton
            variant="contained"
            className={styles['apply-btn']}
            onClick={onApplyClicked}
          >
            Apply
          </DentalButton>
        </div>
      </div>
    </Popper>
  );
};

export default CustomPopper;
