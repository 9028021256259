import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';

import parse from 'autosuggest-highlight/parse';
import NoAvatarSrc from 'assets/images/no-avatar.svg';
import { Specialty } from 'interfaces/specialty';
import { filterSpecialties } from 'utils/common';

import styles from './PractitionerOptions.module.scss';

interface Props {
  option: {
    title: string;
    avatar: string;
    specialties: Specialty[];
    rating: number;
    reviewCount: number;
  };
  state: AutocompleteRenderOptionState;
  isHightlighting: boolean;
  classes: ClassNameMap<string>;
}

export const PractitionerOptions: React.FC<Props> = ({
  option,
  state,
  isHightlighting,
  classes,
}) => {
  const firstCharacterIndex = option.title
    .toLocaleLowerCase()
    .indexOf(state.inputValue.toLowerCase());
  const lastCharacterIndex = firstCharacterIndex + state.inputValue.length;
  const parts = isHightlighting
    ? parse(option.title, [[firstCharacterIndex, lastCharacterIndex]])
    : parse(option.title, [[0, option.title.length - 1]]);

  if (option.title !== 'No preference') {
    return (
      <div className={`${styles['flex']} ${styles['section']}`}>
        <img
          src={option.avatar || NoAvatarSrc}
          alt={`${option.title}__avatar`}
          className={styles['practitioner-avatar']}
        />
        <div>
          <div className={styles['bold']}>
            {isHightlighting ? (
              parts.map((part, index) => (
                <span
                  key={index}
                  className={
                    part.highlight && isHightlighting
                      ? classes.highlightText
                      : ''
                  }
                >
                  {part.text}
                </span>
              ))
            ) : (
              <span>{option.title}</span>
            )}
          </div>
          <span>
            {filterSpecialties(option.specialties)
              .map((specialty) => specialty.name)
              .join(', ')}
          </span>
        </div>
      </div>
    );
  }
  return <div className={styles['section']}>No preference</div>;
};
