import { FIT_BOUNDS_PADDING, POP_UP_MODAL_HEIGHT } from './constant';

export const zoomInMarkerWithOffSet = (
  map: L.Map,
  center: L.LatLngExpression,
  zoom: number
) => {
  const targetPoint = map
      .project(center, zoom)
      .add([0, POP_UP_MODAL_HEIGHT / 2]),
    targetLatLng = map.unproject(targetPoint, zoom);
  map.setView(targetLatLng, zoom, { animate: true });
};

export const fitAllMarkers = (map: L.Map, bounds: [number, number][]) => {
  map.fitBounds(bounds, { padding: FIT_BOUNDS_PADDING, animate: true });
};
