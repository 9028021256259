import { range } from 'lodash';
import {
  AvailableBlockDentistForSplitScheduling,
  AvailableBlockOperatory,
  SearchingService,
} from 'interfaces/timeslotType';

const EXAM_PLACEMENT = {
  HEAD: 'head',
  TAIL: 'tail',
};

export interface DentistInfo {
  id: string | null;
  timeSlot: number | null;
  timeSlotType: string | null;
}

export const getOperatoryId = ({
  date,
  doctorId,
  timeSlot,
  doctors,
}: {
  date: string;
  doctorId: string;
  timeSlot: number;
  doctors: {
    id: string;
    availableBlocks: {
      date: string;
      availableBlockOperatories?: AvailableBlockOperatory[];
    }[];
  }[];
}) => {
  const selectedDoctor = doctors.find((item: any) => item.id === doctorId);

  const availableBlocksByDate =
    selectedDoctor?.availableBlocks.find((item: any) => item.date === date) ||
    null;

  const availableBlockOperatoriesByDate =
    availableBlocksByDate?.availableBlockOperatories || [];

  const operatoryIds = availableBlockOperatoriesByDate
    .filter((item: any) => item.blocks.includes(timeSlot))
    .map((item: any) => item.operatoryId);

  return operatoryIds[0] || '';
};

const isFirstContainedBySecondBlockNumbers = (
  firstBlockNumbers: number[],
  secondBlockNumbers: number[]
) => {
  return firstBlockNumbers.every((item) => secondBlockNumbers.includes(item));
};

export const getDentistIdForSplitScheduling = ({
  date,
  doctorId,
  timeSlot,
  doctors,
}: {
  date: string;
  doctorId: string | null;
  timeSlot: number;
  doctors: {
    id: string;
    availableBlocks: {
      date: string;
      searchingService: SearchingService;
      availableBlockOperatories?: AvailableBlockOperatory[];
      dentists?: AvailableBlockDentistForSplitScheduling[];
    }[];
  }[];
}) => {
  if (doctorId === null) {
    const isExistingHead = doctors.some((doctor) => {
      const availableBlock = doctor.availableBlocks.find(
        (item) => item.date === date
      );

      const searchingService = availableBlock?.searchingService;

      const doctorDuration = searchingService?.doctorDuration || 0;

      const headRange = range(timeSlot, timeSlot + doctorDuration);
      const dentists = availableBlock?.dentists || [];

      const isExistingHead = dentists.some((item) =>
        isFirstContainedBySecondBlockNumbers(
          headRange,
          item.headAvailableBlockNumbers
        )
      );

      return isExistingHead;
    });

    const isExistingTail = doctors.some((doctor) => {
      const availableBlock = doctor.availableBlocks.find(
        (item) => item.date === date
      );

      const searchingService = availableBlock?.searchingService;

      const doctorDuration = searchingService?.doctorDuration || 0;
      const hygienistDuration = searchingService?.hygienistDuration || 0;

      const tailRange = range(
        timeSlot + hygienistDuration,
        timeSlot + hygienistDuration + doctorDuration
      );

      const dentists = availableBlock?.dentists || [];

      const isExistingTail = dentists.some((item) =>
        isFirstContainedBySecondBlockNumbers(
          tailRange,
          item.tailAvailableBlockNumbers
        )
      );

      return isExistingTail;
    });

    if (isExistingHead && isExistingTail) {
      return {
        id: null,
        timeSlot,
        timeSlotType: null,
      };
    }

    if (isExistingHead && !isExistingTail) {
      return {
        id: null,
        timeSlot,
        timeSlotType: EXAM_PLACEMENT.HEAD,
      };
    }

    if (isExistingTail && !isExistingHead) {
      return {
        id: null,
        timeSlot,
        timeSlotType: EXAM_PLACEMENT.TAIL,
      };
    }

    return {
      id: null,
      timeSlot,
      timeSlotType: null,
    };
  } else {
    const doctor = doctors.find((item) => item.id === doctorId);

    if (!doctor) {
      return {
        id: null,
        timeSlot,
        timeSlotType: null,
      };
    }

    const availableBlock = doctor.availableBlocks.find(
      (item) => item.date === date
    );

    const searchingService = availableBlock?.searchingService;

    const doctorDuration = searchingService?.doctorDuration || 0;
    const hygienistDuration = searchingService?.hygienistDuration || 0;

    const headRange = range(timeSlot, timeSlot + doctorDuration);
    const tailRange = range(
      timeSlot + hygienistDuration,
      timeSlot + hygienistDuration + doctorDuration
    );

    const dentists = availableBlock?.dentists || [];

    const isExistingHead = dentists.some((item) =>
      isFirstContainedBySecondBlockNumbers(
        headRange,
        item.headAvailableBlockNumbers
      )
    );

    const isExistingTail = dentists.some((item) =>
      isFirstContainedBySecondBlockNumbers(
        tailRange,
        item.tailAvailableBlockNumbers
      )
    );

    if (isExistingHead && isExistingTail) {
      return {
        id: null,
        timeSlot,
        timeSlotType: null,
      };
    }

    if (isExistingHead && !isExistingTail) {
      return {
        id: null,
        timeSlot,
        timeSlotType: EXAM_PLACEMENT.HEAD,
      };
    }

    if (isExistingTail && !isExistingHead) {
      return {
        id: null,
        timeSlot,
        timeSlotType: EXAM_PLACEMENT.TAIL,
      };
    }

    return {
      id: null,
      timeSlot,
      timeSlotType: null,
    };
  }
};
