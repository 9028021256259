import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { IFilterState } from 'interfaces/filterTypes';

const initialState: IFilterState = {
  service: '',
  date: moment().format('YYYY-MM-DD'),
  location: {
    name: '',
    lat: 0,
    lng: 0,
    placeId: '',
    timezone: '',
  },
};

export const filterFields = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<IFilterState>) => {
      const { location, ...rest } = action.payload;
      const timezone = location.timezone || state.location.timezone;

      return {
        ...state,
        ...rest,
        location: {
          ...state.location,
          ...location,
          timezone,
        },
      };
    },
    updateTimezone: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        location: {
          ...state.location,
          timezone: action.payload,
        },
      };
    },
    clearFilters: () => {
      return { ...initialState };
    },
  },
});

export const { setFilter, updateTimezone, clearFilters } = filterFields.actions;
export default filterFields.reducer;
