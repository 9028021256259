const addToCartTracking = (pixelId?: string | null) => {
  if (!pixelId) return;
  (window as any)?.fbq?.('trackSingle', pixelId, 'AddToCart');
};

const initiateCheckoutTracking = (pixelId?: string | null) => {
  if (!pixelId) return;
  (window as any)?.fbq?.('trackSingle', pixelId, 'InitiateCheckout');
};

const scheduleTracking = (pixelId?: string | null) => {
  if (!pixelId) return;
  (window as any)?.fbq?.('trackSingle', pixelId, 'Schedule');
};

export { addToCartTracking, initiateCheckoutTracking, scheduleTracking };
