import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISearchBarState {
  service?: boolean,
  date?: boolean,
  location?: boolean,
}

const initialState: ISearchBarState = {
  service: false,
  date: false,
  location: false,
};

export const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState,
  reducers: {
    setSearchBarFieldsIsOpen: (state, action: PayloadAction<ISearchBarState>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSearchBarFieldsIsOpen } = searchBarSlice.actions;
export default searchBarSlice.reducer;
