import { FC, useState } from 'react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { ReactComponent as DropdownVector } from 'assets/icons/dropdown-vector.svg';
import styles from './NavigationLink.module.scss';
import { logoutThunk } from 'redux/authSlice';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import LoginOptionDialog from 'components/LoginOptionDialog/LoginOptionDialog';
import { useHistory } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from 'services/firebase';
import { ROUTES } from 'routes/config';
import Avatar from '../Avatar/Avatar';

interface NavigationLinkProps {}

const NavigationLink: FC<NavigationLinkProps> = () => {
  const { isLoggedIn } = useAppSelector((state) => state.authSlice);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();

  const onLogout = async () => {
    await signOut(auth);
    await dispatch(logoutThunk());
    setAnchorEl(null);
  };

  return isLoggedIn ? (
    <>
      <button
        className={styles['avatar-container']}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Avatar />
        <DropdownVector />
      </button>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '-40, 10',
          },
        }}
        className={styles['popper']}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <button className={styles['drop-down']} onClick={onLogout}>
            Sign out
          </button>
        </ClickAwayListener>
      </Popper>
    </>
  ) : (
    <>
      <LoginOptionDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onNavigateEmail={() => history.push(ROUTES.SIGN_UP_PAGE)}
      />
      <DentalButton
        variant="text"
        className={styles['sign-in']}
        onClick={() => history.push(ROUTES.SIGN_IN_PAGE)}
      >
        Sign in
      </DentalButton>
      <DentalButton
        variant="outlined"
        className={styles['sign-up']}
        onClick={() => setIsDialogOpen(true)}
      >
        Sign up
      </DentalButton>
    </>
  );
};

export default NavigationLink;
