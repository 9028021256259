import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './LoadingScreen.module.scss';

const LoadingScreen = () => {
  return (
    <div className={styles['backdrop']}>
      <div className={styles['container']}>
        <CircularProgress className={styles['circular-progress']} />
        <span className={styles['info']}>Loading...</span>
      </div>
    </div>
  );
};

export default LoadingScreen;
