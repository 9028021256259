import React from 'react';

import { PATIENT_LIST_TYPE } from 'components/BookingForm/Step3/Step3';
import { BookableSelectedPatient, Patient } from 'services/APIs';
import { formatFullPhoneNumber } from 'utils/formatPhoneNumber';

import styles from './DetailDescriptionSection.module.scss';

interface IProps {
  type: (typeof PATIENT_LIST_TYPE)[keyof typeof PATIENT_LIST_TYPE];
  isFamilyBooking?: boolean;
  patients: (Patient | BookableSelectedPatient)[];
  clinicInfo: {
    name: string;
    phoneNumber: string;
    email: string;
  };
}

const DetailDescriptionSection = ({
  type,
  patients,
  clinicInfo,
  isFamilyBooking = false,
}: IProps) => {
  const bookablePatients = patients.filter((item: any) => item.isBookable);

  const newPatientsNotInPast = patients.filter(
    (item) => item.isBookable === false && item.appointment?.isInPast === false
  );

  const newPatientsInPast = patients.filter(
    (item) => item.isBookable === false && item.appointment?.isInPast === true
  );

  const patientsWithApptAtTheSameTime = patients.filter(
    (item) =>
      item.isBookable === false &&
      item.isExistingApptWithinRequestedTime === true
  );

  if (
    type === PATIENT_LIST_TYPE.NORMAL &&
    bookablePatients.length !== 0 &&
    isFamilyBooking === false
  ) {
    return (
      <div className={styles['detail-section']}>
        <div className={styles['title']}>
          {bookablePatients.length === 1 && 'We found you'}
          {bookablePatients.length > 1 && (
            <>
              <div>We found {bookablePatients.length} matches </div>
              <div className={styles['title-select']}>
                Please select a correct patient
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  if (
    type === PATIENT_LIST_TYPE.INCOMPLETE_FUTURE_APPT &&
    newPatientsNotInPast.length > 1
  ) {
    return (
      <div className={styles['detail-section']}>
        <div className={styles['title']}>
          <div>Patients with future appointments</div>
          <div className={styles['title-select']}>
            Please contact {clinicInfo.name} at&nbsp;
            <b>{formatFullPhoneNumber(clinicInfo.phoneNumber)}</b> or&nbsp;
            <b>{clinicInfo.email}</b> to schedule another appointment for any of
            the user below.
          </div>
        </div>
      </div>
    );
  }

  if (
    type === PATIENT_LIST_TYPE.INCOMPLETE_FUTURE_APPT &&
    newPatientsNotInPast.length === 1
  ) {
    return (
      <div className={styles['detail-section']}>
        <div className={styles['title']}>
          <div>Looks like this patient already has a future appointment</div>
          <div className={styles['title-select']}>
            Please contact {clinicInfo.name} at&nbsp;
            <b>{formatFullPhoneNumber(clinicInfo.phoneNumber)}</b> or&nbsp;
            <b>{clinicInfo.email}</b> to schedule another appointment.
          </div>
        </div>
      </div>
    );
  }

  if (
    type === PATIENT_LIST_TYPE.INCOMPLETE_PAST_APPT &&
    newPatientsInPast.length > 1
  ) {
    return (
      <div className={styles['detail-section']}>
        <div className={styles['title']}>
          <div>Patients with incomplete appointments</div>
          <div className={styles['title-select']}>
            Please contact {clinicInfo.name} at&nbsp;
            <b>{formatFullPhoneNumber(clinicInfo.phoneNumber)}</b> or&nbsp;
            <b>{clinicInfo.email}</b> to schedule another appointment for any of
            the user below.
          </div>
        </div>
      </div>
    );
  }

  if (
    type === PATIENT_LIST_TYPE.INCOMPLETE_PAST_APPT &&
    newPatientsInPast.length === 1
  ) {
    return (
      <div className={styles['detail-section']}>
        <div className={styles['title']}>
          <div>
            Looks like this patient already has an incomplete appointment
          </div>
          <div className={styles['title-select']}>
            Please contact {clinicInfo.name} at&nbsp;
            <b>{formatFullPhoneNumber(clinicInfo.phoneNumber)}</b> or&nbsp;
            <b>{clinicInfo.email}</b> to schedule another appointment.
          </div>
        </div>
      </div>
    );
  }

  if (
    type === PATIENT_LIST_TYPE.SAME_TIME_APPT &&
    patientsWithApptAtTheSameTime.length >= 1
  ) {
    return (
      <div className={styles['detail-section']}>
        <div className={styles['title']}>
          <div>Patients with another appointment at the same time</div>
          <div className={styles['title-select']}>
            Please go back and change the time of this booking to schedule
            another appointment for any of the users below.
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default DetailDescriptionSection;
