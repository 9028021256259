export const FULL_NAME = 'Full name';
export const PHONE_NUMBER = 'Phone number';
export const EMAIL = 'Email';
export const MONTH = 'Month';
export const DAY = 'Day';
export const YEAR = 'Year';
export const MILES_IN_METER = 1609.34;
export const KILOMETER_IN_METER = 1000;
export const PRACTITIONER_SEARCH_TITLE = 'No preference';

export const REQUEST_CODE_OK = 201;

export const localStorageKey = {
  BOOKING_CONFIRMATION_DATA: 'bookingConfirmationData',
};

export const MAP_ATTRIBUTION =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';
export const MAP_PROVIDER_URL =
  'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png';
export const ALLOWED_PRACTITIONER_STUDIES = process.env
  .REACT_APP_DENTISTRY_QUALIFICATIONS
  ? process.env.REACT_APP_DENTISTRY_QUALIFICATIONS.split(',')
  : [];

export const MOBILE_OS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  OTHER: 'other',
};

export const CUSTOM_AUTH_HEADER = {
  FIRSTIN: 'firstin',
  OTP: 'otp',
};

export const LOADING_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
};

export const AUTH_SYS_TYPE = {
  PASSWORD: 'password',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const PROVIDER_ID = {
  GOOGLE: 'google.com',
  FACEBOOK: 'facebook.com',
};

export const GOOGLE_PEOPLE_API =
  'https://people.googleapis.com/v1/people/me?personFields=names,birthdays,phoneNumbers';

export const GOOGLE_ADD_BIRTHDAY_READ =
  'https://www.googleapis.com/auth/user.birthday.read';

export const FACEBOOK_BASE_API =
  'https://graph.facebook.com/v15.0/me?fields=first_name,last_name,birthday';

export const GOOGLE_ADD_PROFILE_READ = 'profile';

export const GOOGLE_ADD_EMAIL_READ = 'email';

export const SOCIAL_POPUP_CLOSED_CODES = [
  'auth/popup-closed-by-user',
  'auth/user-cancelled',
  'auth/cancelled-popup-request',
];

export const GOOGLE_GEO_LOCATION_API =
  'https://www.googleapis.com/geolocation/v1/geolocate';

export const CLOSED = 'Closed';
export const HOLIDAY = 'Holiday';
