import { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from '../FilterList.module.scss';
import mainStyles from './AllFilterButton.module.scss';
import CustomPopper from '../CustomPopper/CustomPopper';
import AllFilter from './AllFilter/AllFilter';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { batch } from 'react-redux';
import { setSearchResultFilter } from 'redux/searchResultFilterSlice';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import useIsMobile from 'hooks/useIsMobile';
import CustomDialog from '../CustomDialog/CustomDialog';

const AllFilterButton = () => {
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const searchResultFilterSlice = useAppSelector(
    (state) => state.searchResultFilterSlice
  );

  const dispatch = useAppDispatch();

  const [sort, setSort] = useState(searchResultFilterSlice.sort);
  const [gender, setGender] = useState(searchResultFilterSlice.gender);
  const [minRating, setMinRating] = useState(searchResultFilterSlice.minRating);
  const [timeBlocks, setTimeBlocks] = useState(
    searchResultFilterSlice.timeBlocks
  );

  const isResetBtnDisabled =
    !sort && gender.length === 0 && minRating === 0 && timeBlocks.length === 0;

  const numberOfAppliedFilters = searchResultFilterSlice.numberOfAppliedFilters;

  const onResetClicked = () => {
    setSort(null);
    setGender([]);
    setMinRating(0);
    setTimeBlocks([]);
  };

  const onClose = () => {
    const { gender, minRating, sort, timeBlocks } = searchResultFilterSlice;
    setSort(sort);
    setGender(gender);
    setMinRating(minRating);
    setTimeBlocks(timeBlocks);
    setAnchorEl(null);
  };

  const onApplyClicked = () => {
    // PREVENT UPDATE IF NO CHANGED
    const isValueNotChanged =
      sort === searchResultFilterSlice.sort &&
      minRating === searchResultFilterSlice.minRating &&
      gender === searchResultFilterSlice.gender &&
      timeBlocks === searchResultFilterSlice.timeBlocks;

    if (isValueNotChanged) {
      return onClose();
    }

    batch(() => {
      setAnchorEl(null);
      dispatch(setSearchResultFilter({ sort, gender, minRating, timeBlocks }));
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
    });
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <button
          data-filter="all"
          className={styles['btn']}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          data-open={!!anchorEl}
          data-active={numberOfAppliedFilters > 0}
        >
          All Filters{' '}
          {numberOfAppliedFilters > 0 ? `(${numberOfAppliedFilters})` : ''}
        </button>
        {isMobile ? (
          <CustomDialog
            isAllFilter
            open={Boolean(anchorEl)}
            isResetBtnDisabled={isResetBtnDisabled}
            onResetClicked={onResetClicked}
            onApplyClicked={onApplyClicked}
            onClose={onClose}
          >
            <div className={mainStyles['wrapper']}>
              <button onClick={onClose}>
                <CloseIcon />
              </button>
              <AllFilter
                sort={sort}
                setSort={setSort}
                gender={gender}
                setGender={setGender}
                minRating={minRating}
                setMinRating={setMinRating}
                timeBlocks={timeBlocks}
                setTimeBlocks={setTimeBlocks}
              />
            </div>
          </CustomDialog>
        ) : (
          <CustomPopper
            isAllFilter
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            isResetBtnDisabled={isResetBtnDisabled}
            onResetClicked={onResetClicked}
            onApplyClicked={onApplyClicked}
          >
            <div className={mainStyles['wrapper']}>
              <button onClick={onClose}>
                <CloseIcon />
              </button>
              <AllFilter
                sort={sort}
                setSort={setSort}
                gender={gender}
                setGender={setGender}
                minRating={minRating}
                setMinRating={setMinRating}
                timeBlocks={timeBlocks}
                setTimeBlocks={setTimeBlocks}
              />
            </div>
          </CustomPopper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default AllFilterButton;
