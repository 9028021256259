import React from 'react';
import ShowMoreText from 'react-show-more-text';

import styles from './BioInformation.module.scss';
import { IPractitionerDetails } from 'interfaces/practitionerDetailsTypes';
import getPractitionerName from 'utils/getPractitionerName';
import { filterSpecialties } from 'utils/common';

interface IBioInformationProps {
  data: IPractitionerDetails;
}

const BioInformation = React.forwardRef<HTMLDivElement, IBioInformationProps>(
  ({ data }: IBioInformationProps, ref) => {
    const renderSpecialty = (specialty: string[]) => {
      const displaySpecialty = specialty.join(', ');
      return <span>{displaySpecialty}</span>;
    };

    const filteredSpecialties = filterSpecialties(data.specialty);

    return data ? (
      <div className={styles.container}>
        <div
          className={`${styles['bold-title']} ${styles['head-line']}`}
          ref={ref}
        >{`About ${getPractitionerName(data)}`}</div>
        <div className={styles.body}>
          <ShowMoreText
            lines={5}
            more="Show more"
            less="Show less"
            expanded={false}
            className={styles.link}
          >
            {data.description}
          </ShowMoreText>
        </div>
        <div className={styles['bold-title']}>Specialties</div>
        {renderSpecialty(filteredSpecialties.map((item) => item.name))}
      </div>
    ) : null;
  }
);

export default BioInformation;
