import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, {
  AutocompleteRenderOptionState,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { ReactComponent as DropdownIcon } from 'assets/icons/arrow-down.svg';
import { PractitionerOptions } from 'components/PractitionerOptions/PractitionerOptions';

import CustomPopper from './CustomPopper';
import useStyles from './useStyles';
import { FilterOptionsState } from '@material-ui/lab';

interface Option {
  id: string;
  title: string;
  duration?: number;
}

interface Props {
  options: any[];
  onChangeServiceOption?: (option: any) => void;
  isOpen: boolean;
  onChangeIsOpen: (isOpen: boolean) => void;
  optionValue: any | null;
  inputValue?: string;
  defaultValue?: Option | null;
  setOptionValue?: (value: any) => void;
  setInputValue?: (inputValue: string) => void;
  setError?: (error: string) => void;
  renderOption?: (
    option: any,
    state: AutocompleteRenderOptionState,
    isHightlighting: boolean,
    classes: ClassNameMap<string>
  ) => JSX.Element;
  defaultOption?: any;
  height?: string;
  isPractitionerDropdown?: boolean;
  isReasonDropdown?: boolean;
  isSingular?: boolean;
  placeholder?: string;
  getOptionDisabled?: any;
  groupBy?: any;
  renderGroup?: any;
  isForceFullWidth?: boolean;
}

export type AutoCompleteComponent = React.FC<Props>;

const MAX_CHARACTERS_COUNT = 50;

const AutoComplete: AutoCompleteComponent = (props) => {
  const {
    onChangeServiceOption = () => {},
    optionValue,
    setError = () => {},
    height,
    isPractitionerDropdown = false,
    isReasonDropdown = false,
    isSingular = false,
    placeholder,
    getOptionDisabled,
    groupBy,
    renderGroup,
    isForceFullWidth = false,
  } = props;
  const classes = useStyles();

  const [isHightlighting, setIsHightlighting] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(
    optionValue?.title || ''
  );
  const [optionState, setOptionState] = useState<any>(optionValue);

  let inputRef = useRef<HTMLInputElement>();

  const allOptions = props.defaultOption
    ? [props.defaultOption, ...props.options]
    : props.options;

  const _filterOptions = createFilterOptions() as (
    options: { title: string }[],
    state: FilterOptionsState<{ title: string }>
  ) => { title: string }[];
  const filterOptions = props.defaultOption
    ? (options: any, state: FilterOptionsState<any>) => {
        const results = _filterOptions(options, state) as {
          id: string;
          title: string;
        }[];
        return results;
      }
    : _filterOptions;

  const onChange = (e: any, value: any) => {
    setOptionState(value);
    setInputValue(value?.title || '');
    setIsHightlighting(false);
    if (value) {
      onChangeServiceOption(value);
    } else {
      if (props.optionValue) {
        setOptionState(props.optionValue);
      }
    }
  };

  const onClose = () => {
    props.onChangeIsOpen(false);
    if (optionState) {
      setInputValue(optionState.title);
    }
  };

  const handleInputChange = (e: any, value: string) => {
    if (value === '') {
      setError('Select Service');
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const onFocus = () => {
      setInputValue('');
    };

    const onBlur = () => {
      setIsHightlighting(false);
      if (!optionState) {
        setError('Select Service');
      } else {
        setError('');
      }
    };

    return (
      <TextField
        {...params}
        inputRef={(input) => {
          inputRef.current = input;
        }}
        variant="outlined"
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{
          ...params.inputProps,
          maxLength: MAX_CHARACTERS_COUNT,
          value: inputValue,
          ...(height && { style: { height, cursor: 'pointer' } }),
          readOnly: true,
        }}
        placeholder={placeholder || optionValue.title}
      />
    );
  };

  useEffect(() => {
    if (optionState) {
      inputRef.current?.blur();
      setInputValue(optionState.title);
      if (optionValue.id !== optionState.id) {
        onChangeServiceOption(optionState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionState]);

  useEffect(() => {
    if (optionState) {
      if (optionValue?.id !== optionState?.id) {
        // eslint-disable-next-line no-console
        setOptionState(optionValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue]);

  return (
    <Autocomplete
      openOnFocus
      fullWidth
      className={classes.root}
      options={allOptions}
      getOptionSelected={(option: any, value: any) => {
        return option.id === value.id || option.title === value.title;
      }}
      popupIcon={<DropdownIcon />}
      closeIcon={<></>}
      onInputChange={handleInputChange}
      onChange={onChange}
      onClose={onClose}
      value={optionState}
      renderInput={renderInput}
      PopperComponent={(props) => (
        <CustomPopper
          {...props}
          isPractitionerDropdown={isPractitionerDropdown}
          isReasonDropdown={isReasonDropdown}
          isSingular={isSingular}
          isForceFullWidth={isForceFullWidth}
        />
      )}
      getOptionLabel={(option: { title: string }) => option.title}
      filterOptions={filterOptions}
      renderOption={(option: any, state) =>
        props.renderOption ? (
          props.renderOption(option, state, isHightlighting, classes)
        ) : (
          <PractitionerOptions
            option={option}
            state={state}
            isHightlighting={isHightlighting}
            classes={classes}
          />
        )
      }
      defaultValue={props.defaultValue}
      getOptionDisabled={getOptionDisabled}
      groupBy={groupBy}
      renderGroup={renderGroup}
    />
  );
};

export default AutoComplete;
