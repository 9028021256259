import { FC } from 'react';
import Sort from '../../SortButton/Sort/Sort';
import Rating from '../../RatingButton/Rating/Rating';
import Gender from '../../GenderButton/Gender/Gender';
import TimeOfDay from '../../TimeOfDayButton/TimeOfDay/TimeOfDay';
import styles from './AllFilter.module.scss';
interface AllFilterProps {
  sort: 'distance' | 'rating' | null;
  setSort: React.Dispatch<React.SetStateAction<'distance' | 'rating' | null>>;
  gender: ('male' | 'female' | 'non-binary')[];
  setGender: React.Dispatch<
    React.SetStateAction<('male' | 'female' | 'non-binary')[]>
  >;
  minRating: number;
  setMinRating: React.Dispatch<React.SetStateAction<number>>;
  timeBlocks: number[][];
  setTimeBlocks: React.Dispatch<React.SetStateAction<number[][]>>;
}

const AllFilter: FC<AllFilterProps> = ({
  sort,
  setSort,
  gender,
  setGender,
  minRating,
  setMinRating,
  timeBlocks,
  setTimeBlocks,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['section']}>
        <div className={styles['section-header']}>
          <h3>Sort</h3>
          {sort && <button onClick={() => setSort(null)}>Reset</button>}
        </div>
        <Sort value={sort} setValue={setSort} />
      </div>
      <div className={styles['section']}>
        <div className={styles['section-header']}>
          <h3>Rating</h3>
          {minRating !== 0 && (
            <button onClick={() => setMinRating(0)}>Reset</button>
          )}
        </div>
        <Rating value={minRating} setValue={setMinRating} />
      </div>
      <div className={styles['section']}>
        <div className={styles['section-header']}>
          <h3>Gender</h3>
          {gender.length > 0 && (
            <button onClick={() => setGender([])}>Reset</button>
          )}
        </div>
        <Gender value={gender} setValue={setGender} />
      </div>
      <div className={styles['section']}>
        <div className={styles['section-header']}>
          <h3>Time Of Day</h3>
          {timeBlocks.length > 0 && (
            <button onClick={() => setTimeBlocks([])}>Reset</button>
          )}
        </div>
        <TimeOfDay value={timeBlocks} setValue={setTimeBlocks} />
      </div>
    </div>
  );
};

export default AllFilter;
