import axios, { AxiosError } from 'axios';
import {
  ICreateBookingData,
  ICreateFamilyBookingData,
} from 'interfaces/createBookingDataTypes';
import moment from 'moment';
import { SelectedPatient } from 'redux/familyBookingSlice';
import { CUSTOM_AUTH_HEADER } from 'utils/constants/common';
import { loadAuthToken, removeAuthToken, saveAuthToken } from 'utils/storage';

export interface Patient {
  id: string;
  createdAt: string;
  updatedAt: string;
  externalId: string;
  pmsType: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  dob: string;
  address: string;
  useInsurance: true;
  insuranceImgFront: string;
  insuranceImgBack: string;
  name: string;
  isNewPatient: boolean;
  isPmsNewPatient: boolean;
  isBookable: boolean;
  isExistingApptWithinRequestedTime?: boolean;
  appointment?: {
    id: string;
    appointmentDate: string;
    startTime: string;
    service: string;
    doctor: string;
    status: string;
    timezone: string;
    isInPast: boolean;
  };
}

export interface UserProfile {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  authenticationType: string;
  firstName: string;
  lastName: string;
  dob: string;
  phoneNumber: string;
  name: string;
}

export interface SearchNextAvailabilityPractitioners {
  metadata: {
    total: number;
    limit?: number;
    page?: number;
  };
  data: {
    doctorId: string;
    serviceId: string;
    clinicId?: string;
    serviceDuration?: number;
    nextAvailabilityDate: string | null;
    blocks: number[];
  }[];
}

export interface SearchNextAvailabilityClinics {
  metadata: {
    total: number;
    limit?: number;
    page?: number;
  };
  data: {
    clinicId: string;
    serviceId: string;
    serviceDuration?: number;
    doctors: {
      doctorId: string;
      nextAvailabilityDate: string | null;
      blocks: number[];
    }[];
  }[];
}

export interface BookablePatient {
  patientId: string;
  isBookable: boolean;
  appointment?: {
    id: string;
    appointmentDate: string;
    startTime: string;
    service: string;
    doctor: string;
    status: string;
    timezone: string;
    isInPast: boolean;
  };
  isExistingApptWithinRequestedTime?: boolean;
}

export interface BookablePatientWithoutService {
  patientId: string;
  services: {
    id: string;
    isBookable: boolean;
  }[];
}

export type BookableSelectedPatient = SelectedPatient &
  Omit<BookablePatient, 'patientId'>;

const authenticatedAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BE_URL,
});

authenticatedAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const refreshToken = loadAuthToken()?.refreshToken;

      if (!refreshToken) {
        return Promise.reject('Unauthorized');
      }

      try {
        const {
          data: { accessToken, refreshToken: newRefreshToken },
        } = await axios.post<{ accessToken: string; refreshToken: string }>(
          '/user/auth/refresh-token',
          undefined,
          {
            headers: {
              Authorization: `JWT ${refreshToken}`,
            },
            baseURL: process.env.REACT_APP_AXIOS_BE_URL,
          }
        );
        saveAuthToken({ accessToken, refreshToken: newRefreshToken });

        const response = await axios.request({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `JWT ${accessToken}`,
          },
        });
        return response;
      } catch (error: any) {
        removeAuthToken();
        return Promise.reject('Unauthorized');
      }
    }
    throw error;
  }
);

const getXAuth = (isLoggedIn: boolean, { otpToken }: { otpToken?: string }) => {
  let XAuth = '';

  if (isLoggedIn) {
    XAuth = CUSTOM_AUTH_HEADER.FIRSTIN;
  } else if (otpToken) {
    XAuth = CUSTOM_AUTH_HEADER.OTP;
  }

  return XAuth;
};

export const getPatientsAPI = async (
  payload: {
    clinicId: string;
    serviceId: string;
    phoneNumber?: string;
    dob?: string;
    startTime: string;
    appointmentDate: string;
  },
  {
    otpToken, // return by otp api
  }: {
    otpToken?: string;
  }
) => {
  const isLoggedIn = !!loadAuthToken()?.accessToken;
  const token = loadAuthToken()?.accessToken || otpToken;

  let XAuth = getXAuth(isLoggedIn, { otpToken });

  const response = await authenticatedAxiosInstance.get<Patient[]>(
    '/patients',
    {
      headers: {
        Authorization: `JWT ${token}`,
        'X-Auth': XAuth,
      },
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: payload,
    }
  );

  return response.data;
};
export const getFamilyBookingPatientsAPI = async (
  payload: {
    clinicId: string;
    phoneNumber: string;
    dob: string;
  },
  {
    otpToken, // return by otp api
  }: {
    otpToken?: string;
  }
) => {
  const isLoggedIn = !!loadAuthToken()?.accessToken;
  const token = loadAuthToken()?.accessToken || otpToken;

  let XAuth = getXAuth(isLoggedIn, { otpToken });

  const response = await authenticatedAxiosInstance.get<Patient[]>(
    '/patients/family-booking',
    {
      headers: {
        Authorization: `JWT ${token}`,
        'X-Auth': XAuth,
      },
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: payload,
    }
  );

  return response.data;
};

export const createBookingAPI = async (
  payload: ICreateBookingData,
  {
    otpToken, // return by otp api
    tmpAccessToken,
  }: {
    otpToken?: string;
    tmpAccessToken?: string;
  }
) => {
  const isLoggedIn = !!tmpAccessToken || !!loadAuthToken()?.accessToken;
  const token = tmpAccessToken || loadAuthToken()?.accessToken || otpToken;

  let XAuth = getXAuth(isLoggedIn, { otpToken });

  const response = await authenticatedAxiosInstance.post('/bookings', payload, {
    headers: {
      Authorization: `JWT ${token}`,
      'X-Auth': XAuth,
    },
    baseURL: process.env.REACT_APP_AXIOS_BE_URL,
  });

  return response.data;
};

export const verifySetupAccountTokenAPI = async (
  token: string,
  email: string
) => {
  try {
    await axios.post(
      '/user/auth/verify',
      { token, email },
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const verifyResetPasswordTokenAPI = async (
  token: string,
  email: string
) => {
  try {
    await axios.post(
      '/user/auth/reset-password/verify',
      { token, email },
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const checkEmail = async (email: string) => {
  const result: {
    data: null | {
      isExistingEmail: boolean;
      isVerified: boolean;
    };
    error: null | AxiosError;
  } = { data: null, error: null };
  try {
    const request = await axios.post<{
      isExistingEmail: boolean;
      isVerified: boolean;
    }>(
      '/user/auth/check-email',
      { email },
      { baseURL: process.env.REACT_APP_AXIOS_BE_URL }
    );
    result.data = request.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      result.error = error;
    }
  }
  return result;
};

export const getUserProfile = async () => {
  const accessToken = loadAuthToken()?.accessToken;
  const response = await authenticatedAxiosInstance.get<UserProfile>(
    'user/auth/profile',
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      headers: {
        Authorization: `JWT ${accessToken}`,
        'X-Auth': CUSTOM_AUTH_HEADER.FIRSTIN,
      },
    }
  );
  return response.data;
};

export const sendResetPasswordLink = async (email: string) => {
  try {
    await axios.post<{ message: string }>(
      '/user/auth/reset-password-link',
      { email },
      { baseURL: process.env.REACT_APP_AXIOS_BE_URL }
    );
  } catch (error: any) {
    let message = 'Something went wrong. Please try again later';

    if (error.response.data) {
      const errorMessage = error.response.data.message;
      message =
        typeof errorMessage === 'string' ? errorMessage : errorMessage[0];
    }

    throw new Error(message);
  }
};

export const verifyEmailAddress = async (
  token: string | null,
  email: string | null
) => {
  try {
    const response = await axios.post<{
      hasAlreadyVerified: boolean;
      message: string;
    }>(
      '/user/auth/email/verify',
      { token, email },
      { baseURL: process.env.REACT_APP_AXIOS_BE_URL }
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      return error.response?.status;
    }
  }
};

export const resendEmailVerificationLink = async (email: string | null) => {
  try {
    await axios.post(
      '/user/auth/verify-email-link',
      { email },
      { baseURL: process.env.REACT_APP_AXIOS_BE_URL }
    );
    return true;
  } catch (error: any) {
    return false;
  }
};

export const getNextAvailabilityByPractitioners = async ({
  practitionerIds,
  date,
  serviceId,
  filter = {},
}: {
  practitionerIds: string[];
  date: string;
  serviceId: string;
  filter?: {
    sort?: string;
    sortBy?: string;
    limit?: number;
    page?: number;
    latitude?: number;
    longitude?: number;
    distances?: number[];
    timeBlocks?: number[];
    rating?: number[];
    includedNonRated?: boolean;
    genders?: ('male' | 'female')[];
    specialties?: string[];
  };
}) => {
  try {
    const response = await axios.get<SearchNextAvailabilityPractitioners>(
      '/practitioners/next-availability',
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          doctorIds: practitionerIds,
          date,
          serviceId,
          ...filter,
          timeBlocks:
            (filter?.timeBlocks ?? []).length > 0 ? filter?.timeBlocks : [0],
        },
      }
    );

    const { data } = response.data;

    const nextAvailabilitiesByPractitioners = [...data].filter(
      (item) => item.nextAvailabilityDate !== null
    );

    const nextAvailabilityDates = nextAvailabilitiesByPractitioners.map(
      (item) => item.nextAvailabilityDate
    );

    nextAvailabilityDates.sort((firstItem, secondItem) => {
      const firstItemMoment = moment(firstItem, 'YYYY-MM-DD');
      const secondItemMoment = moment(secondItem, 'YYYY-MM-DD');

      if (firstItemMoment.isSameOrBefore(secondItemMoment, 'days')) return -1;
      return 1;
    });

    const mostRecentDate = nextAvailabilityDates[0];

    const mostRecentNextAvailabilitiesByPractitioners =
      nextAvailabilitiesByPractitioners.filter(
        (item) => item.nextAvailabilityDate === mostRecentDate
      );

    return mostRecentNextAvailabilitiesByPractitioners;
  } catch (error) {
    return [];
  }
};

export const getNextAvailabilityByClinics = async ({
  clinicIds,
  date,
  serviceId,
  filter = {},
}: {
  clinicIds: string[];
  date: string;
  serviceId: string;
  filter?: {
    sort?: string;
    sortBy?: string;
    limit?: number;
    page?: number;
    latitude?: number;
    longitude?: number;
    distances?: number[];
    timeBlocks?: number[];
    rating?: number[];
    includedNonRated?: boolean;
  };
}): Promise<
  {
    clinicId: string;
    serviceId: string;
    serviceDuration: number;
    doctorId: string;
    nextAvailabilityDate: string;
    blocks: number[];
  }[]
> => {
  try {
    const response = await axios.get<SearchNextAvailabilityClinics>(
      '/clinics/next-availability',
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          clinicIds,
          date,
          serviceId,
          ...filter,
          timeBlocks:
            (filter.timeBlocks ?? []).length > 0 ? filter?.timeBlocks : [0],
        },
      }
    );

    const { data } = response.data;

    const tmpData = [...data].filter((item) => item.doctors.length !== 0);

    const nextAvailabilitiesByPractitioners: {
      clinicId: string;
      serviceId: string;
      serviceDuration: number;
      doctorId: string;
      nextAvailabilityDate: string;
      blocks: number[];
    }[] = tmpData.reduce((accum: any, currentValue) => {
      for (let i = 0; i < currentValue.doctors.length; i++) {
        const doctor = currentValue.doctors[i];

        if (doctor.nextAvailabilityDate) {
          const tmp = {
            clinicId: currentValue.clinicId,
            serviceId: currentValue.serviceId,
            serviceDuration: currentValue.serviceDuration,
            doctorId: doctor.doctorId,
            nextAvailabilityDate: doctor.nextAvailabilityDate,
            blocks: doctor.blocks,
          };

          accum.push(tmp);
        }
      }

      return accum;
    }, []);

    const nextAvailabilityDates = nextAvailabilitiesByPractitioners.map(
      (item) => item.nextAvailabilityDate
    );

    nextAvailabilityDates.sort((firstItem, secondItem) => {
      const firstItemMoment = moment(firstItem, 'YYYY-MM-DD');
      const secondItemMoment = moment(secondItem, 'YYYY-MM-DD');

      if (firstItemMoment.isSameOrBefore(secondItemMoment, 'days')) return -1;
      return 1;
    });

    const mostRecentDate = nextAvailabilityDates[0];

    const mostRecentNextAvailabilitiesByPractitioners =
      nextAvailabilitiesByPractitioners.filter(
        (item) => item.nextAvailabilityDate === mostRecentDate
      );

    return mostRecentNextAvailabilitiesByPractitioners;
  } catch (error) {
    return [];
  }
};

export const unsubscribeOnlineIntro = async (
  token: string | null,
  email: string | null
) => {
  try {
    const response = await axios.post<{
      hasAlreadyVerified: boolean;
      message: string;
    }>(
      '/patients/unsubscribe',
      { token, email },
      { baseURL: process.env.REACT_APP_AXIOS_BE_URL }
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      return error.response?.status;
    }
  }
};

//------- FAMILY BOOKING------
export const checkBookablePatientAPI = async (
  payload: {
    patientId: string;
    clinicId: string;
    serviceId: string;
    appointmentDate: string;
    startTime: string;
  }[],
  {
    otpToken, // return by otp api
    tmpAccessToken,
  }: {
    otpToken?: string;
    tmpAccessToken?: string;
  }
): Promise<BookablePatient[]> => {
  const isLoggedIn = !!tmpAccessToken || !!loadAuthToken()?.accessToken;
  const token = tmpAccessToken || loadAuthToken()?.accessToken || otpToken;

  let XAuth = getXAuth(isLoggedIn, { otpToken });

  const response = await authenticatedAxiosInstance.post(
    '/patients/services/check-bookable',
    payload,
    {
      headers: {
        Authorization: `JWT ${token}`,
        'X-Auth': XAuth,
      },
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    }
  );

  return response.data;
};

export const checkBookablePatientWithoutServiceAPI = async (
  payload: {
    patientId: string;
    clinicId: string;
  }[],
  {
    otpToken, // return by otp api
    tmpAccessToken,
  }: {
    otpToken?: string;
    tmpAccessToken?: string;
  }
): Promise<BookablePatientWithoutService[]> => {
  const isLoggedIn = !!tmpAccessToken || !!loadAuthToken()?.accessToken;
  const token = tmpAccessToken || loadAuthToken()?.accessToken || otpToken;

  let XAuth = getXAuth(isLoggedIn, { otpToken });

  const response = await authenticatedAxiosInstance.post(
    '/patients/check-bookable',
    payload,
    {
      headers: {
        Authorization: `JWT ${token}`,
        'X-Auth': XAuth,
      },
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    }
  );

  return response.data;
};

export const createFamilyBookingAPI = async (
  payload: ICreateFamilyBookingData,
  {
    otpToken, // return by otp api
    tmpAccessToken,
  }: {
    otpToken?: string;
    tmpAccessToken?: string;
  }
) => {
  const isLoggedIn = !!tmpAccessToken || !!loadAuthToken()?.accessToken;
  const token = tmpAccessToken || loadAuthToken()?.accessToken || otpToken;

  let XAuth = getXAuth(isLoggedIn, { otpToken });

  const response = await authenticatedAxiosInstance.post(
    '/family-booking',
    payload,
    {
      headers: {
        Authorization: `JWT ${token}`,
        'X-Auth': XAuth,
      },
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    }
  );

  return response.data;
};
