import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISearchResultFilterSlice {
  sort: null | 'rating' | 'distance';
  gender: ('male' | 'female' | 'non-binary')[];
  minRating: number;
  timeBlocks: number[][];
  numberOfAppliedFilters: number;
}

const initialState: ISearchResultFilterSlice = {
  sort: null,
  gender: [],
  minRating: 0,
  timeBlocks: [],
  numberOfAppliedFilters: 0,
};

export const searchResultFilterSlice = createSlice({
  name: 'searchResultFilterSlice',
  initialState,
  reducers: {
    setSearchResultFilter: (
      state,
      action: PayloadAction<
        Omit<Partial<ISearchResultFilterSlice>, 'numberOfAppliedFilters'>
      >
    ) => {
      const updatedState = { ...state, ...action.payload };
      let numberOfAppliedFilters = 0;
      if (updatedState.sort) {
        numberOfAppliedFilters += 1;
      }
      if (updatedState.gender.length > 0) {
        numberOfAppliedFilters += 1;
      }
      if (updatedState.minRating !== 0) {
        numberOfAppliedFilters += 1;
      }
      if (updatedState.timeBlocks.length > 0) {
        numberOfAppliedFilters += 1;
      }
      return { ...updatedState, numberOfAppliedFilters };
    },
    resetSearchResultFilter: () => {
      return { ...initialState };
    },
  },
});

export const { setSearchResultFilter, resetSearchResultFilter } =
  searchResultFilterSlice.actions;
export default searchResultFilterSlice.reducer;
