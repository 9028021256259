import React, { FC, useState } from 'react';
import { ReactComponent as TriangleUpIcon } from 'assets/icons/triangle-up.svg';
import { makeStyles } from '@material-ui/core/styles';
import NoAvatarSrc from 'assets/images/no-avatar.svg';
import { IClinicData } from 'redux/clinicDetailsSlice';
import useIsMobile from 'hooks/useIsMobile';

import styles from './ContactInfomation.module.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

interface IContactInformationProps {
  data: IClinicData;
}

interface IInformation {
  data: IClinicData;
  isExpanded?: boolean;
}

interface IClinicNameProps {
  name: string;
}
interface IClinicAvatarProps {
  src: string;
}

const Avatar = ({ src }: IClinicAvatarProps) => {
  return (
    <img
      src={`${src || NoAvatarSrc}`}
      alt="practitioner-avatar"
      className={styles['avatar']}
    />
  );
};

const Name = ({ name }: IClinicNameProps) => {
  return (
    <div className={`${styles.name} ${styles.bold}`}>
      <span>{name}</span>
    </div>
  );
};

const Information = ({ data, isExpanded }: IInformation) => {
  const isMobile = useIsMobile();
  return (
    <div className={styles['flex-row']}>
      <div className={styles['container--left']}>
        <Avatar src={data.avatar} />
        {isMobile && (
          <TriangleUpIcon
            className={
              isExpanded ? styles['rotate-down'] : styles['rotate-right']
            }
          />
        )}
        <Name name={data.name} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
  },
});

const ContactInformation: FC<IContactInformationProps> = (props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  const [isExpanded, setisExpanded] = useState(false);

  return !isMobile ? (
    <div className={styles['container']}>
      <Information data={props.data} />
    </div>
  ) : (
    <Accordion
      classes={classes}
      onChange={(_, expanded) => setisExpanded(expanded)}
    >
      <AccordionSummary>
        <Information data={props.data} isExpanded={isExpanded} />
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

export default ContactInformation;
