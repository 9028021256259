import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { emailValidation } from 'utils/inputValidation';

import FormTextInput from 'components/BookingForm/FormTextInput/FormTextInput';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { sendResetPasswordLink } from 'services/APIs';

import { useAppDispatch } from 'redux/reduxHooks';
import { ROUTES } from 'routes/config';
import { popStep, resetBookingForm } from 'redux/bookingFormSlice';

import styles from './ForgotPasswordForm.module.scss';

interface IProps {
  isInBookingFlow?: boolean;
}

const ForgotPasswordForm = ({ isInBookingFlow = false }: IProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { control, formState, getValues } = useForm({
    mode: 'all',
  });

  const [isLoading, setIsLoading] = useState(false);

  const [isDone, setIsDone] = useState(false);

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const email = getValues('email');
      await sendResetPasswordLink(email);
      setIsDone(true);
    } catch (error: any) {
      setIsDone(true);
    }

    setIsLoading(false);
  };

  const handleClickBackToSignIn = () => {
    if (isInBookingFlow) {
      dispatch(popStep());
    } else {
      history.push(ROUTES.SIGN_IN_PAGE);
    }
  };

  const handleClickContinueWithoutSignIn = () => {
    if (isInBookingFlow) {
      dispatch(resetBookingForm());
    } else {
      // Go back two pages
      history.go(-2);
    }
  };

  return (
    <form className={styles.container} noValidate>
      <h3 className={styles.header}>Forgot Password</h3>

      <div className={styles.body}>
        {!isDone ? (
          <div>
            <div className={styles.description}>
              Have trouble remembering your FirstIn password? Please enter your
              email address below. We will send you a link to reset your
              password.
            </div>
            <FormTextInput
              type="text"
              name="email"
              label="Email address"
              control={control}
              placeholder="Input your email address"
              required="This field is required"
              rules={{
                validate: async (value: string) => {
                  if (!emailValidation(value)) {
                    return 'Invalid email address';
                  }
                },
              }}
            />
          </div>
        ) : (
          <div>
            <div className={styles.description}>
              We have sent a link to your email{' '}
              <span className={styles['magenta-text']}>
                {getValues('email')}
              </span>
              . Please check your inbox to process further.
            </div>
          </div>
        )}

        <div className={styles.footer}>
          {!isDone && (
            <DentalButton
              className={styles['submit-button']}
              disabled={!formState.isValid}
              variant="contained"
              onClick={handleOnSubmit}
              type="submit"
              isLoading={isLoading}
            >
              Reset password
            </DentalButton>
          )}

          <button
            type="button"
            className={styles['btn-link']}
            onClick={handleClickBackToSignIn}
          >
            Back to Sign in
          </button>

          {isDone && (
            <button
              type="button"
              className={styles['btn-link']}
              onClick={handleClickContinueWithoutSignIn}
            >
              Continue without sign in
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
