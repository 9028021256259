import React from 'react';

import { renderTextOption as defaultRenderTextOption } from 'utils/renderTextOptionsAutocomplete';
import AutoComplete from 'components/AppointmentInfoCard/Service/AutoComplete';

import css from './ReasonDropdownSection.module.scss';

interface Props {
  isOpen: boolean;
  options: { id: string; title: string; slug: string }[];
  optionValue: { id: string; title: string; slug: string } | null;
  onChangeIsOpen: (isOpen: boolean) => void;
  onChangeServiceOption: (option: {
    id: string;
    title: string;
    duration: number;
    slug: string;
  }) => void;
  isSingular?: boolean;
  isShownTitle?: boolean;
  placeholder?: string;
  renderTextOption?: any;
  getOptionDisabled?: any;
  groupBy?: any;
  renderGroup?: any;
  isReasonDropdown?: boolean;
  isPractitionerDropdown?: boolean;
  isForceFullWidth?: boolean;
}

const ReasonDropdownSection: React.FC<Props> = ({
  isOpen,
  options,
  optionValue,
  onChangeIsOpen,
  onChangeServiceOption,
  isSingular = false,
  isShownTitle = true,
  placeholder,
  renderTextOption,
  getOptionDisabled,
  groupBy,
  renderGroup,
  isReasonDropdown = false,
  isPractitionerDropdown = false,
  isForceFullWidth = false,
}) => {
  return (
    <div className={css['root']}>
      {isShownTitle && <h4 className={css['title']}>Reason</h4>}
      <AutoComplete
        isOpen={isOpen}
        options={options}
        optionValue={optionValue}
        onChangeIsOpen={onChangeIsOpen}
        renderOption={renderTextOption || defaultRenderTextOption}
        onChangeServiceOption={onChangeServiceOption}
        height="2.1rem"
        isSingular={isSingular}
        placeholder={optionValue ? optionValue.title : placeholder}
        getOptionDisabled={getOptionDisabled}
        groupBy={groupBy}
        renderGroup={renderGroup}
        isReasonDropdown={isReasonDropdown}
        isPractitionerDropdown={isPractitionerDropdown}
        isForceFullWidth={isForceFullWidth}
      />
    </div>
  );
};

export default ReasonDropdownSection;
