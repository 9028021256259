import HomePage from 'pages/HomePage';
import PractitionerDetailsPage from 'pages/PractitionerDetailsPage/PractitionerDetailsPage';
import BookingConfirmPage from 'pages/BookingConfirmPage/BookingConfirmPage';
import { IRoute } from 'interfaces/routeTypes';
import SetupAccountPage from 'pages/SetupAccountPage/SetupAccountPage';
import SignInPage from 'pages/SignInPage/SignInPage';
import SignUpPage from 'pages/SignUpPage/SignUpPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import InvalidPage from 'pages/InvalidPage/InvalidPage';
import VerifyEmailPage from 'pages/VerifyEmailPage/VerifyEmailPage';
import UpdatedSearchResultPage from 'pages/UpdatedSearchResultPage/UpdatedSearchResultPage';
import ClinicDetailsPage from 'pages/ClinicDetailsPage/ClinicDetailsPage';
import UnsubscribePage from 'pages/UnsubscribePage/UnsubscribePage';

export const ROUTES = {
  HOME: '/',
  BOOKING_CONFIRM_PAGE: '/confirmation',
  RESULT_PAGE: '/result',
  PRACTITIONER_PAGE: '/practitioner',
  CLINIC_PAGE: '/clinic',
  SETUP_ACCOUNT_PAGE: '/setup-account',
  SIGN_IN_PAGE: '/sign-in',
  SIGN_UP_PAGE: '/sign-up',
  FORGOT_PASSWORD_PAGE: '/forgot-password',
};

export const publicRoutes: IRoute[] = [
  { path: '/', key: 'HOMEPAGE', component: HomePage, exact: true },
  {
    path: '/confirmation',
    key: 'BOOKINGCONFIRMPAGE',
    component: BookingConfirmPage,
    exact: true,
  },
  {
    path: '/result',
    key: 'RESULTPAGE',
    component: UpdatedSearchResultPage,
    exact: true,
  },
  {
    path: '/setup-account',
    key: 'SETUPACCOUNTPAGE',
    component: SetupAccountPage,
    exact: true,
  },
  {
    path: '/sign-in',
    key: 'SIGNINPAGE',
    component: SignInPage,
    exact: true,
  },
  {
    path: '/sign-up',
    key: 'SIGNUPPAGE',
    component: SignUpPage,
    exact: true,
  },
  {
    path: '/forgot-password',
    key: 'FORGOTPASSWORDPAGE',
    component: ForgotPasswordPage,
    exact: true,
  },
  {
    path: '/reset-password',
    key: 'RESETPASSWORDPAGE',
    component: ResetPasswordPage,
    exact: true,
  },
  {
    path: '/verify',
    key: 'VERIFYEMAIL',
    component: VerifyEmailPage,
    exact: true,
  },
  {
    path: '/unsubscribe',
    key: 'UNSUBSCRIBE',
    component: UnsubscribePage,
    exact: true,
  },
  // NOTE: KEEP dynamic url at the bottom due to route order
  {
    path: '/:clinicSlugName/:practitionerSlugName',
    key: 'PRACTITIONERPAGE',
    component: PractitionerDetailsPage,
    exact: true,
  },
  {
    path: '/:clinicSlugName',
    key: 'CLINICPAGE',
    component: ClinicDetailsPage,
    exact: true,
  },

  {
    path: '*',
    key: 'INVALIDPAGE',
    component: InvalidPage,
  },
];
