import { useEffect, useState } from 'react';
import { Header } from 'components/Layout';
import cx from 'classnames';
import { NavigationBar } from 'components/NavigationBar';
import InnerSearchBar from 'components/NavigationBar/InnerSearchBar/InnerSearchBar';
import ExpandedSearchBar from './ExpandedSearchBar/ExpandedSearchBar';
import SearchResultContent from './SearchResultContent/SearchResultContent';
import SwitchTabBar from './SwitchTabBar/SwitchTabBar';
import { getServicesOptions } from 'redux/searchBarOptionsSlice';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';

import usePopulateSearchParams from './hooks/usePopulateSearchParams';
import useScrollDirection from './hooks/useScrollDirection';

import styles from './UpdatedSearchResultPage.module.scss';

const UpdatedSearchResultPage = () => {
  const [isPracticeTab, setIsPracticeTab] = useState(true);

  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);

  const scrollDirection = useScrollDirection();

  const dispatch = useAppDispatch();

  const { filterSlice, searchBarOptionsSlice } = useAppSelector(
    (state) => state
  );

  usePopulateSearchParams();

  const headerClassName = cx({
    [styles['main-header']]: true,
    [styles['animate-top']]: scrollDirection && scrollDirection === 'down',
  });

  useEffect(() => {
    dispatch(getServicesOptions());
  }, [dispatch]);

  const isLoading =
    searchBarOptionsSlice.service.ids.length === 0 ||
    !filterSlice.location.name;

  return (
    <>
      <nav className={headerClassName} id="header">
        <ExpandedSearchBar
          isSearchBarExpanded={isSearchBarExpanded}
          onClose={() => setIsSearchBarExpanded(false)}
        />
        <Header hasBoxShadow isSearchResultPage>
          <NavigationBar isAuthButton displaySidebar>
            <InnerSearchBar
              onClick={() => {
                setIsSearchBarExpanded(true);
              }}
            />
          </NavigationBar>
        </Header>

        <SwitchTabBar
          isPracticeTab={isPracticeTab}
          setIsPracticeTab={setIsPracticeTab}
        />
      </nav>
      {!isLoading && (
        <SearchResultContent
          isPracticeTab={isPracticeTab}
          key={`${filterSlice.date} ${filterSlice.service} ${filterSlice.location.placeId}`}
        />
      )}
    </>
  );
};

export default UpdatedSearchResultPage;
