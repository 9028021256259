import React, { FC } from 'react';
import cx from 'classnames';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';

import styles from './NavigationBtnControl.module.scss';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface NavigationBtnControlProps {
  onBack: () => void;
  onNext?: () => void;
  isDisabledNextBtn?: boolean;
  isLoading?: boolean;
  nextBtnTitle?: string;
  [key: string]: any;
}

const NavigationBtnControl: FC<NavigationBtnControlProps> = ({
  onBack,
  onNext,
  isDisabledNextBtn,
  isLoading,
  nextBtnTitle = 'Next',
  ...rest
}) => {
  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['action-btn-container']]: true,
    [styles['action-btn-container-iframe']]: isLoadedInsideIframe,
    [styles['fixed']]: isLoadedInsideIframe,
  });

  return (
    <div className={containerClassName}>
      <DentalButton variant="outlined" onClick={onBack}>
        Back
      </DentalButton>
      {onNext && (
        <DentalButton
          {...rest}
          variant="contained"
          className={styles['action-btn']}
          disabled={isDisabledNextBtn}
          onClick={onNext}
          isLoading={isLoading}
        >
          {nextBtnTitle}
        </DentalButton>
      )}
    </div>
  );
};

export default NavigationBtnControl;
