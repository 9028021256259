import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

import DentalButton from 'components/Buttons/DentalButton/DentalButton';
import { Header } from 'components/Layout';
import { NavigationBar } from 'components/NavigationBar';
import { SearchBar } from 'components/SearchBar';
import { FC } from 'react';
import { MOBILE_OS } from 'utils/constants/common';
import { getMobileOS } from 'utils/getMobileOS';
import { MOBILE_SCREEN_WIDTH } from 'utils/screens';
import styles from './ExpandedSearchBar.module.scss';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';

import { setFilter } from 'redux/filterSlice';
import { getTimezoneByPlaceDetails } from 'services/APIs/getTimezoneByPlaceDetails';
import { clearClinicSearchDetailsV2 } from 'redux/clinicsSearchResultsSliceV2';
import { clearPractitionerSearchDetails } from 'redux/practitionerSearchResultSlice';
import { resetSearchResultFilter } from 'redux/searchResultFilterSlice';

interface ExpandedSearchBarProps {
  isSearchBarExpanded: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  container: {
    height: 'unset',
    width: '100vw',
    [`@media (max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
      height: '100vh',
      position: 'relative',
    },
  },
  paper: {
    margin: 0,
    paddingBottom: '3.3rem',
  },
  paperWidthSm: {
    maxWidth: '100vw',
    width: '100vw',
    overflow: 'visible',
  },
  scrollPaper: {
    display: 'block',
  },
  paperScrollPaper: {
    [`@media (max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
      maxHeight: '100vh',
      height: '100vh',
    },
  },
});

const ExpandedSearchBar: FC<ExpandedSearchBarProps> = ({
  isSearchBarExpanded,
  onClose,
}) => {
  const classes = useStyles();

  const { date, location } = useAppSelector((state) => state.filterSlice);

  const dispatch = useAppDispatch();

  const handleOnClickSearch = async (
    selectedFields: {
      service: string;
      date: string;
      location: string;
    },
    place: {
      formatted_address: string;
      geometry: {
        location: {
          lat: () => number;
          lng: () => number;
        };
      };
    }
  ) => {
    let timezone = location.timezone;

    const newLat = place.geometry.location.lat();
    const newLng = place.geometry.location.lng();

    const isLocationSame = location.lat === newLat && location.lng === newLng;

    if (!isLocationSame) {
      timezone = await getTimezoneByPlaceDetails(newLat, newLng);
    }

    batch(() => {
      dispatch(resetSearchResultFilter());
      dispatch(clearClinicSearchDetailsV2());
      dispatch(clearPractitionerSearchDetails());
      dispatch(
        setFilter({
          service: selectedFields.service,
          date: selectedFields.date,
          location: {
            name: place.formatted_address,
            lat: newLat,
            lng: newLng,
            placeId: selectedFields.location,
            timezone,
          },
        })
      );
    });

    onClose();
  };

  const isLocationSearchOrDatePickerShown =
    document.getElementById('location-search-popper') !== null ||
    document.getElementById('date-picker-search-popper') !== null;

  // This is used for android device only because virtual keyboard overlaps popper
  const isHeightExpandedInAndroid =
    getMobileOS() === MOBILE_OS.ANDROID && isLocationSearchOrDatePickerShown;

  return (
    <Dialog
      open={isSearchBarExpanded}
      onClose={onClose}
      classes={{
        container: classes.container,
        paper: classes.paper,
        paperWidthSm: classes.paperWidthSm,
        scrollPaper: classes.scrollPaper,
        paperScrollPaper: classes.paperScrollPaper,
      }}
    >
      <div className={styles['search-container']}>
        <DentalButton className={styles['close-button']} onClick={onClose}>
          <CloseIcon />
        </DentalButton>
        <p className={styles['title-on-mobile']}>Search</p>
      </div>

      <Header>
        <div className={styles['responsive-navbar-container']}>
          <NavigationBar isAuthButton />
        </div>
        <div
          className={styles['search-bar-mobile']}
          style={{
            height: isHeightExpandedInAndroid ? '100%' : 'auto',
            paddingBottom: isHeightExpandedInAndroid ? '700px' : 0,
          }}
        >
          <SearchBar
            isResultPage
            currentDate={date}
            handleOnClickSearch={handleOnClickSearch}
          />
        </div>
      </Header>
    </Dialog>
  );
};

export default ExpandedSearchBar;
