export interface ModeOption {
  id: string;
  label: string;
}

const MYSELF_OPTION: ModeOption = {
  id: 'myself',
  label: 'Booking for myself',
};

const MYSELF_AND_OTHERS_OPTION: ModeOption = {
  id: 'myselfAndOthers',
  label: 'Booking for myself & others',
};

const OTHERS_OPTION: ModeOption = {
  id: 'others',
  label: 'Booking for others',
};

const SAME_DAY_OPTION = {
  id: 'sameDay',
  label: 'On the same day',
};

const MULTIPLE_DAYS_OPTION = {
  id: 'multipleDays',
  label: 'On multiple days',
};

export {
  MYSELF_OPTION,
  MYSELF_AND_OTHERS_OPTION,
  OTHERS_OPTION,
  SAME_DAY_OPTION,
  MULTIPLE_DAYS_OPTION,
};
