import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { Header, Layout } from 'components/Layout';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { NavigationBar } from 'components/NavigationBar';

import { resendEmailVerificationLink, verifyEmailAddress } from 'services/APIs';
import SuccessOrInvalidPage from './SuccessOrInvalidPage';
import styles from './VerifyEmailPage.module.scss';
import { renderToast } from 'components/CustomToast/CustomToast';

const VerifyEmailPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifiedSuccessfully, setIsVerifiedSuccessfully] = useState(false);
  const [isResendCliked, setIsResendClicked] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const token = query.get('token');

  useEffect(() => {
    const handleVerifyEmailAddress = async () => {
      setIsLoading(true);
      const data = await verifyEmailAddress(token, email);
      if (typeof data === 'object') {
        setIsVerifiedSuccessfully(true);
      }
      if ((typeof data === 'number' && data === 404) || !data) {
        history.replace('/404');
      }
      setIsLoading(false);
    };
    handleVerifyEmailAddress();
  }, [token, history, email]);

  const handleResendEmailVerification = async () => {
    setIsButtonLoading(true);
    const isResendSuccessfully = await resendEmailVerificationLink(email);

    renderToast({
      message: isResendSuccessfully
        ? 'Email has been sent successfully'
        : 'Something went wrong. Please try again later',
      type: isResendSuccessfully ? 'success' : 'error',
    });

    setIsButtonLoading(false);
    setIsResendClicked(true);
  };

  if (!email || !token) {
    return <Redirect to="/404" />;
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header hasBoxShadow>
            <NavigationBar hasFullLogo />
          </Header>
        </div>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <div className={styles['content-container']}>
            {isVerifiedSuccessfully ? (
              <SuccessOrInvalidPage
                message={'Your email has been successfully verified'}
              />
            ) : (
              <SuccessOrInvalidPage
                message={'Your verify email link has expired'}
                isInvalid={true}
                handleResendEmailVerification={handleResendEmailVerification}
                isButtonDisabled={isResendCliked}
                isButtonLoading={isButtonLoading}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default VerifyEmailPage;
