import { Dispatch, FC, useState } from 'react';
import { createPortal } from 'react-dom';
import Popper from '@material-ui/core/Popper';
import { ClickAwayListener } from '@material-ui/core';
import moment from 'moment';
import cx from 'classnames';

import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter_list.svg';
import DayViewNavigation from 'components/DayViewNavigation/DayViewNavigation';
import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';

import styles from './DateSlider.module.scss';
import useIsMobile from 'hooks/useIsMobile';
import FilterList from './FilterList/FilterList';
import { useAppSelector } from 'redux/reduxHooks';

interface DateSliderProps {
  isDayView: boolean;
  setIsDayView: Dispatch<React.SetStateAction<boolean>>;
  isFilterShown: boolean;
  setIsFilterShown: Dispatch<React.SetStateAction<boolean>>;
  date: string;
  setDate: (value: string) => void;
}
const VIEW_OPTIONS = {
  DAY_VIEW: 'Day View',
  MONTH_VIEW: 'Month View',
};

const { DAY_VIEW, MONTH_VIEW } = VIEW_OPTIONS;

const dropdownOptions = [DAY_VIEW, MONTH_VIEW];

const DateSlider: FC<DateSliderProps> = ({
  isDayView,
  setIsDayView,
  date,
  setDate,
  isFilterShown,
  setIsFilterShown,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMobile = useIsMobile();

  const numberOfAppliedFilters = useAppSelector(
    (state) => state.searchResultFilterSlice.numberOfAppliedFilters
  );

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['remove-border']]: isFilterShown,
  });

  const handleOptionClicked = (option: string) => {
    setIsDayView(option === DAY_VIEW ? true : false);
    setAnchorEl(null);
  };

  const isOptionActive = (option: string) => {
    if (isDayView && option === DAY_VIEW) {
      return true;
    } else if (!isDayView && option === MONTH_VIEW) {
      return true;
    }
    return false;
  };

  const headerContainer = document.querySelector('#header');

  return (
    headerContainer &&
    createPortal(
      <>
        <div className={containerClassName}>
          <div className={styles.navigator}>
            {isDayView ? (
              <DayViewNavigation
                date={date}
                onDateUpdate={(date) => {
                  setDate(moment(new Date(date)).format('YYYY-MM-DD'));
                }}
              />
            ) : (
              <MonthViewNavigation date={date} setDate={setDate} />
            )}
          </div>
          <div className={styles.controller}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <div>
                <button
                  className={styles['controller-view']}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <span>
                    {isDayView ? dropdownOptions[0] : dropdownOptions[1]}
                  </span>
                  <ArrowIcon className={styles['controller-view-icon']} />
                </button>
                <Popper
                  placement="bottom"
                  disablePortal={true}
                  anchorEl={anchorEl}
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                    preventOverflow: {
                      enabled: false,
                      boundariesElement: 'scrollParent',
                    },
                    hide: { enabled: false },
                  }}
                  open={Boolean(anchorEl)}
                >
                  <div className={styles['controller-dropdown']}>
                    {dropdownOptions.map((option) => (
                      <button
                        key={option}
                        className={styles['controller-dropdown-item']}
                        onClick={() => handleOptionClicked(option)}
                        data-active={isOptionActive(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </Popper>
              </div>
            </ClickAwayListener>
            <button
              className={styles['controller-view']}
              onClick={() => setIsFilterShown((value) => !value)}
              data-active={numberOfAppliedFilters > 0}
            >
              <FilterIcon className={styles['controller-view-filter-icon']} />
              {isMobile && numberOfAppliedFilters > 0 && (
                <span>{numberOfAppliedFilters}</span>
              )}
              {!isMobile && (
                <span>
                  Filters{' '}
                  {numberOfAppliedFilters > 0
                    ? `(${numberOfAppliedFilters})`
                    : ''}
                </span>
              )}
            </button>
          </div>
        </div>
        {isFilterShown && <FilterList />}
      </>,
      headerContainer
    )
  );
};

export default DateSlider;
