import { FC } from 'react';
import { resetBookingForm, STEPS } from 'redux/bookingFormSlice';
import BookingSummary from '../BookingSummary/BookingSummary';
import Step1 from '../Step1/Step1';
import Step2 from '../Step2/Step2';
import Step3 from '../Step3/Step3';
import { getBookingDate } from '../utils/helperFunction';
import {
  convertTime,
  convertTimeHHmmss,
  MINUTE_OF_A_BLOCK,
} from 'utils/convertMinutesIntoHoursOfDay';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';
import { ChairOptimization } from 'pages/ClinicDetailsPage/ClinicDetailsPage';
import { batch } from 'react-redux';
import { resetSysType } from 'redux/authSlice';
import { useAppDispatch } from 'redux/reduxHooks';

interface BookingStepProps {
  date: string;
  timeBlock: number;
  clinicId: string;
  serviceId: string;
  practitionerId: string;
  operatoryId?: string;
  bookingSummaryInfo: {
    avatar: string;
    title: string;
    serviceTitle: string;
    clinicName: string;
    clinicAddress: string;
    clinicEmail: string;
    clinicPhoneNumber: string;
  };
  dentist: DentistInfo;
  chairOptimizations?: ChairOptimization[];
  isPreferredDoctor?: boolean;
  closeBookingDialog: () => void;
  currentPage: string;
}

const {
  PATIENT_INFO,
  REVIEW,
  FORGET_PASSWORD,
  NEW_PATIENT,
  SIGN_IN,
  SIGN_UP,
  VERIFICATION,
} = STEPS;

const STEP2 = [FORGET_PASSWORD, NEW_PATIENT, SIGN_IN, SIGN_UP, VERIFICATION];

const BookingStep: FC<BookingStepProps> = ({ currentPage, ...props }) => {
  const bookingDate = getBookingDate(props.date);
  const bookingTime = convertTime(props.timeBlock * MINUTE_OF_A_BLOCK);
  const startTime = convertTimeHHmmss(props.timeBlock * MINUTE_OF_A_BLOCK);

  const dispatch = useAppDispatch();

  const onClose = () => {
    batch(() => {
      dispatch(resetBookingForm());
      dispatch(resetSysType());
    });
    props.closeBookingDialog();
  };

  return (
    <>
      {![FORGET_PASSWORD].includes(currentPage) && (
        <BookingSummary
          avatar={props.bookingSummaryInfo.avatar}
          title={props.bookingSummaryInfo.title}
          serviceTitle={props.bookingSummaryInfo.serviceTitle}
          clinicAddress={
            currentPage === REVIEW ? props.bookingSummaryInfo.clinicAddress : ''
          }
          bookingDateTime={`${bookingDate} at ${bookingTime}`}
        />
      )}
      {currentPage === PATIENT_INFO && (
        <Step1
          onBack={onClose}
          clinicId={props.clinicId}
          serviceId={props.serviceId}
          appointmentDate={props.date}
          startTime={startTime}
        />
      )}
      {STEP2.includes(currentPage) && (
        <Step2
          page={currentPage}
          clinicId={props.clinicId}
          serviceId={props.serviceId}
          appointmentDate={props.date}
          startTime={startTime}
        />
      )}
      {currentPage === REVIEW && (
        <Step3
          bookingSummaryInfo={props.bookingSummaryInfo}
          onClose={props.closeBookingDialog}
          clinicId={props.clinicId}
          practitionerId={props.practitionerId}
          date={props.date}
          timeBlock={props.timeBlock}
          operatoryId={props.operatoryId}
          dentist={props.dentist}
          serviceId={props.serviceId}
          chairOptimizations={props.chairOptimizations}
          isPreferredDoctor={props.isPreferredDoctor}
        />
      )}
    </>
  );
};

export default BookingStep;
