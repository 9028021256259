import { FC } from 'react';
import styles from '../../FilterList.module.scss';

const GENDERS = ['male', 'female', 'non-binary'] as const;

interface GenderProps {
  value: ('male' | 'female' | 'non-binary')[];
  setValue: React.Dispatch<
    React.SetStateAction<('male' | 'female' | 'non-binary')[]>
  >;
}

const Gender: FC<GenderProps> = ({ value, setValue }) => {
  return (
    <div className={styles.group}>
      {GENDERS.map((gender) => (
        <button
          data-active={value.includes(gender)}
          key={gender}
          onClick={() =>
            setValue((value) =>
              value.includes(gender)
                ? value.filter((g) => g !== gender)
                : [...value, gender]
            )
          }
        >
          {gender}
        </button>
      ))}
    </div>
  );
};

export default Gender;
