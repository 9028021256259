const trackTiktok = (customScript: string) => {
  const indexOfIdentify = customScript.indexOf('ttq.identify');
  // CONTENT WILL BE THE ACTUAL CODE BY TIKTOK
  const content = customScript.substring(indexOfIdentify);

  // FORCE RUN CONTENT AS STRING TO CODE
  setTimeout(content, 0);
};

export default trackTiktok;
