import React from 'react';

import { ReactComponent as PractitionerIcon } from 'assets/icons/practitioners.svg';

import styles from './Bio.module.scss';

interface IProps {
  description: string;
}

const Bio = ({ description }: IProps) => {
  return (
    <div className={styles['container']}>
      <PractitionerIcon className={styles['practitioner-profile-icon']} />
      <div className={styles['description']}>{description}</div>
    </div>
  );
};

export default Bio;
