import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { isCurrentDate } from 'utils/isCurrentDate';
import { getBufferdTimeString } from 'utils/getBufferdTimeString';
import { calculateBlocks } from 'utils/getTimeBlockFromTImeString';
import { getCurrentTimeString } from 'utils/getCurrentTimeInString';
import { IPractitionerResponseData } from 'interfaces/axiosPractitionerResponseType';
import { IAxiosGetClinicListResponseData } from 'interfaces/axiosGetClinicListResponseType';
import {
  ALLOWED_PRACTITIONER_STUDIES,
  KILOMETER_IN_METER,
} from 'utils/constants/common';
import { IFilterState } from 'interfaces/filterTypes';
import { Specialty } from 'interfaces/specialty';
import { concatFullName } from 'utils/common';

export interface IMapClinicData {
  id: string;
  name: string;
  avatar: string;
  totalScore: number;
  reviewCount: number;
  address: string;
  distance: number;
  city: string;
  state: string;
  phone: string;
  website: string;
  services: { id: string; name: string }[];
  practitionerIds: string[];
  workingHours: {
    day: string;
    time: string;
  }[];
  coordinates: [number, number];
}

export interface IMapClinics {
  ids: string[];
  data: {
    [key: string]: IMapClinicData;
  };
}

export interface IMapPractitioner {
  name: string;
  avatar: string;
  totalScore: number;
  reviewCount: number;
  services: { id: string; name: string; clinicId: string; duration: number }[];
  study: string[];
  specialty: Specialty[];
  id: string;
  practice: string;
  address: string;
  clinic: string;
  clinicId: string;
  email: string;
  phone: string;
  distance: number;
}

interface IMapPractitioners {
  [key: string]: IMapPractitioner;
}

export interface IMapSliceState {
  clinics: IMapClinics;
  practitioners: IMapPractitioners;
  status: 'loading' | 'success' | 'failure';
}

interface Props {
  serviceId: string;
  lat: number;
  lng: number;
  date: string;
}

export const getMapClinicsAndPractitioners = createAsyncThunk(
  'mapSlice/getMapClinicsAndPractitioners',
  async ({ serviceId, lat, lng, date }: Props, { getState }) => {
    const { filterSlice } = getState() as {
      filterSlice: IFilterState;
    };
    const clinics: IMapClinics = {
      ids: [],
      data: {},
    };
    const practitioners: IMapPractitioners = {};
    let timeBlocks = [0];

    const locationTimezone = filterSlice.location.timezone;
    if (isCurrentDate(date, locationTimezone)) {
      const oneHourBufferedCurrentTimeString = getBufferdTimeString(
        getCurrentTimeString(locationTimezone),
        60
      );
      const currentTimeInTimeBlock = calculateBlocks(
        oneHourBufferedCurrentTimeString
      );
      timeBlocks = [currentTimeInTimeBlock];
    }
    const clinicResponse = await axios.get<IAxiosGetClinicListResponseData>(
      '/clinics',
      {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          serviceId: serviceId,
          date: date,
          latitude: lat,
          longitude: lng,
          timeBlocks: timeBlocks,
          distances: [0, 20 * KILOMETER_IN_METER],
        },
      }
    );
    const getClinicsPractitionersPromises: Promise<
      AxiosResponse<{ data: IPractitionerResponseData[] }>
    >[] = [];
    if (clinicResponse.data.data) {
      clinicResponse.data.data.forEach((item) => {
        getClinicsPractitionersPromises.push(
          axios.get<{
            data: IPractitionerResponseData[];
          }>(`/clinics/${item.id}/practitioners`, {
            baseURL: process.env.REACT_APP_AXIOS_BE_URL,
          })
        );
        clinics.ids.push(item.id);
        clinics.data[item.id] = {
          id: item.id,
          name: item.name,
          avatar: item.avatar,
          address: item.address,
          city: item.location.city,
          distance: _.round(item.distance / KILOMETER_IN_METER, 1),
          state: item.location.state,
          services: item.services.map((item) => ({
            id: item.id,
            name: item.name,
          })),
          phone: item.phoneNumber,
          workingHours: item.workingHours,
          reviewCount: item.reviewCount,
          totalScore: item.rating,
          website: item.website,
          practitionerIds: [],
          coordinates: [item.latitude, item.longitude],
        } as IMapClinicData;
      });
      const practitionersResponse = await Promise.all(
        getClinicsPractitionersPromises
      );
      if (practitionersResponse) {
        practitionersResponse.forEach((item) => {
          if (item.data) {
            item.data.data.forEach((practitioner) => {
              clinics.data[practitioner.clinic.id].practitionerIds.push(
                practitioner.id
              );
              practitioners[practitioner.id] = {
                name: concatFullName(
                  practitioner.firstName,
                  practitioner.lastName
                ),
                avatar: practitioner.avatar,
                totalScore: practitioner.rating,
                reviewCount: practitioner.reviewCount,
                services: practitioner.services.map((item) => ({
                  id: item.id,
                  name: item.name,
                  clinicId: item.clinicId,
                  duration: item.duration,
                })),
                study: practitioner.studies
                  ? practitioner.studies
                      .map((item) => item.name)
                      .filter((study) =>
                        ALLOWED_PRACTITIONER_STUDIES.includes(study)
                      )
                  : [],
                specialty:
                  practitioner.specialties &&
                  Array.isArray(practitioner.specialties)
                    ? practitioner.specialties
                    : [],
                id: practitioner.id,
                practice: practitioner.practice,
                address: practitioner.clinic.address,
                clinic: practitioner.clinic.name,
                clinicId: practitioner.clinic.id,
                email: practitioner.clinic.email,
                phone: practitioner.clinic.phoneNumber,
                distance: clinics.data[practitioner.clinic.id].distance,
              };
            });
          }
        });
      }
    }

    return {
      clinics,
      practitioners,
    };
  }
);

const initialState: IMapSliceState = {
  clinics: {
    ids: [],
    data: {},
  },
  practitioners: {},
  status: 'loading',
};

const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getMapClinicsAndPractitioners.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: 'success',
          ...action.payload,
        };
      }
    );
    builder.addCase(getMapClinicsAndPractitioners.pending, (state, action) => {
      return {
        ...initialState,
      };
    });
  },
});

export default mapSlice.reducer;
