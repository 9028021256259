import React, { useRef } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';

import styles from './BookingConfirmation.module.scss';

import Message from '../Message/Message';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface IProps {
  isMessageDisplayed?: boolean;
  isSocialLoggedIn?: boolean;
}

const BookingConfirmation = ({
  isMessageDisplayed,
  isSocialLoggedIn,
}: IProps) => {
  const history = useHistory();

  const containerRef = useRef<HTMLDivElement>(null);
  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  return (
    <div className={containerClassName} ref={containerRef}>
      <div className={styles.wrapper}>
        <ConfirmIcon />
        <div className={styles.title}>Appointment Request Submitted</div>
        <p className={styles.description}>
          Your request was successfully submitted. The practice will get back to
          you shortly to confirm.
        </p>
        {isMessageDisplayed && (
          <Message
            type={isSocialLoggedIn ? 'success' : 'warning'}
            message={
              isSocialLoggedIn
                ? 'Your FirstIn account has been set up successfully.'
                : 'Further action is required for FirstIn account registration. Please click the link in the verification email to complete setting up your account.'
            }
          />
        )}
        {!isLoadedInsideIframe && (
          <DentalButton
            variant="contained"
            className={styles['btn']}
            onClick={() => history.go(0)}
          >
            Done
          </DentalButton>
        )}
      </div>
    </div>
  );
};

export default BookingConfirmation;
