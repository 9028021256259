import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { IPractitioner } from 'interfaces/practitionerTypes';
import { IPractitionerResponseData } from 'interfaces/axiosPractitionerResponseType';
import {
  ALLOWED_PRACTITIONER_STUDIES,
  KILOMETER_IN_METER,
} from 'utils/constants/common';
import {
  MINUTE_OF_A_BLOCK,
  convertTime,
} from 'utils/convertMinutesIntoHoursOfDay';
import { isCurrentDate } from 'utils/isCurrentDate';
import { getCurrentTimeString } from 'utils/getCurrentTimeInString';
import { calculateBlocks } from 'utils/getTimeBlockFromTImeString';
import { getBufferdTimeString } from 'utils/getBufferdTimeString';
import { IFilterState } from 'interfaces/filterTypes';
import { getNextAvailabilityByPractitioners } from 'services/APIs';
import { concatFullName } from 'utils/common';

export interface NextAvailabilityByPractitioner {
  blocks: number[];
  clinicId: string;
  doctorId: string;
  nextAvailabilityDate: string;
  serviceDuration: number;
  serviceId: string;
}

export interface IPractitionerExtraFilters {
  gender: ('male' | 'female')[];
  specialty: string[];
  rating: {
    range: number[];
    includeNonRated: boolean;
  };
  distance: number;
  availability: number[];
  isAppliedClicked: boolean;
}

interface IPractitionerState {
  status: 'loading' | 'success' | 'failure' | null;
  practitioner: IPractitioner[];
  hasMore: boolean | null;
  totalPractitioners: number;
  specialties: string[];
  nextAvailabilities: NextAvailabilityByPractitioner[];
}

export interface IGetPractitionersProps {
  serviceId: string;
  latitude: number;
  longitude: number;
  date: string;
  extraFilters: IPractitionerExtraFilters;
  isShowMore?: boolean;
  page: number;
  sortBy: 'rating' | 'distance';
}

interface IAxiosResponseData {
  data: IPractitionerResponseData[];
  metadata: {
    total: number;
    page: number;
    limit: number;
  };
}

export const getPractitioners = createAsyncThunk(
  'pratitioners/getPractitioners',
  async (
    {
      date,
      serviceId,
      latitude,
      longitude,
      isShowMore,
      extraFilters,
      page,
      sortBy,
    }: IGetPractitionersProps,
    { getState }
  ) => {
    const { practitionerSlice, filterSlice } = getState() as {
      practitionerSlice: IPractitionerState;
      filterSlice: IFilterState;
    };

    let sort: string = '';
    let sortByParam: string = '';
    let distanceRange: number[] = [];
    let timeBlocks = extraFilters.availability;
    switch (sortBy) {
      case 'rating': {
        sort = 'desc,desc';
        sortByParam = 'rating,reviewCount';
        break;
      }
      case 'distance': {
        sort = 'asc,desc,desc';
        sortByParam = 'distance,rating,reviewCount';
        break;
      }
      default:
        break;
    }
    if (extraFilters.distance === 100) {
      distanceRange = [0, 20 * KILOMETER_IN_METER];
    } else {
      distanceRange = [0, (extraFilters.distance / 10) * KILOMETER_IN_METER];
    }

    const locationTimezone = filterSlice.location.timezone;

    if (isCurrentDate(date, locationTimezone)) {
      const oneHourBufferedCurrentTimeString = getBufferdTimeString(
        getCurrentTimeString(locationTimezone),
        60
      );

      const currentTimeInTimeBlock = calculateBlocks(
        oneHourBufferedCurrentTimeString
      );
      if (timeBlocks.length > 0 && timeBlocks[1] < currentTimeInTimeBlock) {
        return {
          practitioner: [],
          hasMore: false,
          totalPractitioners: 0,
        };
      }
      if (timeBlocks.length > 0 && timeBlocks[0] < currentTimeInTimeBlock) {
        timeBlocks = [currentTimeInTimeBlock, timeBlocks[1]];
      }
      if (timeBlocks.length <= 0) {
        timeBlocks = [currentTimeInTimeBlock];
      }
    }

    const response = await axios.get<IAxiosResponseData>('/practitioners', {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      params: {
        sort: sort,
        sortBy: sortByParam,
        limit: 5,
        date: date,
        page: page,
        latitude: latitude,
        longitude: longitude,
        serviceId: serviceId,
        distances: distanceRange,
        timeBlocks: timeBlocks.length > 0 ? timeBlocks : [0],
        rating: [
          extraFilters.rating.range[0] / 20,
          extraFilters.rating.range[1] / 20,
        ],
        includedNonRated: extraFilters.rating.includeNonRated,
        genders: extraFilters.gender,
        specialties: extraFilters.specialty,
      },
    });

    const practitioners: IPractitioner[] = response.data.data.map((item) => {
      const clinic = item.clinic;

      return {
        id: item.id,
        slug: item.slug,
        avatar: item.avatar,
        name: concatFullName(item.firstName, item.lastName),
        practice: item.practice,
        specialty:
          item.specialties && Array.isArray(item.specialties)
            ? item.specialties
            : [],
        description: item.bio,
        workingHours: item.workingHours,
        distance: _.round(clinic.distance / KILOMETER_IN_METER, 1),
        clinic: clinic.name,
        clinicId: clinic.id,
        address: clinic.address,
        study: item.studies
          ? item.studies
              .map((item) => item.name)
              .filter((item) => ALLOWED_PRACTITIONER_STUDIES.includes(item))
          : [],
        email: clinic.email,
        phone: clinic.phoneNumber,
        availableBlocks: clinic.availableBlocks.map((timeBlock) => ({
          value: timeBlock,
          displayString: convertTime(timeBlock * MINUTE_OF_A_BLOCK),
        })),

        totalScore: item.rating,
        reviewCount: item.reviewCount,
        services: item.services.map((item) => ({
          id: item.id,
          name: item.name,
          clinicId: item.clinicId,
          duration: item.duration,
        })),
        firstName: item.firstName,
        specialist: item.specialist,
        title: item.title,
      };
    });

    const newPractitionerList = isShowMore
      ? [...practitionerSlice.practitioner, ...practitioners]
      : practitioners;

    const result: any = {
      practitioner: newPractitionerList,
      hasMore: newPractitionerList.length < response.data.metadata.total,
      totalPractitioners: response.data.metadata.total,
      nextAvailabilities: [],
    };

    const isSomePractitionerNotEmpty = newPractitionerList.some(
      (item) => item.availableBlocks && item.availableBlocks.length > 0
    );

    if (!isSomePractitionerNotEmpty && newPractitionerList.length !== 0) {
      const practitionerIdsWithoutAvailableSlots = newPractitionerList.map(
        (item) => item.id
      );

      const nextAvailabilities = await getNextAvailabilityByPractitioners({
        practitionerIds: practitionerIdsWithoutAvailableSlots,
        date: moment(date).add(1, 'days').format('YYYY-MM-DD'),
        serviceId,
        filter: {
          sort: sort,
          sortBy: sortByParam,
          latitude: latitude,
          longitude: longitude,
          distances: distanceRange,
          timeBlocks:
            extraFilters.availability.length > 0
              ? extraFilters.availability
              : [0],
          rating: [
            extraFilters.rating.range[0] / 20,
            extraFilters.rating.range[1] / 20,
          ],
          includedNonRated: extraFilters.rating.includeNonRated,
          genders: extraFilters.gender,
          specialties: extraFilters.specialty,
        },
      });

      result.nextAvailabilities = nextAvailabilities;
    }

    return result;
  }
);

export const getAllSpecialtyFilters = createAsyncThunk(
  'practitionerSlice/getAllSpecialtyFilters',
  async ({
    service,
    lat,
    lng,
  }: {
    service: string;
    lat: number;
    lng: number;
  }) => {
    try {
      const distances = [0, 20 * KILOMETER_IN_METER];
      const response = await axios.get('/specialties', {
        baseURL: process.env.REACT_APP_AXIOS_BE_URL,
        params: {
          latitude: lat,
          longitude: lng,
          distances: distances,
          serviceId: service,
        },
      });
      if (response.data) {
        return {
          specialties: response.data,
        };
      }
      return {
        specialties: [],
      };
    } catch (error) {}
  }
);

const initialState: IPractitionerState = {
  practitioner: [],
  status: 'loading',
  hasMore: null,
  totalPractitioners: 0,
  specialties: [],
  nextAvailabilities: [],
};

const practitionerSlice = createSlice({
  name: 'pratitioners',
  initialState,
  reducers: {
    clearPractitionerSearchDetails: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPractitioners.fulfilled, (state, action) => {
      return {
        ...state,
        status: 'success',
        ...action.payload,
      };
    });
    builder.addCase(getPractitioners.pending, (state, action) => {
      return {
        ...state,
        status: 'loading',
      };
    });
    builder.addCase(getPractitioners.rejected, (state, action) => {
      return {
        ...state,
        status: 'failure',
      };
    });
    builder.addCase(getAllSpecialtyFilters.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
  },
});

export const { clearPractitionerSearchDetails } = practitionerSlice.actions;
export default practitionerSlice.reducer;
