import React from 'react';
import moment from 'moment';

import { ReactComponent as LeftArrow } from 'assets/icons/left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/right.svg';

import styles from './MonthHeader.module.scss';

interface IProps {
  date: Date;
  changeYear: (year: number) => void;
}

const MonthHeader = (props: IProps) => {
  const { date, changeYear } = props;

  const onClickPreviousYear = () => {
    changeYear(moment(date).clone().subtract(1, 'years').year());
  };

  const onClickNextYear = () => {
    changeYear(moment(date).clone().add(1, 'years').year());
  };

  const isCurrentYear = moment(date).clone().isSame(moment(), 'years');

  return (
    <div className={styles.headerWrapper}>
      <div>{moment(date).format('YYYY')}</div>
      <div className={styles.headerNavigation}>
        <button
          disabled={isCurrentYear}
          className={styles.leftArrowIcon}
          onClick={onClickPreviousYear}
        >
          <LeftArrow />
        </button>
        <button className={styles.rightArrowIcon} onClick={onClickNextYear}>
          <RightArrow />
        </button>
      </div>
    </div>
  );
};

export default MonthHeader;
