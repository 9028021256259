/* eslint-disable react-hooks/exhaustive-deps */
import React, { SVGProps, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import { primaryPurple, deactiveIconColor } from 'utils/theme';
import { ESearchFields } from 'interfaces/filterTypes';

import styles from './SearchField.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { getServicesOptions } from 'redux/searchBarOptionsSlice';
import { PlaceType } from 'components/AutoComplete/LocationAutoComplete';

interface ISearchFieldProps {
  title: ESearchFields;
  placeHolder?: string;
  icon: React.FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  onSelectionChanged: Function;
  inputComponent: React.ElementType;
  error?: boolean;
  isResultPage?: boolean;
  currentDate?: string;
  onClose: () => void;
  isLocationFieldHadValue?: boolean;
}

const SearchField = ({
  title,
  icon: Icon,
  placeHolder,
  onSelectionChanged,
  inputComponent: InputComponent,
  error,
  isResultPage,
  currentDate = '',
  onClose,
  isLocationFieldHadValue,
}: ISearchFieldProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.filterSlice);
  const params = new URLSearchParams(location.search).get(`${title}`);
  const defaultIsActive = () => {
    if (!isResultPage) {
      return title === 'location' ? filters[title].name : filters[title];
    }

    return title === 'location'
      ? new URLSearchParams(location.search).get('lat')
      : params;
  };

  const [isActive, setIsActive] = useState(defaultIsActive);
  const inputWrapper = cx({
    [styles['input-wrapper']]: true,
    [styles['location-input']]: title === 'location',
  });

  const services = useAppSelector(
    (state) => state.searchBarOptionsSlice.service
  );

  const locationFilter = useAppSelector((state) => state.filterSlice.location);

  const options2 =
    title === ESearchFields.service &&
    services.ids.map((item) => ({ title: services.data[item], id: item }));

  useEffect(() => {
    const fetchOptions = async () => {
      if (title === 'service') {
        if (services.ids.length <= 0) {
          dispatch(getServicesOptions());
        }
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    setIsActive(defaultIsActive());
  }, [filters]);

  return (
    <div className={styles.container}>
      <Icon
        className={styles.icon}
        color={isActive ? primaryPurple : deactiveIconColor}
      />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {title}
          {error ? <div className={styles.asterix}>*</div> : null}
        </div>
        <div className={inputWrapper}>
          <InputComponent
            options={options2}
            title={title}
            onSelectionChanged={onSelectionChanged}
            placeHolder={placeHolder}
            isActive={isActive}
            setIsActive={setIsActive}
            isResultPage={isResultPage}
            localStorageDate={currentDate}
            defaultValue={
              {
                place_id: locationFilter.placeId,
                description: locationFilter.name,
              } as PlaceType
            }
            onClose={onClose}
            isLocationFieldHadValue={isLocationFieldHadValue}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchField;
