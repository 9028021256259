import { ThemeProvider } from '@material-ui/core/styles';

import AppRoute from 'routes/AppRoute';
import ToasterWrapper from 'components/ToasterWrapper/ToasterWrapper';
import { theme } from 'utils/theme';

import './App.scss';

// function loadScript(src: string, position: HTMLElement | null, id: string) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

function App() {
  // const loaded = useRef(false);

  // if (!(window as any).google && !loaded.current) {
  //   if (!document.querySelector('#google-maps')) {
  //     loadScript(
  //       `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`,
  //       document.querySelector('head'),
  //       'google-maps'
  //     );
  //   }

  //   loaded.current = true;
  // }
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppRoute />
        <ToasterWrapper />
      </ThemeProvider>
    </div>
  );
}

export default App;
