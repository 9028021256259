import { FC } from 'react';
import { useStyles } from './useStyles';
import { Dialog, DialogProps } from '@material-ui/core';
import styles from './CustomDialog.module.scss';
import DentalButton from 'components/Buttons/DentalButton/DentalButton';
interface CustomDialogProps extends DialogProps {
  isResetBtnDisabled: boolean;
  isAllFilter?: boolean;
  onResetClicked: () => void;
  onApplyClicked: () => void;
}

const CustomDialog: FC<CustomDialogProps> = ({
  children,
  isResetBtnDisabled,
  isAllFilter = false,
  onResetClicked,
  onApplyClicked,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Dialog {...props} className={classes.dialog}>
      {children}
      <div className={styles['action']}>
        <DentalButton
          variant="text"
          className={styles['reset-btn']}
          disabled={isResetBtnDisabled}
          onClick={onResetClicked}
        >
          {isAllFilter ? 'Reset all' : 'Reset'}
        </DentalButton>
        <DentalButton
          variant="contained"
          className={styles['apply-btn']}
          onClick={onApplyClicked}
        >
          Apply
        </DentalButton>
      </div>
    </Dialog>
  );
};

export default CustomDialog;
