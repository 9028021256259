import {
  parsePhoneNumberFromString as parsePhoneNumber,
  AsYouType,
  CountryCode,
  getCountryCallingCode,
} from 'libphonenumber-js';

export const formatPhoneNumber = (phoneInput: string) => {
  const internationalCode = phoneInput.split(' ')[0];
  const rest = phoneInput.split(`${internationalCode} `)[1];
  const result = internationalCode + ' ' + rest.split(' ').join('-');
  return result;
};

export const formatNationalPhoneNumber = (
  fullPhoneNumber: string, // eg: 12133734
  countryCode: CountryCode
) => {
  const phoneNumber = parsePhoneNumber(`+${fullPhoneNumber}`);
  const callingCode = getCountryCallingCode(countryCode as CountryCode);

  if (phoneNumber && callingCode === phoneNumber.countryCallingCode) {
    const formattedPhoneNumber = phoneNumber.formatNational();
    return formattedPhoneNumber;
  } else {
    const asYouType = new AsYouType(countryCode);
    const formattedPhoneNumber = asYouType.input(fullPhoneNumber);
    return formattedPhoneNumber;
  }
};

export const concatCountryCodeAndPhoneNumber = (
  input: string,
  countryCode: string
) => {
  const countryCallingCode = getCountryCallingCode(
    countryCode.toUpperCase() as CountryCode
  );

  const parsedPhoneNumber = parsePhoneNumber(`+${input}`, {
    extract: true,
  });

  if (
    parsedPhoneNumber &&
    countryCallingCode === parsedPhoneNumber.countryCallingCode
  ) {
    return `${parsedPhoneNumber.countryCallingCode}${parsedPhoneNumber.nationalNumber}`;
  }

  return `${countryCallingCode}${input}`;
};

export const formatPhoneNumberWithCountryCode = (
  fullPhoneNumber: string, // eg: 12133734
  countryCode: CountryCode
) => {
  if (!fullPhoneNumber) return '';

  try {
    const phoneNumber = parsePhoneNumber(`+${fullPhoneNumber}`);
    const callingCode = getCountryCallingCode(
      countryCode.toUpperCase() as CountryCode
    );

    if (phoneNumber && callingCode === phoneNumber.countryCallingCode) {
      const formattedPhoneNumber = phoneNumber.formatNational();
      return `${callingCode} ${formattedPhoneNumber}`;
    }

    return fullPhoneNumber;
  } catch (e) {
    return fullPhoneNumber;
  }
};

export const geCountryByPhoneNumber = (
  fullPhoneNumber: string
): CountryCode | string => {
  try {
    const phoneNumber = parsePhoneNumber(`+${fullPhoneNumber}`);
    return phoneNumber?.country?.toLowerCase() || '';
  } catch (e) {
    return '';
  }
};

export const formatFullPhoneNumber = (fullPhoneNumber: string) => {
  // eg: +123456
  const parsedPhoneNumber = parsePhoneNumber(`+${fullPhoneNumber}`);
  return `${
    parsedPhoneNumber?.countryCallingCode
  } ${parsedPhoneNumber?.formatNational()}`;
};

// In case that can not get country code from phone number
export const formatFullPhoneNumberV2 = (fullPhoneNumber: string) => {
  const countryCode = geCountryByPhoneNumber(fullPhoneNumber);

  if (countryCode) {
    return formatPhoneNumberWithCountryCode(
      fullPhoneNumber,
      countryCode as CountryCode
    );
  } else {
    return fullPhoneNumber;
  }
};
