import { Location } from 'history';

export const parseQueryUrl = (location: Location) => {
  const search = location.search;
  const query = new URLSearchParams(search);
  const dateUrl = query.get('date') || new Date().toString();
  const serviceSlugNameUrl = query.get('service') || '';
  const codeUrl = query.get('code');
  const isOpen = query.get('isOpen') ? Boolean(query.get('isOpen')) : null;
  const id = query.get('id') || '';

  return {
    dateUrl,
    serviceSlugNameUrl,
    codeUrl,
    isOpen,
    id,
  };
};
