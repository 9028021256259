import { makeStyles } from '@material-ui/core/styles';
import { MOBILE_SCREEN_WIDTH } from 'utils/screens';
import { primaryPurple, fontSize, colors } from 'utils/theme';

export default makeStyles({
  customPopper: {
    zIndex: 1300,
    // Fix popper auto detect height
    height: '0px',
    '& .MuiAutocomplete-paper': {
      borderRadius: '2rem',
      border: `0.05rem solid ${colors.lightGrey}`,
      width: '45rem',
      maxHeight: '36.8rem',
      overflow: 'auto',
      fontFamily: 'Nexa',
      fontSize: fontSize.s,
      lineHeight: '2.4rem',
      [`@media (max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        // Based on width of hz bar + padding + 2 pixel border
        width: 'calc(100% + 7.1rem)',
      },
      '& [aria-selected="true"]': {
        backgroundColor: colors.white,
        color: primaryPurple,
      },
      '& .MuiAutocomplete-option:hover': {
        backgroundColor: colors.white,
        color: primaryPurple,
      },
      '& .MuiAutocomplete-option > div': {
        width: '100%',
      },
      '& .MuiAutocomplete-listbox': {
        overflow: 'visible',
        maxHeight: '28rem',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: colors.lightMidGrey,
        borderRadius: '2rem',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
  },
});
